import styled, { css } from 'styled-components';

interface KPICardStyledProps {
  full?: boolean;
  padding: string;
}

export const Container = styled.div<KPICardStyledProps>`
  margin: 0;
  width: 100%;

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};

  > div > div {
    display: flex;
    align-items: center;
  }
`;

export const KPICard = styled.div<KPICardStyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: fit-content;
  min-height: 64px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.gray.white};
  gap: 0.5rem;

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}
`;

export const Results = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
`;
