import React, { ReactElement, useState } from 'react';
import { DeleteDialogType, IconNames } from './type';
import { Box, Dialog, Typography } from 'mino-ui';
import { makeDeleteCustomField } from 'main/factories/usecases/custom_field/remote-delete-custom-fields-factory';

const DeleteDialog = (props: DeleteDialogType): ReactElement => {
  const { isOpen, setOpen, callback, id } = props;

  const [loading, setLoading] = useState(false);

  async function DeleteSharedLink(id: string) {
    try {
      setLoading(true);
      await makeDeleteCustomField(id).execute();
      callback();
      setOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog
      title="Excluir campo personalizado"
      open={isOpen}
      onOpenChange={(state) => {
        !state;
        setOpen(state);
      }}
      onSubmit={() => id && DeleteSharedLink(id)}
      submitVariant="danger"
      submitText="Excluir"
      submitIconName={IconNames.Delete20Regular}
      submitIconColor={'white' as any}
      loading={loading}
      content={
        <Box gap={24} flexDirection="column">
          <Typography noWrap variant="body" color="gray">
            Você tem certeza que deseja excluir este campo personalizado?
            <br />
            <br />
            <strong>
              Esse campo pode conter registros no sistema. Esses registros serão
              excluídos permanentemente.
            </strong>
          </Typography>
        </Box>
      }
    />
  );
};

export default DeleteDialog;
