import styled from 'styled-components';

export const Container = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  height: 100% !important;

  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.gray[30]};
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray[50]};
    border-radius: 20px;
  }
`;

export const PipelineWrapper = styled.div`
  display: flex;
  position: relative;
  padding-bottom: 8px;
  max-width: 100%;
  height: 100% !important;
  gap: 8px;
`;
