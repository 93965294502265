import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Button, Icon, Input, Select, Typography } from 'mino-ui';
import * as S from '../styles';
import { useServices } from 'main/hooks/useServices';
import { ISale } from 'domain/entity/ISale';
import { maskReal } from 'main/helpers/format-to-real';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { CreateSaleResponse } from 'domain/usecases/sales/create-sale-use-case';

type DealContactProps = {
  setDealSales: (sales: CreateSaleResponse[]) => void;
  setDealValues: (value: number) => void;
  initialSales?: ISale[];
  onRemoveSale?(id: string): void;
};

export const DealSales = (props: DealContactProps): ReactElement => {
  const { setDealSales, setDealValues, initialSales, onRemoveSale } = props;

  const [searchService, setSearchService] = useState<string>('');

  const [sales, setSales] = useState<ISale[]>(
    initialSales ?? [
      {
        id: Math.floor(10000000 + Math.random() * 90000000).toString(),
        discount: 0,
        quantity: 1,
        service_id: '0',
        product_id: '0',
        total: 0,
        service: {
          id: '0',
          name: '',
          price: 0,
        },
      },
    ]
  );

  const [hasSales, setHasSales] = useState(initialSales ? true : false);

  const { services: products, fetchServices } = useServices();

  function calculateTotalWithDiscountApplied(
    price: number,
    quantity: number,
    discount: number
  ) {
    const total = price * quantity;
    const discountApplied = total * (discount / 100);
    return total - discountApplied;
  }

  function handleSaleChanges(field: string, value: string, index: number) {
    const newSales = sales.map((sale, i) => {
      if (i === index) {
        return {
          ...sale,
          [field]: value,
          total: calculateTotalWithDiscountApplied(
            sale.service.price || 0,
            field === 'quantity' ? Number(value) : sale.quantity,
            field === 'discount' ? Number(value) : sale.discount
          ),
        };
      }
      return sale;
    });

    setSales(newSales);
  }

  function handleProductChange(productId: string, index: number) {
    const product = products.find((p) => p.id === productId);

    if (!product) return;

    if (product) {
      const newSales = sales.map((sale, i) => {
        if (i === index) {
          return {
            ...sale,
            service_id: product.id,
            service: {
              id: product.id,
              name: product.name,
              price: product.price || 0,
            },
            total: calculateTotalWithDiscountApplied(
              product.price || 0,
              sale.quantity,
              sale.discount
            ),
          };
        }
        return sale;
      });

      setSales(newSales);
    }
  }

  useDebouncedEffect(
    () => {
      fetchServices(searchService);
    },
    [searchService],
    300
  );

  useEffect(() => {
    setDealSales(
      sales.map((s) => ({
        id: s.id,
        discount: s.discount,
        product_id: s.product_id,
        quantity: s.quantity,
        service_id: s.service_id,
        total: s.total,
      }))
    );
  }, [sales, setDealSales]);

  if (!hasSales) {
    return (
      <Box flexDirection="column" gap={16}>
        <S.Separator />

        <Input
          defaultValue="R$ 0,00"
          mask={(v) => {
            const value = v?.replace(/\D/g, '');
            setDealValues(Number(value) / 100);
            return maskReal(v || '0');
          }}
          name="value"
          label="Valor"
        />

        <Button
          onClick={() => setHasSales(true)}
          variant="outline"
          size="small"
          text="Adicionar Produto"
          icon="AddCircle20Regular"
        />
      </Box>
    );
  }

  return (
    <Box flexDirection="column" gap={16}>
      <S.Separator />
      <Box gap={8}>
        <Icon iconName="Box24Regular" color="blue" />
        <Typography variant="h3" fontSize={16} noWrap>
          Produtos ou Serviços
        </Typography>
      </Box>

      {sales.map((sale, index) => {
        const isSaleFromInitialSales = initialSales
          ?.map((s) => s.id)
          .includes(sale.id);

        return (
          <Box key={sale.id} flexDirection="column" gap={16}>
            <S.ProductWrap>
              <Select
                disabled={isSaleFromInitialSales}
                value={
                  isSaleFromInitialSales
                    ? {
                        label: sale.service.name,
                        value: sale.service_id,
                      }
                    : undefined
                }
                onInputChange={(v) => setSearchService(v)}
                name="service_id"
                fullWidth
                placeholder="Selecione"
                label="Produto"
                options={products.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                required
                onChange={(v) =>
                  v?.value && handleProductChange(v.value, index)
                }
              />
              <Input
                full
                label="Preço"
                placeholder="R$0,00"
                name="service_price"
                value={sale?.service.price?.toString()}
                disabled
              />
              <Input
                full
                label="Qnt."
                placeholder="Ex:1"
                name="quantity"
                value={sale.quantity?.toString()}
                type="number"
                onChange={(e) =>
                  handleSaleChanges('quantity', e.target.value, index)
                }
              />
              <Input
                onChange={(e) =>
                  handleSaleChanges('discount', e.target.value, index)
                }
                full
                value={sale.discount?.toString()}
                label="Desconto %"
                placeholder="Ex:10%"
                name="discount"
              />

              <Box
                style={{
                  position: 'relative',
                }}
              >
                <Input
                  full
                  label="Total"
                  placeholder="R$0,00"
                  name="total"
                  disabled
                  mask={(v) => maskReal(v || '0')}
                  value={sale.total?.toString()}
                />
                <S.RemoveButton
                  onClick={() => {
                    const newSales = sales.filter((s) => s.id !== sale.id);
                    setSales(newSales);
                    onRemoveSale?.(sale.id);
                  }}
                  type="button"
                >
                  <Icon iconName="Delete20Regular" />
                </S.RemoveButton>
              </Box>
            </S.ProductWrap>
          </Box>
        );
      })}

      <Box justifyContent="space-between">
        <Button
          text="Adicionar"
          icon="AddCircle20Regular"
          iconColor="dark"
          size="small"
          type="button"
          variant="ghost"
          onClick={() =>
            setSales([
              ...sales,
              {
                id: Math.floor(10000000 + Math.random() * 90000000).toString(),
                discount: 0,
                quantity: 1,
                service_id: '0',
                product_id: '0',
                total: 0,
                service: {
                  id: '0',
                  name: '',
                  price: 0,
                },
              },
            ])
          }
        />

        <Box
          style={{
            paddingRight: 16,
          }}
        >
          <Typography variant="body" color="gray" fontSize={12}>
            Total: R$ {sales.reduce((acc, sale) => acc + sale.total, 0)}
          </Typography>
        </Box>
      </Box>

      <S.Separator />
    </Box>
  );
};
