import { RemoteSaveTag } from 'data/usecases/tags/remote-save-tag';
import { SaveTagUseCase } from 'domain/usecases/tag/save-tag-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeSaveTagUseCase = (id?: string): SaveTagUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteSaveTag(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/tags${id ? `/${id}` : ''}`,
    toastify
  );
};
