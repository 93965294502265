import { RemoteListChannels } from 'data/usecases/channel/remote-list-channels';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeListChannelUseCase = (): RemoteListChannels => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteListChannels(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/channels`,
    toastify
  );
};
