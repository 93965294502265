import { RemoteGetReportLostReasonsDealsUseCase } from 'data/usecases/deal/remote-get-lost-deals-report-use-case';
import { GetReportLostReasonsDealsUseCase } from 'domain/usecases/deal/report-lost-deals.use-case';
import { AxiosProvider } from 'infra/http';
import { useToken } from 'main/hooks/usetoken';

export const makeGetLostReasonsReport =
  (): GetReportLostReasonsDealsUseCase => {
    const { token = '', tenant = '' } = useToken();
    const axios = new AxiosProvider({ tenant, token });

    return new RemoteGetReportLostReasonsDealsUseCase(
      axios,
      `${process.env.REACT_APP_API_BASE_URL}/reports/lost-reasons`
    );
  };
