import React, { ReactElement } from 'react';
import { getColorByName } from './actions';
import * as S from './styles';
import * as T from './types';

const Avatar = (props: T.AvatarProps): ReactElement => {
  const { image, fallback, name, width, variant } = props;

  function convertNameToInitials(name: string): string {
    const nameArray = name.split(' ');
    const initials = nameArray.map((name) => name[0]).join('');

    const initialsConverted =
      initials.length > 2 ? initials.substring(0, 2) : initials;

    return initialsConverted.toLocaleUpperCase();
  }

  const styles = {
    width: width,
    variant,
  };

  return (
    <S.Container>
      <S.Avatar {...styles} variant={variant || getColorByName(name)}>
        {image && <S.AvatarImage src={image} alt={name} />}
        <S.AvatarFallback>
          {(fallback && convertNameToInitials(fallback)) ||
            convertNameToInitials(name)}
        </S.AvatarFallback>
      </S.Avatar>
    </S.Container>
  );
};

export default Avatar;
