import React, { ReactElement, useContext } from 'react';
import { Box } from 'mino-ui';
import {
  ConfigResourcesPermissions,
  hasConfigPermissions,
} from 'presentation/hooks/usePermissions';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import CustomTabs from './components/customTabs/component';

export type TriggerProps = {
  id: string;
  text: string;
  icon: string;
};

export type ConfigTypeProps = {
  id: string;
  text: string;
  icon: string;
  triggers: TriggerProps[];
};

type ConfigPages =
  | 'general'
  | 'comercial'
  | 'admin'
  | 'integrations'
  | 'integrations/:id';

const ConfigPage = (): ReactElement => {
  const { session } = useContext(SessionContext);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const defaultActive = urlParams.get('active');

  const UsersConfigs: TriggerProps[] = [];
  const ComercialConfigsTriggers: TriggerProps[] = [];
  const IntegrationsConfigsTriggers: TriggerProps[] = [];

  const configs: ConfigTypeProps[] = [
    {
      id: 'general',
      text: 'Configurações de Usuários',
      icon: 'PeopleSettings',
      triggers: UsersConfigs,
    },
    {
      id: 'comercial',
      text: 'Configurações Comerciais',
      icon: 'ShoppingBag20Regular',
      triggers: ComercialConfigsTriggers,
    },
    {
      id: 'integrations',
      text: 'Integrações',
      icon: 'Connected20Regular',
      triggers: IntegrationsConfigsTriggers,
    },
  ];

  if (
    hasConfigPermissions(session, [ConfigResourcesPermissions.USERS], 'every')
  ) {
    UsersConfigs.push({
      id: 'users',
      text: 'Usuários',
      icon: 'Person20Regular',
    });
  }

  if (
    hasConfigPermissions(session, [ConfigResourcesPermissions.GROUPS], 'every')
  ) {
    UsersConfigs.push({
      id: 'groups',
      text: 'Grupos de usuários',
      icon: 'People',
    });
  }

  if (
    hasConfigPermissions(session, [ConfigResourcesPermissions.TEAMS], 'every')
  ) {
    UsersConfigs.push({
      id: 'teams',
      text: 'Times e equipes',
      icon: 'PeopleTeam',
    });
  }

  if (
    hasConfigPermissions(
      session,
      [ConfigResourcesPermissions.SERVICES],
      'every'
    )
  ) {
    ComercialConfigsTriggers.push({
      id: 'services',
      text: 'Produtos e Serviços',
      icon: 'Box20Regular',
    });
  }

  if (
    hasConfigPermissions(session, [ConfigResourcesPermissions.FUNNELS], 'every')
  ) {
    ComercialConfigsTriggers.push({
      id: 'funnels',
      text: 'Funís de venda',
      icon: 'ColumnTriple20Regular',
    });
  }

  if (
    hasConfigPermissions(
      session,
      [ConfigResourcesPermissions.CHANNELS],
      'every'
    )
  ) {
    ComercialConfigsTriggers.push({
      id: 'channels',
      text: 'Canais',
      icon: 'Channel20Regular',
    });
  }

  if (session && session.is_admin) {
    ComercialConfigsTriggers.push({
      icon: 'TextBulletListAdd20Regular',
      id: 'fields',
      text: 'Campos personalizados',
    });
  }

  if (
    hasConfigPermissions(session, [ConfigResourcesPermissions.TAGS], 'every')
  ) {
    ComercialConfigsTriggers.push({
      id: 'tags',
      text: 'Tags',
      icon: 'Tag',
    });
  }

  if (
    hasConfigPermissions(session, [ConfigResourcesPermissions.LOSSES], 'every')
  ) {
    ComercialConfigsTriggers.push({
      id: 'losses',
      text: 'Motivos de perda',
      icon: 'Thumb',
    });
  }
  if (
    hasConfigPermissions(session, [ConfigResourcesPermissions.LINKS], 'every')
  ) {
    ComercialConfigsTriggers.push({
      id: 'sharedLinks',
      text: 'Links úteis',
      icon: 'Pin20Regular',
    });
  }

  if (
    hasConfigPermissions(session, [ConfigResourcesPermissions.PAGES], 'every')
  ) {
    {
      ComercialConfigsTriggers.push({
        id: 'ImportBatch',
        text: 'Importações',
        icon: 'Archive20Regular',
      });
    }
  }
  if (
    hasConfigPermissions(
      session,
      [ConfigResourcesPermissions.INTEGRATIONS],
      'every'
    )
  ) {
    IntegrationsConfigsTriggers.push({
      id: 'appStore',
      text: 'Loja de aplicativos',
      icon: 'Apps20Regular',
    });
  }

  return (
    <Box boxMain gap={16} alignItems="center">
      <Box style={{ maxWidth: 1200, width: '100%', display: 'block' }}>
        {configs.length > 0 ? (
          <CustomTabs
            defaultActive={
              defaultActive ? (defaultActive as ConfigPages) : 'general'
            }
            configs={configs}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default ConfigPage;
