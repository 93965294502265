import React, { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { makeResetPassword } from 'main/factories/usecases/sessions/reset-password';
import { Box, Button, Divider, Input, Spacer, Typography } from 'mino-ui';
import * as S from './styles';

type MailSendForm = {
  password: string;
  passwordConfirmation: string;
  token: string;
};

export const ResetPasswordForm = (): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm<MailSendForm>();

  async function submitForm(data: MailSendForm) {
    if (data.password !== data.passwordConfirmation) {
      setError('passwordConfirmation', {
        type: 'manual',
        message: 'As senhas não conferem',
      });
      return;
    }

    try {
      setLoading(true);
      const response = await makeResetPassword().execute({
        password: data.password,
        token: data.token,
      });

      if (response.success) {
        setSuccess(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');

  useEffect(() => {
    if (token) {
      setValue('token', token);
    }
  }, [token, setValue]);

  return (
    <form
      autoComplete="off"
      style={{ width: '100%' }}
      onSubmit={handleSubmit(submitForm)}
    >
      <Typography
        noWrap={true}
        alignMobile={success ? 'center' : 'left'}
        variant="h2"
        align={success ? 'center' : 'left'}
        weight="bold"
        color="dark"
        fill
      >
        Redefinição de senha
      </Typography>
      {success ? (
        <Box
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap={24}
        >
          <Box width="80%">
            <Typography
              variant="h4"
              color="dark"
              fontSize={14}
              weight="regular"
              noWrap
              align="center"
            >
              Senha alterada com sucesso! Faça login para continuar.
            </Typography>
          </Box>

          <Button
            variant="primary"
            text="Voltar para o login"
            onClick={() => (window.location.href = '/login')}
          />
        </Box>
      ) : (
        <>
          <Typography
            variant="h4"
            color="dark"
            fontSize={14}
            weight="regular"
            noWrap
          >
            Preencha os campos abaixo para redefinir sua senha.
          </Typography>

          <Spacer height={12} heightMobile={24} />

          <Input
            label="Nova senha"
            name="password"
            autoComplete="off"
            register={register}
            errors={errors}
            type="password"
            placeholder="Digite sua nova senha"
            validations={{
              required: true,
              minLength: 6,
            }}
          />

          <Spacer height={16} />

          <Input
            autoComplete="off"
            label="Repita a nova senha"
            name="passwordConfirmation"
            type="password"
            register={register}
            errors={errors}
            placeholder="Digite sua nova senha"
            validations={{
              required: true,
            }}
          />

          <Spacer height={32} />

          <S.WrapperButton>
            <Box gap={16}>
              <Button
                disabled={loading}
                type="submit"
                full
                text={'Confirmar'}
              />
              <Button
                onClick={() => (window.location.href = '/login')}
                variant="ghost"
                type="button"
                full
                text={'Voltar'}
              />
            </Box>
          </S.WrapperButton>
        </>
      )}
    </form>
  );
};
