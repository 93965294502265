import { ActivityTypeResponseDTO } from 'domain/dtos/activity/activity-dto';

export type ActivityTypes =
  | 'call'
  | 'message'
  | 'email'
  | 'meeting'
  | 'note'
  | 'purpose'
  | 'system'
  | 'sell'
  | 'loss';

export function translateActivityType(
  type: ActivityTypeResponseDTO
): ActivityTypes {
  switch (type) {
    case 'Anotação':
      return 'note';
    case 'E-mail':
      return 'email';
    case 'Mensagem':
      return 'message';
    case 'Proposta':
      return 'purpose';
    case 'Reunião':
      return 'meeting';
    case 'Ligação':
      return 'call';
    case 'Sell':
      return 'sell';
    case 'Loss':
      return 'loss';
    default:
      return 'system';
  }
}
