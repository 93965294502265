import React, { ReactElement, useContext } from 'react';
import { Calendar, Card } from 'mino-ui';
import { IActivity } from 'domain/entity/IActivity';
import ActivityListContext from '../../context/ActivityListContext';

interface CalendarViewProps {
  activities: IActivity[];
}

const CalendarView = (props: CalendarViewProps): ReactElement => {
  const { activities } = props;

  const { setIsDrawerOpen, setInitialStartDate, setInitialEndDate } =
    useContext(ActivityListContext);

  return (
    <Card>
      <Calendar
        handleSelect={(arg) => {
          setIsDrawerOpen(true);
          setInitialStartDate?.(arg.start);
          setInitialEndDate?.(arg.end);
        }}
        events={activities.map((ac) => ({
          start: ac.start,
          end: ac.end,
          allDay: ac.allDay,
          title: ac.title,
        }))}
      />
    </Card>
  );
};

export default CalendarView;
