import styled from 'styled-components';

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 72px;
  background-color: ${({ theme }) => theme.colors.gray.white};
  border: solid 1px ${({ theme }) => theme.colors.gray[30]};
  width: 100%;
  padding: 1.25rem 1.5rem;
  position: relative;
`;

export const ToolbarLeftContent = styled.div`
  position: absolute;
  left: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;
export const ToolbarRightContent = styled.div`
  position: absolute;
  right: 1.5rem;
`;

export const Separator = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.gray[30]};
`;

export const GridArea = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`;
export const MainContent = styled.div``;
export const RightContent = styled.div``;
export const TaskCardsWrap = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;
export const ListCardsWrap = styled.div`
  display: grid;
  gap: 1rem;
`;
