import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import {
  Box,
  Button,
  Input,
  Table,
  Typography,
  Dialog,
  Avatar,
  ToggleGroup,
  PopOver,
  Dropdown,
} from 'mino-ui';
import AddIcon from './icons/add.svg';
import { IUser } from 'domain/entity/IUser';
import {
  makeDeleteUserUseCase,
  makeLoadUsersUseCase,
} from 'main/factories/usecases';
import Pagination from 'react-js-pagination';
import { ManageDrawer } from './ManageDrawer';
import { SaveUserRequestPayload } from 'domain/usecases/user/save-user-use-case';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import { UserTabContext } from '../context/UserTabContext';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import { copyContent } from 'main/helpers';
import { makeToastify } from 'main/factories/notification';

const UsersConfig = (): ReactElement => {
  const {
    session: { id: currentUserId },
  } = useContext(SessionContext);

  const [manageOpen, setManageOpen] = useState(false);
  const [updatePayload, setUpdatePayload] = useState<SaveUserRequestPayload>();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [users, setUsers] = useState<IUser[]>([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [invited, setInvited] = useState<'1' | '0'>('1');

  const groupListArray = useMemo(() => {
    const array = [
      {
        label: 'Editar',
        value: 'edit',
        icon: 'Pen20Regular',
      },
      {
        label: 'Excluir',
        value: 'delete',
        icon: 'Delete20Regular',
      },
    ];

    if (invited === '0') {
      return [
        ...array,
        {
          icon: 'Pin20Regular',
          label: 'Copiar link',
          value: 'link',
        },
      ];
    }

    return array;
  }, [invited]);

  async function fetchUsers(search: string, page: number, invited: '1' | '0') {
    try {
      const {
        data,
        page: activePage,
        total,
      } = await makeLoadUsersUseCase().execute({
        name: search,
        invited: invited,
        page: page,
      });

      setUsers(data);
      setTotal(total);
      setPage(activePage);
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteUser() {
    if (deleteUserId === currentUserId) return;

    try {
      setDeleteLoading(true);
      await makeDeleteUserUseCase(deleteUserId).execute();

      fetchUsers('', 1, '1');
      setSearch('');
      setInvited('1');
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteLoading(false);
      setDeleteUserId('');
      pressEscKey();
    }
  }

  useEffect(() => {
    fetchUsers(search, page, invited);
  }, [search, page, invited]);

  function handlePageChange(pageNumber: number) {
    setPage(pageNumber);
  }

  function handleSearchChange(e: ChangeEvent<HTMLInputElement>) {
    setPage(1);
    setSearch(e.target.value);
  }
  return (
    <UserTabContext.Provider
      value={{
        invited,
        setInvited,
      }}
    >
      <Box flexDirection="column" gap={16}>
        <Box gap={16}>
          <Button
            onClick={() => {
              setManageOpen(true);
            }}
            icon={AddIcon}
            text="Adicionar"
          />

          <Input
            onChange={handleSearchChange}
            placeholder="Buscar Usuário"
            name="search"
          />
          <ToggleGroup
            type="single"
            value={invited}
            onValueChange={(value) => {
              if (value == '1') {
                setInvited('1');
              } else {
                setInvited('0');
              }
            }}
            items={[
              { value: '1', label: 'Aceitos', icon: 'MailCheckmark20Regular' },
              { value: '0', label: 'Pendentes', icon: 'MailClock20Regular' },
            ]}
          />
        </Box>
        <Table
          hasOutline
          columns={[
            {
              field: 'name',
              headerName: 'Name',
            },
            {
              field: 'email',
              headerName: 'E-mail',
              hiddenOnMobile: true,
            },
            {
              field: 'leads',
              headerName: 'Recebe Leads',
              hiddenOnMobile: true,
            },
            {
              field: 'team',
              headerName: 'Equipe',
              hiddenOnMobile: true,
            },
            {
              field: 'actions',
              headerName: '',
            },
          ]}
          rows={users.map((u) => ({
            id: u.id,
            name: (
              <Box gap={8} alignItems="center">
                <Avatar name={u.username} image={u.avatar} />
                <Typography variant="body" fontSize={14} color="gray">
                  {u.username}
                </Typography>
              </Box>
            ),
            email: u.email,
            leads: u.automation ? 'Sim' : 'Não',
            team: u.team?.name,
            actions: (
              <Dropdown
                align="end"
                trigger="action-button"
                items={groupListArray}
                onChange={(value) => {
                  if (value === 'edit') {
                    setUpdatePayload(u);
                    setManageOpen(true);
                  }
                  if (value === 'delete') {
                    setDeleteDialogOpen(true);
                    setDeleteUserId(u.id);
                  }
                  if (value === 'link') {
                    if (u.invite_link) {
                      copyContent(u.invite_link);

                      makeToastify().show('Link copiado com sucesso!', {
                        type: 'success',
                      });
                    }
                  }
                }}
              />
            ),
          }))}
        />

        <Box justifyContent="center">
          <Pagination
            activePage={page}
            className="pagination"
            itemClass="page-item"
            linkClass="page-link"
            activeClass="active-page"
            itemsCountPerPage={10}
            totalItemsCount={total}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
          />
        </Box>

        <ManageDrawer
          updatePayload={updatePayload}
          setUpdatePayload={setUpdatePayload}
          isOpen={manageOpen}
          setIsOpen={setManageOpen}
          callback={() => {
            fetchUsers('', 1, '1');
          }}
        />

        <Dialog
          loading={deleteLoading}
          open={deleteDialogOpen}
          onOpenChange={(st) => {
            setDeleteDialogOpen(st);

            if (!st) {
              setDeleteUserId('');
            }
          }}
          title="Excluir Usuário"
          trigger={<></>}
          onSubmit={() => {
            deleteUser();
          }}
          submitText="Excluir"
          content={
            <Box gap={16}>
              <Typography variant="body" fontSize={14} color="gray">
                Tem certeza que deseja excluir este usuário?
              </Typography>
            </Box>
          }
        />
      </Box>
    </UserTabContext.Provider>
  );
};

export default UsersConfig;
