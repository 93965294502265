import React from 'react';
import { Route, Routes as ReactRoutes, Navigate } from 'react-router-dom';
import { ensureAuthentication } from '../middlewares/EnsureAuthentication';
import routes from './routes';

const Routes: React.FC = () => {
  const isAuth = ensureAuthentication();

  return (
    <ReactRoutes>
      {routes.map((route) => (
        <Route
          key={route.name}
          path={route.path}
          element={
            <>
              {isAuth && route.restricted && <Navigate replace to="/" />}
              {!isAuth && route.private ? (
                <Navigate to="/login" />
              ) : (
                <route.component name={route.name} />
              )}
            </>
          }
        />
      ))}
    </ReactRoutes>
  );
};

export default Routes;
