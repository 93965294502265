import React, { ReactElement, useRef } from 'react';
import * as T from './types';
import * as S from './styles';

const PopOver = (props: T.PopOverProps): ReactElement => {
  const {
    trigger,
    content,
    defaultOpen,
    side,
    align,
    preventActiveTriggerStyles,
    fullWidthContent,
    noWrap = false,
    hasFocus,
    open,
    onOpenChange,
    foceMount,
    autoContentFocus = true,
    closeOnInteraction = false,
  } = props;

  const closeRef = useRef<HTMLButtonElement>(null);

  return (
    <S.Container
      open={open}
      modal={false}
      onOpenChange={onOpenChange}
      defaultOpen={defaultOpen}
    >
      <S.Trigger
        hasFocus={hasFocus}
        autoFocus={false}
        className={preventActiveTriggerStyles ? 'no-style' : ''}
      >
        {trigger}
      </S.Trigger>

      <S.Portal>
        <S.Content
          onClick={() => {
            if (closeOnInteraction) {
              closeRef.current?.click();
            }
          }}
          forceMount={foceMount || undefined}
          onOpenAutoFocus={(e) => !autoContentFocus && e.preventDefault()}
          onCloseAutoFocus={(e) => !autoContentFocus && e.preventDefault()}
          sideOffset={4}
          fullWidthContent={fullWidthContent}
          side={side}
          align={align}
          noWrap={noWrap}
        >
          <S.Close ref={closeRef} />

          {content}
        </S.Content>
      </S.Portal>
    </S.Container>
  );
};

export default PopOver;
