import styled from 'styled-components';

export const ActivitiesWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray.white};
  padding: 1rem;
  border-radius: 10px;
`;

export const ActivitiesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

export const ActivitiesHeaderRightWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: fit-content;
  gap: 8px;
`;

export const ActivitiesCount = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const LoadButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const ActivityCardDate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    gap: 0.5rem;
  }

  > span:first-child {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray[50]};
  }

  > span:nth-child(2) {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 33px;
    color: ${({ theme }) => theme.colors.gray[600]};
  }

  > span:last-child {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray[600]};
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray[30]};
`;

export const DailyContent = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 2rem 0;
  gap: 1.25rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
`;
