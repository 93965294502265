import styled from 'styled-components';

export const EditDrawerForm = styled.div`
  display: grid;
  gap: 1rem;
`;
export const InputsWrap = styled.div`
  display: flex;
  gap: 0.5rem;
`;
export const ButtonsWrap = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
`;
