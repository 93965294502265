import React from 'react';
import * as T from './types';
import * as S from './styles';
import { ReactSVG } from 'react-svg';
import Tag20Filled from './icon/Tag20Filled.svg';
import Dismiss from './icon/Dismiss.svg';

const Tag = (props: T.TagsProps) => {
  const { name, color, onClose } = props;

  return (
    <S.Container color={color}>
      <ReactSVG src={Tag20Filled} />
      <span>{name}</span>
      {onClose ? (
        <S.ButtonWrap onClick={onClose}>
          <ReactSVG src={Dismiss} />
        </S.ButtonWrap>
      ) : null}
    </S.Container>
  );
};

export default Tag;
