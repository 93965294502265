import React, { useContext, useEffect, useState } from 'react';
import { Box, Dialog, Select, Typography } from 'mino-ui';
import { useForm } from 'react-hook-form';
import { useUsers } from 'main/hooks/useUsers';
import { makeBulkActionsCompanies } from 'main/factories/usecases/company/bulk-actions-companies-factory';
import { BulkActionsCompaniesRequest } from 'domain/usecases/company/bulk-actions-companies-use-case';
import CompanyContext from '../../contexts/CompanyContext';

interface BulkOwnerProps {
  ids: string[];
}

export enum IconNames {
  Save20Regular = 'Save20Regular',
}
export enum IconColors {
  white = 'white',
}

const BulkOwnerCompanies = (props: BulkOwnerProps) => {
  const { ids } = props;
  const { bulkCompaniesOwner, setBulkCompaniesOwner } =
    useContext(CompanyContext);
  const { users } = useUsers();
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, setValue, reset } =
    useForm<BulkActionsCompaniesRequest>();

  async function bulkActionsCompanies(data: BulkActionsCompaniesRequest) {
    try {
      setLoading(true);
      await makeBulkActionsCompanies().execute(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setBulkCompaniesOwner(false);
    }
  }

  useEffect(() => {
    if (ids) {
      setValue('ids', ids);
    }
  }, [ids, setValue]);

  return (
    <Dialog
      title="Mudança de Responsável"
      open={bulkCompaniesOwner}
      onOpenChange={() => {
        setBulkCompaniesOwner(false);
        reset();
      }}
      onSubmit={handleSubmit(bulkActionsCompanies)}
      submitIconName={IconNames.Save20Regular}
      submitIconColor={IconColors.white}
      loading={loading}
      content={
        <Box flexDirection="column" gap={24}>
          <Typography color="primary" variant="body" fontSize={14}>
            {ids.length} selecionados
          </Typography>
          <form onSubmit={handleSubmit(bulkActionsCompanies)}>
            <Select
              fullWidth
              required
              name="user_id"
              control={control}
              placeholder="Selecione o dono"
              label="Responsável"
              options={users.map((u) => ({
                label: u.username,
                value: u.id,
              }))}
            />
            <Select
              fullWidth
              name="changeOwner"
              control={control}
              placeholder="Selecione o dono"
              label="Alterar dados relacionados"
              defaultValue={'false'}
              options={[
                {
                  label: 'Sim',
                  value: 'true',
                },
                {
                  label: 'Não',
                  value: 'false',
                },
              ]}
            />
          </form>
          <input type="submit" hidden />
        </Box>
      }
    />
  );
};

export default BulkOwnerCompanies;
