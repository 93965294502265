import styled, { css } from 'styled-components';

export interface TitleProps {
  result?: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;

export const GridArea = styled.div`
  width: 100%;
  height: 57px;
  display: grid;
  grid-template-columns: calc(70% - 0.5rem) calc(30% - 0.5rem);
  gap: 1rem;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 960px) {
    grid-template-columns: calc(60% - 0.5rem) calc(40% - 0.5rem);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 100%;
  }
`;
export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Toolbar = styled.div<TitleProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.colors.gray.white};
  width: 100%;
  border: solid 1px ${({ theme }) => theme.colors.gray[30]};
  gap: 8px;
  overflow: hidden;
  height: 78px;

  ${({ result }) =>
    result == false &&
    css`
      border: solid 1.5px ${({ theme }) => theme.colors.system.error};
    `}

  ${({ result }) =>
    result == true &&
    css`
      border: solid 1.5px ${({ theme }) => theme.colors.system.success};
    `}
`;

export const ToolbarLeftContent = styled.div`
  position: absolute;
  left: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const ToolbarRightContent = styled.div`
  position: absolute;
  right: 1.5rem;
  display: flex;
  gap: 16px;
`;

export const ArrayInfoWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const TitlePrice = styled.div`
  gap: 6px;
`;

export const TitleIcons = styled.div`
  padding-left: 1rem;
  gap: 16px;
`;

export const IconWrap = styled.div<TitleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.blue[100]};

  > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
  }

  ${({ result }) =>
    result == true &&
    css`
      background-color ${({ theme }) => theme.colors.system.success};
    `}

  ${({ result }) =>
    result == false &&
    css`
      background-color ${({ theme }) => theme.colors.system.error};
    `}
`;

export const FunnelSwitcher = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.gray.white};
  height: 100%;
  border-radius: 8px;
  justify-content: center;
`;

export const DropdownTriggerWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 55px;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.gray.white};

  > div > button {
    background-color: ${({ theme }) => theme.colors.gray.white};
  }
  & button:hover {
    background-color: ${({ theme }) => theme.colors.gray.white};
  }
`;

export const DropdownWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-top: 0.5rem;
`;

export const ActionButtonWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
`;

export const EditButtonWrap = styled.div`
  cursor: pointer;
  & svg {
    path,
    circle {
      stroke: ${({ theme }) => theme.colors.gray[200]};
    }
  }
`;

export const DeleteButtonWrap = styled.div`
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: calc(100% - 2rem);
    background: ${({ theme }) => theme.colors.gray[30]};
    height: 1px;
  }
`;

export const SalesTrigger = styled.button`
  align-items: center;
  width: fit-content;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  gap: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  line-height: 1.25rem;
  border: 1px solid ${({ theme }) => theme.colors.gray[30]};

  :hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 0;

    & p {
      display: none;
    }
  }
`;

export const SalesContent = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-height: 300px;
  overflow-y: auto;
  max-width: 700px;
  width: 100%;
  border-radius: 0.25rem;
`;
