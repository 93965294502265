import styled from 'styled-components';
import Background from 'presentation/assets/images/background.png';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray.white};
`;

export const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
  background-color: ${({ theme }) => theme.colors.blue.primary};
  background-image: url(${Background});

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 900px) {
    > div > div > svg {
      width: 80%;
      display: block;
      margin: auto;
    }
  }

  > span {
    position: absolute;
    bottom: 2.813rem;
    font-size: 10px;
    color: ${({ theme }) => theme.colors.gray[50]};
  }
`;

export const WrapperDesktopLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding-top: 76px;
  padding-left: 92px;
  & svg {
    width: 140px;
  }
`;

export const WrapperText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
`;

export const WrapperForm = styled.div`
  width: 100%;
  overflow-y: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 122px;
  padding-right: 122px;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (max-width: 1160px) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media (max-width: 768px) {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: auto;
    padding: 0px 22px;
    width: 100%;
    border-radius: 0;
    padding-top: 76px;
    padding-bottom: 76px;
    background-color: ${({ theme }) => theme.colors.gray.white};
  }
`;

export const WrapperBreadCrumb = styled.div``;

export const StyledForm = styled.div`
  transition: all 0.3s ease-in-out;
  max-width: 493px;
  width: 100%;

  @media (max-width: 768px) {
    max-width: unset;
  }
`;

export const LogoMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    padding-bottom: 90px;
  }

  & svg {
    width: 140px;
  }
`;

export const WrapperSmallText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 32px;
  line-height: 150%;
  & a {
    font-weight: bold;
  }
`;

export const ForgotPassText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.gray.black};
`;

export const Title = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.blue.primary};
  text-align: center;
  @media (max-width: 768px) {
    color: ${({ theme }) => theme.colors.gray.white};
  }
`;

export const Highlight = styled.span`
  color: ${({ theme }) => theme.colors.blue.primary};
  > a {
    color: ${({ theme }) => theme.colors.blue.primary};
  }
`;

export const SmallText = styled.p`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.gray.black};
  opacity: 0.5;
  margin-left: 23px;
  margin-top: 10px;
  > svg {
    margin-right: 5px;
  }
`;
