import React, { ReactElement } from 'react';
import * as T from './types';
import * as S from './styles';
import { ReactSVG } from 'react-svg';
import Typography from '../Typography';
import Skeleton from '../Skeleton';

const KPICard = (props: T.KPICardProps): ReactElement => {
  const {
    title,
    icon,
    value,
    full = false,
    padding = '1rem',
    titleColorStyle,
    secondValue,
    loading,
  } = props;

  const styles = {
    full,
    padding,
    paddingvalue: icon ? '5px' : '10px',
  };

  return (
    <S.Container {...styles}>
      <S.KPICard {...styles}>
        <S.Title>
          {icon && <ReactSVG src={icon} />}

          <Typography
            lineHeight={22}
            variant="body"
            weight="bold"
            color={titleColorStyle}
          >
            {title}
          </Typography>
        </S.Title>

        <S.Results>
          {loading ? (
            <Skeleton
              height={38}
              lines={[
                {
                  widthPercent: 30,
                  height: 38,
                },
              ]}
            />
          ) : (
            <Typography variant="h2" weight="bold">
              {value}
            </Typography>
          )}

          {secondValue && (
            <>
              {loading ? (
                <Skeleton
                  alignItems="flex-end"
                  height={38}
                  lines={[
                    {
                      widthPercent: 20,
                      height: 38,
                    },
                  ]}
                />
              ) : (
                <Typography
                  fill
                  align="right"
                  variant="body"
                  color="gray"
                  fontSize={18}
                  weight="bold"
                >
                  {secondValue}
                </Typography>
              )}
            </>
          )}
        </S.Results>
      </S.KPICard>
    </S.Container>
  );
};

export default KPICard;
