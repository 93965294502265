import React, { useContext, useEffect, useState } from 'react';
import { Box, Datepicker, Dialog, Typography } from 'mino-ui';
import { useForm } from 'react-hook-form';
import DealContext from 'presentation/pages/Deal/contexts/DealContext';
import { makeBulkActionsDeals } from 'main/factories/usecases/deal/bulk-actions-deals-factory';
import { BulkActionsDealsRequest } from 'domain/usecases/deal/bulk-actions-deals-use-case';
import EarningsIcon from './icons/earnings.gif';

interface BulkOwnerProps {
  ids: string[];
}

export enum IconNames {
  ThumbLike20Regular = 'ThumbLike20Regular',
}
export enum IconColors {
  white = 'white',
}

const BulkWon = (props: BulkOwnerProps) => {
  const { ids } = props;
  const { bulkWon, setBulkWon, fetchDeals } = useContext(DealContext);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, setValue, control, reset } =
    useForm<BulkActionsDealsRequest>();

  async function bulkActionsDeals(data: BulkActionsDealsRequest) {
    try {
      setLoading(true);
      await makeBulkActionsDeals().execute(data);
    } catch (error) {
      console.log(error);
    } finally {
      fetchDeals();
      reset();
      setLoading(false);
      setBulkWon(false);
    }
  }

  useEffect(() => {
    if (ids) {
      setValue('ids', ids);
    }
    setValue('result', 1);
  }, [ids, setValue]);

  return (
    <Dialog
      title="Marcar como Ganho"
      open={bulkWon}
      onOpenChange={() => {
        setBulkWon(false);
        reset();
      }}
      onSubmit={handleSubmit(bulkActionsDeals)}
      submitIconName={IconNames.ThumbLike20Regular}
      submitIconColor={IconColors.white}
      submitText="Confirmar"
      loading={loading}
      content={
        <>
          <form onSubmit={handleSubmit(bulkActionsDeals)}>
            <Box flexDirection="column" gap={24} alignItems="center">
              <Typography color="primary" variant="body" fontSize={14}>
                {ids.length} selecionados
              </Typography>
              <Datepicker
                fullWidth
                time
                required
                placeholder="Selecione a data da venda"
                name="date_sale"
                label="Data da venda"
                control={control}
              />
              <input name="result" value={1} hidden />
              <input type="submit" hidden />

              <img src={EarningsIcon} width="400" />
            </Box>
          </form>
        </>
      }
    />
  );
};

export default BulkWon;
