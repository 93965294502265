import { makeLocalStorageAdapter } from '../factories/cache';

export const ensureAuthentication = (): boolean => {
  const storage = makeLocalStorageAdapter();
  const accessToken = storage.getItem('token');

  if (accessToken) {
    return true;
  }

  return false;
};
