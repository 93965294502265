import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { CompanyRequestParams } from 'domain/usecases/company/list-companies-use-case';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import { ListCompanyUseCase } from 'domain/usecases/company/list-companies-use-case';
import { useToken } from 'main/hooks/usetoken';
import { CompanyResponseDTO } from 'domain/dtos/company/company-response-dto';

export class RemoteListCompanies implements ListCompanyUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(params: CompanyRequestParams): Promise<CompanyResponseDTO> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Tenant: tenant,
      },
      params: params,
    });

    const { data } = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return {
          total: response.body.total,
          perPage: response.body.perPage,
          lastPage: response.body.lastPage,
          page: response.body.page,
          data: data.map((company) => ({
            id: company.id,
            name: company.name,
            phone: company.phone,
            email: company.email,
            cnpj: company.cnpj,
            site: company.site,
            state: company.state,
            tags: company.tags,
            description: company.description,
            address: company.address,
            created_at: company.created_at,
          })),
        };

      case HttpStatusCode.unauthorized:
        this.toastService.show('Unauthorized', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');
      default:
        this.toastService.show('Unexpected error', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
