interface DescriptionProps {
  date: Date;
  type: 'sell' | 'loss';
  loss?: string[];
}

export function renderLostActivityDescription(props: DescriptionProps): string {
  const { date, type, loss } = props;

  if (type === 'sell') {
    return `Parabens! Essa negócio foi concluído ${new Date(
      date
    ).toLocaleDateString('pt-BR', {
      day: 'numeric',
      weekday: 'long',
      month: 'long',
      year: 'numeric',
    })}. Você pode reabrir este negócio clicando no botão de Reabrir Negócio.`;
  } else {
    return `Infelizmente esse negócio foi perdido ${new Date(
      date
    ).toLocaleDateString('pt-BR', {
      day: 'numeric',
      weekday: 'long',
      month: 'long',
      year: 'numeric',
    })}. O motivo da perda foi: ${loss}. Você pode reabrir este negócio clicando no botão de Reabrir Negócio.`;
  }
}
