import { RemoteSaveUser } from 'data/usecases/user/remote-save-user';
import { SaveUserUseCase } from 'domain/usecases/user/save-user-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeSaveUserUseCase = (id?: string): SaveUserUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteSaveUser(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/users${id ? `/${id}` : ''}`,
    toastify
  );
};
