import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import * as S from './styles';
import * as T from './types';
import {
  ModivLogoOnly,
  ModivLogoDarkTextRigth,
} from 'components/common/Icon/assets';
import ModivFooter from './icons/modivFooter.svg';
import { ReactSVG } from 'react-svg';
import {
  PanelLeftExpand20Regular,
  PanelLeftContract20Regular,
} from '@fluentui/react-icons';

import { Box, Icon, Dropdown } from 'components/';
import { GroupListItem } from 'components/common/Dropdown/types';

const SideBar = (props: T.SideBarProps): ReactElement => {
  const {
    onCollapsed,
    matchRoute,
    bodyLinks,
    navigator,
    footerLinks,
    externalNavigator,
    position,
    sharedLinks,
  } = props;

  const sharedLinksList: GroupListItem[] = useMemo(() => {
    const sharedLinksArray: GroupListItem[] = [];

    if (sharedLinks) {
      sharedLinks.map((el) => {
        sharedLinksArray.push({
          label: el.shared_link_name,
          value: el.url,
          icon: 'Link20Regular',
          iconColor: 'blue',
        });
      });
    }
    return sharedLinksArray;
  }, [sharedLinks]);

  const openCache = localStorage.getItem('sidebar');
  const defaultOpened = openCache
    ? openCache === 'open'
      ? true
      : false
    : true;

  const [isOpen, setIsOpen] = useState<boolean>(defaultOpened);

  useEffect(() => {
    if (isOpen) localStorage.setItem('sidebar', 'open');
    if (!isOpen && window.innerWidth > 960)
      localStorage.setItem('sidebar', 'closed');

    onCollapsed && onCollapsed(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 960) {
        setIsOpen(false);
      } else {
        setIsOpen(defaultOpened);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpen, defaultOpened]);
  return (
    <S.Container isOpen={isOpen} position={position}>
      <S.Top isOpen={isOpen}>
        <S.Toggle isOpen={isOpen}>
          {isOpen ? (
            <PanelLeftContract20Regular onClick={() => setIsOpen(!isOpen)} />
          ) : (
            <PanelLeftExpand20Regular
              onClick={() => {
                if (window.innerWidth > 960) {
                  setIsOpen(!isOpen);
                }
              }}
            />
          )}
        </S.Toggle>
        <S.LogoWrapper isOpen={isOpen}>
          <S.Logo isOpen={isOpen}>
            <ReactSVG
              src={isOpen ? ModivLogoDarkTextRigth : ModivLogoOnly}
              onClick={() => navigator && navigator('/')}
            />
          </S.Logo>
        </S.LogoWrapper>

        <S.BodyContent>
          <S.Links>
            {bodyLinks?.map(
              (link) =>
                !link?.hidden && (
                  <S.Link
                    key={link.label}
                    active={
                      link.route
                        ? matchRoute && matchRoute(link.route + '/*')
                        : false
                    }
                  >
                    <S.ButtonLink
                      onClick={() => navigator && navigator(link.navTo)}
                    >
                      <Icon iconName={link.icon} />
                      {isOpen ? link.label : ''}
                    </S.ButtonLink>
                  </S.Link>
                )
            )}
          </S.Links>
        </S.BodyContent>
      </S.Top>

      <S.Bottom>
        <S.FooterLinks isOpen={isOpen}>
          {sharedLinks && sharedLinks.length > 0 && (
            <S.SharedLinks>
              <S.Link>
                <Dropdown
                  align="start"
                  side="right"
                  trigger={
                    <S.ButtonLink>
                      <Icon iconName="Pin20Regular" />
                      {isOpen ? 'Links úteis' : ''}
                    </S.ButtonLink>
                  }
                  maxHeight={200}
                  items={sharedLinksList}
                  onChange={(value) => {
                    if (value !== '') {
                      window.open(`https://${value}`, '_blank');
                    }
                  }}
                />
              </S.Link>
            </S.SharedLinks>
          )}

          {footerLinks?.map(
            (link) =>
              !link.hidden && (
                <S.Link
                  key={link.label}
                  active={
                    link.route
                      ? matchRoute &&
                        !link.external &&
                        matchRoute(link.route + '/*')
                      : false
                  }
                >
                  <S.ButtonLink
                    onClick={() =>
                      link.external
                        ? externalNavigator && externalNavigator(link.navTo)
                        : navigator && navigator(link.navTo)
                    }
                  >
                    <Icon iconName={link.icon} />
                    {isOpen ? link.label : ''}
                  </S.ButtonLink>
                </S.Link>
              )
          )}
        </S.FooterLinks>

        <S.Info isOpen={isOpen}>
          <Box justifyContent="center" alignItems="center" gap={4}>
            <S.Logo footer isOpen={isOpen}>
              <ReactSVG src={ModivFooter} onClick={() => undefined} />
            </S.Logo>

            <S.InfoText isOpen={isOpen}>Modiv CRM - Versão 1.0.0.1</S.InfoText>
          </Box>
        </S.Info>
      </S.Bottom>
    </S.Container>
  );
};

export default SideBar;
