import React, { ReactElement } from 'react';
import * as T from './types';
import * as S from './styles';
import { ReactComponent as Bar } from './icons/breadcrumb-bar.svg';

const BreadCrumb = (props: T.BreadCrumbProps): ReactElement => {
  const { items } = props;

  return (
    <S.Container>
      <S.BreadCrumb>
        {items.map((item, index) => (
          <S.Item active={item.active} key={item.title}>
            <S.Link onClick={item.action}>
              <S.Title>{item.title}</S.Title>
            </S.Link>
            {index < items.length - 1 && <Bar />}
          </S.Item>
        ))}
      </S.BreadCrumb>
    </S.Container>
  );
};

export default BreadCrumb;
