import styled, { css } from 'styled-components';

export const Container = styled.div<{ fullWidth?: boolean }>`
  max-width: 311px;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      max-width: unset;
    `}

  > p {
    margin-top: 0.5rem;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${({ theme }) => theme.colors.system.error};
  }
`;

export const ReactSelectCustomStyles = {
  container: (base): any => ({
    ...base,
    miHeight: '35px',
    margin: 0,
    padding: 0,
    fontSize: '14px',
  }),
  input: (base: any): any => ({
    ...base,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '19px',
    margin: 0,
    height: 35,
    color: '#212121',
    padding: '8px 4px',
    borderRadius: '8px',
  }),
  placeholder: (base): any => ({
    ...base,
    padding: 0,
    color: '#bfbfbf',
  }),
  valueContainer: (base): any => ({
    ...base,
    padding: 0,
    borderRadius: '8px',
  }),
  control: (base, { isFocused }): any => ({
    ...base,
    fontSize: '0.75rem',
    padding: '0px 8px',
    minHeight: 35,
    borderRadius: '8px',
    borderColor: '#F5F5F5',
    background: isFocused ? '#e6eefa' : '#F5F5F5',
    color: '#2e2e2e',
    boxShadow: 'none',
    ':hover': {
      borderColor: '#6b9be1',
    },
  }),
  indicatorSeparator: (base): any => ({
    ...base,
    display: 'none',
  }),
  option: (base, { isFocused }): any => ({
    ...base,
    fontSize: '0.75rem',
    backgroundColor: isFocused ? '#0052cc' : '#F5F5F5',
    color: isFocused ? '#FFFFFF' : '#212121',
  }),
  menuPortal: (base): any => ({
    ...base,
    zIndex: 9999,
  }),
};
