import { RemoteListContacts } from 'data/usecases';
import { ListContactUseCase } from 'domain/usecases/contact/list-contact-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeListContactUseCase = (): ListContactUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteListContacts(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/contacts?=my`,
    toastify
  );
};
