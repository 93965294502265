import { RemoteBulkActionsDeals } from 'data/usecases/deal/remote-bulk-actions-deas';
import { BulkActionsDealsUseCase } from 'domain/usecases/deal/bulk-actions-deals-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeBulkActionsDeals = (): BulkActionsDealsUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteBulkActionsDeals(
    `${process.env.REACT_APP_API_BASE_URL}/bulk-deals`,
    axios,
    toastify
  );
};
