import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { PaginateDTO } from 'domain/dtos/paginate-dto';
import { IContact } from 'domain/entity/IContact';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  ListContactUseCase,
  RequestParamsListContacts,
} from 'domain/usecases/contact/list-contact-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteListContacts implements ListContactUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(
    params: RequestParamsListContacts
  ): Promise<PaginateDTO<IContact>> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Tenant: tenant,
      },
      params: params,
    });

    const { data } = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return {
          lastPage: response.body.lastPage,
          page: response.body.page,
          perPage: response.body.perPage,
          total: response.body.total,
          data: data.map((contact: IContact) => ({
            id: contact.id,
            name: contact.name,
            email: contact.email,
            phone: contact.phone,
            company: contact.company,
            tags: contact.tags,
          })),
        };

      case HttpStatusCode.unauthorized:
        this.toastService.show('Unauthorized', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');
      default:
        this.toastService.show('Unexpected error', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
