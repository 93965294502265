import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Box, Drawer, Icon, Input, Label, Select, Tooltip } from 'mino-ui';
import { useGroups } from 'main/hooks/useGroups';
import { makeSaveUserUseCase } from 'main/factories/usecases/user/save-users-factory';
import { SaveUserRequestPayload } from 'domain/usecases/user/save-user-use-case';
import NewUser from './icons/newUser.svg';
import { useForm } from 'react-hook-form';
import { useTeams } from 'main/hooks/useTeams';
import { UserTabContext } from '../context/UserTabContext';
import * as S from './styles';

interface ManageDrawerProps {
  callback: () => void;
  updatePayload?: SaveUserRequestPayload;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setUpdatePayload: (value?: SaveUserRequestPayload) => void;
}

export const ManageDrawer = (props: ManageDrawerProps): ReactElement => {
  const { callback, isOpen, setIsOpen, updatePayload, setUpdatePayload } =
    props;

  const { setInvited } = useContext(UserTabContext);

  const updateMode = updatePayload !== undefined ? true : false;
  const [loading, setLoading] = useState(false);

  const { groups } = useGroups();
  const { teams } = useTeams();

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm<SaveUserRequestPayload>({
    defaultValues: {
      active: true,
      automation: false,
      is_supervisor: false,
    },
  });

  async function saveUser(data: SaveUserRequestPayload) {
    try {
      setLoading(true);

      await makeSaveUserUseCase(
        updateMode ? updatePayload?.id : undefined
      ).execute(data, updateMode ? 'update' : 'create');
      reset();
      callback();
      setUpdatePayload(undefined);
      setIsOpen(false);
      setInvited('1');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (updatePayload) {
      setValue('username', updatePayload.username);
      setValue('email', updatePayload.email);
      setValue('group_id', updatePayload.group_id);
      setValue('team_id', updatePayload.team_id);
      setValue('automation', updatePayload.automation);
      setValue('active', updatePayload.active);
      setValue('ramal', updatePayload.ramal);
      setValue('is_supervisor', updatePayload.is_supervisor);
    }
  }, [updatePayload]);

  function clearForm() {
    setValue('username', '');
    setValue('email', '');
    setValue('group_id', '');
    setValue('team_id', '');
    setValue('automation', false);
    setValue('active', true);
    setValue('ramal', '');
    setValue('is_supervisor', false);
  }

  return (
    <Drawer
      width={300}
      isOpen={isOpen}
      onClose={() => {
        reset();
        setIsOpen(false);
        setUpdatePayload(undefined);
      }}
      title={updateMode ? 'Atualizar Usuário' : 'Novo usuário'}
      icon={NewUser}
      submitText={'Salvar'}
      onSubmit={handleSubmit(saveUser)}
      secondarySubmitAction={() => {
        reset();
        setIsOpen(false);
        setUpdatePayload(undefined);
      }}
      secondarySubmitText="Cancelar"
      loadingSubmit={loading}
    >
      <form autoComplete="off" onSubmit={handleSubmit(saveUser)}>
        <Box gap={8} flexDirection="column">
          <Select
            name="active"
            label="Ativo  "
            control={control}
            defaultValue={
              updatePayload?.active === undefined
                ? '1'
                : updatePayload?.active == true
                ? '1'
                : '0'
            }
            options={[
              {
                value: '1',
                label: 'Sim',
              },
              {
                value: '0',
                label: 'Não',
              },
            ]}
            placeholder="Selecione"
            fullWidth
            required
          />

          <Input
            autoComplete="off"
            register={register}
            errors={errors}
            label="Nome"
            placeholder="Ex: João Carlos"
            name="username"
            validations={{
              required: true,
              minLength: 3,
            }}
          />
          <Input
            autoComplete="off"
            register={register}
            errors={errors}
            label="E-mail"
            placeholder="Ex: example@admin.com"
            name="email"
            validations={{
              pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
              required: true,
            }}
          />

          {updatePayload?.id ? (
            <Input
              autoComplete="off"
              register={register}
              errors={errors}
              label="Senha"
              type="password"
              name="password"
              validations={{
                minLength: 6,
              }}
            />
          ) : null}

          <Input
            autoComplete="off"
            register={register}
            errors={errors}
            label="Ramal"
            placeholder="Ex: 400"
            name="ramal"
          />

          <Select
            control={control}
            options={groups.map((g) => ({
              value: g.id,
              label: g.name,
            }))}
            placeholder="Selecione o grupo"
            fullWidth
            label="Grupo de usuário"
            required
            name="group_id"
          />

          <Box flexDirection="column">
            <S.SelectLabelWrap>
              <Label text="Supervisor" removeMargin />
              <Tooltip
                content="O supervisor estará restrito à ver usuários somente da equipe que ele participa"
                trigger={
                  <div>
                    <Icon iconName="QuestionCircle20Regular" />
                  </div>
                }
                align="center"
                side="top"
              />
            </S.SelectLabelWrap>
            <Select
              control={control}
              options={[
                {
                  value: '1',
                  label: 'Sim',
                },
                {
                  value: '0',
                  label: 'Não',
                },
              ]}
              placeholder="Selecione"
              fullWidth
              required
              name="is_supervisor"
              defaultValue={
                updatePayload?.is_supervisor === undefined
                  ? ''
                  : updatePayload?.is_supervisor == true
                  ? '1'
                  : '0'
              }
            />
          </Box>

          <Select
            control={control}
            defaultValue={updatePayload?.automation == true ? '1' : '0'}
            options={[
              {
                value: '1',
                label: 'Sim',
              },
              {
                value: '0',
                label: 'Não',
              },
            ]}
            placeholder="Selecione"
            fullWidth
            name="automation"
            label="Recebe Leads?"
          />

          <Select
            control={control}
            name="team_id"
            options={teams.map((t) => ({
              value: t.id,
              label: t.name,
            }))}
            placeholder="Selecione"
            fullWidth
            label="Equipe"
          />

          <input hidden type="submit" />
        </Box>
      </form>
    </Drawer>
  );
};
