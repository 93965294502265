import styled from 'styled-components';

export const DrawerForm = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
`;
export const WrapTrigger = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
export const TriggerText = styled.span`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.blue.primary};
`;
