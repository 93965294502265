import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  ForgotPasswordResonseDTO,
  ForgotPasswordUseCase,
} from 'domain/usecases/session/forgot-password';

export class RemoteForgotPasswordUseCase implements ForgotPasswordUseCase {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient,
    private readonly toastService: ToastService
  ) {}

  async execute(email: string): Promise<ForgotPasswordResonseDTO> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: {
        email,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        this.toastService.show('Verifique sua caixa de email para continuar!', {
          type: 'success',
        });

        return {
          message: httpResponse.body.message,
          success: httpResponse.body.success,
        };

      case HttpStatusCode.noContent:
        this.toastService.show('Verifique sua caixa de email para continuar!', {
          type: 'success',
        });

        return {
          message: httpResponse.body.message,
          success: httpResponse.body.success,
        };
      case HttpStatusCode.unauthorized:
        this.toastService.show('Email não encontrado!', {
          type: 'error',
        });
        throw new NotAuthorizedError('Email não encontrado!');
      default:
        this.toastService.show('Erro inesperado!', {
          type: 'error',
        });
        throw new UnexpectedError('Erro inesperado!');
    }
  }
}
