import React, { useEffect, useState } from 'react';
import { DealResponseDTO } from 'domain/dtos/deal/deal-response-dto';
import { FunnelResponse } from 'domain/usecases/funnel/list-funnels-use-case';
import DealPage from 'presentation/pages/Deal/list';
import { makeListDealsUseCase, makeListFunnelsUseCase } from '../usecases';

export const makeDealPage = (): React.ReactElement => {
  const [deals, setDeals] = useState<DealResponseDTO>();
  const [loading, setLoading] = useState(false);
  const [funnels, setFunnels] = useState<FunnelResponse[]>([]);

  async function fetchFunnels(): Promise<void> {
    try {
      setLoading(true);
      const funnels = await makeListFunnelsUseCase().execute();
      setFunnels(funnels);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchDeals(): Promise<void> {
    const filters = localStorage.getItem('filter');

    try {
      setLoading(true);
      const response = await makeListDealsUseCase().execute({
        ...JSON.parse(filters as any),
      });
      setDeals(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchFunnels();
  }, []);

  return (
    <DealPage
      fetchFunnels={fetchFunnels}
      fetchDeals={fetchDeals}
      deals={deals?.data || []}
      funnels={funnels}
      loading={loading}
      activePage={deals?.page || 1}
      lastPage={deals?.lastPage || 1}
      totalDeals={deals?.total || 0}
    />
  );
};
