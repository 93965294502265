import React, { ReactElement, useEffect, useState } from 'react';
import { useCompanies } from 'main/hooks/useCompanies';
import { useContacts } from 'main/hooks/useContacts';
import { Box, Icon, Input, Select, Typography } from 'mino-ui';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { IContact } from 'domain/entity/IContact';
import {
  makeRemoteSaveContact,
  makeSaveCompanyUseCase,
} from 'main/factories/usecases';
import { makeGetSingleContactUseCase } from 'main/factories/usecases/contact/get-contact-factory';

type DealContactProps = {
  setContact: (contact: any) => void;
};

export const DealContact = (props: DealContactProps): ReactElement => {
  const { setContact } = props;
  const [searchContact, setSearchContact] = useState('');
  const [searchCompany, setSearchCompany] = useState('');

  const { contacts, fetchContacts, loading: loadingContacts } = useContacts();

  const [newContact, setNewContact] = useState<IContact>();
  const [newCompany, setNewCompany] = useState<IContact>();

  const [existingContact, setExistingContact] = useState<boolean>(false);
  const [existingCompany, setExistingCompany] = useState<boolean>(false);

  const {
    companies,
    fetchCompanies,
    loading: loadingCompanies,
  } = useCompanies();

  useDebouncedEffect(
    () => {
      fetchContacts(searchContact);
    },
    [searchContact],
    500
  );

  useDebouncedEffect(
    () => {
      fetchCompanies(searchCompany);
    },
    [searchCompany],
    500
  );

  async function quickCreateContact(value: string) {
    try {
      const result = await makeRemoteSaveContact().execute({
        name: value,
      });

      setExistingContact(false);
      setExistingCompany(false);
      setNewContact({
        id: result.id,
        name: result.name,
      });
      setNewCompany(undefined);
    } catch (error) {
      console.error(error);
    }
  }

  async function quickCreateCompany(value: string) {
    try {
      const result = await makeSaveCompanyUseCase().execute({
        name: value,
      });

      setExistingCompany(false);
      setNewCompany({
        id: result.id,
        name: result.name,
      });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (newContact) {
      setContact(newContact);
    }
  }, [newContact, setContact]);

  useEffect(() => {
    if (newCompany?.id) {
      setContact({
        ...newContact,
        company_id: newCompany.id,
      });
    }
  }, [newCompany, newContact, setContact]);

  async function handleExistingContactSelect(label: string, value: string) {
    try {
      const contact = await makeGetSingleContactUseCase(value).execute();

      setNewContact({
        id: contact.id,
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
        company_id: contact.company_id,
      });

      setExistingContact(true);

      if (contact.company_id && contact.company) {
        setExistingCompany(true);
        setNewCompany({
          id: contact.company_id,
          name: contact.company.name,
        });
      } else {
        setExistingCompany(true);
        setNewCompany(undefined);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Box
      flexDirection="column"
      gap={16}
      style={{
        padding: '16px 0',
      }}
    >
      <Box gap={8}>
        <Icon iconName="LinkSquare24Regular" color="blue" />
        <Typography variant="h3" fontSize={16} noWrap>
          Vincular Contato/Empresa
        </Typography>
      </Box>

      <Select
        label="Contato"
        creatable
        onCreateOption={quickCreateContact}
        value={
          newContact
            ? {
                value: newContact?.id,
                label: newContact?.name,
              }
            : null
        }
        isLoading={loadingContacts}
        name="contact"
        onInputChange={(value) => setSearchContact(value)}
        placeholder="Selecione ou crie um contato"
        onChange={(value) => {
          if (value) {
            handleExistingContactSelect(value.label || '', value.value);
            setNewContact({
              id: value.value,
              name: value.label || '',
              email: '',
              phone: '',
              company_id: '',
            });
          }
        }}
        options={contacts.map((c) => ({
          label: c.name,
          value: c.id,
        }))}
      />

      {newContact ? (
        <>
          <Select
            creatable={true}
            name="company"
            onInputChange={(value) => setSearchCompany(value)}
            isLoading={loadingCompanies}
            onCreateOption={quickCreateCompany}
            placeholder="Selecione a empresa"
            label="Empresa"
            disabled={existingCompany}
            value={
              newCompany
                ? {
                    value: newCompany?.id,
                    label: newCompany?.name,
                  }
                : null
            }
            onChange={(value) => {
              if (value)
                setNewCompany({
                  id: value.value,
                  name: value.label || '',
                });
            }}
            options={companies.map((c) => ({
              label: c.name,
              value: c.id,
            }))}
          />
          <Input
            label="E-mail"
            placeholder="E-mail do contato"
            name="contact.email"
            type="text"
            value={newContact?.email}
            disabled={existingContact}
            onChange={(e) => {
              setNewContact({
                ...newContact,
                email: e.target.value,
              });
            }}
          />
          <Input
            disabled={existingContact}
            label="Telefone"
            placeholder="Telefone do contato"
            name="contact.phone"
            type="text"
            value={newContact?.phone}
            onChange={(e) => {
              setNewContact({
                ...newContact,
                phone: e.target.value,
              });
            }}
          />
        </>
      ) : null}
    </Box>
  );
};
