import React, { ReactElement, useContext, useEffect, useState } from 'react';
import Cam from './icons/camera.svg';
import * as S from './styles';
import { Box, Button, Card, Input, Typography, Dialog, Label } from 'mino-ui';
import { ReactSVG } from 'react-svg';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import { useForm } from 'react-hook-form';
import { SaveUserRequestPayload } from 'domain/usecases/user/save-user-use-case';
import { makeUpdateProfileUseCase } from 'main/factories/usecases/profile/update-profile-use-factory';
import { makeSendImageUseCase } from 'main/factories/usecases/profile/send-image-factory';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import Avatar from 'presentation/assets/icons/avatar.svg';
import Close from './icons/close.svg';
import { makeGetSession } from 'main/factories/usecases/sessions/get-session-factory';
import { ISession } from 'domain/entity/ISession';
import RichText from 'presentation/shared/common/RichText';

interface SendImageForm {
  avatar: File;
}

export const ProfileView = (): ReactElement => {
  const { session } = useContext(SessionContext);
  const [loading, setLoading] = useState(false);
  const [showAvatar, setShowAvatar] = useState<string>();
  const [sessionData, setSessionData] = useState<ISession>();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors },
    reset,
    getValues,
    setError,
    watch,
  } = useForm<SendImageForm>();

  const inputChange = watch('avatar');
  const avatarField = getValues('avatar');

  const {
    handleSubmit,
    register,
    setValue,
    watch: watchForm,
  } = useForm<SaveUserRequestPayload>();

  async function fetchSessionData() {
    try {
      const data = await makeGetSession().execute();

      setSessionData(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function updateProfile(data: SaveUserRequestPayload) {
    try {
      setLoading(true);
      await makeUpdateProfileUseCase(session?.id ? session?.id : '').execute(
        data
      );
      fetchSessionData();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function sendImage(data: SendImageForm) {
    try {
      setLoading(true);
      await makeSendImageUseCase().execute({ avatar: data.avatar[0] });
      pressEscKey();
      setShowAvatar(undefined);
      reset();
      fetchSessionData();
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setValue('username', `${session?.username}`);
    setValue('email', `${session?.email}`);
    setValue('signature', `${session?.signature}`);
  }, [session]);

  useEffect(() => {
    if (avatarField && avatarField[0]) {
      if (avatarField[0].size > 500000) {
        setError('avatar', {
          type: 'manual',
          message: 'O tamanho máximo do arquivo é 500kb',
        });
        return;
      }

      setShowAvatar(URL.createObjectURL(avatarField[0]));
    }
  }, [inputChange]);

  useEffect(() => {
    fetchSessionData();
  }, []);

  return (
    <Box boxMain>
      <S.Container>
        <S.Content>
          <div style={{ maxHeight: '300px' }}>
            <Card align="center" direction="column" padding="2rem" gap={16}>
              <S.PhotoWrap>
                <S.Photo>
                  {sessionData?.avatar ? (
                    <img src={sessionData.avatar} alt="User Photo" />
                  ) : (
                    <ReactSVG src={Avatar} />
                  )}
                </S.Photo>
                <S.DialogWrap>
                  <Dialog
                    modal
                    title="Atualizar imagem de perfil"
                    onOpenChange={() =>
                      setShowAvatar(session?.avatar || undefined)
                    }
                    trigger={
                      <S.ButtonWrap>
                        <ReactSVG src={Cam} />
                      </S.ButtonWrap>
                    }
                    content={
                      <Box flexDirection="column" gap={32}>
                        <form onSubmit={handleSubmit2(sendImage)}>
                          <Box flexDirection="column">
                            <Box justifyContent="center">
                              <S.AvatarWrap>
                                <S.showAvatar>
                                  <S.removeAvatar
                                    onClick={() => {
                                      setShowAvatar(undefined), reset();
                                    }}
                                  >
                                    <ReactSVG src={Close} />
                                  </S.removeAvatar>
                                  {showAvatar !== undefined ? (
                                    <img src={showAvatar} alt="avatar" />
                                  ) : (
                                    <ReactSVG src={Avatar} />
                                  )}
                                </S.showAvatar>
                              </S.AvatarWrap>
                            </Box>
                            <Box gap={32}>
                              <Input
                                name="avatar"
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                register={register2}
                                errors={errors}
                                validations={{
                                  required: true,
                                }}
                              />
                              <Button
                                type="submit"
                                size="medium"
                                loading={loading}
                                text="Atualizar"
                              />
                            </Box>
                          </Box>
                        </form>
                      </Box>
                    }
                  />
                </S.DialogWrap>
              </S.PhotoWrap>

              <S.TextWrap>
                <Typography
                  fontSize={18}
                  align="center"
                  fill
                  variant="body"
                  weight="bold"
                >
                  {sessionData && sessionData?.username}
                </Typography>
              </S.TextWrap>
            </Card>
          </div>
          <Card padding="2rem" direction="column">
            <Typography fontSize={18} variant="body" weight="bold">
              Editar Perfil
            </Typography>
            <S.FormWrap>
              <form onSubmit={handleSubmit(updateProfile)}>
                <S.InputWrap>
                  <Label text="Nome:" />
                  <Input
                    name="username"
                    validations={{
                      required: true,
                    }}
                    full
                    register={register}
                  />
                </S.InputWrap>

                <S.InputWrap>
                  <Label text="E-mail:" />
                  <Input
                    name="email"
                    validations={{
                      required: true,
                    }}
                    full
                    register={register}
                  />
                </S.InputWrap>

                <S.InputWrap>
                  <Label text="Senha:" />
                  <Input
                    name="password"
                    type="password"
                    register={register}
                    full
                  />
                </S.InputWrap>
                <S.InputWrap>
                  <Label text="Empresa:" />
                  <Input
                    name="company"
                    disabled={true}
                    value={sessionData?.tenant.company}
                    full
                  />
                </S.InputWrap>
                <S.InputWrap>
                  <Label text="Ramal:" />
                  <Input
                    disabled={true}
                    name="ramal"
                    value={sessionData?.ramal}
                    full
                  />
                </S.InputWrap>
                <S.InputWrap>
                  <Label text="Assinatura:" />
                  <RichText
                    onChange={(v) => setValue('signature', v)}
                    defaultValue={session.signature}
                  />
                </S.InputWrap>
                <S.ActionsWrap>
                  <Button text="Salvar" loading={loading} size="medium" />
                </S.ActionsWrap>
              </form>
            </S.FormWrap>
          </Card>
        </S.Content>
      </S.Container>
    </Box>
  );
};
