import styled, { css } from 'styled-components';
import * as ToggleGroup from '@radix-ui/react-toggle-group';

export const Container = styled(ToggleGroup.Root)`
  display: inline-flex;

  &[data-orientation='vertical'] {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Items = styled(ToggleGroup.Item)<{
  size?: 'small' | 'medium' | 'large';
  arrayIndex: number;
}>`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.gray[200]};
  border: solid 1.5px ${({ theme }) => theme.colors.gray[200]};
  display: flex;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
  position: relative;

  > div > div {
    display: flex;
    align-items: center;
    width: 16px;
    height: 16px;
    justify-content: center;
  }

  & svg {
    path {
      fill: ${({ theme }) => theme.colors.gray[200]};
    }
  }

  ${({ size }) =>
    size === 'small' &&
    css`
      padding: 0.25rem 0.5rem;
      font-size: 0.75rem;
      gap: 0.25rem;
    `}

  ${({ size }) =>
    size === 'medium' &&
    css`
      padding: 0.5rem 0.75rem;
      font-size: 0.75rem;
      gap: 0.25rem;
    `}

  ${({ size }) =>
    size === 'large' &&
    css`
      padding: 0.656rem 1rem;
      font-size: 0.75rem;
      gap: 0.25rem;
    `}

  ${({ arrayIndex }) =>
    css`
      right: ${arrayIndex + 0.5}px;
      &[data-orientation='vertical'] {
        bottom: ${arrayIndex + 0.5}px;
        right: 0;
      }
    `}

  :first-child {
    border: solid 1.5px ${({ theme }) => theme.colors.gray[200]};
    border-radius: 0.5rem 0 0 0.5rem;
  }

  :last-child {
    border-radius: 0 0.5rem 0.5rem 0;
  }

  &[data-orientation='vertical'] {
    border: solid 1.5px ${({ theme }) => theme.colors.gray[200]};

    :first-child {
      border-top: solid 1.5px ${({ theme }) => theme.colors.gray[200]};
      border-radius: 0.5rem 0.5rem 0 0;
    }
    :last-child {
      border-radius: 0 0 0.5rem 0.5rem;
    }
  }

  &[data-state='on'] {
    color: ${({ theme }) => theme.colors.blue.primary};
    border-color: ${({ theme }) => theme.colors.blue.primary};
    background-color: ${({ theme }) => theme.colors.blue[50]};

    & svg {
      path {
        fill: ${({ theme }) => theme.colors.blue.primary};
      }
    }

    :hover {
      color: ${({ theme }) => theme.colors.blue[500]};
      border-color: ${({ theme }) => theme.colors.blue[500]};

      & svg {
        path {
          fill: ${({ theme }) => theme.colors.blue[500]};
        }
      }
    }
  }

  &[data-state='off'] {
    :hover {
      background-color: ${({ theme }) => theme.colors.gray[20]};
    }

    :focus {
      border-color: transparent;
      outline: 2px solid ${({ theme }) => theme.colors.blue[100]};
      transition: outline 0.125s linear;
    }
  }

  :active {
    background-color: ${({ theme }) => theme.colors.gray[30]};
  }
`;
