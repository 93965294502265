import React, { ReactElement, useState } from 'react';
import { Box, Button, Dialog, Label, Select, Typography } from 'mino-ui';
import { IconColors, IconNames } from './type';
import { useUsers } from 'main/hooks/useUsers';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';

interface EditModalProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
}

const EditModal = (props: EditModalProps): ReactElement => {
  const { isOpen, setOpen } = props;

  const [loading, setLoading] = useState(false);

  const [phoneHasUser, setPhoneHasUser] = useState(false);

  const { fetchUsers, loading: loadingUsers, users } = useUsers();

  const [searchUser, setSearchUser] = useState('');
  const [selectedUser, setSelectedUser] = useState('');

  useDebouncedEffect(
    () => {
      fetchUsers(searchUser);
    },
    [searchUser],
    500
  );

  return (
    <Dialog
      modal
      maxWidth={400}
      open={isOpen}
      onOpenChange={(v) => setOpen(v)}
      title="Editar telefone"
      submitVariant="primary"
      submitText={'Salvar'}
      submitIconName={IconNames.Save20Regular}
      submitIconColor={IconColors.white}
      loading={loading}
      content={
        <Box style={{ padding: 32 }} flexDirection="column" gap={16}>
          <Typography noWrap variant="body">
            Editando número +55 11 99999-9999
          </Typography>

          <Box flexDirection="column" gap={16}>
            <Box gap={16}>
              <Box alignItems="center" gap={4}>
                <input
                  style={{ margin: 0 }}
                  type="radio"
                  name="phoneHasUser"
                  checked={!phoneHasUser}
                  onChange={() => setPhoneHasUser(false)}
                />
                <Label removeMargin text="Não vincular usuário" />
              </Box>

              <Box alignItems="center" gap={4}>
                <input
                  style={{ margin: 0 }}
                  type="radio"
                  name="phoneHasUser"
                  checked={phoneHasUser}
                  onChange={() => setPhoneHasUser(true)}
                />
                <Label removeMargin text="Vincular usuário" />
              </Box>
            </Box>

            {phoneHasUser ? (
              <Select
                onChange={(v) => v && setSelectedUser(v.value)}
                isLoading={loadingUsers}
                onInputChange={(v) => setSearchUser(v)}
                label="Selecione o usuário"
                options={users.map((user) => ({
                  value: user.id,
                  label: user.username,
                }))}
              />
            ) : null}
          </Box>

          <Box
            gap={16}
            style={{
              borderTop: '1px solid #E5E5E5',
              paddingTop: 16,
            }}
          >
            <Button
              text="Salvar"
              icon="ArrowNext20Regular"
              iconColor={'white' as any}
              loading={loading}
            />
          </Box>
        </Box>
      }
    />
  );
};

export default EditModal;
