import React, { ReactElement } from 'react';
import * as T from './types';
import * as S from './styles';

const Label = (props: T.LabelProps): ReactElement => {
  const { text, removeMargin = false } = props;

  return (
    <S.Container className={removeMargin ? 'remove-margin' : ''}>
      <S.Label>{text}</S.Label>
    </S.Container>
  );
};

export default Label;
