import styled, { css } from 'styled-components';
import { ActivityTypes } from './types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray[30]};
  border-radius: 8px;
  position: relative;
  padding: 1rem;
`;

export const Header = styled.div<{ type: ActivityTypes }>`
  display: flex;
  align-items: end;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const FilesPopOverList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  > li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.2rem;
    padding: 0 0.5rem;
    height: 1.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.gray[600]};

    > span {
      font-size: 0.85rem;
      color: ${({ theme }) => theme.colors.gray[200]};
    }

    > button {
      cursor: pointer;
      padding: 4px;
      border-radius: 4px;
      border: none;
      outline: none;
      background-color: transparent;

      :hover {
        background-color: ${({ theme }) => theme.colors.gray[30]};
      }
    }
  }
`;

export const Title = styled.div<{ type: ActivityTypes }>`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 28px;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 14px;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: ${({ type }) => {
    switch (type) {
      case 'call':
        return ({ theme }) => theme.colors.blue[50];
      case 'email':
        return ({ theme }) => theme.colors.violet[50];
      case 'meeting':
        return ({ theme }) => theme.colors.green[50];
      case 'purpose':
        return ({ theme }) => theme.colors.teal[50];
      case 'message':
        return ({ theme }) => theme.colors.red[50];
      case 'note':
        return ({ theme }) => theme.colors.orange[50];
      case 'sell':
        return ({ theme }) => theme.colors.system.success50;
      case 'loss':
        return ({ theme }) => theme.colors.system.error50;
      default:
        return ({ theme }) => theme.colors.gray[30];
    }
  }};

  ::before {
    content: '';
    position: absolute;
    left: 16px;
    width: 2px;
    height: 16px;
    border-radius: 0.5rem;
    background-color: ${({ type }) => {
      switch (type) {
        case 'call':
          return ({ theme }) => theme.colors.blue.primary;
        case 'email':
          return ({ theme }) => theme.colors.violet.primary;
        case 'meeting':
          return ({ theme }) => theme.colors.green.primary;
        case 'purpose':
          return ({ theme }) => theme.colors.teal.primary;
        case 'message':
          return ({ theme }) => theme.colors.red.primary;
        case 'note':
          return ({ theme }) => theme.colors.orange.primary;
        case 'sell':
          return ({ theme }) => theme.colors.system.success;
        case 'loss':
          return ({ theme }) => theme.colors.system.error;
        default:
          return ({ theme }) => theme.colors.gray[200];
      }
    }};
  }

  ${({ type }) =>
    css`
      color: ${() => {
        switch (type) {
          case 'call':
            return ({ theme }) => theme.colors.blue.primary;
          case 'email':
            return ({ theme }) => theme.colors.violet.primary;
          case 'meeting':
            return ({ theme }) => theme.colors.green.primary;
          case 'purpose':
            return ({ theme }) => theme.colors.teal.primary;
          case 'message':
            return ({ theme }) => theme.colors.red.primary;
          case 'note':
            return ({ theme }) => theme.colors.orange.primary;
          case 'sell':
            return ({ theme }) => theme.colors.system.success;
          case 'loss':
            return ({ theme }) => theme.colors.system.error;
          default:
            return ({ theme }) => theme.colors.gray[200];
        }
      }};
    `}
`;

export const UserAndDate = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.lg};
`;

export const User = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const TypographyWraper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.gray[20]};
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  gap: 4px;
`;
