import React, { ReactElement, useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import * as T from './types';
import * as S from './styles';
import Typography from '../Typography';
import Box from '../Box';
import { Button, Icon, Tooltip } from 'components';

const Table = (props: T.TableProps): ReactElement => {
  const {
    rows: initialRows = [],
    columns,
    hasOutline,
    dataLoading,
    loading,
    activePage,
    itemsCountPerPage = 10,
    onChangePage,
    pageRangeDisplayed = 5,
    totalItemsCount = 0,
    compact = false,
    hasBulkActions,
    bulkActions,
    onSelectRows,
    onSort,
  } = props;

  const [columnSort, setColumnSort] = useState<T.SortProps | undefined>();
  const [rows, setRows] = useState<T.RowProps[]>([]);
  const [selectedRows, setSelectedRows] = useState<T.RowProps[]>([]);

  const styles = {
    hasOutline,
    compact,
  };

  function handleSelectAllRows(event: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = event.target;
    const selected = checked ? rows : [];
    setSelectedRows(selected);
  }

  function verifyIsSelected(row: T.RowProps, selectedRows: T.RowProps[]) {
    return selectedRows.some((selectedRow) => selectedRow.id === row.id);
  }

  function handleSelectCheckbox(
    event: React.ChangeEvent<HTMLInputElement>,
    row: T.RowProps
  ) {
    const { checked } = event.target;
    const selected = checked
      ? [...selectedRows, row]
      : selectedRows.filter((selectedRow) => selectedRow.id !== row.id);
    setSelectedRows(selected);
  }

  useEffect(() => {
    onSelectRows?.(selectedRows);
  }, [onSelectRows, selectedRows]);

  useEffect(() => {
    setRows(initialRows);
  }, [initialRows]);

  function handleSort(column: T.ColumnProps): void {
    const { field } = column;

    const isSorted =
      columnSort?.field === field ? columnSort?.order : undefined;

    if (isSorted === 'asc') {
      setColumnSort({ field, order: 'desc' });

      onSort ? onSort({ field, order: 'desc' }) : sortRowsDesc(field);
    } else if (isSorted === 'desc') {
      setColumnSort(undefined);

      onSort ? onSort({ field: '', order: undefined }) : setRows(initialRows);
    } else {
      setColumnSort({ field, order: 'asc' });

      onSort ? onSort({ field, order: 'asc' }) : sortRowsAsc(field);
    }
  }

  function sortRowsAsc(field: keyof (typeof rows)[0]) {
    setRows([...rows].sort((a, b) => (a[field] < b[field] ? -1 : 1)));
  }

  function sortRowsDesc(field: keyof (typeof rows)[0]) {
    setRows([...rows].sort((a, b) => (a[field] > b[field] ? -1 : 1)));
  }

  return (
    <S.Container {...styles}>
      <S.Table>
        <S.Thead>
          {hasBulkActions && (
            <S.BulkActions active={selectedRows.length > 0}>
              <th align="center" colSpan={1}>
                <Box alignItems="center" gap={4}>
                  <S.CheckBoxTableColumn bulkActions>
                    <input
                      onChange={(e) => handleSelectAllRows(e)}
                      checked={selectedRows.length === rows.length}
                      type="checkbox"
                    />
                  </S.CheckBoxTableColumn>
                </Box>
              </th>

              <th colSpan={columns.length - 1} style={{ width: '100%' }}>
                <S.WrapperActions>
                  <p>({selectedRows.length})</p>

                  <S.WrapperPills>
                    {bulkActions &&
                      bulkActions.map((action) => (
                        <Tooltip
                          key={action.label}
                          content={action.label}
                          align="center"
                          side="top"
                          trigger={
                            <div>
                              <Button
                                onClick={() =>
                                  action.action(
                                    selectedRows.map((row) => row.id)
                                  )
                                }
                                size="small"
                                icon={action.iconName}
                                variant={action.buttonVariant}
                                text=""
                                iconColor={action.iconColor}
                              />
                            </div>
                          }
                        />
                      ))}
                  </S.WrapperPills>
                </S.WrapperActions>
              </th>
            </S.BulkActions>
          )}

          <S.TableRow isHeadingRow={true}>
            {hasBulkActions && (
              <S.TableHeading
                className="has-checkbox"
                hiddenOnMobile
                align="center"
                width={20}
                colSpan={1}
              >
                <input
                  onChange={(e) =>
                    handleSelectAllRows(
                      e as React.ChangeEvent<HTMLInputElement>
                    )
                  }
                  checked={selectedRows.length === rows.length}
                  type="checkbox"
                />
              </S.TableHeading>
            )}
            {columns.map((column) => (
              <S.TableHeading
                compact={compact}
                hiddenOnMobile={column.hiddenOnMobile}
                key={column.field}
                width={column.width}
                align={column.align || 'left'}
                onClick={() => {
                  if (column.sortable) {
                    handleSort(column);
                  }
                }}
              >
                <S.HeadingContent sortable={column.sortable}>
                  {column.headerName}

                  {column.sortable &&
                    columnSort?.field === column.field &&
                    columnSort?.order === 'asc' && (
                      <Icon
                        color={'dark' as any}
                        iconName="ArrowSortDown16Regular"
                      />
                    )}

                  {column.sortable &&
                    columnSort?.field === column.field &&
                    columnSort?.order === 'desc' && (
                      <Icon color="dark" iconName="ArrowSortUp16Regular" />
                    )}
                </S.HeadingContent>
              </S.TableHeading>
            ))}
          </S.TableRow>
        </S.Thead>
        <tbody>
          {dataLoading && loading ? (
            <tr>{dataLoading}</tr>
          ) : (
            rows.map((row, index) => (
              <S.TableRow
                key={index}
                selected={verifyIsSelected(row, selectedRows)}
              >
                {hasBulkActions && (
                  <S.CheckBoxTableColumn>
                    <input
                      type="checkbox"
                      checked={verifyIsSelected(row, selectedRows)}
                      onChange={(event) => handleSelectCheckbox(event, row)}
                    />
                  </S.CheckBoxTableColumn>
                )}
                {columns.map((column) => (
                  <S.DataColumn
                    compact={compact}
                    widthMobile={column.widthMobile}
                    hiddenOnMobile={column.hiddenOnMobile}
                    align={column.align || 'left'}
                    width={column.width}
                    key={column.field}
                  >
                    {column.template && !compact ? (
                      column.template(row)
                    ) : (
                      <S.TextContent
                        align={column.align}
                        fontSize={column.fontSize}
                        fontWeight={column.fontWeight}
                      >
                        {row[column.field] || '- -'}
                      </S.TextContent>
                    )}
                  </S.DataColumn>
                ))}
              </S.TableRow>
            ))
          )}
        </tbody>

        {totalItemsCount > itemsCountPerPage && activePage && (
          <tfoot>
            <tr>
              <S.TableHeading
                colSpan={hasBulkActions ? columns.length + 1 : columns.length}
              >
                <Box alignItems="center" justifyContent="space-between">
                  <Typography
                    variant="body"
                    align="right"
                    color="gray"
                    fontSize={12}
                  >
                    {`${totalItemsCount} registros`}
                  </Typography>

                  <Box>
                    <Pagination
                      activePage={activePage}
                      className="pagination"
                      itemClass="page-item"
                      linkClass="page-link"
                      activeClass="active-page"
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItemsCount}
                      pageRangeDisplayed={pageRangeDisplayed}
                      onChange={onChangePage}
                    />
                  </Box>

                  <Box>
                    <span />
                  </Box>
                </Box>
              </S.TableHeading>
            </tr>
          </tfoot>
        )}
      </S.Table>
    </S.Container>
  );
};

export default Table;
