import { useEffect, useState } from 'react';
import { IGroup } from 'domain/entity/IGroup';
import { makeLoadGroupsUseCase } from 'main/factories/usecases/group/load-groups-factory';

type UseGroupsType = {
  groups: IGroup[] | [];
  fetchGroups: () => Promise<void>;
  loading: boolean;
};

export const useGroups = (): UseGroupsType => {
  const [groups, setGroups] = useState<IGroup[] | []>([]);
  const [loading, setLoading] = useState(false);

  async function fetchGroups(): Promise<void> {
    try {
      setLoading(true);
      const data = await makeLoadGroupsUseCase().execute();

      setGroups(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchGroups();
  }, []);

  return { groups, fetchGroups, loading };
};
