import { RemoteSaveTeam } from 'data/usecases/team/remote-save-team';
import { SaveTeamUseCase } from 'domain/usecases/team/save-teams-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeSaveTeamUseCase = (id?: string): SaveTeamUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteSaveTeam(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/teams${id ? `/${id}` : ''}`,
    toastify
  );
};
