import { RemoteListSharedLinks } from 'data/usecases/sharedlink/remote-list-sharedlinks';
import { ListSharedLinksUsecase } from 'domain/usecases/SharedLink/list-SharedLinks-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeListSharedLinkUseCase = (): ListSharedLinksUsecase => {
  const Axios = new AxiosProvider();
  const Toastify = new ToastifyProvier();

  return new RemoteListSharedLinks(
    Axios,
    `${process.env.REACT_APP_API_BASE_URL}/shared-links?=my`,
    Toastify
  );
};
