import styled, { css, keyframes } from 'styled-components';
import * as Accordion from '@radix-ui/react-accordion';

interface AccordionStyledProps {
  border?: boolean;
  hasDivider?: boolean;
}

const slideDown = keyframes`
  from {
    height:0;
    transition:ease-in-out 3s;
  }
  to{
    height: var(--radix-accordion-content-height);
    transition:ease-in-out 3s;
  }
`;
const slideUp = keyframes`
  from {
    height:var(--radix-accordion-content-height);;
    transition:ease-in-out 3s;
  }
  to{
    height:0;
    transition:ease-in-out 3s;
  }
`;

export const Container = styled(Accordion.Root)`
  transition: ease all 10s;
  width: 100%;
`;
export const Item = styled(Accordion.Item)<AccordionStyledProps>`
  padding: 1rem;

  ${({ border }) =>
    border === true &&
    css`
      margin-bottom: 1rem;
      border: 1px solid ${({ theme }) => theme.colors.gray[30]};
      border-radius: 0.5rem;
    `}
`;

export const TriggerWrap = styled(Accordion.Header)`
  cursor: pointer;
`;

export const Trigger = styled(Accordion.Trigger)<AccordionStyledProps>`
  background: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &[data-state='open'] {
    ${({ hasDivider }) =>
      hasDivider === true &&
      css`
        padding-bottom: 1rem;
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray[30]};
      `}
  }

  &[data-state='closed'] {
    ${({ hasDivider }) =>
      hasDivider === true &&
      css`
        border-bottom: none;
        transition: ${({ theme }) => theme.animation.default};
      `}
  }
`;

export const ArrowTrigger = styled(Accordion.Trigger)`
  background: none;
  cursor: pointer;

  &[data-state='open'] {
    > div > svg {
      transform: rotate(180deg);
      transition: ${({ theme }) => theme.animation.default};
    }
  }

  &[data-state='closed'] {
    > div > svg {
      transition: ${({ theme }) => theme.animation.default};
    }
  }
`;

export const Content = styled(Accordion.Content)`
  overflow: hidden;

  &[data-state='open'] {
    animation: ${slideDown} 0.3s;
  }
  &[data-state='closed'] {
    animation: ${slideUp} 0.3s;
  }
`;
