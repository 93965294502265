import React, { ReactElement } from 'react';
import * as T from './types';
import * as S from './styles';
import { ReactSVG } from 'react-svg';
import Spinner from '../Spinner';
import { IconNames } from '../Icon/types';
import Icon from '../Icon';

const Button = (props: T.ButtonProps): ReactElement => {
  const {
    id,
    text,
    full = false,
    disabled,
    onClick,
    type,
    variant = 'primary',
    size = 'medium',
    icon,
    iconColor,
    iconPosition = 'left',
    hiddenTextOnMobile = false,
    ariaLabel,
    loading,
    noWrap = false,
  } = props;

  const styles = {
    full,
    disabled,
    variant,
    size,
    iconPosition,
    text,
    loading,
    noWrap,
  };

  const iconLib = Object.keys(IconNames).map((key) => IconNames[key as string]);
  const hasIcon = iconLib.includes(icon as IconNames);

  return (
    <S.Container {...styles}>
      <S.Button
        data-testid={id ?? `${text}-button`}
        {...styles}
        type={type}
        onClick={onClick}
        disabled={disabled || loading}
        aria-label={ariaLabel}
      >
        {icon && !loading && !hasIcon ? (
          <S.Icon
            data-testid="icon"
            hiddenTextOnMobile={hiddenTextOnMobile}
            variant={variant}
          >
            <ReactSVG src={icon} />
          </S.Icon>
        ) : null}

        {icon && !loading && hasIcon ? (
          <Icon iconName={icon} color={iconColor} />
        ) : null}

        {loading && <Spinner id="spinner" size="mini" />}

        <S.Text
          noWrap={noWrap}
          className={hiddenTextOnMobile ? 'h-mobile' : ''}
        >
          {text}
        </S.Text>
      </S.Button>
    </S.Container>
  );
};

export default Button;
