import styled from 'styled-components';

export const listContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 0.5rem 0 0.5rem;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  gap: 1rem;
  min-width: 330px;
  min-height: 130px;
`;
export const LogoWrap = styled.div``;

export const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const ButtonWrap = styled.div``;

export const InputWrap = styled.div`
  max-width: 280px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const IconWrap = styled.button`
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
`;
