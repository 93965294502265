/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';

export const useDebouncedEffect = (
  callback: () => void,
  variables: any[],
  timeout = 100
): void => {
  const callbackTimeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    const newCallbackTimeout = setTimeout(callback, timeout);
    if (callbackTimeout.current) clearTimeout(callbackTimeout.current);
    callbackTimeout.current = newCallbackTimeout;
  }, variables);
};
