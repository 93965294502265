import React, { ReactElement } from 'react';
import { Box, Card, Skeleton } from 'mino-ui';

export const SingleEntityPageSkeleton = (): ReactElement => {
  return (
    <>
      <Box flexDirection="column" gap={16}>
        <Card>
          <Skeleton
            height={23}
            lines={[
              {
                height: 23,
                widthPercent: 100,
              },
            ]}
          />
        </Card>

        <Card direction="column" gap={8}>
          <Skeleton
            height={96}
            flexDirection="row"
            lines={[
              {
                height: 40,
                widthPercent: 12,
              },
              {
                height: 40,
                widthPercent: 12,
              },
              {
                height: 40,
                widthPercent: 12,
              },
              {
                height: 40,
                widthPercent: 12,
              },
              {
                height: 40,
                widthPercent: 12,
              },
              {
                height: 40,
                widthPercent: 12,
              },
            ]}
          />

          <Skeleton
            height={96}
            lines={[
              {
                height: 100,
                widthPercent: 100,
              },
            ]}
          />
          <Skeleton
            height={100}
            flexDirection="row"
            lines={[
              {
                height: 32,
                widthPercent: 20,
              },
              {
                height: 32,
                widthPercent: 20,
              },
            ]}
          />
        </Card>

        <div>
          <Skeleton
            height={32}
            flexDirection="row"
            justifyContent="space-between"
            lines={[
              {
                height: 32,
                widthPercent: 25,
              },
              {
                height: 32,
                widthPercent: 10,
              },
            ]}
          />
        </div>

        <Card direction="column" gap={8}>
          <Skeleton
            height={32}
            flexDirection="row"
            justifyContent="space-between"
            lines={[
              {
                height: 24,
                widthPercent: 20,
              },
              {
                height: 24,
                widthPercent: 40,
              },
            ]}
          />
          <Skeleton
            height={100}
            lines={[
              {
                height: 16,
                widthPercent: 100,
              },
              {
                height: 16,
                widthPercent: 80,
              },
              {
                height: 16,
                widthPercent: 70,
              },
            ]}
          />
        </Card>
        <Card direction="column" gap={8}>
          <Skeleton
            height={32}
            flexDirection="row"
            justifyContent="space-between"
            lines={[
              {
                height: 24,
                widthPercent: 20,
              },
              {
                height: 24,
                widthPercent: 40,
              },
            ]}
          />
          <Skeleton
            height={100}
            lines={[
              {
                height: 16,
                widthPercent: 100,
              },
              {
                height: 16,
                widthPercent: 80,
              },
              {
                height: 16,
                widthPercent: 70,
              },
            ]}
          />
        </Card>
        <Card direction="column" gap={8}>
          <Skeleton
            height={32}
            flexDirection="row"
            justifyContent="space-between"
            lines={[
              {
                height: 24,
                widthPercent: 20,
              },
              {
                height: 24,
                widthPercent: 40,
              },
            ]}
          />
          <Skeleton
            height={100}
            lines={[
              {
                height: 16,
                widthPercent: 100,
              },
              {
                height: 16,
                widthPercent: 80,
              },
              {
                height: 16,
                widthPercent: 70,
              },
            ]}
          />
        </Card>
      </Box>

      <Box flexDirection="column" gap={16}>
        <Card>
          <Skeleton
            height={23}
            lines={[
              {
                height: 23,
                widthPercent: 100,
              },
            ]}
          />
        </Card>
        <Card>
          <Skeleton
            height={100}
            lines={[
              {
                height: 23,
                widthPercent: 75,
              },
              {
                height: 130,
                widthPercent: 100,
              },
            ]}
          />
        </Card>
        <Card>
          <Skeleton
            height={100}
            lines={[
              {
                height: 23,
                widthPercent: 75,
              },
              {
                height: 130,
                widthPercent: 100,
              },
            ]}
          />
        </Card>
        <Card>
          <Skeleton
            height={100}
            lines={[
              {
                height: 23,
                widthPercent: 75,
              },
              {
                height: 130,
                widthPercent: 100,
              },
            ]}
          />
        </Card>
      </Box>
    </>
  );
};
