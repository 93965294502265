import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { UnexpectedError } from 'domain/errors';
import {
  SendImageRequest,
  SendImageUseCase,
} from 'domain/usecases/profile/send-image-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteSendImageUseCase implements SendImageUseCase {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient,
    private readonly toastService: ToastService
  ) {}

  async execute(data: SendImageRequest): Promise<{ success: boolean }> {
    const { token, tenant } = useToken();

    const formData = new FormData();
    formData.append('avatar', data.avatar);

    const response = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        Tenant: tenant,
        'Content-Type': 'application/json',
      },
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        this.toastService.show('Foto atualizada com sucesso!', {
          type: 'success',
        });
        return {
          success: true,
        };

      default:
        this.toastService.show('Erro ao atualizar imagem!', {
          type: 'error',
        });
        throw new UnexpectedError('Erro ao atualizar imagem!');
    }
  }
}
