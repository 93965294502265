import { RemoteDisconnectFacebookToken } from 'data/usecases/facebook/remote-disconnect-facebook-token';
import { makeAxiosHttpClient } from 'main/factories/http';
import { makeToastify } from 'main/factories/notification';

export const makeRemoteDisconnectFacebook =
  (): RemoteDisconnectFacebookToken => {
    const axios = makeAxiosHttpClient();
    const toastify = makeToastify();

    return new RemoteDisconnectFacebookToken(
      axios,
      `${process.env.REACT_APP_API_BASE_URL}/facebook/disconnect`,
      toastify
    );
  };
