import { RemoteSaveContact } from 'data/usecases/contact/remote-save-contact';
import { SaveContactUseCase } from 'domain/usecases/contact/save-contact-use-case';
import { makeAxiosHttpClient } from 'main/factories/http';
import { makeToastify } from 'main/factories/notification';

export const makeRemoteSaveContact = (): SaveContactUseCase => {
  const axios = makeAxiosHttpClient();
  const toastify = makeToastify();

  const remoteSaveContact = new RemoteSaveContact(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/contacts?=my`,
    toastify
  );

  return remoteSaveContact;
};
