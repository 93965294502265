import styled, { css } from 'styled-components';

export const Container = styled.div<{ className?: string }>`
  margin-bottom: 0.5rem;

  ${({ className }) =>
    className?.includes('remove-margin') &&
    css`
      margin-bottom: 0;
    `}
`;

export const Label = styled.label`
  line-height: 19px;
  font-size: 0.78rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray.black};
`;
