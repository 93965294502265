import { RemoteDeleteTeam } from 'data/usecases/team/remote-delete-team';
import { DeleteTeamUseCase } from 'domain/usecases/team/delete-team-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeDeleteTeamUseCase = (id: string): DeleteTeamUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteDeleteTeam(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/teams/${id}`,
    toastify
  );
};
