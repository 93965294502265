import styled, { css } from 'styled-components';
import { ButtonSizes, ButtonVariants } from './types';

interface ButtonStyledProps {
  full?: boolean;
  disabled?: boolean;
  variant?: ButtonVariants;
  size?: ButtonSizes;
  iconPosition?: 'left' | 'right';
  background?: boolean;
  hiddenTextOnMobile?: boolean;
  text?: string;
  loading?: boolean;
  noWrap?: boolean;
}

export const Container = styled.div<ButtonStyledProps>`
  margin: 0;

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}
`;

export const Button = styled.button<ButtonStyledProps>`
  font-family: 'Noto Sans', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.656rem 1rem;
  font-weight: 700;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.blue.primary};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.gray.white};
  cursor: pointer;
  border: 1px solid transparent;
  font-size: 0.875rem;
  line-height: 1rem;
  white-space: nowrap;
  gap: 0.5rem;

  ${({ text }) =>
    text === '' &&
    css`
      gap: 0;
    `}

  :active {
    & svg {
      transform: scale(0.85);
      transition: transform 0.125s linear;
    }
  }

  :focus {
    border: 1px solid ${({ theme }) => theme.colors.gray[20]};
    outline: 2px solid ${({ theme }) => theme.colors.blue[100]};
  }

  ${({ loading }) =>
    loading &&
    css`
      background-color: ${({ theme }) => theme.colors.blue[500]};
      color: ${({ theme }) => theme.colors.gray[20]};
    `}

  ${({ loading }) =>
    !loading &&
    css`
      :disabled {
        background-color: ${({ theme }) => theme.colors.gray[30]};
        color: ${({ theme }) => theme.colors.gray[200]};
        border-color: transparent;
        cursor: auto;
      }
    `}

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      padding: 0.406rem 1rem;
      height: 32px;
    `}

  ${({ size }) =>
    size === 'large' &&
    css`
      font-size: 1rem;
      padding: 0.844rem 1rem;
      height: 46px;
    `}

  transition: outline 0.1s linear;

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      :hover {
        :not(:disabled) {
          background-color: ${({ theme }) => theme.colors.blue[500]};
        }
      }

      :active {
        :not(:disabled) {
          background-color: ${({ theme }) => theme.colors.blue[500]};
        }
      }
    `}

  ${({ variant }) =>
    variant === 'outline' &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.gray[600]};
      color: ${({ theme }) => theme.colors.gray[600]};

      :disabled {
        background-color: transparent;
        border-color: ${({ theme }) => theme.colors.gray[200]};
      }

      :hover {
        background-color: ${({ theme }) => theme.colors.gray[20]};
      }

      :active {
        :not(:disabled) {
          background-color: ${({ theme }) => theme.colors.gray[20]};
        }
      }
    `}

  ${({ variant }) =>
    variant === 'ghost' &&
    css`
      background-color: transparent;
      border: 1px solid transparent;
      color: ${({ theme }) => theme.colors.blue.primary};

      :disabled {
        background-color: transparent;
      }

      :hover {
        background-color: ${({ theme }) => theme.colors.gray[20]};
      }

      :active {
        :not(:disabled) {
          background-color: ${({ theme }) => theme.colors.gray[20]};
        }
      }
    `}

  ${({ variant }) =>
    variant === 'active' &&
    css`
      background-color: ${({ theme }) => theme.colors.blue[50]};
      color: ${({ theme }) => theme.colors.blue.primary};
      border-radius: 8px;

      :hover {
        :not(:focus) {
          border-color: ${({ theme }) => theme.colors.blue[500]};
        }
      }
    `}

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      background-color: ${({ theme }) => theme.colors.gray[30]};
      border: 1px solid transparent;
      color: ${({ theme }) => theme.colors.gray[200]};
      :disabled {
        background-color: transparent;
      }

      :hover {
        :not(:focus) {
          border-color: ${({ theme }) => theme.colors.blue[500]};
        }
      }

      :active {
        :not(:disabled) {
          background-color: ${({ theme }) => theme.colors.gray[50]};
        }
      }
    `}

  ${({ variant }) =>
    variant === 'ghostSecondary' &&
    css`
      background-color: transparent;
      border: 1px solid transparent;
      color: ${({ theme }) => theme.colors.gray[600]};
      :disabled {
        background-color: transparent;
      }

      :hover {
        background-color: ${({ theme }) => theme.colors.gray[20]};
      }

      :active {
        :not(:disabled) {
          background-color: ${({ theme }) => theme.colors.gray[20]};
        }
      }
    `}

  ${({ variant }) =>
    variant === 'danger' &&
    css`
      background-color: ${({ theme }) => theme.colors.system.error};
      border: 1px solid transparent;
      color: ${({ theme }) => theme.colors.gray.white};

      & svg {
        path {
          fill: ${({ theme }) => theme.colors.gray.white};
        }
      }

      :disabled {
        color: ${({ theme }) => theme.colors.gray[50]};

        & svg {
          path {
            fill: ${({ theme }) => theme.colors.gray[50]};
          }
        }
      }

      :hover {
        :not(:disabled) {
          background-color: transparent;
          border: 1px solid ${({ theme }) => theme.colors.system.error};
          color: ${({ theme }) => theme.colors.system.error};

          & svg {
            path {
              fill: ${({ theme }) => theme.colors.system.error};
            }
          }
        }
      }

      :active {
        :not(:disabled) {
          border-color: ${({ theme }) => theme.colors.red[100]};
        }
      }

      :focus {
        :not(:disabled) {
          outline: 2px solid ${({ theme }) => theme.colors.system.error};
        }
      }
    `}

  ${({ iconPosition }) =>
    iconPosition === 'right' &&
    css`
      flex-direction: row-reverse;
    `}
`;

export const Icon = styled.span<ButtonStyledProps>`
  > div > div {
    display: flex;
  }
  & svg {
    width: 18px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-right: 0;
  }
`;

export const Text = styled.span<{ className?: string; noWrap?: boolean }>`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1rem;
  white-space: nowrap;

  ${({ noWrap }) =>
    noWrap == true &&
    css`
      white-space: break-spaces;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    ${({ className }) =>
      className?.includes('h-mobile') &&
      css`
        display: none;
      `}
  }
`;
