import React, {
  createRef,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, Drawer, Input, Accordion, Checkbox, Label } from 'mino-ui';
import { makeSaveGroupUseCase } from 'main/factories/usecases/group/save-group-factory';
import { SaveGroupRequestPayload } from 'domain/usecases/group/save-group-use-case';
import { useForm } from 'react-hook-form';
import * as S from './styles';
import { makeSavePermissionsUseCase } from 'main/factories/usecases/group/save-permissions-factory';
import NewGroup from './icons/newGroup.svg';
import { omit } from 'lodash';

export type UpdateGroupPayload = SaveGroupRequestPayload & {
  contactRoleId: string;
  productRoleId: string;
  configRoleId: string;
};

interface ManageDrawerProps {
  callback: () => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  updatePayload?: UpdateGroupPayload;
}

export const ManageDrawer = (props: ManageDrawerProps): ReactElement => {
  const { isOpen, setIsOpen, callback, updatePayload } = props;

  const [loading, setLoading] = useState(false);

  const updateMode = updatePayload ? true : false;

  type FormType = SaveGroupRequestPayload & {
    allProductRoles?: boolean;
    allContactRoles?: boolean;
    allConfigRoles?: boolean;
  };

  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
  } = useForm<FormType>({
    defaultValues: {
      contact_role: {
        delete: false,
        edit: false,
        exports: false,
        watch_all: false,
      },
      product_role: {
        delete: false,
        edit: false,
        exports: false,
        watch_all: false,
      },
      config_role: {
        channels: false,
        funnels: false,
        groups: false,
        integrations: false,
        losses: false,
        pages: false,
        services: false,
        tags: false,
        teams: false,
        users: false,
        links: false,
      },
    },
  });

  const submitRef = createRef<HTMLInputElement>();

  async function saveGroup(data: SaveGroupRequestPayload) {
    try {
      setLoading(true);

      const group = await makeSaveGroupUseCase(
        updateMode ? updatePayload?.id : undefined
      ).execute(data, updateMode ? 'update' : 'create');

      if (data.product_role && group) {
        await makeSavePermissionsUseCase(
          'product',
          updateMode ? updatePayload?.productRoleId : undefined
        ).execute(
          {
            group_id: group.id as string,
            ...data.product_role,
          },
          updateMode && updatePayload?.productRoleId ? 'update' : 'create'
        );
      }
      if (data.contact_role && group) {
        await makeSavePermissionsUseCase(
          'contact',
          updateMode ? updatePayload?.contactRoleId : undefined
        ).execute(
          {
            group_id: group.id as string,
            ...data.contact_role,
          },
          updateMode && updatePayload?.contactRoleId ? 'update' : 'create'
        );
      }
      if (data.config_role && group) {
        await makeSavePermissionsUseCase(
          'config',
          updateMode ? updatePayload?.configRoleId : undefined
        ).execute(
          {
            group_id: group.id as string,
            ...data.config_role,
          },
          updateMode && updatePayload?.configRoleId ? 'update' : 'create'
        );
      }

      callback();
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      reset();
    }
  }

  const ignoreProductEffect = useRef(false);
  const ignoreContactEffect = useRef(false);
  const ignoreConfigEffect = useRef(false);

  const getProductRoles = omit(
    watch('product_role'),
    'tenant_id',
    'id',
    'group_id'
  );
  const getContactRoles = omit(
    watch('contact_role'),
    'tenant_id',
    'id',
    'group_id'
  );
  const getConfigRoles = omit(
    watch('config_role'),
    'tenant_id',
    'id',
    'group_id'
  );

  const [productChecked, setProductChecked] = useState<
    boolean | 'indeterminate'
  >(false);
  const [contactChecked, setContactChecked] = useState<
    boolean | 'indeterminate'
  >(false);
  const [configChecked, setConfigChecked] = useState<boolean | 'indeterminate'>(
    false
  );

  function handleSelectAll(
    setChecked: React.Dispatch<React.SetStateAction<boolean | 'indeterminate'>>,
    ignoreEffect: React.MutableRefObject<boolean>
  ) {
    ignoreEffect.current = true;
    setChecked((prev) => !prev);
  }

  useEffect(() => {
    if (!ignoreContactEffect.current) {
      const isNotEmptyObject = Object.keys(getContactRoles).length > 0;

      if (isNotEmptyObject) {
        const allPropertiesTrue = Object.values(getContactRoles).every(
          (value) => value == true
        );
        const allPropertiesFalse = Object.values(getContactRoles).every(
          (value) => value == false
        );
        setContactChecked(
          allPropertiesTrue
            ? true
            : allPropertiesFalse
            ? false
            : 'indeterminate'
        );
      } else {
        setValue('contact_role', {
          delete: false,
          edit: false,
          exports: false,
          watch_all: false,
        });
      }
    } else {
      ignoreContactEffect.current = false;
    }

    if (!ignoreProductEffect.current) {
      const isNotEmptyObject = Object.keys(getProductRoles).length > 0;

      if (isNotEmptyObject) {
        const allPropertiesTrue = Object.values(getProductRoles).every(
          (value) => value == true
        );
        const allPropertiesFalse = Object.values(getProductRoles).every(
          (value) => value == false
        );
        setProductChecked(
          allPropertiesTrue
            ? true
            : allPropertiesFalse
            ? false
            : 'indeterminate'
        );
      } else {
        setValue('product_role', {
          delete: false,
          edit: false,
          exports: false,
          watch_all: false,
        });
      }
    } else {
      ignoreProductEffect.current = false;
    }

    if (!ignoreConfigEffect.current) {
      const isNotEmptyObject = Object.keys(getConfigRoles).length > 0;

      if (isNotEmptyObject) {
        const allPropertiesTrue = Object.values(getConfigRoles).every(
          (value) => value == true
        );
        const allPropertiesFalse = Object.values(getConfigRoles).every(
          (value) => value == false
        );
        setConfigChecked(
          allPropertiesTrue
            ? true
            : allPropertiesFalse
            ? false
            : 'indeterminate'
        );
      } else {
        setValue('config_role', {
          channels: false,
          funnels: false,
          groups: false,
          integrations: false,
          losses: false,
          pages: false,
          services: false,
          tags: false,
          teams: false,
          users: false,
          links: false,
        });
      }
    } else {
      ignoreConfigEffect.current = false;
    }
  }, [getContactRoles, getProductRoles, getConfigRoles, setValue]);

  useEffect(() => {
    if (productChecked != 'indeterminate') {
      setValue('product_role', {
        delete: productChecked,
        edit: productChecked,
        exports: productChecked,
        watch_all: productChecked,
      });
    }
    if (contactChecked != 'indeterminate') {
      setValue('contact_role', {
        delete: contactChecked,
        edit: contactChecked,
        exports: contactChecked,
        watch_all: contactChecked,
      });
    }
    if (configChecked != 'indeterminate') {
      setValue('config_role', {
        channels: configChecked,
        funnels: configChecked,
        groups: configChecked,
        integrations: configChecked,
        losses: configChecked,
        pages: configChecked,
        services: configChecked,
        tags: configChecked,
        teams: configChecked,
        users: configChecked,
        links: configChecked,
      });
    }
  }, [productChecked, contactChecked, configChecked, setValue]);

  useEffect(() => {
    setValue('name', updatePayload?.name || '');
    setValue('product_role', updatePayload?.product_role);
    setValue('contact_role', updatePayload?.contact_role);
    setValue('config_role', updatePayload?.config_role);
  }, [setValue, updatePayload]);

  return (
    <Drawer
      width={325}
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      title={'Novo Grupo de Usuários'}
      icon={NewGroup}
      submitText={'Salvar'}
      onSubmit={() => submitRef.current?.click()}
      secondarySubmitText="Cancelar"
      secondarySubmitAction={() => setIsOpen(false)}
      loadingSubmit={loading}
    >
      <form onSubmit={handleSubmit(saveGroup)}>
        <Box gap={8} flexDirection="column">
          <Input hidden type="text" name="id" register={register} />

          <Input
            label="Nome do Grupo"
            placeholder="Ex: Colaboradores"
            name="name"
            register={register}
            errors={errors}
            validations={{
              required: true,
            }}
          />

          <Label text="Permissões" />
          <S.AccordionWrap>
            <Accordion
              type="multiple"
              defaultValue={['contact_role', 'product_role', 'config_role']}
              items={[
                {
                  trigger: 'Negócios',
                  content: (
                    <S.AccordionContentWrap>
                      <S.SelectAllWrap>
                        <Checkbox
                          name="allProductRoles"
                          value="select_products"
                          label="Selecionar todos"
                          checked={productChecked}
                          onCheckedChange={() =>
                            handleSelectAll(
                              setProductChecked,
                              ignoreProductEffect
                            )
                          }
                        />
                      </S.SelectAllWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'product_role.watch_all'}
                          label="Ver todos"
                          control={control}
                        />
                      </S.CheckboxWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'product_role.exports'}
                          label="Exportar"
                          control={control}
                        />
                      </S.CheckboxWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'product_role.edit'}
                          label="Editar"
                          control={control}
                        />
                      </S.CheckboxWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'product_role.delete'}
                          label="Excluir"
                          control={control}
                        />
                      </S.CheckboxWrap>
                    </S.AccordionContentWrap>
                  ),
                  value: 'product_role',
                },
                {
                  trigger: 'Contatos/Empresas',
                  content: (
                    <S.AccordionContentWrap>
                      <S.SelectAllWrap>
                        <Checkbox
                          name="allContactRoles"
                          value="select_contacts"
                          label="Selecionar todos"
                          checked={contactChecked}
                          onCheckedChange={() =>
                            handleSelectAll(
                              setContactChecked,
                              ignoreContactEffect
                            )
                          }
                        />
                      </S.SelectAllWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'contact_role.watch_all'}
                          label="Ver todos"
                          control={control}
                        />
                      </S.CheckboxWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'contact_role.exports'}
                          label="Exportar"
                          control={control}
                        />
                      </S.CheckboxWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'contact_role.edit'}
                          label="Editar"
                          control={control}
                        />
                      </S.CheckboxWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'contact_role.delete'}
                          label="Excluir"
                          control={control}
                        />
                      </S.CheckboxWrap>
                    </S.AccordionContentWrap>
                  ),
                  value: 'contact_role',
                },
                {
                  trigger: 'Configurações',
                  content: (
                    <S.AccordionContentWrap>
                      <S.SelectAllWrap>
                        <Checkbox
                          name="allConfigRoles"
                          value="select_configs"
                          label="Selecionar todos"
                          checked={configChecked}
                          onCheckedChange={() =>
                            handleSelectAll(
                              setConfigChecked,
                              ignoreConfigEffect
                            )
                          }
                        />
                      </S.SelectAllWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'config_role.pages'}
                          label="Acesso a Página"
                          control={control}
                        />
                      </S.CheckboxWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'config_role.users'}
                          label="Usuários"
                          control={control}
                        />
                      </S.CheckboxWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'config_role.groups'}
                          label="Grupo de Usuários"
                          control={control}
                        />
                      </S.CheckboxWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'config_role.channels'}
                          label="Origens"
                          control={control}
                        />
                      </S.CheckboxWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'config_role.losses'}
                          label="Motivos de perda"
                          control={control}
                        />
                      </S.CheckboxWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'config_role.tags'}
                          label="Tags"
                          control={control}
                        />
                      </S.CheckboxWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'config_role.services'}
                          label="Produtos e Serviços"
                          control={control}
                        />
                      </S.CheckboxWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'config_role.teams'}
                          label="Times/Equipes"
                          control={control}
                        />
                      </S.CheckboxWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'config_role.funnels'}
                          label="Funís de Vendas"
                          control={control}
                        />
                      </S.CheckboxWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'config_role.integrations'}
                          label="Integrações"
                          control={control}
                        />
                      </S.CheckboxWrap>
                      <S.CheckboxWrap>
                        <Checkbox
                          name={'config_role.links'}
                          label="Links úteis"
                          control={control}
                        />
                      </S.CheckboxWrap>
                    </S.AccordionContentWrap>
                  ),
                  value: 'config_role',
                },
              ]}
            />
          </S.AccordionWrap>
          <input ref={submitRef} hidden type="submit" />
        </Box>
      </form>
    </Drawer>
  );
};
