import { ActivityTypes } from './types';

export enum IconColors {
  dark = 'dark',
  blue = 'blue',
  green = 'green',
  gray = 'gray',
  red = 'red',
  white = 'white',
  orange = 'orange',
  violet = 'violet',
  teal = 'teal',
  systemGreen = 'systemGreen',
  systemRed = 'systemRed',
}

export function renderTypeInformation(type: ActivityTypes): string {
  switch (type) {
    case 'call':
      return 'Ligação';
    case 'message':
      return 'Mensagem';
    case 'email':
      return 'E-mail';
    case 'meeting':
      return 'Reunião';
    case 'purpose':
      return 'Proposta';
    case 'note':
      return 'Nota';
    case 'sell':
      return 'Negocio Ganho';
    case 'loss':
      return 'Negocio Perdido';

    default:
      return 'Sistema';
  }
}

export function renderIcon(type: ActivityTypes): string {
  switch (type) {
    case 'call':
      return 'Call20Regular';
    case 'message':
      return 'Chat20Regular';
    case 'email':
      return 'Mail20Regular';
    case 'meeting':
      return 'BuildingPeople20Regular';
    case 'purpose':
      return 'Handshake20Regular';
    case 'note':
      return 'Notepad20Regular';
    case 'sell':
      return 'ThumbLike20Regular';
    case 'loss':
      return 'ThumbDislike20Regular';

    default:
      return 'ModivLogo16Filled';
  }
}

export function RenderIconColor(type: ActivityTypes): any {
  switch (type) {
    case 'call':
      return IconColors.blue;
    case 'message':
      return IconColors.red;
    case 'email':
      return IconColors.violet;
    case 'meeting':
      return IconColors.green;
    case 'purpose':
      return IconColors.teal;
    case 'note':
      return IconColors.orange;
    case 'sell':
      return IconColors.systemGreen;
    case 'loss':
      return IconColors.systemRed;

    default:
      return IconColors.gray;
  }
}
