import React, { ReactElement, useContext, useState } from 'react';
import { Box, Button, Dialog, Input, Typography } from 'mino-ui';
import { useForm } from 'react-hook-form';
import DownloadIcon from './icons/downloadIcon.svg';
import { makeImportDealsUseCase } from 'main/factories/usecases/deal/import-deals-factory';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import DealContext from '../contexts/DealContext';

interface ImportForm {
  file: File;
}

interface importDalsParams {
  callback: () => void;
}

export const ImportDeals = (params: importDalsParams): ReactElement => {
  const { callback } = params;
  const { register, handleSubmit } = useForm<ImportForm>();
  const [loading, setLoading] = useState(false);

  const { importDialogOpen, setImportDialogOpen } = useContext(DealContext);

  async function handleImportSubmit(data: ImportForm): Promise<void> {
    try {
      setLoading(true);
      await makeImportDealsUseCase().execute({
        file: data.file[0],
      });
      callback();
      setTimeout(() => {
        pressEscKey();
      }, 500);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function handleDownloadFromUrl(): void {
    window.open(
      'https://minocrm.s3.sa-east-1.amazonaws.com/modelo-deals.xlsx',
      '_blank'
    );
  }

  return (
    <Dialog
      title="Importar negócios"
      onSubmit={handleSubmit(handleImportSubmit)}
      loading={loading}
      open={importDialogOpen}
      onOpenChange={() => setImportDialogOpen(false)}
      content={
        <Box flexDirection="column" gap={24}>
          <Box flexDirection="column" gap={16}>
            <Box flexDirection="column" gap={8}>
              <Box flexDirection="column" gap={16}>
                <Typography variant="body" fontSize={12} color="gray" noWrap>
                  1. Para importar negócios é necessário baixar o modelo de
                  importação. Com esse modelo você consegue importar negócios,
                  contatos e empresas ao mesmo tempo.
                </Typography>
                <Button
                  onClick={handleDownloadFromUrl}
                  size="medium"
                  text="Baixar modelo"
                  variant="ghost"
                  icon={DownloadIcon}
                />
              </Box>
            </Box>

            <form onSubmit={handleSubmit(handleImportSubmit)}>
              <Box gap={16} flexDirection="column">
                <Typography variant="body" fontSize={12} color="gray" noWrap>
                  2. Faça o upload do seu arquivo com as as colunas devidamente
                  preenchidas. A importação será salva no quadro de importações
                  com o nome do arquivo.
                </Typography>
                <Input
                  name="file"
                  type="file"
                  register={register}
                  validations={{
                    required: true,
                  }}
                />
                <Typography variant="body" fontSize={12} color="gray" noWrap>
                  3. Agora clique em Importar e confira se os dados estão
                  corretos.
                </Typography>
              </Box>
            </form>
          </Box>
        </Box>
      }
    />
  );
};
