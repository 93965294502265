import React, { ReactElement, useState, useEffect } from 'react';
import { IContact } from 'domain/entity/IContact';
import SingleContactPage from 'presentation/pages/Contact/single';
import { useParams } from 'react-router-dom';
import { makeGetSingleContactUseCase } from '../usecases/contact/get-contact-factory';

export const makeSingleContactPage = (): ReactElement => {
  const { id = '' } = useParams();

  const [contact, setContact] = useState<IContact>({} as IContact);
  const [loading, setLoading] = useState<boolean>(false);

  async function fetchContact(): Promise<void> {
    try {
      setLoading(true);
      const contact = await makeGetSingleContactUseCase(id).execute();
      setContact(contact);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchContact();
  }, []);

  return (
    <SingleContactPage
      loading={loading}
      callback={fetchContact}
      contact={contact}
    />
  );
};
