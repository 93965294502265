import { TooltipProvider } from '@radix-ui/react-tooltip';
import React, { ReactElement } from 'react';
import * as S from './styles';
import * as T from './types';

const Tooltip = (props: T.TooltipProps): ReactElement => {
  const {
    delayDuration = 100,
    disableHoverableContent,
    defaultOpen,
    trigger,
    content,
    side,
    sideOffset = 12,
    align,
    alignOffset,
  } = props;

  return (
    <TooltipProvider
      delayDuration={delayDuration}
      disableHoverableContent={disableHoverableContent}
    >
      <S.Container defaultOpen={defaultOpen}>
        <S.Trigger asChild>{trigger}</S.Trigger>
        <S.Portal>
          <S.Content
            align={align}
            alignOffset={alignOffset}
            sideOffset={sideOffset}
            side={side}
          >
            <S.Arrow />
            {content}
          </S.Content>
        </S.Portal>
      </S.Container>
    </TooltipProvider>
  );
};

export default Tooltip;
