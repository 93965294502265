import styled, { css } from 'styled-components';

type SkelletonStyledProps = {
  height: number;
  width: number;
};

type ContainerStyledProps = {
  gap?: number;
  alignItems?: 'flex-start' | 'center' | 'flex-end';
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between';
  flexDirection?: 'row' | 'column';
  flexDirectionMobile?: 'row' | 'column';
};

export const Container = styled.div<ContainerStyledProps>`
  display: flex;
  width: 100%;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  gap: ${({ gap }) => gap || 8}px;

  @media (max-width: 768px) {
    flex-direction: ${({ flexDirectionMobile }) => flexDirectionMobile};
  }
`;

export const Skeleton = styled.div<SkelletonStyledProps>`
  ${({ height, width }) => css`
    height: ${height}px;
    width: ${width}%;
  `}

  border-radius: 4px;
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(#eee, #eee),
    radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
    linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;

  @keyframes loading {
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
`;
