import System from './icons/system.svg';
import { ActivityTypeResponseDTO } from 'domain/dtos/activity/activity-dto';

type IconType = ActivityTypeResponseDTO | 'google';

export function renderIcon(activity_type: IconType): string {
  switch (activity_type) {
    case 'Ligação':
      return `Call20Regular`;
    case 'E-mail':
      return `Mail20Regular`;
    case 'Reunião':
      return `BuildingPeople20Regular`;
    case 'Proposta':
      return `Handshake20Regular`;
    case 'Mensagem':
      return `Chat20Regular`;
    case 'Anotação':
      return `Notepad20Regular`;
    case 'Sell':
      return `ThumbLike20Regular`;
    case 'Loss':
      return `ThumbDislike20Regular`;
    case 'google':
      return `${System}`;

    default:
      return `${System}`;
  }
}

export function renderType(activity_type: IconType): string {
  switch (activity_type) {
    case 'Ligação':
      return 'Ligação';
    case 'E-mail':
      return 'E-mail';
    case 'Reunião':
      return 'Reunião';
    case 'Proposta':
      return 'Proposta';
    case 'Mensagem':
      return `Mensagem`;
    case 'Anotação':
      return 'Anotação';
    case 'Sell':
      return 'Negócio Ganho';
    case 'Loss':
      return `Negócio Perdido`;
    case 'google':
      return `Criado no Google`;
    default:
      return `Criado no sistema`;
  }
}
