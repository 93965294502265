import styled, { css } from 'styled-components';

interface ActionButtonStyledProps {
  action?: 'sell' | 'loss';
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const IconWrapper = styled.div<ActionButtonStyledProps>`
  width: 20px;
  height: 20px;
  > div {
    display: flex;
    align-items: center;
  }
`;

export const ActionButton = styled.button<ActionButtonStyledProps>`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: ${({ theme }) => theme.colors.green[50]};
  color: ${({ theme }) => theme.colors.system.success};
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 700;
  white-space: break-spaces;

  ${({ action }) =>
    action === 'loss' &&
    css`
      background: ${({ theme }) => theme.colors.red[50]};
      color: ${({ theme }) => theme.colors.system.error};
    `}

  ${({ action }) =>
    action === undefined &&
    css`
      background: ${({ theme }) => theme.colors.gray[50]};
      color: ${({ theme }) => theme.colors.gray[200]};
    `}

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    > span {
      display: none;
    }
  }
`;
