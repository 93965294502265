import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  ChannelsDealsRequestDTO,
  ChannelsDealsResponseDTO,
  GetReportChannelsDealsUseCase,
} from 'domain/usecases/deal/report-channels-deals.use-case';

export class RemoteGetChannelsDealsReport
  implements GetReportChannelsDealsUseCase
{
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string
  ) {}

  async execute(
    params: ChannelsDealsRequestDTO
  ): Promise<ChannelsDealsResponseDTO[]> {
    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params,
    });

    const data = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return data.map((report) => ({
          name: report.name,
          deals: report.deals,
        }));
      case HttpStatusCode.unauthorized:
        throw new NotAuthorizedError('Unauthorized');
      default:
        throw new UnexpectedError('Unexpected error');
    }
  }
}
