import { IImportBatch } from 'domain/entity/IImportBatch';
import { MakeListImportBatchUseCase } from 'main/factories/usecases/import_batch/list-import-batch-factory';
import { useEffect, useState } from 'react';

type UseImportBatchType = {
  importBatch: IImportBatch[];
  fatchImportBatch: () => void;
  loading: boolean;
};

export const UseImportBatch = (): UseImportBatchType => {
  const [importBatch, setImportBatch] = useState<IImportBatch[]>([]);

  const [loading, setLoading] = useState(false);

  async function fatchImportBatch(): Promise<void> {
    try {
      setLoading(true);
      const data = await MakeListImportBatchUseCase().execute();
      setImportBatch(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fatchImportBatch();
  }, []);

  return { importBatch, fatchImportBatch, loading };
};
