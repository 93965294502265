import { RemoteSendImageUseCase } from 'data/usecases/profile/remote-send-image';
import { SendImageUseCase } from 'domain/usecases/profile/send-image-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeSendImageUseCase = (): SendImageUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteSendImageUseCase(
    `${process.env.REACT_APP_API_BASE_URL}/profile-upload`,
    axios,
    toastify
  );
};
