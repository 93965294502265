import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  ListImportBatchUseCase,
  ResponseListImportBatch,
} from 'domain/usecases/import_batch/list-import-batch-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteListImportBatch implements ListImportBatchUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly ToastService: ToastService
  ) {}

  async execute(): Promise<ResponseListImportBatch[]> {
    const { tenant, token } = useToken();

    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      headers: {
        authorization: `Bearer ${token}`,
        Tenant: tenant,
      },
    });

    const data = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return data.map((batch) => ({
          id: batch.batch,
          count: batch?.count,
          import_batch_title: batch?.batch?.split('Z-')?.[1],
          created_at: batch?.batch?.split('Z-')?.[0],
        }));

      case HttpStatusCode.unauthorized:
        this.ToastService.show('Não autorizado', { type: 'error' });
        throw new NotAuthorizedError('unauthorized');

      default:
        this.ToastService.show('Erro inesperado', { type: 'error' });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
