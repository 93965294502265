import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { IChannel } from 'domain/entity/IChannel';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  SaveChannelUseCase,
  RequestPayloadChannels,
} from 'domain/usecases/channel/create-channel-use-case';

import { useToken } from 'main/hooks/usetoken';

export class RemoteSaveChannel implements SaveChannelUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(
    payload: RequestPayloadChannels,
    type?: 'update' | 'create'
  ): Promise<IChannel> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: type === 'update' ? 'put' : 'post',
      url: this.url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Tenant: tenant,
      },
      body: payload,
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        this.toastService.show('Salvo com sucesso!', {
          type: 'success',
        });

        return {
          id: response.body.id,
          name: response.body.name,
          created_at: response.body.created_at,
        };
      case HttpStatusCode.unauthorized:
        this.toastService.show('Não autorizado', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');

      case HttpStatusCode.badRequest:
        this.toastService.show('Preencha todos os campos obrigatórios', {
          type: 'error',
        });
        throw new UnexpectedError('Preencha todos os campos obrigatórios');
      default:
        this.toastService.show('Unexpected Error', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected Error');
    }
  }
}
