import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { ContactRequestDTO } from 'domain/dtos/contact/contact-request-dto';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  SaveContactUseCase,
  ContactSaveResponseDTO,
} from 'domain/usecases/contact/save-contact-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteSaveContact implements SaveContactUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(contact: ContactRequestDTO): Promise<ContactSaveResponseDTO> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'post',
      url: this.url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Tenant: tenant,
      },
      body: contact,
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        this.toastService.show('Salvo com sucesso!', {
          type: 'success',
        });
        return {
          id: response.body.id,
          name: response.body.name,
          email: response.body.email,
        };
      case HttpStatusCode.unauthorized:
        this.toastService.show('Unauthorized', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');

      case HttpStatusCode.badRequest:
        this.toastService.show('Preencha todos os campos obrigatórios', {
          type: 'error',
        });
        throw new UnexpectedError('Preencha todos os campos obrigatórios');
      default:
        this.toastService.show('Unexpected Error', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected Error');
    }
  }
}
