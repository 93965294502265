import styled from 'styled-components';

export const WrapCards = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.lg};

  > div {
    max-width: 50%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;

    > div {
      max-width: 100%;
    }
  }
`;
