import { RefObject, useEffect } from 'react';

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: UIEvent) => void
): void {
  useEffect(() => {
    const listener = (event: any) => {
      const el = ref?.current;
      const isHandleable = el && !el.contains(event.target);
      if (isHandleable) return handler(event);
    };

    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);
    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };
  }, [ref, handler]);
}

export default useOnClickOutside;
