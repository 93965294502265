import styled from 'styled-components';
import Background from 'presentation/assets/images/background.png';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray.white};
`;

export const TextColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray[20]};
  height: 100%;
  width: 50%;
  background-color: ${({ theme }) => theme.colors.blue.primary};
  background-image: url(${Background});

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 900px) {
    > div > div > svg {
      width: 80%;
      display: block;
      margin: auto;
    }
  }

  > span {
    position: absolute;
    bottom: 2.813rem;
    font-size: 10px;
    color: ${({ theme }) => theme.colors.gray[50]};
  }
`;
export const BrowserWrap = styled.div`
  position: absolute;
  bottom: 2.813rem;
  right: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  > span {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.gray[50]};
  }
`;
export const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
`;

export const WrapperDesktopLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
`;

export const WrapperText = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: auto;
  width: 100%;
`;

export const WrapperForm = styled.div`
  padding: 45px 40px;
  position: relative;
  width: 100%;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  > form {
    width: auto;
    @media (min-width: 768px) {
      min-width: 450px;
    }
  }

  @media (max-width: 1160px) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media (max-width: 768px) {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: auto;
    padding: 0px 22px;
    width: 100%;
    border-radius: 0;
    padding-top: 76px;
    padding-bottom: 76px;
    background-color: ${({ theme }) => theme.colors.gray.white};
  }
`;

export const StyledForm = styled.div`
  max-width: 493px;
  width: 100%;

  @media (max-width: 768px) {
    max-width: unset;
  }
`;

export const LogoMobile = styled.div`
  display: none;

  & svg {
    width: 140px;
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    padding-bottom: 45px;
  }
`;

export const WrapperSmallText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.gray.black};

  > label {
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px) {
    & p {
      font-size: 14px;
    }
    & label {
      font-size: 14px;
    }
  }
`;

export const ForgotPassText = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.gray[200]};
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Title = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.blue.primary};
  text-align: center;
  @media (max-width: 768px) {
    color: ${({ theme }) => theme.colors.gray.white};
  }
`;

export const Highlight = styled.span`
  color: ${({ theme }) => theme.colors.blue.primary};
  > a {
    color: ${({ theme }) => theme.colors.blue.primary};
  }
`;
