import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { FunnelResponse } from 'domain/usecases/funnel/list-funnels-use-case';
import { PipeCard } from '..';
import * as S from './styles';
import { useDrop } from 'react-dnd';
import {
  makeListDealsUseCase,
  makeUpdateDealUseCase,
} from 'main/factories/usecases';
import PipeDealContext from 'presentation/pages/Deal/contexts/PipeDealContext';
import DealContext from 'presentation/pages/Deal/contexts/DealContext';
import { Box, Button } from 'mino-ui';
import {
  formatToReal,
  formatToRealRelative,
  maskReal,
} from 'main/helpers/format-to-real';

interface PipeListProps {
  funnel: FunnelResponse;
}

const PipeList = (props: PipeListProps): ReactElement => {
  const { funnel } = props;
  const { fetchFunnels, filter } = useContext(DealContext);

  const { deals, setDeals } = useContext(PipeDealContext);

  const [, setLoading] = useState(false);

  const [funnelDealsInfo, setFunnelDealsInfo] = useState({
    lastPage: 1,
    page: 1,
    totalValue: 0,
    total: 0,
  });

  async function updateDealAndMoveCard(
    dealId: string,
    funnelId: string
  ): Promise<void> {
    try {
      setLoading(true);
      await makeUpdateDealUseCase(dealId).execute({
        funnel_id: funnelId,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      fetchFunnels();
    }
  }

  const [{ isOver, canDrop }, dropRef] = useDrop(() => ({
    accept: 'CARD',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop(item: any) {
      const deal = item;

      if (deal.funnel_id === funnel.id) {
        return;
      }

      updateDealAndMoveCard(deal.id, funnel.id);
    },
  }));

  async function handleMoreButton() {
    try {
      const { data, lastPage, page } = await makeListDealsUseCase().execute({
        ...filter,
        page: funnelDealsInfo.page + 1,
        funnel_id: funnel.id,
        order_updated: 'desc',
      });

      const oldDeals = deals.filter((d) => d.funnel_id !== funnel.id);
      const oldFunnelDeals = deals.find(
        (d) => d.funnel_id === funnel.id
      )?.deals;

      setDeals([
        ...oldDeals,
        {
          deals: oldFunnelDeals ? [...oldFunnelDeals, ...data] : data,
          totalValue: funnelDealsInfo.totalValue,
          funnel_id: funnel.id,
          lastPage: lastPage,
          page: page,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    function getFunnelDealsInfo() {
      const funnelDeals = deals.find((d) => d.funnel_id === funnel.id);

      return {
        page: funnelDeals?.page || 1,
        lastPage: funnelDeals?.lastPage || 1,
        totalValue: funnelDeals?.totalValue || 0,
        total: funnelDeals?.total || 0,
      };
    }

    setFunnelDealsInfo(getFunnelDealsInfo());
  }, [deals, funnel.id]);

  return (
    <Box flexDirection="column">
      <S.ColumnTitle>
        <span>{funnel.name}</span>
        <div>
          <p>{funnelDealsInfo.total} Negócio(s)</p>

          <p>{maskReal(formatToReal(funnelDealsInfo.totalValue))}</p>
        </div>
      </S.ColumnTitle>
      <S.PipelineColumn
        ref={dropRef}
        className={isOver && canDrop ? 'is-over' : ''}
        key={funnel.id}
      >
        {deals.map((deal) => {
          if (deal.funnel_id === funnel.id) {
            return deal.deals.map((deal) => (
              <PipeCard key={deal.id} deal={deal} />
            ));
          }
        })}

        {funnelDealsInfo.lastPage > funnelDealsInfo.page && (
          <Button
            onClick={handleMoreButton}
            variant="ghost"
            text="Mostrar mais"
          />
        )}
      </S.PipelineColumn>
    </Box>
  );
};

export default PipeList;
