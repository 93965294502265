import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';

import {
  IFacebookToken,
  SaveFacebookTokenUseCase,
} from 'domain/usecases/facebook/save-token-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteSaveFacebookToken implements SaveFacebookTokenUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(payload: IFacebookToken): Promise<IFacebookToken> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'post',
      url: this.url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Tenant: tenant,
      },
      body: {
        access_token: payload.access_token,
        pages_ids: payload.pages_ids,
      },
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        this.toastService.show('Salvo com sucesso!', {
          type: 'success',
        });
        return {
          access_token: response.body.access_token,
          pages_ids: response.body.pages_ids,
        };
      case HttpStatusCode.unauthorized:
        this.toastService.show('Unauthorized', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');

      default:
        this.toastService.show('O token não foi salvo corretamente!', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected Error');
    }
  }
}
