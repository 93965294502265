import React, { ReactElement, useContext } from 'react';
import { Box, Button, Card, Typography } from 'mino-ui';
import * as S from './styles';
import { ReactSVG } from 'react-svg';
import Building from './icons/building.svg';
import Add from './icons/add.svg';
import CompanyContext from '../../contexts/CompanyContext';

const EmptyStateCompany = (): ReactElement => {
  const { setCreateCompany } = useContext(CompanyContext);
  return (
    <Card fill>
      <S.Container>
        <S.IconWrap>
          <ReactSVG src={Building} />
        </S.IconWrap>
        <Box flexDirection="column" alignItems="center" gap={24}>
          <Box flexDirection="column" justifyContent="center" gap={24}>
            <Typography
              variant="h4"
              fontSize={18}
              align="center"
              weight="bold"
              fill
              noWrap
            >
              Parece que nenhuma empresa <br />
              foi cadastrada ainda
            </Typography>
            <Typography variant="body" align={'center'} noWrap fontSize={12}>
              Você pode começar adicionando a primeira empresa <br />
              clicando no botão abaixo.
            </Typography>
          </Box>
          <Box>
            <Button
              text="Adicionar uma Empresa"
              size="medium"
              icon={Add}
              onClick={() => setCreateCompany(true)}
            />
          </Box>
        </Box>
      </S.Container>
    </Card>
  );
};

export default EmptyStateCompany;
