import React, { createRef, ReactElement, useEffect, useState } from 'react';
import { Box, Drawer, Input } from 'mino-ui';
import { RequestPayloadLoss } from 'domain/usecases/loss/save-loss-use-case';
import { makeSaveLossUseCase } from 'main/factories/usecases/loss/save-loss-factory';
import { useForm } from 'react-hook-form';

interface ManageDrawerProps {
  callback: () => void;
  updatePayload?: RequestPayloadLoss;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const ManageDrawer = (props: ManageDrawerProps): ReactElement => {
  const { callback, isOpen, setIsOpen, updatePayload } = props;

  const initialPayload: RequestPayloadLoss = {
    id: '',
    name: '',
  };

  const [loading, setLoading] = useState(false);

  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
  } = useForm<RequestPayloadLoss>({
    defaultValues: {
      id: updatePayload?.id,
      name: updatePayload?.name,
    },
  });

  function resetForm() {
    setValue('id', initialPayload.id);
    setValue('name', initialPayload.name);
  }

  function updateForm() {
    if (updatePayload) {
      setValue('id', updatePayload.id);
      setValue('name', updatePayload.name);
    }
  }

  async function saveLoss(data: RequestPayloadLoss) {
    try {
      setLoading(true);

      await makeSaveLossUseCase(data.id).execute(data, 'update');

      resetForm();
      callback();
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (updatePayload) {
      updateForm();
    }
  }, [updatePayload]);

  const submitRef = createRef<HTMLInputElement>();

  return (
    <Drawer
      submitText={'Salvar'}
      title={'Atualizar Perda'}
      onClose={() => {
        setIsOpen(false);
        resetForm();
      }}
      onSubmit={() => submitRef.current?.click()}
      isOpen={isOpen}
      loadingSubmit={loading}
    >
      <form onSubmit={handleSubmit(saveLoss)}>
        <Box gap={8} flexDirection="column">
          <Input hidden type="text" name="id" register={register} />

          <Input
            label="Nome"
            placeholder="Ex: Preço"
            name="name"
            register={register}
            errors={errors}
            validations={{
              required: true,
            }}
          />

          <input ref={submitRef} hidden type="submit" />
        </Box>
      </form>
    </Drawer>
  );
};
