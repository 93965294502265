import { RemoteLoadGroups } from 'data/usecases/group/remote-load-groups';
import { LoadGroupsUseCase } from 'domain/usecases/group/load-groups-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeLoadGroupsUseCase = (): LoadGroupsUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteLoadGroups(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/groups`,
    toastify
  );
};
