import React, { ReactElement, useState } from 'react';
import * as S from './styles';
import * as T from './types';
import Home from './icons/home.svg';
import Tasks from './icons/tasks.svg';
import Contacts from './icons/contacts.svg';
import Companies from './icons/companies.svg';
import Deals from './icons/deal.svg';
import { ReactSVG } from 'react-svg';

const BottomBar = (props: T.BottomBarprops): ReactElement => {
  const { hasText, navigate } = props;

  const styles = {
    hasText,
  };

  const [isSelected, setIsSelected] = useState();

  const location = window.location.pathname;

  return (
    <S.Container>
      <S.BottomBar onClick={() => setIsSelected(isSelected)} {...styles}>
        <S.Button
          onClick={() => navigate('/')}
          active={location == '/' ? true : false}
          {...styles}
        >
          <ReactSVG src={Home} />
          {hasText ? 'Home' : false}
        </S.Button>
        <S.Button
          onClick={() => navigate('/activities')}
          active={location == '/activities' ? true : false}
          {...styles}
        >
          <ReactSVG src={Tasks} />
          {hasText ? 'Tarefas' : false}
        </S.Button>
        <S.Button
          onClick={() => navigate('/deals')}
          active={location == '/deals' ? true : false}
          {...styles}
        >
          <ReactSVG src={Deals} />
          {hasText ? 'Negócios' : false}
        </S.Button>

        <S.Button
          onClick={() => navigate('/contacts')}
          active={location == '/contacts' ? true : false}
          {...styles}
        >
          <ReactSVG src={Contacts} />
          {hasText ? 'Contatos' : false}
        </S.Button>
        <S.Button
          onClick={() => navigate('/companies')}
          active={location == '/companies' ? true : false}
          {...styles}
        >
          <ReactSVG src={Companies} />
          {hasText ? 'Empresas' : false}
        </S.Button>
      </S.BottomBar>
    </S.Container>
  );
};

export default BottomBar;
