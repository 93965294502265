import { RemoteListTags } from 'data/usecases/tags/remote-list-tags';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeListTagUseCase = (): RemoteListTags => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteListTags(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/tags`,
    toastify
  );
};
