import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import {
  NotAuthorizedError,
  NotFoundError,
  UnexpectedError,
} from 'domain/errors';
import {
  DeleteImportBatchUseCase,
  ResponseDeleteImportBatch,
} from 'domain/usecases/import_batch/delete-import-batch-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteDeleteImportBatch implements DeleteImportBatchUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(): Promise<ResponseDeleteImportBatch> {
    const { tenant, token } = useToken();

    const response = await this.httpClient.request({
      method: 'delete',
      url: this.url,
      headers: {
        authorization: `Bearer ${token}`,
        tenant: tenant,
      },
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        this.toastService.show('Importação revertida', {
          type: 'success',
        });
        return { success: true };

      case HttpStatusCode.unauthorized:
        this.toastService.show('Não autorizado', { type: 'error' });
        throw new NotAuthorizedError('Unauthorized error');

      case HttpStatusCode.notFound:
        this.toastService.show('Importação não encontrada', {
          type: 'error',
        });
        throw new NotFoundError('Importation batch not found');

      default:
        this.toastService.show('Erro inesperado', { type: 'error' });
        throw new UnexpectedError('Unexécted error');
    }
  }
}
