import { RemoteCreateDealUseCase } from 'data/usecases';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeCreateDealUseCase = (): RemoteCreateDealUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteCreateDealUseCase(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/products`,
    toastify
  );
};
