import { IContact } from 'domain/entity/IContact';
import { ICustomField } from 'domain/entity/ICustomField';
import { createContext } from 'react';

export type SingleContactContextProps = {
  editContactOpen: boolean;
  setEditContact: (edit: boolean) => void;
  addDealDrawer: boolean;
  setAddDealDrawer: (boolean) => void;
  addTagsDialog: boolean;
  setAddTagsDialog: (boolean) => void;
  contact?: IContact;
  all_custom_fields: ICustomField[];
};
export const SingleContactContext = createContext(
  {} as SingleContactContextProps
);
