export const bodyLinks = [
  {
    icon: 'Home20Regular',
    label: 'Início',
    navTo: '/',
    route: '/',
  },
  {
    icon: 'Briefcase20Regular',
    label: 'Negócios',
    navTo: '/deals',
    route: '/deals',
  },
  {
    icon: 'BookContacts20Regular',
    label: 'Contatos',
    navTo: '/contacts',
    route: '/contacts',
  },
  {
    icon: 'BuildingMultiple20Regular',
    label: 'Empresas',
    navTo: '/companies',
    route: '/companies',
  },

  {
    icon: 'TasksApp20Regular',
    label: 'Tarefas',
    navTo: '/activities',
    route: '/activities',
  },
  {
    icon: 'ContentViewGallery20Regular',
    label: 'Painel',
    navTo: '/panel',
    route: '/panel',
  },
];

export const footerLinks = [
  {
    icon: 'Pin20Regular',
    label: 'Portal do cliente',
    navTo: 'https://billing.stripe.com/p/login/3csdSS4xVcudbteeUU',
    external: true,
  },
  {
    icon: 'Settings20Regular',
    label: 'Configurações',
    navTo: '/config',
    route: '/config',
  },
  {
    icon: 'QuestionCircle20Regular',
    label: 'Suporte e Ajuda',
    navTo:
      'https://minosuite.freshdesk.com/support/tickets/new?ticket_form=reportar_bug',
    external: true,
  },
];
