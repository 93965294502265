import React, { ReactElement, useContext, useRef, useState } from 'react';
import {
  Card,
  Typography,
  Avatar,
  Dialog,
  Box,
  Button,
  Tooltip,
  PopOver,
  Icon,
} from 'mino-ui';
import { makeDeleteActivityUseCase } from 'main/factories/usecases/activity/delete-activity-factory';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import { formatBrazilianDate } from 'main/helpers/formatBrazilianDate';
import { renderIcon, renderType } from './actions';
import * as S from './styles';
import * as T from './types';
import ActivityListContext from '../../context/ActivityListContext';
import { makeSaveActivityUseCase } from 'main/factories/usecases/activity/create-activity-factory';
import { ActivityFileList } from 'presentation/shared/templates/ActivityTabs/atoms/ActivityFileList';
import { IconColors } from '../..';
import { firstLetterUpper } from 'main/helpers';
import { Link } from 'react-router-dom';

const ListActivityCard = (props: T.ListActivityCardProps): ReactElement => {
  const { activity, callback } = props;

  const {
    title,
    id: activityId,
    contact,
    product,
    company,
    activity_type,
    start: date,
    description,
  } = activity;

  const [active, setActive] = useState(false);
  const [load, setLoad] = useState(false);

  const { setActivityToUpdate, setIsDrawerOpen } =
    useContext(ActivityListContext);

  const activityCardRef = useRef<HTMLDivElement>(null);

  const height_style_active = {
    height:
      active && activityCardRef.current
        ? `${activityCardRef.current.scrollHeight}px`
        : '0px',
    margin: '0.5rem 0',
  };

  const rotateIcon = {
    transform: active ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: '.3s ease-in-out',
  };

  const height_style = {
    height: '0px',
  };

  function toggleActive() {
    if (description) {
      setActive(!active);
    }
  }

  async function deleteActivity(activityId: string) {
    try {
      setLoad(true);
      await makeDeleteActivityUseCase(activityId).execute();
      callback();
      pressEscKey();
    } catch (error) {
      console.log(error);
    } finally {
      setLoad(false);
    }
  }

  async function changeActivityStatus(id: string) {
    try {
      setLoad(true);
      await makeSaveActivityUseCase(id).execute(
        {
          status: true,
          title: title,
          description: description,
          activity_type: activity_type,
          start: date,
        },
        'update'
      );

      callback();
      pressEscKey();
    } catch (error) {
      console.log(error);
    } finally {
      setLoad(false);
    }
  }

  function isContentHTML(value: string) {
    const regex = /(<([^>]+)>)/gi;
    return regex.test(value);
  }

  return (
    <Card>
      <S.Container>
        <S.Header>
          <S.Title
            onClick={toggleActive}
            type={activity_type}
            hasDescription={description ? true : false}
          >
            <Icon iconName={renderIcon(activity_type)} />
            <S.TypeWrap type={activity_type}>
              <Typography variant="body" weight="bold" fontSize={14} ellipsis>
                {firstLetterUpper(title ?? renderType(activity_type))}
              </Typography>
            </S.TypeWrap>
            {description ? (
              <div style={rotateIcon}>
                <S.DropIconWrap type={activity_type}>
                  <Icon iconName="ChevronDown20Regular" />
                </S.DropIconWrap>
              </div>
            ) : null}
          </S.Title>
          <Box gap={16}>
            <S.DateHourWrap>
              <Icon iconName="CalendarLtr20Regular" />
              <Typography
                fontSize={12}
                weight="regular"
                variant="body"
                color="gray"
              >
                {date ? formatBrazilianDate({ date: date }) : ''}
              </Typography>
            </S.DateHourWrap>
            <S.DateHourWrap>
              <Icon iconName={'Clock20Regular'} />
              <Typography
                fontSize={12}
                weight="regular"
                variant="body"
                color="gray"
              >
                {date ? formatBrazilianDate({ date: date, time: true }) : ''}
              </Typography>
            </S.DateHourWrap>
          </Box>
        </S.Header>

        {description ? (
          <S.Content
            ref={activityCardRef}
            style={active ? height_style_active : height_style}
          >
            {typeof description === 'string' ? (
              <>
                {isContentHTML(description) ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: description }}
                    style={{
                      fontSize: '80%',
                      background:
                        activity_type == 'E-mail' ? '#f9f9f9' : 'none',
                      padding: 16,
                      borderRadius: 8,
                    }}
                  />
                ) : (
                  <Typography
                    noWrap
                    lineHeight={16}
                    fontSize={12}
                    weight="regular"
                    color="gray"
                    variant="body"
                  >
                    {description}
                  </Typography>
                )}
              </>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: description || '' }} />
            )}
          </S.Content>
        ) : null}

        <S.Footer>
          <Box>
            {product && (
              <S.LinkWrap>
                <Link to={`/deals/${product.id}`}>
                  <Icon iconName="Briefcase20Regular" color={IconColors.blue} />
                  <Typography
                    align="center"
                    fontSize={12}
                    weight="bold"
                    color="primary"
                    variant="body"
                  >
                    {product.name}
                  </Typography>
                </Link>
              </S.LinkWrap>
            )}
            {contact && (
              <S.LinkWrap>
                <Link to={`/contacts/${contact.id}`}>
                  <Avatar width={20} name={contact.name} />
                  <Typography
                    align="center"
                    fontSize={12}
                    weight="bold"
                    color="primary"
                    variant="body"
                  >
                    {contact.name}
                  </Typography>
                </Link>
              </S.LinkWrap>
            )}
            {company && (
              <S.LinkWrap>
                <Link to={`/companies/${company.id}`}>
                  <Avatar width={20} name={company.name} />
                  <Typography
                    align="center"
                    fontSize={12}
                    weight="bold"
                    color="primary"
                    variant="body"
                  >
                    {company.name}
                  </Typography>
                </Link>
              </S.LinkWrap>
            )}
          </Box>

          <S.Actions>
            {activity.files && activity.files.length > 0 ? (
              <>
                <PopOver
                  side="bottom"
                  align="end"
                  content={<ActivityFileList files={activity.files} />}
                  trigger={
                    <S.IconWrap>
                      <Icon
                        iconName="Attach20Regular"
                        color={IconColors.dark}
                      />
                      <S.FileCount>
                        <span> {activity.files?.length}</span>
                      </S.FileCount>
                    </S.IconWrap>
                  }
                />
              </>
            ) : null}

            {!activity.on_google ? (
              <>
                <Tooltip
                  content="Excluir tarefa"
                  side="bottom"
                  trigger={
                    <Dialog
                      modal
                      title="Atenção!"
                      trigger={
                        <Button
                          size="small"
                          icon="Delete20Regular"
                          iconColor={IconColors.systemRed}
                          text=""
                          variant="ghost"
                        />
                      }
                      content={
                        <Box flexDirection="column" gap={32}>
                          <Typography noWrap variant="body">
                            Tem certeza que deseja excluir essa tarefa?
                          </Typography>

                          <Box gap={8}>
                            <Button
                              variant="primary"
                              disabled={load}
                              text={load ? 'Excluindo...' : 'Excluir'}
                              onClick={() => deleteActivity(`${activityId}`)}
                            />
                            <Button
                              onClick={() => pressEscKey()}
                              variant="outline"
                              text="Cancelar"
                            />
                          </Box>
                        </Box>
                      }
                    />
                  }
                />

                {!activity.on_google && !activity.status ? (
                  <>
                    <Tooltip
                      side="bottom"
                      content="Editar tarefa"
                      trigger={
                        <Button
                          size="small"
                          icon="Edit20Regular"
                          iconColor={IconColors.dark}
                          text=""
                          variant="ghost"
                          onClick={() => {
                            setActivityToUpdate(activity);
                            setIsDrawerOpen(true);
                          }}
                        />
                      }
                    />
                  </>
                ) : null}

                {activity.status != true ? (
                  <>
                    <Tooltip
                      content="Marcar como finalizada"
                      side="bottom"
                      trigger={
                        <Dialog
                          modal
                          onSubmit={() => changeActivityStatus(`${activityId}`)}
                          title="Atenção!"
                          trigger={
                            <Button
                              size="small"
                              icon="CheckmarkCircle20Regular"
                              iconColor={IconColors.blue}
                              text=""
                              variant="ghost"
                            />
                          }
                          content={
                            <Box flexDirection="column" gap={32}>
                              <Typography noWrap variant="body">
                                Tem certeza que deseja marcar essa tarefa como
                                finalizada?
                              </Typography>
                            </Box>
                          }
                        />
                      }
                    />
                  </>
                ) : null}
              </>
            ) : null}
          </S.Actions>
        </S.Footer>
      </S.Container>
    </Card>
  );
};
export default ListActivityCard;
