import styled, { css } from 'styled-components';

export const PipelineColumn = styled.div<{ className?: string }>`
  position: relative;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 290px;
  padding: 0 12px;
  overflow-y: scroll !important;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.gray[30]};
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray[50]};
    border-radius: 20px;
  }

  ${({ className }) =>
    className &&
    className.includes('is-over') &&
    css`
      background-color: ${({ theme }) => theme.colors.blue[50]};
      border: 1px dashed ${({ theme }) => theme.colors.gray[20]};
    `}
`;

export const ColumnTitle = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;

  > span {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.blue.primary};
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > p {
      color: ${({ theme }) => theme.colors.gray[200]};
      font-size: 0.75rem;
      font-weight: 700;
    }
  }
`;
