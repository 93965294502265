import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  ChannelResponse,
  ListChannelsUseCase,
} from 'domain/usecases/channel/list-channels-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteListChannels implements ListChannelsUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(): Promise<ChannelResponse[]> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Tenant: tenant,
      },
    });

    const data = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return data.map((channel: ChannelResponse) => ({
          id: channel.id,
          name: channel.name,
          created_at: channel.created_at,
        }));

      case HttpStatusCode.unauthorized:
        this.toastService.show('Unauthorized', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');
      default:
        this.toastService.show('Unexpected error', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
