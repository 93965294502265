import React, { ReactElement } from 'react';
import { ReactSVG } from 'react-svg';
import * as S from './styles';
import * as T from './types';
import DropIcon from './icons/dropbutton.svg';

const Collapse = (props: T.CollapseProps): ReactElement => {
  const { trigger, content, hasArrow, defaultOpen } = props;

  return (
    <S.Container defaultOpen={defaultOpen}>
      <S.Trigger>
        {hasArrow && (
          <S.IconWrap>
            <ReactSVG src={DropIcon} />
          </S.IconWrap>
        )}
        {trigger}
      </S.Trigger>

      <S.Content>{content}</S.Content>
    </S.Container>
  );
};

export default Collapse;
