import React, { ReactElement } from 'react';
import * as T from './types';
import * as S from './styles';
import Label from '../Label';
import { generateValidations } from '../Input/actions';
import { ErrorMessage } from '@hookform/error-message';

const Textarea = (props: T.TextareaProps): ReactElement => {
  const {
    name,
    value,
    placeholder,
    label,
    autoComplete,
    onChange,
    required,
    errors,
    defaultValue,
    full = false,
    rows = 3,
    validations,
    register,
    disabled = false,
  } = props;

  const styles = {
    full,
  };

  return (
    <S.Container {...styles}>
      {label && <Label text={label} />}

      <S.Textarea
        {...styles}
        {...(register &&
          register(name, validations && generateValidations(validations)))}
        data-testid="input-text"
        required={required}
        error={errors && errors[name]}
        autoComplete={autoComplete}
        name={name}
        rows={rows}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
      />
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <p>{message}</p>}
        />
      )}
    </S.Container>
  );
};

export default Textarea;
