import React, { ReactElement } from 'react';
import * as S from './styles';
import * as T from './types';
import Typography from '../Typography';
import Icon from '../Icon';

const Accordion = (props: T.AccordionProps): ReactElement => {
  const {
    type = 'multiple',
    items,
    collapsible = true,
    defaultValue,
    onValueChange,
    disabled = false,
    border = false,
    hasDivider,
  } = props;

  const styles = {
    border,
    hasDivider,
  };

  return (
    <S.Container
      data-testid="accordion"
      type={type}
      collapsible={collapsible}
      defaultValue={defaultValue}
      onValueChange={onValueChange}
      disabled={disabled}
    >
      {items.map((item, index) => (
        <S.Item key={index} value={item.value} {...styles}>
          <S.TriggerWrap>
            <S.Trigger {...styles}>
              {typeof item.trigger == 'string' ? (
                <Typography fontSize={14} weight="bold" variant="body" noWrap>
                  {item.trigger}
                </Typography>
              ) : (
                item.trigger
              )}
              <S.ArrowTrigger>
                <Icon iconName="ChevronDown20Regular" color={'blue'} />
              </S.ArrowTrigger>
            </S.Trigger>
          </S.TriggerWrap>

          <S.Content defaultChecked>
            {typeof item.content == 'string' ? (
              <Typography fontSize={14} weight="regular" noWrap>
                {item.content}
              </Typography>
            ) : (
              item.content
            )}
          </S.Content>
        </S.Item>
      ))}
    </S.Container>
  );
};
export default Accordion;
