import styled from 'styled-components';

export const Container = styled.div`
  @media (max-width: 768px) {
    overflow-x: auto;
  }
  min-height: 500px;
  width: 100%;
  & a {
    font-family: 'Noto Sans', sans-serif !important;
    color: ${({ theme }) => theme.colors.gray[200]};
  }

  --fc-border-color: ${({ theme }) => theme.colors.gray[20]};
  --fc-event-bg-color: ${({ theme }) => theme.colors.blue.primary};
  --fc-event-border-color: ${({ theme }) => theme.colors.blue.primary};
  --fc-event-text-color: ${({ theme }) => theme.colors.gray[20]};
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

  --fc-button-text-color: ${({ theme }) => theme.colors.gray.white};
  --fc-button-bg-color: ${({ theme }) => theme.colors.blue.primary};
  --fc-button-border-color: transparent;
  --fc-button-hover-bg-color: ${({ theme }) => theme.colors.blue[500]};
  --fc-button-hover-border-color: ${({ theme }) => theme.colors.blue[500]};
  --fc-button-active-bg-color: ${({ theme }) => theme.colors.blue[500]};
  --fc-button-active-border-color: ${({ theme }) => theme.colors.blue[500]};
  --fc-button-active-text-color: ${({ theme }) => theme.colors.gray[20]};
  //outline
  --fc-button-outline-text-color: ${({ theme }) => theme.colors.blue.primary};

  --fc-more-link-bg-color: ${({ theme }) => theme.colors.gray[200]};
  --fc-more-link-text-color: ${({ theme }) => theme.colors.blue.primary};

  .fc {
    min-height: inherit;
  }

  .fc-today-button {
    background-color: ${({ theme }) => theme.colors.gray[200]};
  }
`;
