import { IContact } from 'domain/entity/IContact';
import { RequestParamsListContacts } from 'domain/usecases/contact/list-contact-use-case';
import ContactList from 'presentation/pages/Contact/list';
import React, { useCallback, useState } from 'react';
import { makeListContactUseCase } from '../usecases';

export const makeContactListPage = (): React.ReactElement => {
  const [contactList, setContactList] = useState<IContact[]>([]);
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);

  const fetchContacts = useCallback(
    async (params: RequestParamsListContacts) => {
      try {
        setLoading(true);
        const listContactUseCase = makeListContactUseCase();
        const { data, total } = await listContactUseCase.execute({
          ...params,
        });
        setContactList(data);
        setTotal(total);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [setContactList, setLoading, setTotal]
  );

  return (
    <ContactList
      fetchContacts={fetchContacts}
      loading={loading}
      contacts={contactList}
      total={total}
    />
  );
};
