import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ hidden?: boolean }>`
  z-index: 1;
  display: flex;
  min-height: 48px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray.white};
  align-items: center;
  padding: 13px 24px;
  justify-content: space-between;
  position: sticky;
  border-left: solid 1px ${({ theme }) => theme.colors.gray[30]};

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`;

export const DialPadWrapper = styled.div`
  position: relative;
`;
export const DialPadContent = styled.div<{ display: 'none' | 'block' }>`
  position: absolute;
  top: 48px;
  right: 0;
  width: auto;
  visibility: hidden;

  ${({ display }) => display === 'block' && `visibility: visible;`}
`;

export const WrapperIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  gap: 1rem;

  & svg {
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    :hover {
      opacity: 0.8;
    }
  }
`;

export const LogoMobile = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
    height: 32px;
  }
`;

export const WrapperTrigger = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  gap: 0.5rem;
`;

export const MenuDropdown = styled.div``;

export const Linkstyled = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.gray[200]};
  gap: 10px;
  & svg {
    margin-right: 0px;
  }
`;
