import { RemoteLoadUsers } from 'data/usecases/user/remote-load-users';
import { LoadUsersUseCase } from 'domain/usecases/user/load-users-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeLoadUsersUseCase = (): LoadUsersUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteLoadUsers(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/users`,
    toastify
  );
};
