import React, { ReactElement } from 'react';
import * as S from './styles';
import { Spacer, Avatar, BreadCrumb, Icon, Dropdown, Button, Box } from '../..';
import { ReactSVG } from 'react-svg';
import ModivMobile from './icons/ModivMobile.svg';
import { TopbarProps } from './types';
import { generateBreadCrumbOptions } from './actions';
import { IconNames } from 'components/common/Icon/types';

const Topbar = (props: TopbarProps): ReactElement => {
  const {
    hidden,
    navigate,
    username,
    avatar,
    dialPad,
    hasActivePhoneNumber,
    handleLogout,
    dialPadOpen,
    onDialPadOpenChange,
  } = props;

  if (hidden) return <></>;

  const location = window.location.pathname;

  const breadCrumbItems = React.useMemo(
    () => generateBreadCrumbOptions(navigate),
    [location]
  );

  return (
    <S.Container hidden={hidden}>
      <S.LogoMobile onClick={() => navigate('/')}>
        <ReactSVG src={ModivMobile} />
      </S.LogoMobile>

      <BreadCrumb items={breadCrumbItems} />
      <S.WrapperIcons>
        <Spacer width={16} widthMobile={8} />

        {dialPad && hasActivePhoneNumber && (
          <S.DialPadWrapper>
            <Button
              text=""
              icon="Dialpad20Regular"
              variant="ghost"
              type="button"
              onClick={() => onDialPadOpenChange?.(!dialPadOpen)}
            />

            {dialPadOpen && (
              <S.DialPadContent display={dialPadOpen ? 'block' : 'none'}>
                {dialPad}
              </S.DialPadContent>
            )}
          </S.DialPadWrapper>
        )}

        <S.MenuDropdown>
          <Dropdown
            align="end"
            trigger={
              <Box gap={8}>
                <div>
                  <Avatar width={22} image={avatar || ``} name={username} />
                </div>
                <Icon iconName={IconNames.ChevronDown20Regular} />
              </Box>
            }
            items={[
              {
                value: 'profile',
                label: 'Meu perfil',
                avatar: 'https://avatars.githubusercontent.com/u/2254731?v=4',
              },
              {
                value: 'logout',
                label: 'Sair',
                icon: 'SignOut20Regular',
              },
            ]}
            onClick={(value) => {
              if (value === 'profile') {
                navigate('/profile');
              }
              if (value === 'logout') {
                handleLogout();
              }
            }}
          />
        </S.MenuDropdown>
      </S.WrapperIcons>
    </S.Container>
  );
};

export default Topbar;
