import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { UnexpectedError } from 'domain/errors';
import { ExportContactsUseCase } from 'domain/usecases/contact/export-contact-use-case';
import {
  FilterExportParams,
  FilterExportResponse,
} from 'domain/usecases/contact/export-contact-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteExportContacts implements ExportContactsUseCase {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient,
    private readonly toastService: ToastService
  ) {}

  async execute(filters: FilterExportParams): Promise<FilterExportResponse> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      url: this.url,
      method: 'get',
      params: filters,
      headers: {
        Authorization: `Bearer ${token}`,
        Tenant: tenant,
        'Content-Type': 'application/json',
      },
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return {
          url: response.body.url,
          success: response.body.success,
        };

      default:
        this.toastService.show('Erro ao exportar os dados', {
          type: 'error',
        });
        throw new UnexpectedError('Erro ao exportar os dados!');
    }
  }
}
