import { RemoteGetContact } from 'data/usecases/contact/remote-get-contact';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeGetSingleContactUseCase = (
  contactId: string
): RemoteGetContact => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteGetContact(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/contacts/${encodeURI(contactId)}`,
    toastify
  );
};
