import { DeleteGroupUseCase } from 'domain/usecases/group/delete-group-use-case';
import { RemoteDeleteGroup } from 'data/usecases/group/remote-delete-group';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeDeleteGroupUseCase = (id: string): DeleteGroupUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteDeleteGroup(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/groups/${id}`,
    toastify
  );
};
