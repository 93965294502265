import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Dialog,
  Select,
  Table,
  Textarea,
  Typography,
} from 'mino-ui';
import { useForm } from 'react-hook-form';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import { IWebhooks, WebHookForm } from './types';
import {
  DeleteWebHook,
  GetWebHooks,
  handleLoginRD,
  StoreWebHook,
  UpdatedToken,
} from './actions';
import { pressEscKey } from 'main/helpers/scape-key-esc';

export const IntegrateRD = (): ReactElement => {
  const { session } = useContext(SessionContext);

  const [loading, setLoading] = useState(false);
  const [webhooks, setWebHooks] = useState<IWebhooks[]>([]);
  const [access_token, setAccessToken] = useState<string>();

  const RD_TOKEN = session?.integrations?.rd;

  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<WebHookForm>({
    defaultValues: {
      event_type: 'WEBHOOK.CONVERTED',
    },
  });

  async function storeNewWebhook(data: WebHookForm) {
    if (!access_token) return;
    if (!session?.tenant) return;

    try {
      setLoading(true);
      await StoreWebHook({
        data: data,
        access_token,
        apiToken: session.tenant.api_token,
      });
    } catch (error) {
      console.error(error);
    } finally {
      fetchWebHooks(access_token);
      setLoading(false);
      setValue('event_identifiers', '');
      setValue('event_type', 'WEBHOOK.CONVERTED');
    }
  }

  async function handleRemoveWebHook(
    uuid: string,
    access_token: string
  ): Promise<void> {
    try {
      await DeleteWebHook(uuid, access_token);
    } catch (error) {
      console.error(error);
    } finally {
      pressEscKey();
      fetchWebHooks(access_token);
    }
  }

  async function fetchWebHooks(access_token: string): Promise<void> {
    const hooks = await GetWebHooks({
      tokenRD: RD_TOKEN.id,
      access_token,
    });

    setWebHooks(hooks);
  }

  useEffect(() => {
    if (access_token) fetchWebHooks(access_token);
  }, [access_token]);

  useEffect(() => {
    if (!RD_TOKEN.id) return;

    async function updateAccessToken() {
      try {
        const newToken = await UpdatedToken(
          RD_TOKEN.refresh_token,
          RD_TOKEN.id
        );

        if (newToken !== '') setAccessToken(newToken);
      } catch (error) {
        console.error(error);
      }
    }
    updateAccessToken();
  }, []);

  return !RD_TOKEN.access_token ? (
    <Button onClick={() => handleLoginRD()} text="Fazer login no RD Station" />
  ) : (
    <form onSubmit={handleSubmit(storeNewWebhook)}>
      <Card direction="column" gap={16}>
        <Card direction="column" gap={8} hasBorder>
          <Typography variant="h4" color="primary" fontSize={14}>
            Novo Webhook
          </Typography>

          <Select
            required
            label="Tipo de gatilho"
            placeholder="Selecione o tipo"
            name="event_type"
            control={control}
            defaultValue="WEBHOOK.CONVERTED"
            options={[
              {
                value: 'WEBHOOK.CONVERTED',
                label: 'Conversão',
              },
              {
                value: 'WEBHOOK.MARKED_OPPORTUNITY',
                label: 'Oportunidade',
              },
            ]}
          />

          <Textarea
            label="Identificadores"
            name="event_identifiers"
            register={register}
            errors={errors}
            placeholder="Identificadores separados por vírgula"
          />

          <Button
            size="small"
            type="submit"
            text={loading ? 'Carregando' : 'Adicionar'}
            disabled={loading}
          />
        </Card>

        {webhooks.length < 1 ? (
          <Typography variant="body" color="primary" fontSize={14}>
            Para ativar a automação, é necessário cadastrar um webhook.
          </Typography>
        ) : null}

        <Card direction="column" gap={8} hasBorder>
          <Typography variant="h4" color="dark" fontSize={14}>
            Webhooks cadastrados
          </Typography>

          <Table
            columns={[
              {
                field: 'active',
                headerName: 'Ativo',
              },
              {
                field: 'type',
                headerName: 'Gatilho',
              },
              {
                field: 'ids',
                headerName: 'Identificadores',
              },
              {
                field: 'actions',
                headerName: '',
              },
            ]}
            rows={webhooks.map((hook) => ({
              id: hook.uuid,
              active: hook.status ? 'Sim' : 'Não',
              type:
                hook.event_type === 'WEBHOOK.CONVERTED'
                  ? 'Conversão'
                  : 'Oportunidade',
              ids:
                hook.event_identifiers.length > 0
                  ? hook.event_identifiers.map((i) => i).join(', ')
                  : 'Todos',
              actions: (
                <Dialog
                  title="Confirma a exclusão"
                  trigger={
                    <Button
                      size="small"
                      text="Excluir"
                      type="button"
                      variant="ghost"
                    />
                  }
                  content={
                    <Box flexDirection="column" gap={24}>
                      <Typography variant="body" color="dark">
                        Tem certeza que deseja excluir o Webhook?
                      </Typography>

                      <Box gap={8}>
                        <Button
                          onClick={() =>
                            access_token &&
                            handleRemoveWebHook(hook.uuid, access_token)
                          }
                          type="button"
                          text="Excluir"
                          variant="active"
                        />
                        <Button
                          onClick={() => pressEscKey()}
                          type="button"
                          text="Cancelar"
                          variant="ghost"
                        />
                      </Box>
                    </Box>
                  }
                />
              ),
            }))}
          />
        </Card>
      </Card>
    </form>
  );
};
