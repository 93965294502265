declare global {
  interface Window {
    OneSignal: any;
  }
}

export default async function runOneSignal(userId: string): Promise<void> {
  window.OneSignal = window.OneSignal || [];
  window.OneSignal.push(function () {
    window.OneSignal.init({
      appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
      notifyButton: {
        enable: false,
      },
      promptOptions: {
        slidedown: {
          enabled: true,
          autoPrompt: true,

          actionMessage:
            'O Modiv CRM gostaria de enviar notificações importantes para você.',
          acceptButtonText: 'Permitir',
          cancelButtonText: 'Não permitir',
        },
      },
      allowLocalhostAsSecureOrigin: true,
      welcomeNotification: {
        disable: false,
        message:
          'Você ativou as notificações! Agora você receberá notificações tarefas e novos Leads.',
      },
    });

    window.OneSignal.setExternalUserId(userId.toString());
    console.log('OneSignal initialized!');
  });
}
