import React, { ReactElement } from 'react';
import * as S from './styles';
import * as T from './types';
import Icon from 'components/common/Icon';

const ToggleGroup = (props: T.ToggleGroupProps): ReactElement => {
  const {
    type = 'multiple',
    items,
    orientation = 'horizontal',
    onValueChange,
    defaultValue,
    value,
    size = 'medium',
  } = props;

  return (
    <S.Container
      defaultValue={defaultValue}
      type={type}
      orientation={orientation}
      onValueChange={onValueChange}
      value={value}
    >
      {items.map((item, index) => (
        <S.Items size={size} key={index} value={item.value} arrayIndex={index}>
          <Icon iconName={item.icon} />
          {item.label && item.label}
        </S.Items>
      ))}
    </S.Container>
  );
};
export default ToggleGroup;
