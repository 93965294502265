import React, { ReactElement, useRef, useState, useEffect } from 'react';
import * as S from './styles';
import * as T from './types';
import Button from '../Button';
import Back from './icons/back.svg';
import { ReactSVG } from 'react-svg';
import { Box } from '../..';
import useOnClickOutside from '../../../helpers/useOnClickOutside';

const Drawer = (props: T.DrawerProps): ReactElement => {
  const {
    children,
    onClose,
    isOpen,
    title,
    side = 'right',
    closeText = '',
    submitText,
    onSubmit,
    testId,
    width = 300,
    secondarySubmitText,
    secondarySubmitAction,
    loadingSubmit,
    loadingSubmitText,
    icon,
  } = props;

  const [isOpenDrawer, setIsOpenDrawer] = useState(isOpen);

  const DrawerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(DrawerRef, onClose);

  const styles = {
    side,
    width,
    secondarySubmitAction,
    submitText,
  };

  useEffect(() => {
    setIsOpenDrawer(isOpen);
  }, [isOpen]);

  return (
    <S.Container>
      {isOpenDrawer && (
        <S.Overlay>
          <S.Drawer
            data-testid={`${testId}-drawer`}
            ref={DrawerRef}
            {...styles}
          >
            <Box flexDirection="column">
              <S.Header {...styles}>
                <S.TitleWrap>
                  {icon && (
                    <S.IconWrap>
                      <ReactSVG src={icon} />
                    </S.IconWrap>
                  )}
                  <S.Title>{title}</S.Title>
                </S.TitleWrap>

                <S.ButtonWrapper>
                  <S.ButtonMobile onClick={onClose}>
                    <Button
                      size="small"
                      type="button"
                      variant="ghost"
                      onClick={onClose}
                      text=""
                      icon={Back}
                    />
                  </S.ButtonMobile>

                  <S.ButtonDesktop>
                    <Button
                      size="small"
                      type="button"
                      variant="ghost"
                      onClick={onClose}
                      text={closeText}
                      icon={Back}
                    />
                  </S.ButtonDesktop>
                </S.ButtonWrapper>
              </S.Header>

              <S.Content {...styles}>{children}</S.Content>
            </Box>

            {submitText && (
              <S.Footer>
                <Button
                  full
                  loading={loadingSubmit}
                  text={
                    loadingSubmit
                      ? loadingSubmitText || 'Carregando'
                      : submitText
                  }
                  variant="primary"
                  onClick={() => onSubmit && onSubmit()}
                  size="medium"
                />
                <S.ButtonSpacing />

                {secondarySubmitText && (
                  <Button
                    full
                    text={secondarySubmitText}
                    variant="ghostSecondary"
                    onClick={() =>
                      secondarySubmitAction && secondarySubmitAction()
                    }
                    size="medium"
                  />
                )}
              </S.Footer>
            )}
          </S.Drawer>
        </S.Overlay>
      )}
    </S.Container>
  );
};

export default Drawer;
