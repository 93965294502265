import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  Datepicker,
  KPICard,
  Select,
  Spacer,
  Table,
  ToggleGroup,
  Typography,
} from 'mino-ui';
import { NewDealsReportResponseDTO } from 'domain/usecases/report/new-deals-report-use-case';
import { formatToReal } from 'main/helpers/format-to-real';
import Fire from '../Home/icons/Fire.svg';
import MathSymbols from '../Home/icons/MathSymbols.svg';
import Like from '../Home/icons/Like.svg';
import Dislike from '../Home/icons/Dislike.svg';
import { useForm } from 'react-hook-form';
import { Bar, Pie } from 'react-chartjs-2';
import * as S from './styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { FunnelsDealsResponseDTO } from 'domain/usecases/deal/report-funnels-deals.use-case';
import { FilterProps } from 'main/factories/pages/PanelPageFactory';
import { LostReasonsResponseDTO } from 'domain/usecases/deal/report-lost-deals.use-case';
import {
  DealsResourcesPermissions,
  hasDealsPermissions,
} from 'presentation/hooks/usePermissions';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import { UsersDealsResponseDTO } from 'domain/usecases/deal/report-users-deals.use-case';
import First from './icons/1st.svg';
import Second from './icons/2nd.svg';
import Third from './icons/3rd.svg';
import { ReactSVG } from 'react-svg';
import { ChannelsDealsResponseDTO } from 'domain/usecases/deal/report-channels-deals.use-case';
import { EmptyState } from './components/EmptyState';

interface PanelPageProps {
  loading: boolean;
  fetchData: (filters: FilterProps) => void;
  newDeals?: NewDealsReportResponseDTO;
  earnedDeals?: NewDealsReportResponseDTO;
  lossedDeals?: NewDealsReportResponseDTO;
  funnelDealsReport?: FunnelsDealsResponseDTO[];
  filters: FilterProps;
  setFilters: (filters: FilterProps) => void;
  lostReasons?: LostReasonsResponseDTO[];
  usersDeals?: UsersDealsResponseDTO[];
  channelsDeals?: ChannelsDealsResponseDTO[];
}

const Panel = (props: PanelPageProps): ReactElement => {
  const { session } = useContext(SessionContext);

  const [lostReasonsLimit, setLostReasonsLimit] = useState(10);
  const [usersDealsLimit, setUsersDealsLimit] = useState(10);
  const [channelsDealsLimit, setChannelsDealsLimit] = useState(10);

  enum IconColors {
    blue = 'blue',
    dark = 'dark',
    systemRed = 'systemRed',
    systemGreen = 'systemGreen',
    violet = 'violet',
  }

  const {
    newDeals,
    earnedDeals,
    lossedDeals,
    loading,
    funnelDealsReport,
    setFilters,
    filters,
    lostReasons,
    usersDeals,
    channelsDeals,
  } = props;

  const conversionTax = (): number => {
    if (newDeals?.dealsNumber && earnedDeals?.dealsNumber) {
      return (earnedDeals?.dealsNumber / newDeals?.dealsNumber) * 100;
    }

    return 0;
  };

  const averageValue = (): number => {
    if (newDeals?.dealsNumber && newDeals?.dealsValue) {
      return newDeals?.dealsValue / newDeals?.dealsNumber;
    }

    return 0;
  };

  const lossedTax = (): number => {
    if (newDeals?.dealsNumber && lossedDeals?.dealsNumber) {
      return (lossedDeals?.dealsNumber / newDeals?.dealsNumber) * 100;
    }

    return 0;
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  };

  const labels = funnelDealsReport?.map((funnel) => funnel.name);

  const backgrounds = [
    '#00b2a8',
    '#ad6be1',
    '#6b9be1',
    '#667388',
    '#f0ae6b',
    '#6bd2cd',
    '#0052ca',
    '#7200cc',
    '#00cc52',
    '#cc0049',
    '#e57300',
  ];

  const data = {
    labels,
    datasets: [
      {
        label: 'Negócios',
        data: funnelDealsReport?.map((funnel) => funnel.deals),
        backgroundColor: backgrounds,
      },
    ],
  };

  const dataPie = {
    labels: funnelDealsReport?.map(
      (funnel) => funnel.name + ' ' + funnel.deals
    ),
    datasets: [
      {
        label: 'Negócios',
        data: funnelDealsReport?.map((funnel) => funnel.deals),
        backgroundColor: backgrounds,
      },
    ],
  };

  const dataPieLostReasons = {
    labels: lostReasons?.map((reason) => reason.name + ' ' + reason.dealsLost),
    datasets: [
      {
        label: 'Negócios',
        data: lostReasons?.map((reason) => reason.dealsLost),
        backgroundColor: backgrounds,
      },
    ],
  };

  const [chartMode, setChartMode] = useState<'bar' | 'pie'>('pie');

  const [chartModeLostReasons, setChartModeLostReasons] = useState<
    'table' | 'pie'
  >('pie');

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );

  const { control, handleSubmit, setValue } = useForm<FilterProps>();

  function handleFiltersChange(d: FilterProps) {
    setFilters(d);
  }

  useEffect(() => {
    if (filters) {
      setValue('startDate', filters.startDate);
      setValue('endDate', filters.endDate);
      setValue('userContext', filters.userContext);
    }
  }, [filters, setValue]);

  const lostReasonsRows =
    lostReasons?.slice(0, lostReasonsLimit).map((r, i) => ({
      id: i.toString(),
      name: r.name,
      deals: r.dealsLost,
    })) || [];

  const usersDealsRows =
    usersDeals?.slice(0, usersDealsLimit).map((r, i) => ({
      id: i.toString(),
      name: (
        <Box alignItems="center" gap={8}>
          <Avatar image={r.avatar} name={r.name} width={20} />
          <Typography weight="bold" variant="body" fontSize={12}>
            {r.name}
          </Typography>
        </Box>
      ),
      deals: `${r.dealsWon} vendas`,
      avatar: (
        <Box gap={4} alignItems="center">
          <Box alignItems="center">
            {i == 0 && <ReactSVG src={First} />}
            {i == 1 && <ReactSVG src={Second} />}
            {i == 2 && <ReactSVG src={Third} />}
            {i > 2 && <S.RankingWrap>{i + 1}</S.RankingWrap>}
          </Box>
        </Box>
      ),
    })) || [];

  const channelsDealsRows =
    channelsDeals?.slice(0, channelsDealsLimit).map((r, i) => ({
      id: i.toString(),
      name: r.name,
      deals: r.deals,
    })) || [];

  useEffect(() => {
    const hasWatchAllPermission = hasDealsPermissions(
      session,
      DealsResourcesPermissions.WATCH_ALL
    );

    if (hasWatchAllPermission) {
      setValue('userContext', 'all');
    }
  }, [session, setValue]);

  useEffect(() => {
    if (chartModeLostReasons === 'pie') {
      setLostReasonsLimit(50);
    }

    if (chartModeLostReasons === 'table') {
      setLostReasonsLimit(10);
    }
  }, [chartModeLostReasons, setLostReasonsLimit]);

  return (
    <Box boxMain alignItems="center">
      <Box style={{ maxWidth: 1200, width: '100%' }} justifyContent="center">
        <Box flexDirection="column" gap={24} style={{ width: '100%' }}>
          <Box style={{ width: '100%' }}>
            <Card justifyContent="space-between" fill gap={16}>
              <Box>
                <Typography color="dark" variant="h2" weight="black" noWrap>
                  Painel
                </Typography>
              </Box>
              <form onSubmit={handleSubmit(handleFiltersChange)}>
                <Box flexDirectionMobile="column" gap={8}>
                  <Datepicker
                    isClearable
                    name="startDate"
                    placeholder="Selecione a data"
                    control={control}
                  />
                  <Datepicker
                    isClearable
                    name="endDate"
                    placeholder="Selecione a data"
                    control={control}
                  />

                  {hasDealsPermissions(
                    session,
                    DealsResourcesPermissions.WATCH_ALL
                  ) ? (
                    <Select
                      fullWidth
                      name="userContext"
                      placeholder="Selecione o contexto"
                      control={control}
                      options={[
                        {
                          label: 'Meus',
                          value: 'my',
                        },
                        {
                          label: 'Todos',
                          value: 'all',
                        },
                      ]}
                    />
                  ) : null}

                  <Button
                    type="submit"
                    variant="ghost"
                    text="Filtrar"
                    icon="Filter20Filled"
                    iconColor={IconColors.blue}
                  />
                </Box>
              </form>
            </Card>
          </Box>

          <Box flexDirectionMobile="column" gap={24}>
            <KPICard
              loading={loading}
              titleColorStyle="orange"
              full
              title="Em aberto"
              icon={Fire}
              secondValue={newDeals?.dealsNumber.toString() || '0'}
              value={formatToReal(newDeals?.dealsValue || 0)}
            />
            <KPICard
              loading={loading}
              titleColorStyle="green"
              full
              icon={Like}
              title="Negócios Ganhos"
              secondValue={earnedDeals?.dealsNumber.toString() || '0'}
              value={formatToReal(earnedDeals?.dealsValue || 0)}
            />
            <KPICard
              loading={loading}
              icon={Dislike}
              titleColorStyle="red"
              full
              title="Negócios Perdidos"
              secondValue={lossedDeals?.dealsNumber.toString() || '0'}
              value={formatToReal(lossedDeals?.dealsValue || 0)}
            />
          </Box>

          <Box flexDirectionMobile="column" gap={24}>
            <KPICard
              loading={loading}
              titleColorStyle="gray"
              icon={MathSymbols}
              full
              title="Ticket Médio"
              value={formatToReal(averageValue())}
            />
            <KPICard
              loading={loading}
              titleColorStyle="gray"
              icon={MathSymbols}
              full
              title="Taxa de conversão"
              value={conversionTax().toFixed(2).toString().concat('%')}
            />
            <KPICard
              loading={loading}
              titleColorStyle="gray"
              icon={MathSymbols}
              full
              title="Taxa de perda"
              value={lossedTax().toFixed(2).toString().concat('%')}
            />
          </Box>

          <Box flexDirection="column" gap={24}>
            <Card flexWrap gap={16} direction="column" fill>
              <Box width="100%" flexDirection="column" gap={16}>
                <Typography color="gray" variant="body" weight="bold">
                  Funíl de Vendas
                </Typography>

                {data.labels?.length === 0 ? (
                  <EmptyState
                    iconColor={IconColors.blue}
                    title="Adicione novos negócios"
                    text="À medida que novos negócios são adicionados, um relatório será gerado com base na quantidade de negócios em cada etapa do funil"
                  />
                ) : (
                  <Box
                    width="100%"
                    flexDirection="column"
                    alignItems="center"
                    gap={16}
                  >
                    <ToggleGroup
                      type="single"
                      defaultValue={chartMode}
                      onValueChange={(v) =>
                        typeof v === 'string' &&
                        setChartMode(v as typeof chartMode)
                      }
                      items={[
                        {
                          value: 'pie',
                          label: 'Pizza',
                          icon: '',
                        },
                        {
                          value: 'bar',
                          label: 'Barras',
                          icon: '',
                        },
                      ]}
                    />

                    {chartMode === 'bar' && (
                      <S.BarsWraper maxWidth={800}>
                        <Bar data={data} options={options} />
                      </S.BarsWraper>
                    )}

                    {chartMode === 'pie' && (
                      <S.BarsWraper maxWidth={500}>
                        <Pie data={dataPie} options={options} />
                      </S.BarsWraper>
                    )}
                  </Box>
                )}
              </Box>
            </Card>

            {hasDealsPermissions(
              session,
              DealsResourcesPermissions.WATCH_ALL
            ) ? (
              <Card flexWrap gap={16} direction="column">
                <Box width="100%" flexDirection="column" gap={16}>
                  <Typography color="gray" variant="body" weight="bold">
                    Usuários que mais vendem
                  </Typography>
                  {usersDeals?.length === 0 ? (
                    <EmptyState
                      iconColor={IconColors.systemGreen}
                      title="Marque as vendas"
                      text="À medida que os vendedores marcarem vendas, um ranking será gerado com base na quantidade de vendas"
                    />
                  ) : (
                    <>
                      <Table
                        columns={[
                          {
                            field: 'avatar',
                            headerName: 'Posição',
                            width: 30,
                            align: 'left',
                          },
                          {
                            field: 'name',
                            headerName: 'Usuário',
                            width: 30,
                          },
                          {
                            field: 'deals',
                            headerName: 'N. de Vendas',
                            align: 'left',
                            width: 30,
                          },
                        ]}
                        rows={usersDealsRows}
                      />

                      {usersDeals && usersDealsLimit < usersDeals?.length ? (
                        <Button
                          variant="ghost"
                          size="small"
                          text="Ver mais"
                          onClick={() =>
                            setUsersDealsLimit(usersDealsLimit + 5)
                          }
                        />
                      ) : null}
                    </>
                  )}
                </Box>
              </Card>
            ) : null}
          </Box>

          <Box flexDirection="column" gap={24}>
            <Card>
              <Box width="100%" flexDirection="column" gap={16}>
                <Typography color="gray" variant="body" weight="bold">
                  Principais motivos de perda
                </Typography>

                {dataPieLostReasons.labels?.length === 0 ? (
                  <EmptyState
                    iconColor={IconColors.systemRed}
                    title="Marque as perdas"
                    text="À medida que negócios forem perdidos, um relatório será gerado com base nos motivos e na quantidade de perdas"
                  />
                ) : (
                  <>
                    <Box
                      width="100%"
                      flexDirection="column"
                      alignItems="center"
                      gap={16}
                    >
                      <ToggleGroup
                        type="single"
                        defaultValue={chartModeLostReasons}
                        onValueChange={(v) =>
                          typeof v === 'string' &&
                          setChartModeLostReasons(
                            v as typeof chartModeLostReasons
                          )
                        }
                        items={[
                          {
                            value: 'pie',
                            label: 'Pizza',
                            icon: '',
                          },
                          {
                            value: 'table',
                            label: 'Tabela',
                            icon: '',
                          },
                        ]}
                      />
                    </Box>

                    {chartModeLostReasons === 'pie' && (
                      <S.BarsWraper maxWidth={500}>
                        <Pie data={dataPieLostReasons} options={options} />
                      </S.BarsWraper>
                    )}

                    {chartModeLostReasons === 'table' && (
                      <>
                        <Table
                          columns={[
                            {
                              field: 'name',
                              headerName: 'Motivo de perda',
                              align: 'left',
                            },
                            {
                              field: 'deals',
                              headerName: 'Negócios perdidos',
                              align: 'left',
                            },
                          ]}
                          rows={lostReasonsRows}
                        />

                        {lostReasons &&
                        lostReasonsLimit < lostReasons?.length ? (
                          <Button
                            variant="ghost"
                            size="small"
                            text="Ver mais"
                            onClick={() =>
                              setLostReasonsLimit(lostReasonsLimit + 5)
                            }
                          />
                        ) : null}
                      </>
                    )}
                  </>
                )}
              </Box>
            </Card>

            <Card>
              <Box width="100%" flexDirection="column" gap={16}>
                <Typography color="gray" variant="body" weight="bold">
                  Principais canais de aquisão
                </Typography>

                {channelsDealsRows.length === 0 ? (
                  <EmptyState
                    iconColor={IconColors.violet}
                    title="Adicione a origem aos seus negócios"
                    text="À medida que os negócios forem adicionados com uma origem especificada, um relatório será gerado com base na quantidade de negócios por origem"
                  />
                ) : (
                  <>
                    <Table
                      columns={[
                        {
                          field: 'name',
                          headerName: 'Canal de aquisição',
                          align: 'left',
                        },
                        {
                          field: 'deals',
                          headerName: 'Negócios',
                          align: 'left',
                        },
                      ]}
                      rows={channelsDealsRows}
                    />
                    {channelsDeals &&
                    channelsDealsLimit < channelsDeals?.length ? (
                      <Button
                        variant="ghost"
                        size="small"
                        text="Ver mais"
                        onClick={() =>
                          setChannelsDealsLimit(channelsDealsLimit + 5)
                        }
                      />
                    ) : null}
                  </>
                )}
              </Box>
            </Card>
          </Box>

          <Spacer height={16} />
        </Box>
      </Box>
    </Box>
  );
};

export default Panel;
