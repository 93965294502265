import React, { ReactElement } from 'react';
import { BadgeProps } from './types';
import * as S from './styles';

const Badge = (props: BadgeProps): ReactElement => {
  const { variant = 'primary', value } = props;

  const styles = {
    variant,
  };

  return (
    <S.Container {...styles}>
      <S.Badge>{value}</S.Badge>
    </S.Container>
  );
};

export default Badge;
