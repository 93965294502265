import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const FolderContainer = styled.div`
  max-width: 15%;
`;

export const ListContainer = styled.div<{ width?: number }>`
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-left: 1px solid ${({ theme }) => theme.colors.gray[20]};
  padding-left: 8px;
  padding-right: 8px;
  overflow: auto;

  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
`;

export const ViewContainer = styled.div`
  width: 60%;
  height: 100%;
`;

export const MessageCard = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  height: 68px;
  padding: 8px;
  border-radius: 8px;
  gap: 8px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.gray[20]};
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.blue[50]};
    `}
`;
