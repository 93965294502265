import { format } from 'date-fns';

interface formatDateProps {
  date: Date;
  time?: boolean;
  timeAndDate?: boolean;
}
export const formatBrazilianDate = (props: formatDateProps): string => {
  const { date, time, timeAndDate } = props;

  const formatedDate = format(new Date(date), 'dd/MM/yyyy');

  if (formatedDate === '31/12/1969') {
    return '-';
  }

  if (time === true) {
    const hour = format(new Date(date), 'HH:mm');

    return hour;
  }

  if (timeAndDate === true) {
    const dateAndHour = format(new Date(date), 'dd/MM/yyyy - HH:mm');

    return dateAndHour;
  } else {
    return formatedDate;
  }
};
