import React, { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Divider, Input, Spacer, Typography } from 'mino-ui';
import * as S from './styles';
import { makeForgotPassword } from 'main/factories/usecases/sessions/forgot-password';

type MailSendForm = {
  email: string;
};

export const SendMailForm = (): ReactElement => {
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<MailSendForm>();

  async function submitForm(data: MailSendForm) {
    try {
      setLoading(true);
      await makeForgotPassword().execute(data.email);

      setSent(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(submitForm)}>
      <S.StyledForm>
        <Typography
          noWrap={true}
          alignMobile={sent ? 'center' : 'left'}
          variant="h2"
          align={sent ? 'center' : 'left'}
          weight="bold"
          color="dark"
          fill
        >
          Recuperação de senha
        </Typography>
        <Spacer height={8} />
        {sent ? (
          <Box
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap={24}
          >
            <Box width="80%">
              <Typography
                variant="h4"
                color="dark"
                fontSize={14}
                weight="regular"
                noWrap
                align="center"
              >
                Um email foi enviado para você com as instruções para redefinir
                sua senha!
              </Typography>
            </Box>
            <Button
              variant="primary"
              text="Voltar para o login"
              onClick={() => (window.location.href = '/login')}
            />
          </Box>
        ) : (
          <S.StyledForm>
            <Typography
              variant="h4"
              color="dark"
              fontSize={14}
              weight="regular"
              noWrap
            >
              Para recuperar sua senha, digite seu e-mail abaixo.
            </Typography>

            <Spacer height={12} heightMobile={24} />

            <Input
              label="E-mail"
              name="email"
              register={register}
              errors={errors}
              placeholder="E-mail"
              validations={{
                required: true,
                pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
              }}
            />

            <Spacer height={32} />

            <S.WrapperButton>
              <Box gap={16}>
                <Button
                  disabled={loading}
                  type="submit"
                  full
                  text={'Confirmar'}
                />
                <Button
                  onClick={() => (window.location.href = '/login')}
                  variant="ghost"
                  type="button"
                  full
                  text={'Voltar'}
                />
              </Box>
            </S.WrapperButton>
          </S.StyledForm>
        )}
      </S.StyledForm>
    </form>
  );
};
