import React from 'react';
import { createRoot } from 'react-dom/client';
import Layout from './presentation/layout';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://d68514bfc1ff4f1a81efd62fd274fc14@o339567.ingest.sentry.io/4504367708700672',
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0,
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(<Layout />);

serviceWorkerRegistration.register();
