import { RemoteDeleteActivity } from 'data/usecases/activity/remote-delete-activity';
import { DeleteActivityUseCase } from 'domain/usecases/activity/delete-activity-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeDeleteActivityUseCase = (
  activityId: string
): DeleteActivityUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteDeleteActivity(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/activities/${activityId}`,
    toastify
  );
};
