import React, { ReactElement, useState } from 'react';
import { Box, Dialog, Icon, Typography } from 'mino-ui';
import { DealSales } from 'presentation/shared/templates/Deals/dialogs/atoms/DealSales';
import { ISale } from 'domain/entity/ISale';
import Briefcase from '../../icons/Briefcase.svg';
import { CreateSaleResponse } from 'domain/usecases/sales/create-sale-use-case';

type SaleDialogProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  initialSales: ISale[];
  onSalesChange: (sales: CreateSaleResponse[]) => void;
};

export const SaleDialog = (props: SaleDialogProps): ReactElement => {
  const { open, onOpenChange, initialSales, onSalesChange } = props;

  const [sales, setSales] = useState<CreateSaleResponse[]>(initialSales);

  const [salesIdsToRemove, setSalesIdsToRemove] = useState<string[]>([]);

  function handleSaleChanges(newSales: CreateSaleResponse[]) {
    if (newSales !== sales) {
      setSales(
        newSales.map((newSale) => ({
          discount: newSale.discount,
          id: newSale.id,
          product_id: newSale.product_id,
          quantity: newSale.quantity,
          service_id: newSale.service_id,
          total: newSale.total,
        }))
      );
    }
  }

  return (
    <Dialog
      title="Editar Produtos/Valores"
      submitText="Salvar Alterações"
      onSubmit={() => onSalesChange(sales)}
      maxWidth={700}
      open={open}
      onOpenChange={onOpenChange}
      trigger={<></>}
      content={
        <Box flexDirection="column" gap={24}>
          <Box alignItems="center" gap={8}>
            <Icon color="blue" src={Briefcase} />

            <Typography variant="body" fontSize={16}>
              Valor total do negócio: &nbsp;
              <b>
                R$ {sales.reduce((acc, sale) => acc + sale.total, 0).toFixed(2)}
              </b>
            </Typography>
          </Box>

          <DealSales
            initialSales={initialSales.map((sale) => ({
              discount: sale.discount,
              id: sale.id,
              product_id: sale.product_id,
              quantity: sale.quantity,
              service_id: sale.service_id,
              total: sale.total,
              service: {
                id: sale.service.id,
                name: sale.service.name,
                price: sale.service.price,
              },
            }))}
            setDealSales={(newSales) => handleSaleChanges(newSales)}
            setDealValues={() => undefined}
            onRemoveSale={(saleId) => {
              setSalesIdsToRemove([...salesIdsToRemove, saleId]);
            }}
          />
        </Box>
      }
    />
  );
};
