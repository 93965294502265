import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ICompany } from 'domain/entity/ICompany';
import * as S from './styles';
import {
  Box,
  Typography,
  Spacer,
  Avatar,
  Button,
  Dialog,
  PopOver,
  Dropdown,
} from 'mino-ui';
import { SingleCompanyContext } from '../contexts/SingleCompanyContext';
import ActivitiesTemplate from 'presentation/shared/templates/Activitites/template';
import EditCompanyDrawer from './EditCompanyDrawer/component';
import CompanyCard from './components/CompanyCard/component';
import { SingleEntityPageSkeleton } from 'presentation/shared/templates/Skeletons/SingleEntityPageSkeleton';
import AddDealDrawer from './addDealDrawer/component';
import ProductCard from './components/ProductCard/component';
import ContactsCard from './components/ContactCard/component';
import {
  ActivityContext,
  EmailsModel,
  PhoneNumbersModel,
} from 'presentation/pages/Activity/context/ActivityContext';
import { useNavigate } from 'react-router';
import { makeToastify } from 'main/factories/notification';
import {
  ContactsResourcesPermissions,
  hasContactsPermissions,
} from 'presentation/hooks/usePermissions';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import { makeDeleteCompanyUseCase } from 'main/factories/usecases';
import AddContactDrawer from 'presentation/shared/templates/Contacts/addContactDialog';
import AttachTagsDialog from './components/AttachTags/component';
import TagsCard from './components/TagsCard/component';
import { ActivityTypeResponseDTO } from 'domain/dtos/activity/activity-dto';
import { useCustomFields } from 'main/hooks/useCustomFields';

interface PageProps {
  company: ICompany;
  loading: boolean;
  callback: () => void;
}

export enum IconColors {
  dark = 'dark',
  systemRed = 'systemRed',
  blue = 'blue',
}

type ActivityPayload = {
  title: string;
  description: string;
  start: Date;
  end: Date;
  status: boolean;
  activity_type: ActivityTypeResponseDTO;
};

const SingleCompanyPage = (props: PageProps): ReactElement => {
  const { company, callback, loading } = props;
  const [editCompanyOpen, setEditCompany] = useState(false);
  const [addDealDrawer, setAddDealDrawer] = useState(false);
  const [contactDrawer, setContactDrawer] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [addContactDrawerOpen, setAddContactDrawerOpen] = useState(false);
  const [addTagsDialog, setAddTagsDialog] = useState(false);
  const [textWidth, setTextWidth] = useState(0);

  const textContent = useRef<HTMLDivElement>(null);

  const { session } = useContext(SessionContext);

  const { handleSubmit } = useContext(ActivityContext);

  const navigate = useNavigate();

  interface GroupListProps {
    label: string;
    value: string;
    icon: string;
    iconColor?: IconColors;
  }

  interface CompanyArrayInfo {
    label: string;
    icon?: string;
    iconColor?: IconColors;
  }

  const GroupListArray: GroupListProps[] = [];

  if (hasContactsPermissions(session, ContactsResourcesPermissions.EDIT)) {
    GroupListArray.push({
      label: 'Editar',
      value: 'edit',
      icon: 'Pen20Regular',
    });
  }
  if (hasContactsPermissions(session, ContactsResourcesPermissions.DELETE)) {
    GroupListArray.push({
      label: 'Excluir',
      value: 'delete',
      icon: 'Delete20Regular',
      iconColor: IconColors.systemRed,
    });
  }

  const CompanyInfo: CompanyArrayInfo[] = [];

  if (company.email) {
    CompanyInfo.push({
      label: company.email,
      icon: 'Mail20Regular',
    });
  }
  if (company.phone) {
    CompanyInfo.push({
      label: company.phone,
      icon: 'Call20Regular',
    });
  }

  const redirectIfIsNotOwnerAndHasNoPermission = (): void => {
    const toast = makeToastify();
    const hasPermission = hasContactsPermissions(
      session,
      ContactsResourcesPermissions.WATCH_ALL
    );

    if (!hasPermission && company.user) {
      if (company.user.id !== session.id) {
        toast.show(
          'Você não tem permissões para acessar empresas de outros usuários',
          {
            type: 'info',
          }
        );

        navigate('/companies');
      }
    }
  };

  async function deleteCompany(companyId: string) {
    try {
      setDeleteLoading(true);
      const res = await makeDeleteCompanyUseCase(companyId).execute();
      if (res.success) {
        navigate('/companies');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteLoading(false);
    }
  }

  async function submitActivity(data: ActivityPayload) {
    try {
      await handleSubmit(data);
    } catch (error) {
      console.error(error);
    }
  }

  const phoneNumbers: PhoneNumbersModel[] = [];
  const emails: EmailsModel[] = [];

  if (company?.phone) {
    phoneNumbers.push({
      entity: 'company',
      name: company.name,
      phone: company.phone,
    });
  }

  if (company?.email) {
    emails.push({
      entity: 'company',
      name: company.name,
      email: company.email,
    });
  }

  if (company?.contacts) {
    company.contacts.forEach((contact) => {
      if (contact.phone) {
        phoneNumbers.push({
          entity: 'contact',
          name: contact.name,
          phone: contact.phone,
        });
      }

      if (contact.email) {
        emails.push({
          entity: 'contact',
          name: contact.name,
          email: contact.email,
        });
      }
    });
  }

  function handleSendEmail(email: string) {
    window.open(`mailto:${email}`, '_blank');

    submitActivity({
      title: 'E-mail',
      activity_type: 'E-mail',
      description: 'Email enviado',
      start: new Date(),
      end: new Date(),
      status: true,
    });
  }

  function handleCall(number: string) {
    window.open(
      `tel:${number.replace(/\D/g, '')}`,
      '_blank',
      'location=yes,height=570,width=520,scrollbars=yes,status=yes'
    );
  }

  useEffect(() => {
    redirectIfIsNotOwnerAndHasNoPermission();
  }, [company]);

  const handleResize = useCallback(() => {
    if (textContent.current && CompanyInfo.length > 0) {
      setTextWidth(textContent.current.offsetWidth + 48 + 8);
    }
  }, [textContent, CompanyInfo]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const { custom_fields } = useCustomFields('company');

  return (
    <SingleCompanyContext.Provider
      value={{
        editCompanyOpen,
        setEditCompany,
        addDealDrawer,
        setAddDealDrawer,
        contactDrawer,
        setContactDrawer,
        addContactDrawerOpen,
        setAddContactDrawerOpen,
        addTagsDialog,
        setAddTagsDialog,
        company,
        all_custom_fields: custom_fields,
      }}
    >
      <S.Toolbar>
        <S.ToolbarLeftContent>
          <Avatar width={32} name={company?.name || ''} />
          <Typography
            variant="body"
            color="dark"
            fontSize={18}
            weight="bold"
            overflowHidden
            width={`calc(100% - ${textWidth}px)`}
          >
            {company?.name}
          </Typography>
        </S.ToolbarLeftContent>
        <S.ToolbarRightContent ref={textContent}>
          <Box alignItems="center" justifyContent="center" gap={16}>
            {CompanyInfo.map((c, index) =>
              index < CompanyInfo.length - 1 ? (
                <S.ArrayInfoWrap key={index}>
                  <Box alignItems="center" justifyContent="center" gap={4}>
                    <Button
                      text={c.label}
                      variant="ghost"
                      icon={c.icon}
                      iconColor={IconColors.blue}
                      onClick={() => handleSendEmail(c.label)}
                    />
                  </Box>

                  <S.Separator />
                </S.ArrayInfoWrap>
              ) : (
                <S.ArrayInfoWrap key={index}>
                  <Box alignItems="center" justifyContent="center" gap={4}>
                    <Button
                      text={c.label}
                      variant="ghost"
                      icon={c.icon}
                      iconColor={IconColors.blue}
                      onClick={() => {
                        handleCall(c.label);
                      }}
                    />
                  </Box>
                </S.ArrayInfoWrap>
              )
            )}

            <Dropdown
              trigger={'action-button'}
              items={GroupListArray}
              onChange={(value) => {
                if (value === 'edit') {
                  setEditCompany(true);
                }
                if (value === 'delete') {
                  setDialogOpen(true);
                }
              }}
            />
          </Box>
        </S.ToolbarRightContent>
      </S.Toolbar>
      <Box boxMain>
        <S.Container>
          <S.GridArea>
            {loading ? (
              <SingleEntityPageSkeleton />
            ) : (
              <>
                <S.MainContent>
                  {company && (
                    <ActivitiesTemplate
                      context="company"
                      phoneNumbers={phoneNumbers}
                      emails={emails}
                      contextId={company.id}
                    />
                  )}

                  <Spacer height={16} />
                </S.MainContent>

                <S.RightContent>
                  {company && <CompanyCard company={company} />}

                  {company?.deals ? (
                    <ProductCard callback={callback} products={company.deals} />
                  ) : null}

                  {company?.tags ? <TagsCard tags={company.tags} /> : null}

                  {company?.contacts ? (
                    <ContactsCard contacts={company.contacts} />
                  ) : null}
                  <Spacer height={64} />
                </S.RightContent>
              </>
            )}
          </S.GridArea>
        </S.Container>
      </Box>
      {company && (
        <EditCompanyDrawer
          callback={callback}
          company={company}
          isDrawerOpen={editCompanyOpen}
          setDrawerOpen={setEditCompany}
        />
      )}

      {company ? (
        <AddDealDrawer
          isDrawerOpen={addDealDrawer}
          setDrawerOpen={setAddDealDrawer}
          companyId={company.id}
          callback={callback}
        />
      ) : null}

      <AddContactDrawer
        isDialogOpen={addContactDrawerOpen}
        setDialogOpen={setAddContactDrawerOpen}
        callback={callback}
        singleId={company.id}
        context="company"
      />

      {company.tags && (
        <AttachTagsDialog
          callback={callback}
          company={company}
          tags={company.tags}
        />
      )}

      <Dialog
        modal
        title="Atenção!"
        open={dialogOpen}
        onOpenChange={() => setDialogOpen(false)}
        content={
          <Box flexDirection="column" gap={32}>
            <Typography noWrap variant="body">
              Tem certeza que deseja excluir esse empresa?
            </Typography>

            <Box gap={8}>
              <Button
                loading={deleteLoading}
                onClick={() => deleteCompany(company.id)}
                variant="primary"
                text={'Confirmar'}
              />
              <Button
                onClick={() => pressEscKey()}
                variant="outline"
                text="Cancelar"
              />
            </Box>
          </Box>
        }
      />
    </SingleCompanyContext.Provider>
  );
};
export default SingleCompanyPage;
