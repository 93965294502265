import { RemoteDeleteImportBatch } from 'data/usecases/import_batch/remote-delete-import-batch';
import { DeleteImportBatchUseCase } from 'domain/usecases/import_batch/delete-import-batch-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeDeleteImportBatchFactory = (
  id: string
): DeleteImportBatchUseCase => {
  const axios = new AxiosProvider();
  const toast = new ToastifyProvier();

  return new RemoteDeleteImportBatch(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/imports/${id}`,
    toast
  );
};
