import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { IGroup } from 'domain/entity/IGroup';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  SaveGroupRequestPayload,
  SaveGroupUseCase,
} from 'domain/usecases/group/save-group-use-case';

import { useToken } from 'main/hooks/usetoken';

export class RemoteSaveGroup implements SaveGroupUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(
    payload: SaveGroupRequestPayload,
    type?: 'create' | 'update'
  ): Promise<IGroup> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: type === 'update' ? 'put' : 'post',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Tenant: tenant,
      },
      body: payload,
    });

    const data = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok: {
        this.toastService.show(
          type === 'update'
            ? 'Atualizado com sucesso!'
            : ' Adicionado com sucesso!',
          {
            type: 'success',
          }
        );

        return data as IGroup;
      }
      case HttpStatusCode.unauthorized:
        this.toastService.show('Unauthorized', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');

      case HttpStatusCode.badRequest:
        this.toastService.show('Erro nos campos preenchidos', {
          type: 'error',
        });
        throw new NotAuthorizedError('Bad Request');
      default:
        this.toastService.show('Erro inesperado no servidor!', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
