import styled, { css } from 'styled-components';
import { CardPosition } from './types';

interface CardStyledProps {
  position?: CardPosition;
  padding?: string;
  direction?: 'row' | 'column';
  hasShadow?: boolean;
  radius?: string;
  align?: 'flex-start' | 'center' | 'flex-end';
  gap?: number;
  mobileDirection?: 'row' | 'column';
  hasBorder?: boolean;
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between';
  flexWrap?: boolean;
  fillHeight?: boolean;
  fill?: boolean;
}

export const Container = styled.div<CardStyledProps>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray.white};
  padding: ${({ theme }) => theme.spacing.lg};
  border-radius: 0.625rem;
  display: flex;
  box-shadow: none;

  ${({ fillHeight }) =>
    fillHeight &&
    css`
      height: 100%;
    `}
  ${({ fill }) =>
    fill &&
    css`
      flex: 1;
    `}

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}
  ${({ hasBorder }) =>
    hasBorder &&
    css`
      border: solid 1px ${({ theme }) => theme.colors.gray[30]};
    `}

  ${({ hasShadow }) =>
    hasShadow === true &&
    css`
      box-shadow: ${({ theme }) => theme.shadow.default};
    `}

  ${({ radius }) =>
    radius &&
    css`
      border-radius: ${radius};
    `}

  ${({ position }) =>
    position &&
    css`
      position: ${position};
    `}

  ${({ direction }) =>
    direction &&
    css`
      flex-direction: ${direction};
    `}  
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}  
  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: wrap;
    `}  

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    ${({ mobileDirection }) =>
      mobileDirection &&
      css`
        flex-direction: ${mobileDirection};
      `}
  }

  ${({ align }) =>
    align &&
    css`
      align-items: ${align};
    `}

  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}
`;
