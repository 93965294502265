import { RemoteDeleteChannel } from 'data/usecases/channel/remote-delete-channel';
import { DeleteChannelUseCase } from 'domain/usecases/channel/delete-channel-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeDeleteChannelUseCase = (id: string): DeleteChannelUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteDeleteChannel(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/channels/${id}`,
    toastify
  );
};
