import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as S from './styles';
import LogoMobile from 'presentation/assets/icons/ModivLogoDarkTextRigth.svg';
import Logo from 'presentation/assets/icons/ModivWhiteLogoTextRigth.svg';
import { Box, Button, Input, Typography } from 'mino-ui';
import { makeAcceptInvite } from 'main/factories/usecases/sessions/accept-invite';
import { ReactSVG } from 'react-svg';

type InvitationSendForm = {
  token: string;
  email: string;
  password: string;
  passwordConfirmation: string;
};

const InvitationPage = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [name, setName] = useState('');
  const [tenant, setTenant] = useState('');

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    setValue,
  } = useForm<InvitationSendForm>();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const token = urlParams.get('token');
    const email = urlParams.get('email');
    const name = urlParams.get('name');
    const tenant = urlParams.get('tenant');

    if (name) {
      setName(name);
    }
    if (tenant) {
      setTenant(tenant);
    }

    if (token) {
      setValue('token', token);
    }
    if (email) {
      setValue('email', email);
    }
  }, [setValue]);

  async function submitForm(data: InvitationSendForm) {
    if (data.password != data.passwordConfirmation) {
      setError('passwordConfirmation', {
        type: 'manual',
        message: 'As senhas não são iguais',
      });
      return;
    }
    try {
      setLoading(true);
      const response = await makeAcceptInvite().execute({
        token: data.token,
        password: data.password,
      });

      if (response.success) {
        setSuccess(true);
        setTimeout(() => {
          window.location.href = 'login';
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <S.Container>
      <>
        <S.LeftColumn>
          <S.WrapperDesktopLogo>
            <ReactSVG src={LogoMobile} />
          </S.WrapperDesktopLogo>

          <form onSubmit={handleSubmit(submitForm)}>
            <S.WrapperForm>
              <Box flexDirection="column" gap={8}>
                <Typography variant="h3" fontSize={24} weight={'bold'}>
                  Convite do Modiv CRM
                </Typography>
                <Typography variant="body" fontSize={12} noWrap={true}>
                  Olá, <span>{name}</span>! Você recebeu um convite do(a){' '}
                  <span>{tenant}</span> para usar o Modiv CRM. Crie uma senha
                  abaixo para começar a usar.
                </Typography>
              </Box>

              <Box flexDirection="column" gap={20}>
                <Input
                  name="email"
                  label="E-mail"
                  placeholder="Digite seu e-mail"
                  register={register}
                  disabled={true}
                />
                <Input
                  name="password"
                  label="Senha"
                  placeholder="Crie sua senha"
                  errors={errors}
                  register={register}
                  type="password"
                  autoComplete="off"
                  validations={{
                    required: true,
                    minLength: 6,
                  }}
                />
                <Input
                  name="passwordConfirmation"
                  label="Confirmar Senha"
                  placeholder="Confirme sua Senha"
                  errors={errors}
                  register={register}
                  type="password"
                  autoComplete="off"
                  validations={{
                    required: true,
                  }}
                />
              </Box>

              <Button
                text={success ? 'Redirecionando...' : 'Registrar'}
                type="submit"
                full={true}
                disabled={loading || success}
              />
            </S.WrapperForm>
          </form>
        </S.LeftColumn>

        <S.rigthColumn>
          <ReactSVG src={Logo} />
        </S.rigthColumn>
      </>
    </S.Container>
  );
};

export default InvitationPage;
