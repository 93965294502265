import { RemoteGetFunnelsDealsReport } from 'data/usecases/deal/remote-get-funnels-deals-report-use-case';
import { GetReportFunnelsDealsUseCase } from 'domain/usecases/deal/report-funnels-deals.use-case';
import { AxiosProvider } from 'infra/http';

export const makeGetFunnelsDeals = (): GetReportFunnelsDealsUseCase => {
  const axios = new AxiosProvider();

  return new RemoteGetFunnelsDealsReport(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/reports/deals/funnels`
  );
};
