import styled from 'styled-components';
import Background from 'presentation/assets/images/background.png';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f1f5fa;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  height: 565px;
  background-color: ${({ theme }) => theme.colors.gray.white};
  border-radius: 1rem 0rem 0rem 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-width: 358px;
    height: fit-content;
    padding: 2rem 1rem;
    border-radius: 0.5rem;
  }
`;

export const WrapperDesktopLogo = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;

    & svg {
      width: 100px;
    }
  }
`;

export const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 371px;
  gap: 2rem;

  & span {
    font-weight: 700;
  }
`;

export const rigthColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 565px;
  width: 435px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.blue.primary};
  border-radius: 0rem 1rem 1rem 0rem;
  background-image: url(${Background});

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;
