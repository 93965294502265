import axios from 'axios';
import { UnexpectedError } from 'domain/errors';
import { makeToastify } from 'main/factories/notification';
import { useToken } from 'main/hooks/usetoken';
import { IWebhooks, WebHookForm } from './types';

const toastify = makeToastify();

interface GetWebHooksProps {
  access_token: string;
  tokenRD: string;
}

type StoreWebHookRequest = {
  data: WebHookForm;
  access_token: string;
  apiToken: string;
};

export function handleLoginRD(): void {
  window.location.href = `https://api.rd.services/auth/dialog?client_id=${
    process.env.REACT_APP_RD_STATION_CLIENT_ID || ''
  }&redirect_uri=${process.env.REACT_APP_RD_STATION_REDIRECT_URI || ''}`;
}

export async function UpdatedToken(
  refresh_token: string,
  tokenRD: string
): Promise<string> {
  const apiToken = useToken();

  let token = '';

  await axios
    .post(process.env.REACT_APP_API_BASE_URL + '/rd/get-token', {
      refresh_token: refresh_token,
    })
    .then(async (response: any) => {
      await axios.put(
        process.env.REACT_APP_API_BASE_URL + '/token-rd/' + tokenRD,
        {
          access_token: response.data.access_token,
          refresh_token: response.data.refresh_token,
        },
        {
          headers: {
            Authorization: `Bearer ${apiToken.token}`,
            Tenant: apiToken.tenant || '',
          },
        }
      );

      token = response.data.access_token;
    })
    .catch((err) => {
      console.error(err);
      toastify.show('Erro ao atualizar token!', {
        type: 'error',
      });
    });

  return token;
}

export async function GetWebHooks(
  props: GetWebHooksProps
): Promise<IWebhooks[]> {
  const { access_token } = props;

  const data = await axios.get(
    process.env.REACT_APP_API_BASE_URL + '/rd/get-webhooks',
    {
      headers: {
        Token: access_token,
      },
    }
  );

  switch (data.status) {
    case 200:
      return data.data.webhooks as IWebhooks[];

    default:
      toastify.show('Erro ao listar webhooks!', {
        type: 'error',
      });

      return [];
  }
}

export async function StoreWebHook(
  props: StoreWebHookRequest
): Promise<{ success: boolean }> {
  const {
    data: { event_type, event_identifiers },
    access_token,
    apiToken,
  } = props;

  const eventsArray = event_identifiers
    ? event_identifiers?.split(',')
    : undefined;

  const webhookData = await axios.post(
    process.env.REACT_APP_API_BASE_URL + '/rd/create-webhook',
    {
      event_type: event_type || 'WEBHOOK.CONVERTED',
      entity_type: 'CONTACT',
      event_identifiers: eventsArray,
      url: `${process.env.REACT_APP_API_BASE_URL}/rd/insert/${apiToken}`,
      http_method: 'POST',
      include_relations: ['COMPANY', 'CONTACT_FUNNEL'],
    },
    {
      headers: {
        Token: access_token,
      },
    }
  );

  switch (webhookData.status) {
    case 200:
      toastify.show('Salvo com sucesso!', {
        type: 'success',
      });
      return {
        success: true,
      };
    default:
      toastify.show('Erro ao criar webhook!', {
        type: 'error',
      });
      throw new UnexpectedError('Error inesperado ao salvar webhook!');
  }
}

export async function DeleteWebHook(
  uuid: string,
  access_token: string
): Promise<{ success: boolean }> {
  const webhookData = await axios.delete(
    process.env.REACT_APP_API_BASE_URL + '/rd/delete-webhook/' + uuid,
    {
      headers: {
        Token: access_token,
      },
    }
  );

  switch (webhookData.status) {
    case 200:
      toastify.show('Excluído com sucesso!', {
        type: 'success',
      });
      return {
        success: true,
      };
    default:
      toastify.show('Erro ao excluir webhook!', {
        type: 'error',
      });
      throw new UnexpectedError('Error inesperado ao excluir webhook!');
  }
}
