export const segments = [
  {
    label: 'Educação',
    value: 'educacao',
  },
  {
    label: 'Tecnologia',
    value: 'tecnologia',
  },
  {
    label: 'Direito',
    value: 'direito',
  },
  {
    label: 'Vendas',
    value: 'vendas',
  },
  {
    label: 'Marketing',
    value: 'marketing',
  },
  {
    label: 'Outros',
    value: 'outros',
  },
];

export const collaborators = [
  {
    label: '1 - 10',
    value: '1-10',
  },
  {
    label: '11 - 25',
    value: '11-25',
  },
  {
    label: '26 - 50',
    value: '26-50',
  },
  {
    label: '51 - 100',
    value: '51-100',
  },
  {
    label: '101 - 500',
    value: '101-500',
  },
  {
    label: '501 - 1000',
    value: '501-1000',
  },
];

export const whyINeedCRM = [
  {
    label: 'Para acompanhar o desempenho da minha equipe',
    value: 'desempenho da minha equipe',
  },
  {
    label: 'Para gerenciar minhas vendas',
    value: 'gerenciar minhas vendas',
  },
  {
    label: 'Para gerenciar minhas campanhas de marketing',
    value: 'gerenciar minhas campanhas de marketing',
  },
  {
    label: 'Para organizar minhas tarefas',
    value: 'organizar minhas tarefas',
  },
  {
    label: 'Para automatizar processos de vendas',
    value: 'automatizar processos de vendas',
  },
];
