import React, { useEffect, useState, useCallback } from 'react';
import { IDeal } from 'domain/entity/IDeal';
import { IActivity } from 'domain/entity/IActivity';
import HomePage from 'presentation/pages/Home';
import { makeListActivities, makeListDealsUseCase } from '../usecases';
import {
  NewDealsReportRequestDTO,
  NewDealsReportResponseDTO,
} from 'domain/usecases/report/new-deals-report-use-case';
import { makeNewDealsReportUseCase } from 'main/factories/usecases/report/new-deals-report-factory';
import { useGoogleEvents } from 'presentation/pages/Activity/components/Google/hooks/useGoogleEvents';

export const makeHomePage = (): React.ReactElement => {
  const { events } = useGoogleEvents();

  const [activities, setActivities] = useState<IActivity[]>([]);
  const [deals, setDeals] = useState<IDeal[]>([]);
  const [newDeals, setNewDeals] = useState<NewDealsReportResponseDTO>();
  const [earnedDeals, setEarnedDeals] = useState<NewDealsReportResponseDTO>();
  const [lossedDeals, setLossedDeals] = useState<NewDealsReportResponseDTO>();

  const [loading, setLoading] = useState(false);

  const [filterReport, setFilterReport] = useState<NewDealsReportRequestDTO>({
    userContext: 'my',
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
  });

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const { activities: activityData } = await makeListActivities({
        type: 'all',
      }).execute();

      const { data: dealData } = await makeListDealsUseCase().execute({
        type: 'my',
        page: 1,
        result: 'open',
      });

      const responseNews = await makeNewDealsReportUseCase().execute(
        filterReport
      );
      const responseEarned = await makeNewDealsReportUseCase().execute({
        result: 1,
        ...filterReport,
      });

      const responseLossed = await makeNewDealsReportUseCase().execute({
        result: 0,
        ...filterReport,
      });

      setNewDeals(responseNews);
      setEarnedDeals(responseEarned);
      setLossedDeals(responseLossed);
      setDeals(dealData);

      if (events.length > 0) {
        setActivities([...activityData, ...events]);
      } else {
        setActivities(activityData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [filterReport, events]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <HomePage
      setFilterReport={setFilterReport}
      filterReport={filterReport}
      earnedDeals={earnedDeals}
      lossedDeals={lossedDeals}
      newDeals={newDeals}
      loading={loading}
      deals={deals}
      activities={activities}
      fetchData={fetchData}
    />
  );
};
