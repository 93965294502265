import React, { ReactElement, useContext } from 'react';
import { Box, Drawer, Select } from 'mino-ui';
import { useForm } from 'react-hook-form';
import { ActivityFilterParams } from 'domain/usecases/activity/list-activities-use-case';
import FilterIcon from './icons/filterDrawer.svg';
import ActivityListContext from './context/ActivityListContext';

interface FilterDrawerProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  fetchData: (params: ActivityFilterParams) => void;
}

const DrawerFilterActivity = (props: FilterDrawerProps): ReactElement => {
  const { isOpen, setIsOpen, fetchData } = props;
  const { setFilter } = useContext(ActivityListContext);

  function handleFilter(data: ActivityFilterParams): void {
    setFilter(data);
    fetchData(data);
    setIsOpen(false);
  }

  function resetFilter() {
    reset();
    fetchData({});
    setIsOpen(false);
  }

  const { control, handleSubmit, reset } = useForm<ActivityFilterParams>({
    defaultValues: {
      activity_type: undefined,
      status: '',
    },
  });

  return (
    <Drawer
      width={300}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Filtrar Tarefas"
      icon={FilterIcon}
      secondarySubmitText="Limpar"
      secondarySubmitAction={() => {
        resetFilter();
      }}
      submitText="Filtrar"
      onSubmit={handleSubmit(handleFilter)}
    >
      <form onSubmit={handleSubmit(handleFilter)}>
        <Box flexDirection="column" gap={16}>
          <Select
            fullWidth
            label="Tipo"
            control={control}
            name="activity_type"
            defaultValue=""
            options={[
              {
                value: '',
                label: 'Todos',
              },
              {
                value: 'Ligação',
                label: 'Ligação',
              },
              {
                value: 'Mensagem',
                label: 'Mensagem',
              },
              {
                value: 'E-mail',
                label: 'E-mail',
              },
              {
                value: 'Proposta',
                label: 'Proposta',
              },
              {
                value: 'Reunião',
                label: 'Reunião',
              },
              {
                value: 'Anotação',
                label: 'Anotação',
              },
            ]}
          />
          <Select
            fullWidth
            label="Status"
            control={control}
            name="status"
            defaultValue=""
            options={[
              {
                value: '',
                label: 'Todos',
              },
              {
                value: '0',
                label: 'Pendentes',
              },
              {
                value: '1',
                label: 'Finalizadas',
              },
            ]}
          />
        </Box>
        <input type="submit" hidden />
      </form>
    </Drawer>
  );
};

export default DrawerFilterActivity;
