import { RemoteUpdateProfile } from 'data/usecases/profile/remote-update-profile';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeUpdateProfileUseCase = (
  profileId: string
): RemoteUpdateProfile => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteUpdateProfile(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/users/profile/${encodeURI(
      profileId
    )}/`,
    toastify
  );
};
