import styled from 'styled-components';
import { SpacerProps } from '.';

export const Spacer = styled.div<SpacerProps>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;

  @media (max-width: 768px) {
    height: ${({ heightMobile }) => heightMobile}px;
    width: ${({ widthMobile }) => widthMobile}px;
  }
`;
