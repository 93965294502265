import axios from 'axios';
import { Button } from 'mino-ui';
import React, { useState } from 'react';
import { useToken } from 'main/hooks/usetoken';

type GoogleAuthProps = {
  active: boolean;
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
};

const GoogleAuthButton = (props: GoogleAuthProps) => {
  const { active, size, onClick } = props;

  const [loading, setLoading] = useState(false);

  async function handleGoogleAuth() {
    onClick?.();
    try {
      const { token, tenant = '' } = useToken();

      setLoading(true);

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/google/auth`,
        undefined,
        {
          headers: {
            Tenant: tenant,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      window.open(data.url);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  if (!active) return null;

  return (
    <Button
      full
      size={size}
      loading={loading}
      onClick={handleGoogleAuth}
      text="Integrar com o Google"
    />
  );
};

export default GoogleAuthButton;
