import styled, { css } from 'styled-components';

export const PipeCard = styled.div<{ className?: string; color?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  border: 1px solid transparent;
  background-color: ${({ theme }) => theme.colors.gray.white};
  border-radius: 8px;
  padding: 8px 8px 8px 16px;
  margin-bottom: 16px;
  gap: 0.5rem;
  cursor: grab;

  ::before {
    content: '';
    background: ${({ theme }) => theme.colors.blue.primary};
    border-radius: 32px;
    width: 4px;
    height: 60%;
    position: absolute;
    top: 20%;
    left: 0;

    ${({ color }) =>
      color === 'won' &&
      css`
        background: ${({ theme }) => theme.colors.green.primary};
      `}

    ${({ color }) =>
      color === 'loss' &&
      css`
        background: ${({ theme }) => theme.colors.red.primary};
      `}
  }

  ${({ className }) =>
    className &&
    className.includes('dragging') &&
    css`
      opacity: 0.2;
      cursor: grabbing;
    `}
`;

export const CardTitle = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 0.5rem;

  & a {
    color: ${({ theme }) => theme.colors.gray[600]};
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.gray[600]};
    overflow: hidden;
    text-overflow: ellipsis;

    :hover {
      color: ${({ theme }) => theme.colors.gray[200]};
      text-decoration: underline;
    }

    :focus {
      color: ${({ theme }) => theme.colors.gray[200]};
      text-decoration: underline;
    }
  }
`;

export const CardContact = styled.div`
  font-size: 0.75rem;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.blue.primary};
`;

export const CardUser = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  > p {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.gray[600]};
  }
`;

export const CardValue = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  > span {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.gray[200]};
    display: flex;
    align-items: center;
  }
`;

export const IconGroup = styled.div`
  transition: all 0.2s ease-in-out;
  max-width: 176px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;

  > div > div {
    display: flex;
    align-items: center;
  }

  & svg {
    cursor: pointer;

    :active {
      opacity: 0.5;
    }
  }
`;

export const Iconwrapper = styled.div<{ type: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 0.5rem;

  ${({ type }) =>
    type === 'sell' &&
    css`
      background-color: ${({ theme }) => theme.colors.green[50]};
    `}

  ${({ type }) =>
    type === 'loss' &&
    css`
      background-color: ${({ theme }) => theme.colors.red[50]};
    `}
`;

export const OptionButton = styled.div`
  > div > div {
    display: flex;
    align-items: center;
  }
`;
