import React, { useEffect, useState } from 'react';
import { SharedLinkResponse } from 'domain/usecases/SharedLink/list-SharedLinks-use-case';
import { makeListSharedLinkUseCase } from 'main/factories/usecases/sharedLinks/list-shared-links-factoty';

type useSharedLinksType = {
  links: SharedLinkResponse[] | [];
  fetchLinks: () => Promise<void>;
  loading: boolean;
};

export const useSharedLinks = (): useSharedLinksType => {
  const [links, setLinks] = useState<SharedLinkResponse[] | []>([]);
  const [loading, setLoading] = useState(false);

  async function fetchLinks(): Promise<void> {
    try {
      setLoading(true);
      const data = await makeListSharedLinkUseCase().execute();
      setLinks(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchLinks();
  }, []);

  return { links, loading, fetchLinks };
};
