import { ToastService } from 'data/protocols/notification/ToastService';
import { toast } from 'react-toastify';

export type Severity = 'info' | 'success' | 'warning' | 'error' | 'default';

export type Position =
  | 'top-right'
  | 'top-center'
  | 'top-left'
  | 'bottom-right'
  | 'bottom-center'
  | 'bottom-left';

export interface ToastifyConfigs {
  type?: Severity | 'info';
  position?: Position;
  autoClose?: number;
  hideProgressBar?: boolean;
  closeOnClick?: boolean;
  pauseOnHover?: boolean;
  draggable?: boolean;
  progress?: number;
}

export class ToastifyProvier implements ToastService {
  show(message: string, config: ToastifyConfigs): void {
    toast(message, {
      type: config.type,
      position: config.position,
      autoClose: config.autoClose,
      hideProgressBar: config.hideProgressBar,
      closeOnClick: config.closeOnClick,
      pauseOnHover: config.pauseOnHover,
      draggable: config.draggable,
      progress: config.progress,
    });
  }
}
