import { RemoteGetSessionUseCase } from 'data/usecases';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';
import { useToken } from 'main/hooks/usetoken';

export const makeGetSession = (): RemoteGetSessionUseCase => {
  const { token = '', tenant = '' } = useToken();
  const axios = new AxiosProvider({ token, tenant });
  const toastify = new ToastifyProvier();

  return new RemoteGetSessionUseCase(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/navigation`,
    toastify
  );
};
