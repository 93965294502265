import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.gray[30]};
  width: 264px;
  gap: 0.5rem;
  background-color: ${({ theme }) => theme.colors.gray.white};
  box-shadow: 0px 4px 10px rgba(32, 35, 130, 0.05);
  overflow: hidden;
`;

export const RingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray.white};
`;

export const RingingPhoneNumber = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[600]};
`;

export const RingingText = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[200]};
`;

export const DialPad = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6px;
  width: 100%;
  margin: auto;
`;

export const DialButton = styled.button`
  padding: 0.5rem;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.gray[200]};
  border: 1px solid transparent;
  border-radius: 0.5rem;
  transition: all 0.2s ease-in-out;

  :hover {
    background-color: ${({ theme }) => theme.colors.gray[30]};
    border: 1px solid ${({ theme }) => theme.colors.gray[50]};
    cursor: pointer;
  }

  :active {
    background-color: ${({ theme }) => theme.colors.gray[50]};
  }
`;

export const PhoneNumberDisplay = styled.div`
  height: 50px;
  border: 1px solid ${({ theme }) => theme.colors.gray[30]};
  background-color: ${({ theme }) => theme.colors.gray[20]};
  margin-bottom: 16px;
  padding: 10px;
  text-align: center;
  font-size: 20px;
  width: 100%;
`;
