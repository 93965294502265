import React, { ReactElement, useContext } from 'react';
import { Box, Button, Card, Typography } from 'mino-ui';
import * as S from './styles';
import { ReactSVG } from 'react-svg';
import Search from './icons/search.svg';
import Add from './icons/add.svg';
import Import from './icons/import.svg';
import ContactListContext from '../../context/ContactListContext';

const EmptyStateContact = (): ReactElement => {
  const { setAddContact, setImportDialogOpen } = useContext(ContactListContext);

  return (
    <Card fill>
      <S.Container>
        <S.IconWrap>
          <ReactSVG src={Search} />
        </S.IconWrap>
        <Box flexDirection="column" alignItems="center" gap={24}>
          <Box flexDirection="column" justifyContent="center" gap={24}>
            <Typography
              variant="h4"
              fontSize={18}
              align="center"
              weight="bold"
              fill
              noWrap
            >
              Parece que nenhum contato <br />
              foi cadastrado ainda
            </Typography>
            <Typography variant="body" align={'center'} noWrap fontSize={12}>
              Você pode começar adicionando seu primeiro contato <br />
              ou importando vários contatos de uma só vez.
            </Typography>
          </Box>
          <Box flexDirection="row" flexDirectionMobile="column" gap={8}>
            <Button
              text="Adicionar um Contato"
              size="medium"
              icon={Add}
              onClick={() => setAddContact(true)}
            />
            <Button
              text="Importar contatos"
              variant="ghost"
              size="medium"
              full
              icon={Import}
              onClick={() => setImportDialogOpen(true)}
            />
          </Box>
        </Box>
      </S.Container>
    </Card>
  );
};

export default EmptyStateContact;
