import { ISession } from 'domain/entity/ISession';

export enum DealsResourcesPermissions {
  WATCH_ALL = 'watch_all',
  EDIT = 'edit',
  DELETE = 'delete',
  EXPORT = 'exports',
}

export enum ContactsResourcesPermissions {
  WATCH_ALL = 'watch_all',
  EDIT = 'edit',
  DELETE = 'delete',
  EXPORT = 'exports',
}

export enum ConfigResourcesPermissions {
  CHANNELS = 'channels',
  FUNNELS = 'funnels',
  GROUPS = 'groups',
  INTEGRATIONS = 'integrations',
  LOSSES = 'losses',
  PAGES = 'pages',
  SERVICES = 'services',
  TAGS = 'tags',
  TEAMS = 'teams',
  USERS = 'users',
  LINKS = 'links',
}

export const hasDealsPermissions = (
  session: ISession,
  resourse: DealsResourcesPermissions
): boolean => {
  const dealPermissions = session?.product_roles;

  if (dealPermissions && dealPermissions[resourse] == true) {
    return true;
  }

  return false;
};

export const hasContactsPermissions = (
  session: ISession,
  resourse: ContactsResourcesPermissions
): boolean => {
  const contactPermissions = session?.contact_roles;

  if (contactPermissions && contactPermissions[resourse] == true) {
    return true;
  }

  return false;
};

export const hasConfigPermissions = (
  session: ISession,
  resourses: ConfigResourcesPermissions[],
  mode: 'every' | 'some'
): boolean => {
  const configPermissions = session.config_roles;

  if (mode === 'every') {
    if (
      resourses.every(
        (resourse) => configPermissions && configPermissions[resourse] == true
      )
    ) {
      return true;
    }
  }

  if (mode === 'some') {
    if (
      resourses.some(
        (resourse) => configPermissions && configPermissions[resourse] == true
      )
    ) {
      return true;
    }
  }

  return false;
};
