import styled from 'styled-components';
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible';

export const Container = styled(CollapsiblePrimitive.Collapsible)`
  width: 100%;
`;

export const Trigger = styled(CollapsiblePrimitive.Trigger)`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: transparent;

  :active {
    background-color: ${({ theme }) => theme.colors.blue[50]};
  }

  &[data-state='open'] {
    & svg {
      transition: transform 0.2s ease-in-out;
      transform: rotate(180deg);
    }
  }
  &[data-state='closed'] {
    & svg {
      transition: transform 0.2s ease-in-out;
      transform: rotate(0deg);
    }
  }

  :focus {
    border-radius: 4px;
    outline: 1px solid ${({ theme }) => theme.colors.blue[100]};
  }
`;
export const IconWrap = styled.div`
  display: flex;
  align-items: center;
  > div > div {
    display: flex;
    align-items: center;
  }
`;
export const Content = styled(CollapsiblePrimitive.Content)`
  width: 100%;
  padding-top: 8px;
`;
