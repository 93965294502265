import styled from 'styled-components';

export const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.gray.white};
  display: flex;
  border-radius: 0.5rem;
  width: 100%;

  @media (max-width: 769px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const SideContent = styled.div`
  min-width: 240px;
  max-width: 240px;
  border-right: solid 2px ${({ theme }) => theme.colors.gray[30]};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;

  @media (max-width: 769px) {
    border-right: none;
    border-bottom: solid 2px ${({ theme }) => theme.colors.gray[30]};
    padding: 1rem;
  }
`;

export const MainContent = styled.div`
  padding: 0 1rem;
  width: 100%;
`;
export const LogoWrap = styled.div``;
export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;
export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`;
