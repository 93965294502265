import React, { useEffect, useState } from 'react';
import { IDeal } from 'domain/entity/IDeal';
import SingleDealPage from 'presentation/pages/Deal/single';
import { useParams } from 'react-router-dom';
import { makeGetSingleDealUseCase } from '../usecases';

export const makeSingleDealPage = (): React.ReactElement => {
  const [deal, setDeal] = useState<IDeal>({} as IDeal);

  const [loading, setLoading] = useState(false);

  const { id = '' } = useParams();

  async function fetchDeal(): Promise<void> {
    try {
      setLoading(true);
      const deal = await makeGetSingleDealUseCase(id).execute();
      setDeal(deal);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchDeal();
  }, []);

  return (
    <SingleDealPage
      fetchDeal={fetchDeal}
      callback={fetchDeal}
      deal={deal}
      loading={loading}
    />
  );
};
