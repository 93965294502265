import React, { ReactElement, useState } from 'react';
import { Box, Button, Input, Table, Typography, Dialog } from 'mino-ui';
import AddIcon from './icons/add.svg';
import { ManageDrawer } from './ManageDrawer';
import { pressEscKey } from 'main/helpers/scape-key-esc';
import { RequestPayloadLoss } from 'domain/usecases/loss/save-loss-use-case';
import { useLoss } from 'main/hooks/useLoss';
import { makeSaveLossUseCase } from 'main/factories/usecases/loss/save-loss-factory';
import { makeDeleteLossUseCase } from 'main/factories/usecases/loss/delete-loss-factory';
import { useForm } from 'react-hook-form';

const LossConfig = (): ReactElement => {
  const [manageOpen, setManageOpen] = useState(false);
  const [updatePayload, setUpdatePayload] = useState<RequestPayloadLoss>();
  const [deleteListing, setDeleteListing] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);

  const { loss, fetchLoss } = useLoss();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<RequestPayloadLoss>();

  async function saveNewTag(data: RequestPayloadLoss) {
    try {
      setCreateLoading(true);
      await makeSaveLossUseCase().execute(data, 'create');

      fetchLoss();
      setValue('name', '');
    } catch (error) {
      console.log(error);
    } finally {
      setCreateLoading(false);
    }
  }

  async function deleteTag(tagId: string) {
    try {
      setDeleteListing(true);
      await makeDeleteLossUseCase(tagId).execute();
      fetchLoss();
      pressEscKey();
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteListing(false);
    }
  }

  return (
    <Box flexDirection="column" gap={16}>
      <Box gap={16}>
        <form
          onSubmit={handleSubmit(saveNewTag)}
          style={{ display: 'flex', gap: 8 }}
        >
          <Input
            full
            autoComplete="off"
            register={register}
            errors={errors}
            validations={{
              required: true,
            }}
            placeholder="Ex: Preço"
            name="name"
          />

          <Button
            disabled={createLoading}
            icon={AddIcon}
            text="Adicionar"
            type="submit"
          />
        </form>
      </Box>

      <Table
        hasOutline
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            width: 100,
          },
          {
            field: 'actions',
            headerName: '',
          },
        ]}
        rows={loss.map((c) => ({
          id: c.id,
          name: c.name,

          actions: (
            <Box gap={8}>
              <Button
                size="small"
                onClick={() => {
                  setUpdatePayload(c);
                  setManageOpen(true);
                }}
                variant="ghost"
                text="Editar"
              />

              <Dialog
                modal
                title="Atenção!"
                trigger={
                  <Button text="Excluir" size="small" variant="secondary" />
                }
                content={
                  <Box flexDirection="column" gap={32}>
                    <Typography noWrap variant="body">
                      Tem certeza que deseja excluir essa Perda?
                    </Typography>

                    <Box gap={8}>
                      <Button
                        variant="primary"
                        disabled={deleteListing}
                        text={deleteListing ? 'Removendo...' : 'Excluir'}
                        onClick={() => deleteTag(c.id)}
                      />
                      <Button
                        onClick={() => pressEscKey()}
                        variant="outline"
                        text="Cancelar"
                      />
                    </Box>
                  </Box>
                }
              />
            </Box>
          ),
        }))}
      />

      <ManageDrawer
        updatePayload={updatePayload}
        isOpen={manageOpen}
        setIsOpen={setManageOpen}
        callback={() => {
          fetchLoss();
          setUpdatePayload(undefined);
        }}
      />
    </Box>
  );
};

export default LossConfig;
