export const activityTypesOptions = [
  {
    value: 'Ligação',
    label: 'Ligação',
  },
  {
    value: 'Reunião',
    label: 'Reunião',
  },
  {
    value: 'Mensagem',
    label: 'Mensagem',
  },
  {
    value: 'E-mail',
    label: 'E-mail',
  },
  {
    value: 'Proposta',
    label: 'Proposta',
  },
  {
    value: 'Anotação',
    label: 'Anotação',
  },
];

export const durationOptions = [
  {
    value: '15',
    label: '15 minutos',
  },
  {
    value: '30',
    label: '30 minutos',
  },
  {
    value: '45',
    label: '45 minutos',
  },
  {
    value: '60',
    label: '1 hora',
  },
  {
    value: '90',
    label: '1 hora e 30 minutos',
  },
  {
    value: '120',
    label: '2 horas',
  },
  {
    value: '150',
    label: '2 horas e 30 minutos',
  },
  {
    value: '180',
    label: '3 horas',
  },
  {
    value: '210',
    label: '3 horas e 30 minutos',
  },
  {
    value: '240',
    label: '4 horas',
  },
  {
    value: '270',
    label: '4 horas e 30 minutos',
  },
  {
    value: '300',
    label: '5 horas',
  },
];
