import { IImportBatch } from 'domain/entity/IImportBatch';

export interface ImportBatchDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  batchPayload?: IImportBatch;
  setBatchPayload?: (batch?: IImportBatch) => void;
  callback: () => void;
}

export enum IconNames {
  ArchiveArrowBack20Regular = 'ArchiveArrowBack20Regular',
  Save20Regular = 'Save20Regular',
}

export enum IconColors {
  blue = 'blue',
}
