import styled from 'styled-components';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

export const Container = styled(TooltipPrimitive.Root)``;

export const Trigger = styled(TooltipPrimitive.Trigger)`
  background-color: transparent;
`;

export const Portal = styled(TooltipPrimitive.Portal)``;

export const Content = styled(TooltipPrimitive.Content)`
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing.md};
  font-size: 0.875rem;
  font-weight: 400;
  background: ${({ theme }) => theme.colors.gray[200]};
  z-index: 100 !important;
  color: ${({ theme }) => theme.colors.gray.white};
`;

export const Arrow = styled(TooltipPrimitive.Arrow)`
  fill: ${({ theme }) => theme.colors.gray[200]};
`;
