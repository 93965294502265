import React, { ReactElement, useState } from 'react';
import { DeleteDialogType, IconColors, IconNames } from './type';
import { Box, Dialog, Icon, Typography } from 'mino-ui';
import { makeDeleteSharedLinks } from 'main/factories/usecases/sharedLinks/delete-sharedLinks-factory';

const DeleteDialog = (props: DeleteDialogType): ReactElement => {
  const { isOpen, setOpen, callback, deletePayload } = props;

  const [loading, setLoading] = useState(false);

  async function DeleteSharedLink(id: string) {
    try {
      setLoading(true);
      await makeDeleteSharedLinks(id).execute();
      callback();
      setOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog
      title="Excluir link"
      open={isOpen}
      onOpenChange={(state) => {
        !state;
        setOpen(state);
      }}
      onSubmit={() => deletePayload && DeleteSharedLink(`${deletePayload.id}`)}
      submitVariant="danger"
      submitText="Excluir"
      submitIconName={IconNames.Delete20Regular}
      submitIconColor={IconColors.white}
      loading={loading}
      content={
        <Box gap={24} flexDirection="column">
          <Typography variant="body" fontSize={12} color="gray">
            Você tem certeza que deseja excluir este link?
          </Typography>

          <Box gap={16}>
            <Icon iconName="Link20Regular" color={IconColors.blue} />
            <Typography variant="body" fontSize={12} color="dark">
              {deletePayload?.shared_link_name}
            </Typography>
          </Box>
        </Box>
      }
    />
  );
};

export default DeleteDialog;
