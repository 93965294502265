import { createContext } from 'react';
import { PipeDeals } from '../list/pipeline';

interface PipeDealContextProps {
  deals: PipeDeals[];
  setDeals: (value: PipeDeals[]) => void;
  loadingDeals: boolean;
  fetchDeals: () => void;
  setOpenChangeResult: (result: ChangeResultProps) => void;
}

export interface ChangeResultProps {
  open: boolean;
  type: 'sell' | 'loss';
  dealId?: string;
}

const PipeDealContext = createContext<PipeDealContextProps>(
  {} as PipeDealContextProps
);

export default PipeDealContext;
