import { ICompany } from 'domain/entity/ICompany';
import { CompanyRequestParams } from 'domain/usecases/company/list-companies-use-case';
import { createContext } from 'react';

interface CompanyContextProps {
  createCompany: boolean;
  setCreateCompany: (value: boolean) => void;
  filter: CompanyRequestParams;
  setFilter: (filter: CompanyRequestParams) => void;
  isFilterOpen: boolean;
  setFilterOpen: (value: boolean) => void;
  fetchCompanies: (params: CompanyRequestParams) => void;
  companies: ICompany[];
  loading: boolean;
  bulkCompaniesOwner: boolean;
  setBulkCompaniesOwner: (value: boolean) => void;
  bulkCompaniesTags: boolean;
  setBulkCompaniesTags: (value: boolean) => void;
}

const CompanyContext = createContext<CompanyContextProps>(
  {} as CompanyContextProps
);

export default CompanyContext;
