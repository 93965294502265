import React, { ReactElement } from 'react';
import { Box, Skeleton } from 'mino-ui';

export const PipeListSkeleton = (): ReactElement => {
  return (
    <>
      <Box flexDirection="column" gap={16}>
        <Box gap={16} style={{ overflow: 'hidden' }}>
          <Box
            gap={8}
            width="300px"
            flexDirection="column"
            style={{ padding: '1rem', flex: 'none' }}
          >
            <Skeleton
              height={18}
              flexDirection="row"
              justifyContent="space-between"
              lines={[
                {
                  height: 18,
                  widthPercent: 40,
                },
              ]}
            />
            <Skeleton
              height={18}
              flexDirection="row"
              justifyContent="space-between"
              lines={[
                {
                  height: 18,
                  widthPercent: 55,
                },
                {
                  height: 18,
                  widthPercent: 25,
                },
              ]}
            />
            <Skeleton
              height={1000}
              flexDirection="column"
              lines={[
                {
                  height: 110,
                  widthPercent: 100,
                },
                {
                  height: 110,
                  widthPercent: 100,
                },
                {
                  height: 110,
                  widthPercent: 100,
                },
                {
                  height: 110,
                  widthPercent: 100,
                },
              ]}
            />
          </Box>
          <Box
            gap={8}
            width="300px"
            flexDirection="column"
            style={{ padding: '1rem', flex: 'none' }}
          >
            <Skeleton
              height={18}
              flexDirection="row"
              justifyContent="space-between"
              lines={[
                {
                  height: 18,
                  widthPercent: 40,
                },
              ]}
            />
            <Skeleton
              height={18}
              flexDirection="row"
              justifyContent="space-between"
              lines={[
                {
                  height: 18,
                  widthPercent: 55,
                },
                {
                  height: 18,
                  widthPercent: 25,
                },
              ]}
            />
            <Skeleton
              height={1000}
              flexDirection="column"
              lines={[
                {
                  height: 110,
                  widthPercent: 100,
                },
                {
                  height: 110,
                  widthPercent: 100,
                },
                {
                  height: 110,
                  widthPercent: 100,
                },
                {
                  height: 110,
                  widthPercent: 100,
                },
              ]}
            />
          </Box>
          <Box
            gap={8}
            width="300px"
            flexDirection="column"
            style={{ padding: '1rem', flex: 'none' }}
          >
            <Skeleton
              height={18}
              flexDirection="row"
              justifyContent="space-between"
              lines={[
                {
                  height: 18,
                  widthPercent: 40,
                },
              ]}
            />
            <Skeleton
              height={18}
              flexDirection="row"
              justifyContent="space-between"
              lines={[
                {
                  height: 18,
                  widthPercent: 55,
                },
                {
                  height: 18,
                  widthPercent: 25,
                },
              ]}
            />
            <Skeleton
              height={1000}
              flexDirection="column"
              lines={[
                {
                  height: 110,
                  widthPercent: 100,
                },
                {
                  height: 110,
                  widthPercent: 100,
                },
                {
                  height: 110,
                  widthPercent: 100,
                },
                {
                  height: 110,
                  widthPercent: 100,
                },
              ]}
            />
          </Box>
          <Box
            gap={8}
            width="300px"
            flexDirection="column"
            style={{ padding: '1rem', flex: 'none' }}
          >
            <Skeleton
              height={18}
              flexDirection="row"
              justifyContent="space-between"
              lines={[
                {
                  height: 18,
                  widthPercent: 40,
                },
              ]}
            />
            <Skeleton
              height={18}
              flexDirection="row"
              justifyContent="space-between"
              lines={[
                {
                  height: 18,
                  widthPercent: 55,
                },
                {
                  height: 18,
                  widthPercent: 25,
                },
              ]}
            />
            <Skeleton
              height={1000}
              flexDirection="column"
              lines={[
                {
                  height: 110,
                  widthPercent: 100,
                },
                {
                  height: 110,
                  widthPercent: 100,
                },
                {
                  height: 110,
                  widthPercent: 100,
                },
                {
                  height: 110,
                  widthPercent: 100,
                },
              ]}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
