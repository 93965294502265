import { RemoteSaveFileUseCase } from 'data/usecases';

import { SaveFileUseCase } from 'domain/usecases/file/save-file-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeSaveFileUseCase = (activityId?: string): SaveFileUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  const url = activityId
    ? `${process.env.REACT_APP_API_BASE_URL}/activities/${activityId}/files`
    : `${process.env.REACT_APP_API_BASE_URL}/files/save`;

  return new RemoteSaveFileUseCase(url, axios, toastify);
};
