import styled, { keyframes, css } from 'styled-components';
import { ColorScheme } from './types';

interface SpinnerStyledProps {
  color?: ColorScheme;
  size?: 'small' | 'medium' | 'large' | 'mini';
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div``;

export const Spinner = styled.div<SpinnerStyledProps>`
  border-radius: 50%;
  animation: ${spin} 1.25s linear infinite;

  ${({ color }) =>
    color === 'blue' &&
    css`
      border: solid ${({ theme }) => theme.colors.gray[20]};
      border-top: solid ${({ theme }) => theme.colors.blue.primary};
    `}

  ${({ color }) =>
    color === 'light' &&
    css`
      border: solid ${({ theme }) => theme.colors.gray[50]};
      border-top: solid ${({ theme }) => theme.colors.gray.white};
    `}


  ${({ size }) =>
    size === 'mini' &&
    css`
      width: 18px;
      height: 18px;
      border-width: 2px;
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      width: 25px;
      height: 25px;
      border-width: 2px;
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      width: 25px;
      height: 25px;
      border-width: 2px;
    `}

  ${({ size }) =>
    size === 'medium' &&
    css`
      width: 35px;
      height: 35px;
      border-width: 3px;
    `}

  ${({ size }) =>
    size === 'large' &&
    css`
      width: 50px;
      height: 50px;
      border-width: 4px;
    `}
`;
