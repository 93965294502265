import styled, { css } from 'styled-components';

interface ColorProps {
  color: string;
}

export const radioWrap = styled.div<ColorProps>`
  width: 100%;
  display: flex;
  justify-content: center;

  ${({ color }) =>
    color == 'blue' &&
    css`
      color: ${({ theme }) => theme.colors.blue[100]};

      div > div > svg {
        height: 24px;
        path {
          fill: ${({ theme }) => theme.colors.blue[100]};
        }
      }
    `}

  ${({ color }) =>
    color == 'orange' &&
    css`
      color: ${({ theme }) => theme.colors.orange[100]};

      div > div > svg {
        height: 24px;
        path {
          fill: ${({ theme }) => theme.colors.orange[100]};
        }
      }
    `}

  ${({ color }) =>
    color == 'red' &&
    css`
      color: ${({ theme }) => theme.colors.red[100]};

      div > div > svg {
        height: 24px;
        path {
          fill: ${({ theme }) => theme.colors.red[100]};
        }
      }
    `}

  ${({ color }) =>
    color == 'yellow' &&
    css`
      color: ${({ theme }) => theme.colors.yellow.primary};

      div > div > svg {
        height: 24px;
        path {
          fill: ${({ theme }) => theme.colors.yellow.primary};
        }
      }
    `}

  ${({ color }) =>
    color == 'green' &&
    css`
      color: ${({ theme }) => theme.colors.green[100]};

      div > div > svg {
        height: 24px;
        path {
          fill: ${({ theme }) => theme.colors.green[100]};
        }
      }
    `}

  ${({ color }) =>
    color == 'gray' &&
    css`
      color: ${({ theme }) => theme.colors.gray[200]};

      div > div > svg {
        height: 24px;
        path {
          fill: ${({ theme }) => theme.colors.gray[200]};
        }
      }
    `}

  ${({ color }) =>
    color == 'teal' &&
    css`
      color: ${({ theme }) => theme.colors.teal[100]};

      div > div > svg {
        height: 24px;
        path {
          fill: ${({ theme }) => theme.colors.teal[100]};
        }
      }
    `}

  ${({ color }) =>
    color == 'violet' &&
    css`
      color: ${({ theme }) => theme.colors.violet[100]};

      div > div > svg {
        height: 24px;
        path {
          fill: ${({ theme }) => theme.colors.violet[100]};
        }
      }
    `}
`;
export const radioColor = styled.div`
  width: 18px;
  height: 18px;
  clip-path: circle();
`;
