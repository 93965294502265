export type BreadCrumbItem = {
  title: string;
  action: () => void;
  active?: boolean;
};

export function generateBreadCrumbOptions(
  navigate: (path: string) => void
): BreadCrumbItem[] {
  const url = window.location.pathname;
  const urlNames = url.split('/');

  const options: BreadCrumbItem[] = [];

  options.push({
    title: 'Início',
    action: () => navigate('/'),
    active: url === '/',
  });

  urlNames.forEach((name, index) => {
    if (name) {
      const path = urlNames.slice(0, index + 1).join('/');
      const title = translateRoutes(name.replace(/-/g, ' '));
      const action = () => navigate(path);
      const active = index === urlNames.length - 1;

      options.push({ title, action, active });
    }
  });

  return options;
}

export function translateRoutes(route: string): string {
  switch (route) {
    case 'Home':
      return 'Início';
    case 'deals':
      return 'Negócios';
    case 'contacts':
      return 'Contatos';
    case 'companies':
      return 'Empresas';
    case 'activities':
      return 'Atividades';
    case 'panel':
      return 'Painel';
    case 'config':
      return 'Configurações';
    case 'profile':
      return 'Perfil';

    default:
      return route;
  }
}
