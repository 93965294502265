import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Box, Dialog, Select, Spacer, Tag, Typography } from 'mino-ui';
import { makeUpdateDealUseCase } from 'main/factories/usecases';
import { IDeal } from 'domain/entity/IDeal';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { makeListTagUseCase } from 'main/factories/usecases/tags/list-tags-factory';
import { ITag } from 'domain/entity/ITag';
import { SingleDealContext } from 'presentation/pages/Deal/contexts/SingleDealContext';

interface EditTagsDialogProps {
  deal: IDeal;
  callback: () => void;
  tags: ITag[];
}

const AttachTagsDialog = (props: EditTagsDialogProps): ReactElement => {
  const { callback, deal, tags: dealTags } = props;

  const { addTagsDialog, setAddTagsDialog } = useContext(SingleDealContext);

  const [load, setLoad] = useState(false);

  const [loadingTags, setLoadingTags] = useState(false);
  const [tagsOptions, setTagsOptions] = useState<ITag[]>([]);
  const [, setSearch] = useState('');

  const [attach_tags, setAttachTags] = useState<string[]>([]);
  const [detach_tags, setDetachTags] = useState<string[]>([]);

  const [tags, setTags] = useState(
    dealTags?.map((d) => ({
      id: d.id,
      name: d.name,
      color: d.color,
    }))
  );

  const fetchTags = useCallback(async () => {
    try {
      setLoadingTags(true);
      const data = await makeListTagUseCase().execute();
      setTagsOptions(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTags(false);
    }
  }, [setTagsOptions]);

  const handleAttachTags = useCallback(async () => {
    try {
      await makeUpdateDealUseCase(deal.id).execute({
        name: deal.name,
        attach_tags: attach_tags,
        detach_tags: detach_tags,
      });

      fetchTags();
      callback();
      setSearch('');
      setLoad(false);
      setAddTagsDialog(false);
    } catch (error) {
      console.log(error);
    }
  }, [
    fetchTags,
    setSearch,
    attach_tags,
    detach_tags,
    callback,
    setAddTagsDialog,
    deal.id,
    deal.name,
  ]);

  const attachTags = useCallback(
    (id: string, name: string, color: string) => {
      if (attach_tags.includes(id) || tags.map((c) => c.id).includes(id)) {
        return;
      }

      setAttachTags([...attach_tags, id]);

      const newDetach = detach_tags.filter((a) => a !== id);
      setDetachTags(newDetach);

      const newState = tags.filter((t) => t.id !== id);
      const newArray = tagsOptions.filter((t) => t.id == id);
      console.log(newArray);

      setTags([
        ...newState,
        {
          id,
          name,
          color,
        },
      ]);
    },
    [
      attach_tags,
      setTags,
      tags,
      setAttachTags,
      detach_tags,
      setDetachTags,
      tagsOptions,
    ]
  );

  const detachContacts = useCallback(
    (id: string) => {
      if (detach_tags.includes(id)) return;
      setDetachTags([...detach_tags, id]);

      const newAttach = attach_tags.filter((a) => a !== id);
      setAttachTags(newAttach);

      const newState = tags.filter((t) => t.id !== id);
      setTags(newState);
    },
    [attach_tags, setTags, tags, setAttachTags, detach_tags, setDetachTags]
  );

  useDebouncedEffect(fetchTags, [fetchTags], 200);

  const reset = useCallback(() => {
    setAttachTags([]);
    setDetachTags([]);
  }, [setAttachTags, setDetachTags]);

  useEffect(reset, [reset]);

  return (
    <Dialog
      title="Editar tags"
      open={addTagsDialog}
      onOpenChange={() => setAddTagsDialog(false)}
      onSubmit={handleAttachTags}
      loading={load}
      content={
        <>
          <Box flexDirection="column" gap={16}>
            <Select
              fullWidth
              isLoading={loadingTags}
              onInputChange={(value) => {
                setSearch(value);
              }}
              label="Selecionar tag"
              placeholder="Selecione uma tag"
              options={tagsOptions
                .filter((t) => !tags.map((t) => t.id).includes(t.id))
                .map((tag) => ({
                  value: tag.id,
                  label: tag.name,
                }))}
              onChange={(d) =>
                d &&
                attachTags(
                  d.value,
                  d.label || '',
                  `${tagsOptions
                    .filter(function (id) {
                      return id.id == d.value;
                    })
                    .map((tag) => tag.color)}`
                )
              }
            />
          </Box>

          <Spacer height={16} />

          <Box flexDirection="column" justifyContent="center" gap={16}>
            <Typography variant="body" weight="bold" fontSize={14} color="dark">
              Selecionadas:
            </Typography>

            <Box gap={8} style={{ flexWrap: 'wrap' }}>
              {tags?.map((tag) => (
                <Tag
                  key={tag.id}
                  name={tag.name}
                  color={tag.color}
                  onClose={() => detachContacts(tag.id)}
                />
              ))}
            </Box>
          </Box>
        </>
      }
    />
  );
};
export default AttachTagsDialog;
