import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Toolbar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
  height: auto;
  min-height: 72px;
  background-color: ${({ theme }) => theme.colors.gray.white};
  width: 100%;
  border: solid 1px ${({ theme }) => theme.colors.gray[30]};
  gap: 8px;
  overflow: hidden;
`;

export const ToolbarLeftContent = styled.div``;
export const ToolbarRightContent = styled.div``;

export const DropdownContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const WrapInputSearch = styled.div`
  max-width: 340px;
  width: 100%;
  display: flex;
  justify-content: center;

  > div {
    max-width: 340px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
