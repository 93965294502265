/* eslint-disable @typescript-eslint/no-var-requires */
import React, { ReactElement } from 'react';
import * as T from './types';
import * as S from './styles';
import { ReactSVG } from 'react-svg';
import * as Asset from './assets';

const Icon = (props: T.IconProps): ReactElement => {
  const { src, iconName, width, color = 'gray' } = props;

  const IconComponent = iconName && Asset[iconName];

  return (
    <S.Container color={color}>
      {src ? (
        <ReactSVG width={width} src={src} />
      ) : iconName && IconComponent ? (
        <IconComponent />
      ) : null}
    </S.Container>
  );
};

export default Icon;
