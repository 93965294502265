import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { IActivity } from 'domain/entity/IActivity';
import { useToken } from 'main/hooks/usetoken';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';

type GoogleEventsProps = {
  events: IActivity[];
  loading: boolean;
};

export const useGoogleEvents = (): GoogleEventsProps => {
  const [events, setEvents] = useState<IActivity[]>([]);
  const [loading, setLoading] = useState(false);

  const {
    session: {
      integrations: { google },
    },
  } = useContext(SessionContext);

  async function getEvents() {
    if (!google?.active) return;

    try {
      const { token, tenant = '' } = useToken();

      setLoading(true);

      const { data: googleEvents } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/google/get-events`,

        {
          headers: {
            Tenant: tenant,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const events: IActivity[] = googleEvents.map((event: any) => {
        return {
          id: event.id,
          title: event.summary,
          start: event.start.dateTime || event.start.date,
          end: event.end.dateTime || event.end.date,
          allDay: event.start.date ? true : false,
          description: event.description,
          on_google: true,
          activity_type: 'google',
        };
      });

      setEvents(events);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getEvents();
  }, []);

  return { events, loading };
};
