import React, { ReactElement } from 'react';
import { Button, PopOver, Dropdown, Table } from 'mino-ui';
import * as S from './styles';
import { ICustomField } from 'domain/entity/ICustomField';

interface CustomFieldsTableProps {
  customFields: ICustomField[];
  setPayload: (payload: ICustomField) => void;
  setIdToDelete: (id: string) => void;
  setModalOpen: (isOpen: boolean) => void;
}

export const CustomFieldsDataTable = (
  props: CustomFieldsTableProps
): ReactElement => {
  const { customFields, setModalOpen, setPayload, setIdToDelete } = props;

  function translateType(type: string) {
    switch (type) {
      case 'text':
        return 'Texto';
      case 'number':
        return 'Número';
      case 'date':
        return 'Data';
      case 'boolean':
        return 'Sim/Não';
      case 'select':
        return 'Seleção';
      default:
        return '';
    }
  }

  return (
    <Table
      hasOutline
      compact
      columns={[
        { field: 'name', headerName: 'Nome' },
        { field: 'type', headerName: 'Tipo' },
        { field: 'action', headerName: '', align: 'right' },
      ]}
      rows={customFields.map((field) => ({
        id: `${field.id}`,
        name: field.name,
        type: translateType(field.type),
        action: (
          <Dropdown
            trigger="action-button"
            items={[
              {
                label: 'Editar',
                icon: 'Edit20Regular',
                value: 'edit',
              },
              {
                label: 'Excluir',
                icon: 'Delete20Regular',
                iconColor: 'red' as any,
                value: 'delete',
              },
            ]}
            onClick={(value) => {
              if (value === 'edit') {
                setPayload(field);
                setModalOpen(true);
              }
              if (value === 'delete') {
                setIdToDelete(field.id);
              }
            }}
          />
        ),
      }))}
    />
  );
};
