import React, { ReactElement, useEffect, useState } from 'react';
import {
  Dialog,
  Box,
  Typography,
  Table,
  Spinner,
  Button,
  Select,
  Tooltip,
  Label,
} from 'mino-ui';
import { IconColors, IconNames } from './type';
import axios from 'axios';
import { useUsers } from 'main/hooks/useUsers';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { useToken } from 'main/hooks/usetoken';

interface BuyPhoneNumberModalProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  callback?: () => void;
}

const BuyPhoneNumberModal = (props: BuyPhoneNumberModalProps): ReactElement => {
  const { isOpen, setOpen, callback } = props;

  const [loading, setLoading] = useState(false);

  const [phones, setPhones] = useState<Phone[]>([]);
  const [locality, setLocality] = useState('');

  const apiToken = useToken();

  useEffect(() => {
    if (!locality) {
      return;
    }
    async function searchPhoneNumbers() {
      try {
        setLoading(true);
        const response = await axios(
          process.env.REACT_APP_API_BASE_URL +
            '/voice/phones?locality=' +
            locality,

          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${apiToken.token}`,
              Tenant: apiToken.tenant || '',
            },
          }
        );

        setPhones(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    searchPhoneNumbers();
  }, [locality]);

  async function handleBuyPhoneNumber() {
    try {
      setLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL + '/voice/phones',
        {
          phoneNumber: selectedPhone?.phoneNumber,
          userId: phoneHasUser ? selectedUser : null,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiToken.token}`,
            Tenant: apiToken.tenant || '',
          },
        }
      );

      setPhones(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      callback?.();
    }
  }

  type Phone = {
    locality: string;
    phoneNumber: string;
    region: string;
  };

  const [selectedPhone, setSelectedPhone] = useState<Phone | null>(null);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);

  const [phoneHasUser, setPhoneHasUser] = useState(true);

  const { users, fetchUsers, loading: loadingUsers } = useUsers();

  const [searchUser, setSearchUser] = useState('');

  useDebouncedEffect(
    () => {
      fetchUsers(searchUser);
    },
    [searchUser],
    500
  );

  return (
    <Dialog
      modal
      maxWidth={600}
      open={isOpen}
      onOpenChange={setOpen}
      title="Comprar número"
      submitVariant="primary"
      submitText={'Salvar'}
      submitIconName={IconNames.Save20Regular}
      submitIconColor={IconColors.white}
      loading={loading}
      content={
        selectedPhone ? (
          <Box style={{ padding: 32 }} flexDirection="column" gap={16}>
            <Typography noWrap variant="body">
              Você está comprando o número{' '}
              <strong>{selectedPhone.phoneNumber}</strong> da localidade{' '}
              <strong>{selectedPhone.locality}</strong>.
            </Typography>

            <Box flexDirection="column" gap={16}>
              <Box gap={16}>
                <Box alignItems="center" gap={4}>
                  <input
                    style={{ margin: 0 }}
                    type="radio"
                    name="phoneHasUser"
                    checked={!phoneHasUser}
                    onChange={() => setPhoneHasUser(false)}
                  />
                  <Label removeMargin text="Não vincular usuário" />
                </Box>

                <Box alignItems="center" gap={4}>
                  <input
                    style={{ margin: 0 }}
                    type="radio"
                    name="phoneHasUser"
                    checked={phoneHasUser}
                    onChange={() => setPhoneHasUser(true)}
                  />
                  <Label removeMargin text="Vincular usuário" />
                </Box>
              </Box>
              {phoneHasUser ? (
                <Select
                  onChange={(v) => v && setSelectedUser(v.value)}
                  isLoading={loadingUsers}
                  onInputChange={(v) => setSearchUser(v)}
                  label="Selecione o usuário"
                  options={users.map((user) => ({
                    value: user.id,
                    label: user.username,
                  }))}
                />
              ) : null}
            </Box>

            <Box
              gap={16}
              style={{
                borderTop: '1px solid #E5E5E5',
                paddingTop: 16,
              }}
            >
              <Button
                text="Prosseguir"
                icon="ArrowNext20Regular"
                iconColor={'white' as any}
                onClick={handleBuyPhoneNumber}
                loading={loading}
              />

              <Button
                variant="ghost"
                text="Cancelar"
                onClick={() => setSelectedPhone(null)}
              />
            </Box>
          </Box>
        ) : (
          <Box
            style={{
              backgroundColor: 'white',
            }}
            flexDirection="column"
            gap={16}
          >
            <Box
              style={{
                position: 'sticky',
                top: -24,
                zIndex: 2,
                backgroundColor: 'white',
                borderBottom: '1px solid #E5E5E5',
                padding: '16px 24px',
              }}
              gap={8}
            >
              <Select
                fullWidth
                placeholder="Selecione a localidade"
                label="Localidade"
                onChange={(v) => v && setLocality(v.value)}
                options={[
                  {
                    label: 'Belo Horizonte',
                    value: 'Belo Horizonte',
                  },
                  {
                    label: 'Governador Valadares',
                    value: 'Governador Valadares',
                  },
                  {
                    label: 'São Paulo',
                    value: 'Sao Paulo',
                  },
                  {
                    label: 'Rio de Janeiro',
                    value: 'Rio de Janeiro',
                  },
                ]}
              />
            </Box>

            <Box>
              {loading ? (
                <Box
                  style={{ minHeight: 400, width: '100%' }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Spinner />
                </Box>
              ) : (
                <>
                  {phones.length > 0 ? (
                    <Table
                      compact
                      columns={[
                        {
                          field: 'phoneNumber',
                          headerName: 'Número',
                        },
                        {
                          field: 'locality',
                          headerName: 'Localidade',
                        },
                        {
                          field: 'actions',
                          headerName: '',
                        },
                      ]}
                      rows={phones.map((phone) => ({
                        id: phone.phoneNumber,
                        phoneNumber: phone.phoneNumber,
                        locality: phone.locality,
                        actions: (
                          <Tooltip
                            sideOffset={0}
                            align="center"
                            side="left"
                            content="Selecionar"
                            trigger={
                              <div>
                                <Button
                                  variant="secondary"
                                  text=""
                                  icon="ArrowNext20Regular"
                                  onClick={() => {
                                    setSelectedPhone(phone);
                                  }}
                                />
                              </div>
                            }
                          />
                        ),
                      }))}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Box>
          </Box>
        )
      }
    />
  );
};

export default BuyPhoneNumberModal;
