import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const Code = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.blue.darkest};
`;

export const Title = styled.div`
  display: flex;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.blue.darkest};
`;
