import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { IUser } from 'domain/entity/IUser';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  UpdateProfileRequest,
  UpdateProfileUseCase,
} from 'domain/usecases/profile/update-profile-use-case';

import { useToken } from 'main/hooks/usetoken';

export class RemoteUpdateProfile implements UpdateProfileUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(params: UpdateProfileRequest): Promise<IUser> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'put',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Tenant: tenant,
      },
      params: params,
    });

    const profile = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        this.toastService.show('Atualizado com sucesso!', {
          type: 'success',
        });
        return {
          id: profile.id,
          username: profile.username,
          email: profile.email,
          password: profile.password,
          created_at: profile.created_at,
        };

      case HttpStatusCode.unauthorized:
        this.toastService.show('Não autorizado', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');
      default:
        this.toastService.show('Erro no servidor', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
