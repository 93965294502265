import styled from 'styled-components';

export const ListLine = styled.li`
  display: flex;
  padding: 0.5rem 0;
  border-top: solid 1px ${({ theme }) => theme.colors.gray[30]};
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  min-height: 96px;
  min-width: 100%;
  max-width: 100%;
`;

export const InfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;
export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
`;

export const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
`;
