import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { PaginateDTO } from 'domain/dtos/paginate-dto';
import { IService } from 'domain/entity/IService';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  ListServicesRequestDTO,
  ListServicesUseCase,
} from 'domain/usecases/service/list-services-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteListServices implements ListServicesUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(
    params?: ListServicesRequestDTO
  ): Promise<PaginateDTO<IService>> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Tenant: tenant,
      },
      params: params,
    });

    const data = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return {
          data: data.data.map((service: IService) => ({
            id: service.id,
            name: service.name,
            price: service.price,
            created_at: service.created_at,
          })),
          lastPage: data.lastPage,
          page: data.page,
          perPage: data.perPage,
          total: data.total,
        };

      case HttpStatusCode.unauthorized:
        this.toastService.show('Unauthorized', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');
      default:
        this.toastService.show('Unexpected error', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
