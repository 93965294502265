type useTokenProps = {
  token?: string;
  tenant?: string;
};

export const useToken = (): useTokenProps => {
  const token = localStorage.getItem('token');
  const tenant = localStorage.getItem('tenant');

  return {
    token: JSON.parse(token || 'null'),
    tenant: JSON.parse(tenant || 'null'),
  };
};
