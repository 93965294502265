import { SaveLossUseCase } from 'domain/usecases/loss/save-loss-use-case';
import { RemoteSaveLoss } from 'data/usecases/loss/remote-save-loss';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeSaveLossUseCase = (id?: string): SaveLossUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteSaveLoss(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/losses${id ? `/${id}` : ''}`,
    toastify
  );
};
