import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1rem;
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  div > div {
    display: flex;
  }
`;

export const Body = styled.div``;

export const IconTittle = styled.div`
  display: flex;
  padding: 4px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.blue[50]};
`;

export const EmptyState = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 6px;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray[30]};
  border-radius: 8px;
`;

export const ListWrap = styled.ul`
  display: flex;
`;

export const Trigger = styled.div<{ contacts?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  & button {
    background: none;
    padding: 2px;
    border-radius: 4px;

    :hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.blue[50]};
    }
  }
`;

export const ItensWrapper = styled.li`
  padding-top: 1rem;
`;

export const Item = styled.li<{ contacts?: boolean }>`
  > div > div {
    display: flex;
    height: 20px;
  }
  list-style: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-bottom: 1rem;

  :last-child {
    padding: 0;
  }

  & span {
    color: ${({ theme }) => theme.colors.gray[600]};
    font-weight: 700;
  }

  & button {
    background: none;
    padding: 2px;
    border-radius: 4px;

    :hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.gray[20]};
    }
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  flex-wrap: wrap;
`;
