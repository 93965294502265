import styled from 'styled-components';

export const EditorContainer = styled.div`
  .ql-toolbar .ql-snow {
    border: none !important;
    background-color: transparent !important;
  }

  .ql-editor {
    font-family: 'Noto Sans', sans-serif;
    min-height: 80px;
    border: none !important; /* Add !important here */
    background-color: transparent;
  }
`;

export const Container = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray[20]};

  :focus {
    border: solid 1px ${({ theme }) => theme.colors.blue[100]};
    background: ${({ theme }) => theme.colors.blue[50]};
  }

  .ql-toolbar {
    font-family: 'Noto Sans', sans-serif;
    border: none !important;
    background-color: transparent !important;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[20]} !important;
  }

  .ql-snow {
    font-family: 'Noto Sans', sans-serif;
    border: none !important;
    background-color: transparent !important;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[20]} !important;
  }

  .ql-container .ql-snow .ql-editor {
    font-family: 'Noto Sans', sans-serif;
    min-height: 80px;
    border: none;
    background-color: transparent;
  }
`;
