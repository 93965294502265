import React, { ReactElement } from 'react';
import * as S from './styles';
import * as T from './types';

const Card = (props: T.CardProps): ReactElement => {
  const {
    children,
    hasShadow = false,
    padding,
    position = 'relative',
    radius,
    direction = 'row',
    align,
    gap,
    mobileDirection,
    hasBorder,
    justifyContent,
    flexWrap,
    fillHeight,
    fill,
  } = props;

  const styles = {
    hasShadow,
    padding,
    position,
    radius,
    direction,
    align,
    gap,
    mobileDirection,
    hasBorder,
    justifyContent,
    flexWrap,
    fillHeight,
    fill,
  };
  return (
    <S.Container data-testid="card-box" {...styles}>
      {children}
    </S.Container>
  );
};

export default Card;
