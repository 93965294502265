import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  FunnelsDealsRequestDTO,
  FunnelsDealsResponseDTO,
  GetReportFunnelsDealsUseCase,
} from 'domain/usecases/deal/report-funnels-deals.use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteGetFunnelsDealsReport
  implements GetReportFunnelsDealsUseCase
{
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string
  ) {}

  async execute(
    params: FunnelsDealsRequestDTO
  ): Promise<FunnelsDealsResponseDTO[]> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Tenant: tenant,
      },
      params,
    });

    const data = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return data
          .sort((a, b) => a.order - b.order)
          .map((report) => ({
            name: report.name,
            deals: report.deals,
            order: report.order,
          }));
      case HttpStatusCode.unauthorized:
        throw new NotAuthorizedError('Unauthorized');
      default:
        throw new UnexpectedError('Unexpected error');
    }
  }
}
