import { IDeal } from 'domain/entity/IDeal';
import { DealRequestPayload } from 'domain/usecases/deal/list-deals-use-case';
import { createContext } from 'react';

interface DealContextProps {
  loading: boolean;
  view: 'pipeline' | 'list';
  filter: DealRequestPayload;
  setFilter: (filter: DealRequestPayload) => void;
  createDeal: boolean;
  setCreateDeal: (value: boolean) => void;
  isFilterOpen: boolean;
  setIsFilterOpen: (value: boolean) => void;
  bulkOwner: boolean;
  setBulkOwner: (value: boolean) => void;
  bulkTags: boolean;
  setBulkTags: (value: boolean) => void;
  bulkFunnel: boolean;
  setBulkFunnel: (value: boolean) => void;
  bulkWon: boolean;
  setBulkWon: (value: boolean) => void;
  bulkLoss: boolean;
  setBulkLoss: (value: boolean) => void;
  importDialogOpen: boolean;
  setImportDialogOpen: (value: boolean) => void;
  initialFilter: DealRequestPayload;
  fetchFunnels: () => void;
  fetchDeals: () => void;
  deals: IDeal[];
  lastPage: number;
  totalDeals: number;
  activePage: number;
}

const DealContext = createContext<DealContextProps>({} as DealContextProps);

export default DealContext;
