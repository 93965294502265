import React, { ReactElement, createRef, useEffect, useState } from 'react';
import { Box, Dialog, Input, Label, Select, ToggleGroup } from 'mino-ui';
import { useForm } from 'react-hook-form';
import { IconNames, ManageModalType } from './type';
import { makeUpdateCustomField } from 'main/factories/usecases/custom_field/remote-update-custom-fields-factory';
import { ICustomField } from 'domain/entity/ICustomField';
import { makeCreateCustomField } from 'main/factories/usecases/custom_field/remote-create-custom-fields-factory';
import { makeToastify } from 'main/factories/notification';

const ManageModal = (props: ManageModalType): ReactElement => {
  const { isOpen, setOpen, updatePayload, setUpdatePayload, callback } = props;

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
    control,
  } = useForm<ICustomField>({
    defaultValues: {
      type: 'text',
      parent_type: 'deal',
    },
  });

  async function SaveOrUpdateCustomFields(data: ICustomField) {
    if (!data.parent_type) {
      makeToastify().show('É necessário preencher o recurso.', {
        type: 'error',
      });

      return;
    }

    try {
      setLoading(true);

      if (updatePayload?.id) {
        await makeUpdateCustomField(updatePayload.id).execute(data);
      }

      if (!updatePayload) {
        await makeCreateCustomField().execute(data);
      }

      reset();
      callback();
      setOpen(false);
    } catch (error) {
      console.log(error);
      makeToastify().show('Erro ao salvar campo personalizado.', {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (updatePayload) {
      setValue('name', updatePayload.name);
      setValue('parent_type', updatePayload.parent_type);
      setValue('type', updatePayload.type);
    }
  }, [updatePayload, setValue]);

  const submitRef = createRef<HTMLInputElement>();

  return (
    <Dialog
      modal
      maxWidth={400}
      open={isOpen}
      onOpenChange={(state) => {
        !state && reset?.();
        setOpen?.(state);
        setUpdatePayload?.(undefined);
      }}
      title={updatePayload ? 'Editar campo' : 'Adicionar Campo'}
      onSubmit={() => submitRef.current?.click()}
      submitVariant="primary"
      submitText={'Salvar'}
      submitIconName={IconNames.Save20Regular}
      submitIconColor={'white' as any}
      loading={loading}
      content={
        <form
          onSubmit={handleSubmit((data) => {
            SaveOrUpdateCustomFields(data);
          })}
        >
          <Box gap={24} flexDirection="column">
            <Box flexDirection="column">
              {!updatePayload?.parent_type ? (
                <>
                  <Label text="Recurso" />
                  <ToggleGroup
                    size="small"
                    type="single"
                    defaultValue={'deal'}
                    onValueChange={(value) =>
                      setValue(
                        'parent_type',
                        value as ICustomField['parent_type']
                      )
                    }
                    items={[
                      {
                        icon: '',
                        label: 'Negócio',
                        value: 'deal',
                      },
                      {
                        icon: '',
                        label: 'Contato',
                        value: 'contact',
                      },
                      {
                        icon: '',
                        label: 'Empresa',
                        value: 'company',
                      },
                    ]}
                  />
                </>
              ) : null}
            </Box>

            <Input
              name="name"
              label="Nome do campo"
              autoFocus
              placeholder='Ex: "Cargo na empresa"'
              validations={{ required: true, minLength: 3, maxLength: 22 }}
              register={register}
              errors={errors}
            />

            <Select
              label="Tipo de campo"
              required
              name="type"
              defaultValue={'text'}
              control={control}
              options={[
                {
                  label: 'Texto',
                  value: 'text',
                },
                {
                  label: 'Número',
                  value: 'number',
                },
              ]}
            />
            <input type="submit" hidden ref={submitRef} />
          </Box>
        </form>
      }
    />
  );
};

export default ManageModal;
