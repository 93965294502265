import { RemoteSavePermissions } from 'data/usecases';
import { SavePermissionsUseCase } from 'domain/usecases/group/save-permissions-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeSavePermissionsUseCase = (
  resource: 'product' | 'contact' | 'config',
  groupId?: string
): SavePermissionsUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteSavePermissions(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/${resource}-roles${
      groupId ? `/${groupId}` : ''
    }`,
    toastify
  );
};
