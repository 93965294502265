import { RemoteGetDeal } from 'data/usecases';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeGetSingleDealUseCase = (dealId: string): RemoteGetDeal => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteGetDeal(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/products/${encodeURI(dealId)}`,
    toastify
  );
};
