import styled from 'styled-components';

interface BadgeStyledProps {
  variant: 'primary';
}

export const Container = styled.div<BadgeStyledProps>`
  display: flex;
  align-items: center;
`;

export const Badge = styled.span`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 80%;
  background-color: ${({ theme }) => theme.colors.blue[50]};
  color: ${({ theme }) => theme.colors.blue.primary};
  border-radius: 3px;
  height: 1rem;
  padding: 3.5px;
`;
