import React, { ReactElement, useState } from 'react';
import * as T from './types';
import * as S from './styles';
import Icon from '../Icon';

const Tabs = (props: T.TabsProps): ReactElement => {
  const {
    content,
    trigger,
    defaultSelected,
    triggerGap,
    orientation,
    hiddenTriggerTextOnMobile = true,
    onTabChange,
  } = props;

  const [selectedTab, setSelected] = useState(defaultSelected || trigger[0].id);

  return (
    <S.Container
      data-testid="nav-tab"
      onValueChange={(v) => {
        setSelected(v);
        onTabChange?.(v);
      }}
      value={selectedTab}
      orientation={orientation}
    >
      <S.List gap={triggerGap}>
        {trigger.map((tr) => (
          <S.Trigger
            key={tr.id}
            value={tr.id}
            asChild
            onClick={() => setSelected(tr.id)}
            className={selectedTab === tr.id ? 'active' : ''}
          >
            <S.WrapTrigger>
              <S.TabButton className={selectedTab === tr.id ? 'active' : ''}>
                {tr.icon && <Icon iconName={tr.icon} />}
                <S.ButtonText
                  className={hiddenTriggerTextOnMobile ? 'h-text' : ''}
                >
                  {tr.text}
                </S.ButtonText>
              </S.TabButton>
            </S.WrapTrigger>
          </S.Trigger>
        ))}
      </S.List>

      {content.map((cnt) => (
        <S.Content key={cnt.id} value={cnt.id} asChild>
          {cnt.component}
        </S.Content>
      ))}
    </S.Container>
  );
};
export default Tabs;
