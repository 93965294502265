import React, { useEffect } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Controls from './controls/controls';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import * as T from './types';
import * as S from './styles';

const RichText = (props: T.RichTextProps) => {
  const { value, onChange, defaultSetup = true } = props;

  const editor = useEditor({
    extensions: [StarterKit, Underline, Link],

    onBlur({ editor }) {
      const value = editor.getHTML();
      onChange(value);
    },
  });

  const isProseMirrorFocused =
    editor?.view.dom.classList.contains('ProseMirror-focused') || false;

  useEffect(() => {
    editor?.commands.setContent(value as string);
  }, [value, editor]);

  return (
    <S.Container isFocused={isProseMirrorFocused}>
      {editor && <Controls defaultSetup={defaultSetup} editor={editor} />}
      <EditorContent className="ProseMirror" editor={editor} />
    </S.Container>
  );
};

export default RichText;
