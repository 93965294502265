import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Button, InputSearch, Spacer, Table, Avatar } from 'mino-ui';
import { Link } from 'react-router-dom';
import * as S from './styles';
import { firstLetterUpper } from 'main/helpers/firstLetterUpper';
import { ICompany } from 'domain/entity/ICompany';
import DrawerFilterCompany from './components/filterDrawer/filterDrawer';
import CompanyContext from './contexts/CompanyContext';
import { CompanyRequestParams } from 'domain/usecases/company/list-companies-use-case';
import EmptyStateCompany from './components/EmptyState/component';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import NotFoundStateCompany from './components/NotFoundState/component';
import AddCompanyDialog from 'presentation/shared/templates/Companies/addCompanyDialog';
import BulkOwnerCompanies from './components/bulkOwner/component';
import BulkTagsCompanies from './components/bulkTags/component';

type PageProps = {
  companies: ICompany[];
  loading: boolean;
  fetchCompanies: (params: CompanyRequestParams) => void;
  total?: number;
};

export enum IconColors {
  blue = 'blue',
  white = 'white',
  dark = 'dark',
}

const CompaniesList = (props: PageProps): ReactElement => {
  const { companies, fetchCompanies, loading, total } = props;
  const [createCompany, setCreateCompany] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [bulkCompaniesOwner, setBulkCompaniesOwner] = useState(false);
  const [bulkCompaniesTags, setBulkCompaniesTags] = useState(false);
  const [ids, setIds] = useState<string[]>([]);

  const [filter, setFilter] = useState<CompanyRequestParams>({
    page: 1,
    search: '',
  });
  const initialFilter = {
    page: 1,
    search: '',
  };

  const [searchInput, setSearchInput] = useState('initial');

  useDebouncedEffect(
    () => {
      if (searchInput !== 'initial')
        setFilter({
          ...filter,
          search: searchInput,
        });
    },
    [searchInput],
    500
  );

  function handlePageChange(pageNumber: number) {
    setPage(pageNumber);
    setFilter({
      ...filter,
      page: pageNumber,
    });
  }

  useEffect(() => {
    fetchCompanies(filter);
  }, [filter, page]);

  return (
    <CompanyContext.Provider
      value={{
        companies,
        createCompany,
        fetchCompanies,
        loading,
        setCreateCompany,
        isFilterOpen,
        setFilterOpen,
        filter,
        setFilter,
        bulkCompaniesOwner,
        bulkCompaniesTags,
        setBulkCompaniesOwner,
        setBulkCompaniesTags,
      }}
    >
      <S.Toolbar>
        <S.ToolbarLeftContent>
          <Button
            icon="AddCircle20Regular"
            iconColor={IconColors.white}
            hiddenTextOnMobile
            text="Empresa"
            onClick={() => {
              setCreateCompany(true);
            }}
            variant="primary"
            type="button"
          />
        </S.ToolbarLeftContent>

        <S.WrapInputSearch>
          <InputSearch
            full
            placeholder="Pesquisar empresa"
            name="search"
            type="text"
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </S.WrapInputSearch>

        <S.ToolbarRightContent>
          <Button
            iconPosition="left"
            hiddenTextOnMobile
            icon="Filter20Filled"
            iconColor={IconColors.blue}
            text="Filtros"
            variant="ghost"
            onClick={() => setFilterOpen(true)}
          />
        </S.ToolbarRightContent>
      </S.Toolbar>
      <Box boxMain>
        <Spacer height={16} />
        {JSON.stringify(initialFilter) === JSON.stringify(filter) &&
        companies.length === 0 &&
        !loading ? (
          <EmptyStateCompany />
        ) : JSON.stringify(initialFilter) !== JSON.stringify(filter) &&
          companies.length === 0 &&
          !loading ? (
          <NotFoundStateCompany />
        ) : (
          <>
            <Table
              hasBulkActions
              bulkActions={[
                {
                  label: 'Alterar Responsável',
                  action: (ids) => {
                    setBulkCompaniesOwner(true);
                    setIds(ids);
                  },
                  buttonVariant: 'ghost',
                  iconName: 'PersonSync20Regular',
                  iconColor: IconColors.dark,
                },
                {
                  label: 'Alterar Tags',
                  action: (ids) => {
                    setBulkCompaniesTags(true);
                    setIds(ids);
                  },
                  buttonVariant: 'ghost',
                  iconName: 'Tag',
                  iconColor: IconColors.dark,
                },
              ]}
              activePage={page}
              onChangePage={handlePageChange}
              totalItemsCount={total}
              onSort={(sort) => {
                setFilter({
                  ...filter,
                  sortColumn: sort.field,
                  sortDirection: sort.order,
                });
              }}
              columns={[
                { field: 'name', headerName: 'Nome', sortable: true },
                {
                  field: 'email',
                  headerName: 'Email',
                  hiddenOnMobile: true,
                },
                {
                  field: 'phone',
                  headerName: 'Telefone',
                  hiddenOnMobile: true,
                },
                {
                  field: 'site',
                  headerName: 'Site',
                  hiddenOnMobile: true,
                },
                {
                  field: 'state',
                  headerName: 'Estado',
                  hiddenOnMobile: true,
                },
              ]}
              rows={companies.map((company) => ({
                id: company.id.toString(),
                name: (
                  <Box alignItems="center">
                    <Avatar name={company.name} />
                    <Link
                      to={`/companies/${company.id}`}
                      style={{ marginLeft: 8 }}
                    >
                      {firstLetterUpper(company.name)}
                    </Link>
                  </Box>
                ),
                email: company.email,
                phone: company.phone,
                site: company.site,
                state: company.state,
              }))}
            />
          </>
        )}

        <AddCompanyDialog
          isDialogOpen={createCompany}
          setDialogOpen={setCreateCompany}
          context="company"
          singleId=""
        />

        <DrawerFilterCompany />

        <BulkOwnerCompanies ids={ids} />
        <BulkTagsCompanies ids={ids} />
      </Box>
    </CompanyContext.Provider>
  );
};
export default CompaniesList;
