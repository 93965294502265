import styled, { css } from 'styled-components';
import * as AvatarPrimitive from '@radix-ui/react-avatar';

interface AvatartProps {
  variant?: string;
  width?: number;
}

export const Container = styled.div`
  display: flex;
  gap: 20px;
`;

export const Avatar = styled(AvatarPrimitive.Root)<AvatartProps>`
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  user-select: none;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  position: relative;
  color: ${({ theme }) => theme.colors.blue.primary};
  background-color: ${({ theme }) => theme.colors.blue[50]};

  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
      height: ${width}px;
    `}

  ${({ variant }) =>
    variant == 'violet' &&
    css`
      color: ${({ theme }) => theme.colors.violet.primary};
      background-color: ${({ theme }) => theme.colors.violet[50]};
    `}

  ${({ variant }) =>
    variant == 'green' &&
    css`
      color: ${({ theme }) => theme.colors.green.primary};
      background-color: ${({ theme }) => theme.colors.green[50]};
    `}

  ${({ variant }) =>
    variant == 'red' &&
    css`
      color: ${({ theme }) => theme.colors.red.primary};
      background-color: ${({ theme }) => theme.colors.red[50]};
    `}
    
  ${({ variant }) =>
    variant == 'orange' &&
    css`
      color: ${({ theme }) => theme.colors.orange.primary};
      background-color: ${({ theme }) => theme.colors.orange[50]};
    `}
  
  ${({ variant }) =>
    variant == 'teal' &&
    css`
      color: ${({ theme }) => theme.colors.teal.primary};
      background-color: ${({ theme }) => theme.colors.teal[50]};
    `}
`;

export const AvatarImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  color: ${({ theme }) => theme.colors.gray};
  background-color: ${({ theme }) => theme.colors.blue[50]};
`;

export const AvatarFallback = styled(AvatarPrimitive.Fallback)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.75rem;
`;
