import styled, { css } from 'styled-components';
import { ColorDividerProps } from './types';
import * as Separator from '@radix-ui/react-separator';

interface DividerStyledProps {
  color?: ColorDividerProps;
  width?: number;
  height?: number;
}

export const Divider = styled(Separator.Root)<DividerStyledProps>`
  display: block;
  margin: 0 auto;
  width: ${({ width }) => (width ? width : 100)}%;
  background-color: 1px solid ${({ theme }) => theme.colors.gray[30]};

  &[data-orientation='horizontal'] {
    height: 1px;
    width: 100%;
  }

  &[data-orientation='vertical'] {
    height: 100%;
    width: 1px;
  }
`;
