import React, { ReactElement, useContext } from 'react';
import { Button, Typography, Icon } from 'mino-ui';
import * as S from './styles';
import * as T from './types';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';

type CardProps = T.IntegrationCardProps & {
  action: () => void;
};

export const IntegrationCard = (props: CardProps): ReactElement => {
  const { name, description, logo, active, action, id } = props;

  const {
    session: { id: userId },
  } = useContext(SessionContext);

  return (
    <S.ListLine>
      <S.Content>
        <S.InfoWrap>
          <Icon src={logo} />

          <S.TextWrap>
            <Typography noWrap fontSize={16} variant="body" weight="bold">
              {name}
            </Typography>
            <Typography
              noWrap
              fontSize={12}
              color="gray"
              variant="body"
              weight="regular"
            >
              {description}
            </Typography>
          </S.TextWrap>
        </S.InfoWrap>

        <S.ButtonWrap>
          <Button
            text={active ? 'Integrado (Editar)' : 'Saber mais'}
            size="small"
            onClick={action}
            variant={active ? 'active' : 'outline'}
          />
        </S.ButtonWrap>
      </S.Content>
    </S.ListLine>
  );
};
