import React, { ReactElement } from 'react';
import { Box, Button, Typography, Tabs } from 'mino-ui';
import * as S from './styles';
import { IntegrationCardProps } from '../components/types';

type SingleIntegrationsProps = IntegrationCardProps & {
  documentation: string;
  description: string;
  callback: () => void;
};

export const SingleIntegrationPage = (
  props: SingleIntegrationsProps
): ReactElement => {
  const { description, documentation, logo, name, callback, content } = props;

  return (
    <Box>
      <S.Content>
        <S.SideContent>
          <S.LogoWrap>
            <img src={logo} width={150} alt="Apps" />
          </S.LogoWrap>
          <S.TextWrap>
            <Typography
              noWrap
              fontSize={18}
              variant="body"
              align="center"
              fill
              weight="bold"
            >
              {name}
            </Typography>
            <Typography
              noWrap
              fontSize={14}
              variant="body"
              color="gray"
              align="center"
              fill
            >
              {description}
            </Typography>
          </S.TextWrap>
          <S.ButtonWrap>
            <Button
              onClick={callback}
              text="Voltar"
              variant="ghost"
              size="medium"
            />
          </S.ButtonWrap>
        </S.SideContent>
        <S.MainContent>
          <Tabs
            hiddenTriggerTextOnMobile={false}
            trigger={[
              {
                id: 'integration',
                text: 'Integrar',
              },
              {
                id: 'description',
                text: 'Descrição',
              },
              {
                id: 'docs',
                text: 'Documentação',
              },
            ]}
            content={[
              {
                id: 'integration',
                component: content,
              },
              {
                id: 'docs',
                component: (
                  <Typography
                    variant="body"
                    color="gray"
                    noWrap
                    lineHeight={18}
                    fill
                  >
                    <div dangerouslySetInnerHTML={{ __html: documentation }} />
                  </Typography>
                ),
              },
              {
                id: 'description',
                component: (
                  <Typography fill noWrap variant="body">
                    {description}
                  </Typography>
                ),
              },
            ]}
          />
        </S.MainContent>
      </S.Content>
    </Box>
  );
};
