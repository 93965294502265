import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { IService } from 'domain/entity/IService';
import { Box, Drawer, Select, Spacer, Typography } from 'mino-ui';
import {
  makeListServicesUseCase,
  makeUpdateDealUseCase,
} from 'main/factories/usecases';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { useParams } from 'react-router';
import { ReactSVG } from 'react-svg';
import CloseIcon from './icons/close.svg';
import BookIcon from './icons/book.svg';
import BoxIcon from './icons/box.svg';

type ServiceDrawerProps = {
  services: IService[];
  isOpen: boolean;
  openOpen: (val: boolean) => void;
  callback: () => void;
};
export const ServiceDrawer = (props: ServiceDrawerProps): ReactElement => {
  const { services: servicesProps, isOpen, openOpen, callback } = props;
  const { id = '' } = useParams();

  const [servicesOptions, setServicesOptions] = useState<IService[]>([]);
  const [services, setServices] = useState(
    servicesProps.map((c) => ({
      id: c.id,
      name: c.name,
    }))
  );

  const [search, setSearch] = useState('');

  const fetchServices = useCallback(async () => {
    try {
      const { data } = await makeListServicesUseCase().execute({
        search: search,
      });
      setServicesOptions(data);
    } catch (error) {
      console.log(error);
    }
  }, [search, setServicesOptions]);

  const [attach_services, setAttachServices] = useState<string[]>([]);
  const [detach_services, setDetachServices] = useState<string[]>([]);

  const attachServices = useCallback(
    (id: string, name: string) => {
      if (
        attach_services.includes(id) ||
        services.map((c) => c.id).includes(id)
      ) {
        return;
      }

      setAttachServices([...attach_services, id]);

      const newDetach = detach_services.filter((a) => a !== id);
      setDetachServices(newDetach);

      const newState = services.filter((c) => c.id !== id);
      setServices([...newState, { id, name }]);
    },
    [
      attach_services,
      setServices,
      services,
      setAttachServices,
      detach_services,
      setDetachServices,
    ]
  );

  const detachServices = useCallback(
    (id: string) => {
      if (detach_services.includes(id)) return;
      setDetachServices([...detach_services, id]);

      const newAttach = attach_services.filter((a) => a !== id);
      setAttachServices(newAttach);

      const newState = services.filter((c) => c.id !== id);
      setServices(newState);
    },
    [
      setServices,
      services,
      setServices,
      detach_services,
      setDetachServices,
      attach_services,
      setAttachServices,
    ]
  );

  const handleAttachServices = useCallback(async () => {
    try {
      await makeUpdateDealUseCase(id).execute({
        attach_services: attach_services,
        detach_services: detach_services,
      });

      fetchServices();
      setSearch('');
      callback();
      openOpen(false);
    } catch (error) {
      console.log(error);
    }
  }, [fetchServices, setSearch, attach_services, detach_services]);

  useDebouncedEffect(fetchServices, [fetchServices], 200);

  const reset = useCallback(() => {
    setAttachServices([]);
    setDetachServices([]);
  }, [isOpen, setAttachServices, setDetachServices]);

  useEffect(reset, [reset]);

  return (
    <Drawer
      width={300}
      isOpen={isOpen}
      onClose={() => openOpen(false)}
      title="Vincular Produto"
      icon={BoxIcon}
      submitText="Salvar"
      onSubmit={handleAttachServices}
      secondarySubmitText="cancelar"
      secondarySubmitAction={() => {
        openOpen(false);
      }}
    >
      <Box gap={16} flexDirection="column">
        <Select
          onInputChange={(value) => {
            setSearch(value);
          }}
          label="Adicionar Produto"
          placeholder="Busque por produtos"
          options={servicesOptions.map((service) => ({
            value: service.id,
            label: service.name,
          }))}
          onChange={(d) => d && attachServices(d.value, d.label || '')}
        />

        <Spacer height={16} />

        <Box flexDirection="column" justifyContent="center" gap={16}>
          <Typography variant="body" weight="bold" noWrap>
            Produtos Vinculados
          </Typography>

          {services.map((service) => (
            <Box key={service.id} alignItems="center" gap={8}>
              <Box
                width="100%"
                alignItems="center"
                justifyContent="space-between"
                gap={8}
              >
                <Box gap={8} alignItems="center">
                  <ReactSVG src={BookIcon} />
                  <Typography color="gray" variant="body" weight="bold">
                    {service.name}
                  </Typography>
                </Box>

                <ReactSVG
                  onClick={() => detachServices(service.id)}
                  src={CloseIcon}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Drawer>
  );
};
