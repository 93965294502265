import styled from 'styled-components';

export const GridWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;

export const Item = styled.div`
  flex: 1;
  flex-basis: calc(50% - 1rem);
  width: 100%;
  height: 40px;
  background-color: red;
`;

export const Subtitle = styled.h3`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[600]};
  font-size: 1rem;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray[30]};
`;

export const ProductWrap = styled.div`
  display: grid;
  position: relative;
  grid-template-columns:
    calc(30% - 16px) calc(20% - 16px) calc(15% - 16px) calc(15% - 16px)
    calc(20% - 16px);
  gap: 16px;
  align-items: start;
  row-gap: 0;

  ::before {
    content: '';
    position: absolute;
    width: 3px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.blue[100]};
    top: 0;
    left: -10px;
    border-radius: 4px 0 0 4px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    flex-direction: column;
  }
`;

export const OverflowProducts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 86px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.gray[30]};
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray[50]};
    border-radius: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-height: 100%;
  }
`;
export const ProductListWrap = styled.div`
  display: grid;
  grid-template-columns:
    calc(30% - 16px) calc(19% - 16px) calc(8% - 16px) calc(13% - 16px)
    calc(20% - 16px) calc(10% - 16px);
  gap: 16px;
  align-items: end;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const RemoveButton = styled.button`
  width: fit-content;
  background: transparent;
  border: none;
  position: absolute;
  right: -26px;
  top: 40px;

  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;
