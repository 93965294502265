import { useEffect, useState } from 'react';
import { ChannelResponse } from 'domain/usecases/channel/list-channels-use-case';
import { makeListChannelUseCase } from 'main/factories/usecases/channel/list-channel-factory';

type UseChannelsType = {
  channels: ChannelResponse[] | [];
  fetchChannels: () => Promise<void>;
  loading: boolean;
};

export const useChannels = (): UseChannelsType => {
  const [loading, setLoading] = useState(false);

  const [channels, setChannels] = useState<ChannelResponse[] | []>([]);

  async function fetchChannels(): Promise<void> {
    try {
      setLoading(true);
      const data = await makeListChannelUseCase().execute();
      setChannels(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchChannels();
  }, []);

  return { channels, fetchChannels, loading };
};
