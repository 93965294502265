import { RemoteUpdateContact } from 'data/usecases/contact/remote-update-contact';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeUpdateContactUseCase = (
  contactId: string
): RemoteUpdateContact => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteUpdateContact(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/contacts/${encodeURI(contactId)}`,
    toastify
  );
};
