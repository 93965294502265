import { RemoteBulkActionsContacts } from 'data/usecases/contact/remote-bulk-actions-contacts';
import { BulkActionsContactsUseCase } from 'domain/usecases/contact/bulk-actions-contacts-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeBulkActionsContacts = (): BulkActionsContactsUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteBulkActionsContacts(
    `${process.env.REACT_APP_API_BASE_URL}/bulk-contacts`,
    axios,
    toastify
  );
};
