import { useContext, useEffect, useState } from 'react';
import { ICompany } from 'domain/entity/ICompany';
import { makeListCompaniesUseCase } from 'main/factories/usecases/company/list-companies-factory';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import {
  ContactsResourcesPermissions,
  hasContactsPermissions,
} from 'presentation/hooks/usePermissions';

type UseCompaniesType = {
  companies: ICompany[] | [];
  fetchCompanies: (search?: string) => Promise<void>;
  loading: boolean;
};

export const useCompanies = (): UseCompaniesType => {
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [loading, setLoading] = useState(false);

  const { session } = useContext(SessionContext);
  const permission = hasContactsPermissions(
    session,
    ContactsResourcesPermissions.WATCH_ALL
  );

  async function fetchCompanies(search?: string): Promise<void> {
    try {
      setLoading(true);
      const { data } = await makeListCompaniesUseCase().execute({
        search,
        user: permission ? 'all' : 'my',
      });
      setCompanies(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCompanies();
  }, []);

  return { companies, fetchCompanies, loading };
};
