import { RemoteDeleteTag } from 'data/usecases/tags/remote-delete-tag';
import { DeleteTagUseCase } from 'domain/usecases/tag/delete-tag-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeDeleteTagUseCase = (id: string): DeleteTagUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteDeleteTag(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/tags/${id}`,
    toastify
  );
};
