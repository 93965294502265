import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { UnexpectedError } from 'domain/errors';
import { DeleteCustomField } from 'domain/usecases/custom_field/delete-custom-field';

export class RemoteDeleteCustomFields implements DeleteCustomField {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  async execute(): Promise<{
    success: boolean;
  }> {
    const response = await this.httpClient.request({
      url: this.url,
      method: 'delete',
    });

    if (response.statusCode === HttpStatusCode.ok) {
      return response.body;
    }

    throw new UnexpectedError('Erro ao deletar campos personalizados');
  }
}
