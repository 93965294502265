import { RemoteListImportBatch } from 'data/usecases/import_batch/remote-list-import-batch';
import { ListImportBatchUseCase } from 'domain/usecases/import_batch/list-import-batch-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const MakeListImportBatchUseCase = (): ListImportBatchUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteListImportBatch(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/imports/list`,
    toastify
  );
};
