import React, { ReactElement, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import Arrow from './icon/arrow.svg';
import * as S from './styles';
import * as T from './types';

const SelectNative = (props: T.MenuProps): ReactElement => {
  const {
    placeholder,
    items,
    defaultValue: defaultValueProps,
    onValueChange,
    disabled,
  } = props;

  const [defaultValue, setDefaultValue] = useState(defaultValueProps);

  useEffect(() => {
    setDefaultValue(defaultValueProps);
  }, [defaultValueProps]);

  return (
    <S.Select defaultValue={defaultValue} onValueChange={onValueChange}>
      <S.SelectTrigger disabled={disabled}>
        <S.SelectedValue placeholder={placeholder} />
        <S.SelectIcon>
          <ReactSVG src={Arrow} />
        </S.SelectIcon>
      </S.SelectTrigger>
      <S.ContentWrap data-testid="menu-content">
        <S.SelectContent>
          <S.SelectViewport>
            {items.map((item, index) => (
              <S.SelectItem key={index} value={`${item.value}`}>
                <S.SelectItemText>{item.textValue}</S.SelectItemText>
              </S.SelectItem>
            ))}
          </S.SelectViewport>
        </S.SelectContent>
      </S.ContentWrap>
    </S.Select>
  );
};
export default SelectNative;
