import { RemoteSaveService } from 'data/usecases';
import { SaveServicesUseCase } from 'domain/usecases/service/save-services-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeSaveServiceUseCase = (id?: string): SaveServicesUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteSaveService(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/services${id ? `/${id}` : ''}`,
    toastify
  );
};
