import React, { useState } from 'react';
import * as S from './styles';
import { Input, Spacer, Button, Typography, Icon } from 'mino-ui';
import LogoMobile from 'presentation/assets/icons/ModivLogoDarkTextRigth.svg';
import Logo from 'presentation/assets/icons/ModivWhiteLogoTextRigth.svg';
import Browsers from 'presentation/assets/images/browsers.png';
import { makeLoginUseCase } from 'main/factories/usecases';
import { saveLocalToken } from 'main/hooks/saveLocalToken';
import { useForm } from 'react-hook-form';
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router';
import { IconColors } from '../Activity';

type FormType = {
  email: string;
  password: string;
};

const LoginPage = (): JSX.Element => {
  const [loading, setLoading] = useState(false);

  async function submitLogin(data: FormType) {
    setLoading(true);
    const login = makeLoginUseCase();

    login
      .execute(data.email, data.password)
      .then((response) => {
        saveLocalToken(response.token, response.tenant_id);
      })
      .then(() => {
        window.location.href = '/';
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormType>();

  const navigate = useNavigate();

  return (
    <S.Container>
      <S.TextColumn>
        <ReactSVG src={Logo} width={50} />
        <span>Modiv CRM v.1.3.0-beta.2</span>
      </S.TextColumn>
      <S.WrapperForm>
        <S.LogoMobile>
          <ReactSVG src={LogoMobile} />
        </S.LogoMobile>

        <form onSubmit={handleSubmit(submitLogin)}>
          <S.StyledForm>
            <Typography
              noWrap={true}
              variant="h2"
              align="left"
              weight="bold"
              color="dark"
              fill
            >
              Bem vindo(a) de volta!
            </Typography>
            <Spacer height={12} />

            <Input
              full
              label="E-mail"
              name="email"
              register={register}
              errors={errors}
              placeholder="E-mail"
              validations={{
                required: true,
                pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
              }}
            />
            <Spacer height={17} heightMobile={26} />
            <Input
              full
              label="Senha"
              name="password"
              register={register}
              errors={errors}
              placeholder="Senha"
              type="password"
              validations={{
                minLength: 3,
                required: true,
              }}
            />

            <S.WrapperSmallText>
              <label>
                <input style={{ marginRight: 5 }} type="checkbox" />
                Permanecer conectado
              </label>

              <a href="/forgot-password">
                <S.ForgotPassText>
                  <Icon iconName="KeyReset20Regular" />
                  Recuperar senha
                </S.ForgotPassText>
              </a>
            </S.WrapperSmallText>

            <S.WrapperButton>
              <Button loading={loading} type="submit" full text={'Entrar'} />
              <Button
                type="button"
                full
                text={'Criar nova conta'}
                variant="ghost"
                onClick={() => navigate('/signup')}
              />
            </S.WrapperButton>
          </S.StyledForm>
        </form>
        <S.BrowserWrap>
          <span>Navegadores suportados</span>
          <img src={Browsers} />
        </S.BrowserWrap>
      </S.WrapperForm>
    </S.Container>
  );
};

export default LoginPage;
