import styled, { css } from 'styled-components';

export const ButtonLink = styled.button<{ active?: boolean }>`
  display: flex;
  cursor: pointer;
  background: transparent;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray[200]};
  font-weight: 700;
  text-decoration: none;
  font-size: 0.875rem;
  width: 100%;
  white-space: nowrap;
  height: 46px;
  gap: 0.5rem;

  > svg {
    min-width: 20px;
  }

  ${({ active }) =>
    active &&
    css`
      display: none;
    `}
`;

export const BodyWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray[20]};
`;

export const MainWrapper = styled.div<{ offset?: number }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ offset }) => (offset ? `calc(100% - ${offset}px)` : '100%')};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray.white};
`;

export const LoadingContent = styled.div`
  z-index: 12;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;
