import { RemoteDeleteCustomFields } from 'data/usecases/custom_field/remote-delete-custom-fields';
import { DeleteCustomField } from 'domain/usecases/custom_field/delete-custom-field';
import { AxiosProvider } from 'infra/http';
import { useToken } from 'main/hooks/usetoken';

export const makeDeleteCustomField = (
  customFieldId: string
): DeleteCustomField => {
  const { tenant = '', token = '' } = useToken();
  const axios = new AxiosProvider({
    tenant,
    token,
  });

  return new RemoteDeleteCustomFields(
    `${process.env.REACT_APP_API_BASE_URL}/custom-fields/${customFieldId}`,
    axios
  );
};
