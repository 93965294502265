import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { UnexpectedError } from 'domain/errors';
import {
  BulkActionsCompaniesUseCase,
  BulkActionsCompaniesRequest,
  BulkActionsCompaniesResponse,
} from 'domain/usecases/company/bulk-actions-companies-use-case';

import { useToken } from 'main/hooks/usetoken';

export class RemoteBulkActionsCompanies implements BulkActionsCompaniesUseCase {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient,
    private readonly toastService: ToastService
  ) {}

  async execute(
    contacts: BulkActionsCompaniesRequest
  ): Promise<BulkActionsCompaniesResponse> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      url: this.url,
      method: 'post',
      params: contacts,
      headers: {
        Authorization: `Bearer ${token}`,
        Tenant: tenant,
        'Content-Type': 'application/json',
      },
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        this.toastService.show(`${response.body}`, {
          type: 'success',
        });
        return {
          bulkActions: response.body,
        };

      default:
        this.toastService.show('Erro ao atualizar os dados!', {
          type: 'error',
        });
        throw new UnexpectedError('Erro ao atualizar os dados!');
    }
  }
}
