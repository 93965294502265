import { RemoteListCompanies } from 'data/usecases/company/remote-list-companies';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeListCompaniesUseCase = (): RemoteListCompanies => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteListCompanies(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/companies`,
    toastify
  );
};
