import React, { ReactElement } from 'react';
import * as S from './styles';
import * as T from './types';

const Spinner = (props: T.SpinnerProps): ReactElement => {
  const { id, size = 'medium', colorScheme = 'blue' } = props;

  const styles = {
    size: size,
    color: colorScheme,
  };

  return (
    <S.Container>
      <S.Spinner data-testid={id} {...styles} />
    </S.Container>
  );
};

export default Spinner;
