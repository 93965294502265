export type IconProps = {
  src?: string;
  iconName?: string;
  width?: number;
  color?: IconColor;
};

export type IconColor =
  | 'dark'
  | 'blue'
  | 'green'
  | 'gray'
  | 'red'
  | 'white'
  | 'orange'
  | 'violet'
  | 'teal'
  | 'systemGreen'
  | 'systemRed';

export enum IconNames {
  BookContacts20Regular = 'BookContacts20Regular',
  Briefcase20Regular = 'Briefcase20Regular',
  BuildingMultiple20Regular = 'BuildingMultiple20Regular',
  ContentViewGallery20Regular = 'ContentViewGallery20Regular',
  QuestionCircle20Regular = 'QuestionCircle20Regular',
  Settings20Regular = 'Settings20Regular',
  TasksApp20Regular = 'TasksApp20Regular',
  Home20Regular = 'Home20Regular',
  Apps20Regular = 'Apps20Regular',
  PeopleSettings = 'PeopleSettings',
  ShoppingBag20Regular = 'ShoppingBag20Regular',
  People = 'People',
  Person20Regular = 'Person20Regular',
  PeopleTeam = 'PeopleTeam',
  Box20Regular = 'Box20Regular',
  Box24Regular = 'Box24Regular',
  Channel20Regular = 'Channel20Regular',
  ColumnTriple = 'ColumnTriple',
  Tag = 'Tag',
  Thumb = 'Thumn',
  Attach16Regular = 'Attach16Regular',
  Attach20Regular = 'Attach20Regular',
  Delete16Regular = 'Delete16Regular',
  ArrowDownload16Regular = 'ArrowDownload16Regular',
  ChevronDown20Regular = 'ChevronDown20Regular',
  SignOut20Regular = 'SignOut20Regular',
  Checkmark = 'Checkmark',
  ChevronLeft20Regular = 'ChevronLeft20Regular',
  ChevronRight20Regular = 'ChevronRight20Regular',
  MailCheckmark20Regular = 'MailCheckmark20Regular',
  MailClock20Regular = 'MailClock20Regular',
  MoreVertical20Regular = 'MoreVertical20Regular',
  Delete20Regular = 'Delete20Regular',
  Pen20Regular = 'Pen20Regular',
  PersonSwap20Regular = 'PersonSwap20Regular',
  CalendarLtr20Regular = 'CalendarLtr20Regular',
  Payment20Regular = 'Payment20Regular',
  Open20Regular = 'Open20Regular',
  Info20Regular = 'Info20Regular',
  AddCircle20Regular = 'AddCircle20Regular',
  Link20Regular = 'Link20Regular',
  Edit20Regular = 'Edit20Regular',
  Fire20Regular = 'Fire20Regular',
  MathSymbols20Regular = 'MathSymbols20Regular',
  ThumbLike20Regular = 'ThumbLike20Regular',
  ThumbDislike20Regular = 'ThumbDislike20Regular',
  Filter20Filled = 'Filter20Filled',
  LinkSquare20Regular = 'LinkSquare20Regular',
  LinkSquare24Regular = 'LinkSquare24Regular',
  KeyReset20Regular = 'KeyReset20Regular',
  ModivLogo16Filled = 'ModivLogo16Filled',
  Clock20Regular = 'Clock20Regular',
  History24Regular = 'History24Regular',
  Pin20Regular = 'Pin20Regular',
  PanelLeftContract20Regular = 'PanelLeftContract20Regular',
  Tag24Regular = 'Tag24Regular',
  Mail20Regular = 'Mail20Regular',
  Call20Regular = 'Call20Regular',
  ArrowDownload20Regular = 'ArrowDownload20Regular',
  ArrowExportUp20Regular = 'ArrowExportUp20Regular',
  ArrowSync20Regular = 'ArrowSync20Regular',
  GridKanban20Regular = 'GridKanban20Regular',
  Table20Regular = 'Table20Regular',
  Save20Regular = 'Save20Regular',
  PersonSync24Regular = 'PersonSync24Regular',
  PersonSync20Regular = 'PersonSync20Regular',
  ColumnTriple20Regular = 'ColumnTriple20Regular',
  Backspace20Regular = 'Backspace20Regular',
  CallDismiss20Regular = 'CallDismiss20Regular',
  Dialpad20Regular = 'Dialpad20Regular',
  ArrowNext20Regular = 'ArrowNext20Regular',
  CheckmarkCircle20Regular = 'CheckmarkCircle20Regular',
  RadioButton20Filled = 'RadioButton20Filled',
  RadioButton20Regular = 'RadioButton20Regular',
  TextBulletListAdd20Regular = 'TextBulletListAdd20Regular',
  Connected20Regular = 'Connected20Regular',
  ArrowSortDown16Regular = 'ArrowSortDown16Regular',
  ArrowSortUp16Regular = 'ArrowSortUp16Regular',
  FolderArrowUp20Regular = 'FolderArrowUp20Regular',
  Archive20Regular = 'Archive20Regular',
  ArchiveArrowBack20Regular = 'ArchiveArrowBack20Regular',
  ArrowDown16Regular = 'ArrowDown16Regular',
  TextBold20Filled = 'TextBold20Filled',
  TextItalic20Filled = 'TextItalic20Filled',
  TextUnderline20Filled = 'TextUnderline20Filled',
  Link20Filled = 'Link20Filled',
  TextNumberListLtr20Filled = 'TextNumberListLtr20Filled',
  TextBulletList20Filled = 'TextBulletList20Filled',
  ClearFormatting20Filled = 'ClearFormatting20Filled',
}
