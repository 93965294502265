import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  GetReportLostReasonsDealsUseCase,
  LostReasonsRequestDTO,
  LostReasonsResponseDTO,
} from 'domain/usecases/deal/report-lost-deals.use-case';

export class RemoteGetReportLostReasonsDealsUseCase
  implements GetReportLostReasonsDealsUseCase
{
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string
  ) {}

  async execute(
    params: LostReasonsRequestDTO
  ): Promise<LostReasonsResponseDTO[]> {
    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params,
    });

    const data = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return data.map((report) => ({
          name: report.name,
          dealsLost: report.dealsLost,
        }));
      case HttpStatusCode.unauthorized:
        throw new NotAuthorizedError('Unauthorized');
      default:
        throw new UnexpectedError('Unexpected error');
    }
  }
}
