import { RemoteGetFacebookPages } from 'data/usecases/facebook/remote-get-facebook-pages';
import { GetFacebookPages } from 'domain/usecases/facebook/save-token-use-case';
import { makeAxiosHttpClient } from 'main/factories/http';
import { makeToastify } from 'main/factories/notification';

export const makeRemoteGetFacebookPages = (): GetFacebookPages => {
  const axios = makeAxiosHttpClient();
  const toastify = makeToastify();

  return new RemoteGetFacebookPages(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/facebook/pages`,
    toastify
  );
};
