const light = {
  name: 'light',
  colors: {
    gray: {
      white: '#ffffff',
      black: '#000000',
      20: '#f5f6f7',
      30: '#ebecef',
      50: '#bfc5cd',
      200: '#667388',
      600: '#2e405c',
    },
    blue: {
      primary: '#0052cc',
      50: '#e6eefa',
      100: '#6b9be1',
      500: '#00327c',
    },
    violet: {
      primary: '#7200cc',
      50: '#f1e6fa',
      100: '#ad6be1',
      500: '#46007c',
    },
    green: {
      primary: '#00cc52',
      50: '#e6faee',
      100: '#6be19b',
      500: '#007c32',
    },
    red: {
      primary: '#cc0049',
      50: '#fae6ed',
      100: '#e16b95',
      500: '#7c002d',
    },
    orange: {
      primary: '#e57300',
      50: '#fcf1e6',
      100: '#f0ae6b',
      500: '#8c4600',
    },
    teal: {
      primary: '#00b2a8',
      50: '#e6f7f6',
      100: '#6bd2cd',
      500: '#006d66',
    },
    yellow: {
      primary: '#E4C000',
      50: '#FCF9E6',
    },
    system: {
      success: '#1A936F',
      success50: '#E8F4F1',
      error: '#ED254E',
      error50: '#FCECEC',
      warning: '#E4C000',
      background: '#F1F5FA',
    },
  },
  animation: {
    default: '0.3s ease-in-out',
  },
  shadow: {
    default: '0px 4px 32px rgba(0, 0, 0, 0.1)',
  },
  spacing: {
    sm: '.25rem',
    md: '.5rem',
    lg: '1rem',
    xl: '2rem',
    xxl: '4rem',
    xxxl: '8rem',
  },
  breakpoints: {
    sm: '564px',
    md: '769px',
    lg: '1024px',
    xlg: '1140px',
    xl: '1280px',
    xxl: '1440px',
  },
};

export { light };
