import styled from 'styled-components';

export const WrapContent = styled.div`
  > form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const WrapButtons = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const WrapDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const CallLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-top: 0.5rem;

  :hover {
    background-color: ${({ theme }) => theme.colors.gray[20]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const WrapUploadFiles = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.2rem;
`;

export const UploadFiles = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  width: max-content;
  max-width: 100%;
  border-radius: 8px;
  padding: 0 0.2rem;

  :hover {
    background-color: ${({ theme }) => theme.colors.gray[20]};
  }

  :active {
    background-color: ${({ theme }) => theme.colors.gray[30]};
  }

  > input {
    display: none;
  }

  > span {
    width: calc(100% - 1rem);
    color: ${({ theme }) => theme.colors.gray[200]};
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const RemoveFileButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.gray[20]};
  border: none;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.system.danger};
  }
`;

export const ActionButton = styled.span`
  display: flex;
  width: max-content;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[50]};
  cursor: pointer;

  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.gray[200]};
  }
`;

export const EmptyStateMail = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray[30]};
  background-color: ${({ theme }) => theme.colors.gray[20]};
  border-radius: 8px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
`;
