import { ICustomField } from 'domain/entity/ICustomField';
import { createContext } from 'react';

export type SingleDealContextProps = {
  editDealOpen: boolean;
  setEditDeal: (edit: boolean) => void;
  contactDrawerOpen: boolean;
  setContactDrawerOpen: (val: boolean) => void;
  addContactDialogOpen: boolean;
  setAddContactDialogOpen: (val: boolean) => void;
  serviceDrawerOpen: boolean;
  setServiceDrawerOpen: (val: boolean) => void;
  companyDrawerOpen: boolean;
  setCompanyDrawerOpen: (val: boolean) => void;
  addCompanyDialogOpen: boolean;
  setAddCompanyDialogOpen: (val: boolean) => void;
  transferDataDialog: boolean;
  setTransferDataDialog: (val: boolean) => void;
  addTagsDialog: boolean;
  setAddTagsDialog: (val: boolean) => void;
  fetchDeal: () => void;
  all_custom_fields: ICustomField[];
};
export const SingleDealContext = createContext({} as SingleDealContextProps);
