import styled from 'styled-components';
import * as Menu from '@radix-ui/react-select';

export const Select = styled(Menu.Root)``;

export const SelectTrigger = styled(Menu.SelectTrigger)`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 55px;
  padding: 0 1rem;
  background: transparent;
  font-weight: 700;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.blue.primary};
  cursor: pointer;
`;
export const SelectIcon = styled(Menu.Icon)`
  > div > * {
    & svg {
      path {
        stroke: ${({ theme }) => theme.colors.blue.primary};
      }
    }
  }
`;
export const SelectedValue = styled(Menu.Value)``;

export const View = styled(Menu.Viewport)``;

export const ContentWrap = styled.div`
  position: relative;
`;

export const SelectContent = styled(Menu.Content)`
  z-index: 9;
  overflow: hidden;
  max-height: 300px;
  background-color: ${({ theme }) => theme.colors.gray.white};
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadow.default};
`;

export const SelectViewport = styled(Menu.Viewport)``;

export const SelectItem = styled(Menu.Item)`
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.blue.primary};
  :hover {
    background: ${({ theme }) => theme.colors.blue.primary};
    color: ${({ theme }) => theme.colors.gray.white};
  }
`;

export const SelectItemText = styled(Menu.ItemText)``;

export const StyledLabel = styled(Menu.Label)`
  padding: 0 25px;
  font-size: 12;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.blue.primary};
`;
