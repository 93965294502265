import { RemoteGetChannelsDealsReport } from 'data/usecases/deal/remote-get-channels-deals-report-use-case';
import { GetReportChannelsDealsUseCase } from 'domain/usecases/deal/report-channels-deals.use-case';
import { AxiosProvider } from 'infra/http';
import { useToken } from 'main/hooks/usetoken';

export const makeGetChannelsDeals = (): GetReportChannelsDealsUseCase => {
  const { tenant = '', token = '' } = useToken();

  const axios = new AxiosProvider({ tenant, token });

  return new RemoteGetChannelsDealsReport(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/reports/deals/channels`
  );
};
