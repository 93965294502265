import { makeDeleteImportBatchFactory } from 'main/factories/usecases/import_batch/delete-import-batch-factory';
import { Box, Dialog, Icon, Typography } from 'mino-ui';
import React, { ReactElement, useState } from 'react';
import { ImportBatchDialogProps, IconNames, IconColors } from './types';

export const RevertImportBatchDialog = (
  props: ImportBatchDialogProps
): ReactElement => {
  const { open, setOpen, batchPayload, callback } = props;
  const [loading, setLoading] = useState(false);

  const revertImportBatch = async (id: string) => {
    try {
      setLoading(true);
      await makeDeleteImportBatchFactory(id).execute();
      callback();
      setOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(state) => {
        !state;
        setOpen(state);
      }}
      title="Reverter importação"
      submitText="Reverter"
      submitVariant="danger"
      submitIconName={IconNames.ArchiveArrowBack20Regular}
      onSubmit={() => {
        batchPayload && revertImportBatch(batchPayload.id);
      }}
      loading={loading}
      content={
        <Box flexDirection="column" gap={16}>
          <Box flexDirection="column" gap={8}>
            <Typography variant="body" fontSize={14} color="gray" weight="bold">
              Deseja mesmo excluir esta importação?
            </Typography>
            <Typography variant="body" fontSize={12} color="gray" noWrap>
              Todos os negocios, contatos e empresas que vieram desta importação
              serão excluidos e não poderão ser recuperados novamente
            </Typography>
          </Box>
          <Box gap={16}>
            <Icon iconName="Archive20Regular" color={IconColors.blue} />
            <Typography variant="body" fontSize={12} color="dark">
              {batchPayload?.import_batch_title}
            </Typography>
          </Box>
        </Box>
      }
    />
  );
};
