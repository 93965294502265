import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  width: 100%;
`;

export const Body = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray[30]};
  border-radius: 0.5rem;
`;

export const ListWrap = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const Item = styled.li<{ type: string }>`
  list-style: none;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.xlg}) {
    display: grid;
    grid-template-columns: 100%;
    gap: 0;
  }

  & svg {
    path {
      fill: ${({ type }) => {
        switch (type) {
          case 'Ligação':
            return ({ theme }) => theme.colors.blue.primary;
          case 'E-mail':
            return ({ theme }) => theme.colors.violet.primary;
          case 'Reunião':
            return ({ theme }) => theme.colors.green.primary;
          case 'Proposta':
            return ({ theme }) => theme.colors.teal.primary;
          case 'Anotação':
            return ({ theme }) => theme.colors.orange.primary;
          case 'Mensagem':
            return ({ theme }) => theme.colors.red.primary;
          case 'Sell':
            return ({ theme }) => theme.colors.system.success;
          case 'Loss':
            return ({ theme }) => theme.colors.system.error;
          default:
            return ({ theme }) => theme.colors.gray[200];
        }
      }};
    }
  }
`;
export const ButtonWrap = styled.div`
  cursor: pointer;
`;

export const EmpityState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
`;
