import { RegisterRequestDTO } from 'domain/usecases/session/register-use-case';
import { TitleProps } from 'presentation/pages/SignUp';
import { ChangeEvent, createContext } from 'react';

export type BreadCrumbProps = {
  title: string;
  action: () => void;
  active: boolean;
};

interface SignUpContextProps {
  setStep: (step: number) => void;
  setTitle: (title: TitleProps) => void;
  setBreadCrumb: (breadCrumb: BreadCrumbProps[]) => void;
  breadCrumb?: BreadCrumbProps[];
  step: number;
  form: RegisterRequestDTO;
  setForm: (form: RegisterRequestDTO) => void;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSelectInputChange: (name: string, value?: string) => void;
}

export const SignUpContext = createContext({} as SignUpContextProps);
