import styled, { css } from 'styled-components';

interface ButtonStyledProps {
  boxMain?: boolean;
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between';
  flexDirection?: 'row' | 'column';
  flexDirectionMobile?: 'row' | 'column';
  width?: string;
  gap?: number;
  flexWrap?: boolean;
}

export const Container = styled.div<ButtonStyledProps>`
  display: flex;
  margin: 0;
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  width: ${({ width }) => width};
  gap: ${({ gap }) => gap}px;

  ${({ flexWrap }) =>
    flexWrap == true &&
    css`
      flex-wrap: wrap;
    `}

  ${({ boxMain }) =>
    boxMain &&
    css`
      flex-direction: column;
      height: 100%;
      width: 100%;
      padding: 1rem;
      overflow-y: auto;

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-bottom: 90px;
      }

      @media (min-width: 1860px) {
        padding: 1rem 3%;
      }
    `}

  @media (max-width: 768px) {
    flex-direction: ${({ flexDirectionMobile }) => flexDirectionMobile};
  }
`;
