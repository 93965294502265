import { RemoteListCustomField } from 'data/usecases';
import { ListCustomFields } from 'domain/usecases/custom_field/list-custom-fields';
import { AxiosProvider } from 'infra/http';
import { useToken } from 'main/hooks/usetoken';

export const makeListCustomFields = (
  parentType: 'deal' | 'contact' | 'company'
): ListCustomFields => {
  const { tenant = '', token = '' } = useToken();
  const axios = new AxiosProvider({
    tenant,
    token,
  });

  return new RemoteListCustomField(
    `${process.env.REACT_APP_API_BASE_URL}/custom-fields?parent_type=${parentType}`,
    axios
  );
};
