import { ActivityFilterParams } from 'domain/usecases/activity/list-activities-use-case';
import { ScheduleActivityForm } from 'presentation/shared/templates/Activitites/dialogs/ScheduleActivityDialog';
import { createContext } from 'react';

interface ActivityListProps {
  filterOpen: boolean;
  setFilterOpen: (value: boolean) => void;
  filter: ActivityFilterParams;
  setFilter: (filter: ActivityFilterParams) => void;
  fetchData: (params: ActivityFilterParams) => void;
  isDrawerOpen: boolean;
  setIsDrawerOpen: (boolean) => void;
  activityToUpdate?: ScheduleActivityForm;
  setActivityToUpdate: (activity: ScheduleActivityForm) => void;
  initialStartDate?: Date;
  setInitialStartDate?: (date: Date) => void;
  initialEndDate?: Date;
  setInitialEndDate?: (date: Date) => void;
}

const ActivityListContext = createContext<ActivityListProps>(
  {} as ActivityListProps
);

export default ActivityListContext;
