import { RemoteDeleteUser } from 'data/usecases';
import { DeleteUserUseCase } from 'domain/usecases/user/delete-user-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeDeleteUserUseCase = (id: string): DeleteUserUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteDeleteUser(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/users/${id}`,
    toastify
  );
};
