import { RemoteResetPasswordUseCase } from 'data/usecases/sessions/remote-reset-password';
import { ResetPasswordUseCase } from 'domain/usecases/session/reset-password';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeResetPassword = (): ResetPasswordUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteResetPasswordUseCase(
    `${process.env.REACT_APP_API_BASE_URL}/change-password`,
    axios,
    toastify
  );
};
