import { useEffect, useState } from 'react';
import { IUser } from 'domain/entity/IUser';
import { makeLoadUsersUseCase } from 'main/factories/usecases';

type UseUsersType = {
  users: IUser[] | [];
  fetchUsers: (search?: string) => Promise<void>;
  loading: boolean;
};

export const useUsers = (): UseUsersType => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);

  async function fetchUsers(search?: string): Promise<void> {
    try {
      setLoading(true);
      const { data } = await makeLoadUsersUseCase().execute({
        name: search,
      });
      setUsers(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  return { users, fetchUsers, loading };
};
