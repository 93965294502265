import { useEffect, useState } from 'react';
import { ICustomField } from 'domain/entity/ICustomField';
import { makeListCustomFields } from 'main/factories/usecases/custom_field/remote-list-custom-fields-factory';

type UseCustomFieldsType = {
  custom_fields: ICustomField[] | [];
  fetchCustomFields: () => Promise<void>;
  loading: boolean;
};

export const useCustomFields = (
  parent: ICustomField['parent_type']
): UseCustomFieldsType => {
  const [custom_fields, setCustomFields] = useState<ICustomField[]>([]);
  const [loading, setLoading] = useState(false);

  async function fetchCustomFields(): Promise<void> {
    try {
      setLoading(true);
      const data = await makeListCustomFields(parent).execute();
      setCustomFields(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCustomFields();
  }, []);

  return { custom_fields, fetchCustomFields, loading };
};
