import React, { ReactElement } from 'react';
import DatePicker from 'react-datepicker';
import * as T from './types';
import * as S from './styles';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { defaultInputValidationMessages } from 'config/defaultInputValidationMessages';
import Label from '../Label';

registerLocale('pt-BR', ptBR);

const Datepicker = (props: T.DatepickerProps): ReactElement => {
  const {
    startDate,
    control,
    name,
    time = false,
    required,
    placeholder,
    fullWidth,
    label,
    isClearable,
  } = props;

  return (
    <S.Container fullWidth={fullWidth}>
      {label && <Label text={label} />}

      {control ? (
        <Controller
          rules={{
            required: {
              value: required || false,
              message: defaultInputValidationMessages.required,
            },
          }}
          control={control}
          name={name || 'Datepicker'}
          render={({
            field: { onChange, onBlur, value, ref },
            formState: { errors },
          }) => (
            <>
              <DatePicker
                showTimeSelect={time}
                timeFormat="HH:mm"
                dateFormat={time ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
                todayButton={'Hoje'}
                innerRef={ref}
                startDate={startDate}
                selected={value && new Date(value)}
                timeCaption="Hora"
                onChange={onChange}
                onBlur={onBlur}
                locale="pt-BR"
                inputClassName="react-datepicker_input"
                placeholderText={placeholder}
                className={errors && name && errors[name] && 'error'}
                isClearable={isClearable}
              />

              {errors && (
                <ErrorMessage
                  errors={errors}
                  name={name || 'Datepicker'}
                  render={({ message }) => <p>{message}</p>}
                />
              )}
            </>
          )}
        />
      ) : (
        <DatePicker
          isClearable={isClearable}
          showTimeSelect={time}
          timeFormat="HH:mm"
          dateFormat={time ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
          todayButton={'Hoje'}
          startDate={startDate}
          locale="pt-BR"
          inputClassName="react-datepicker_input"
          placeholderText={placeholder}
        />
      )}
    </S.Container>
  );
};

export default Datepicker;
