import React, { ReactElement, useState, useEffect } from 'react';
import { Drawer, Input } from 'mino-ui';
import * as S from './styles';
import { CreateDealRequest } from 'domain/usecases/deal/create-deal-use-case';
import { useForm } from 'react-hook-form';
import { makeCreateDealUseCase } from 'main/factories/usecases';
import Case from '../icons/case.svg';

interface EditDrawerProps {
  isDrawerOpen: boolean;
  setDrawerOpen: (isOpen: boolean) => void;
  companyId: string;
  callback: () => void;
}

const AddDealDrawer = (props: EditDrawerProps): ReactElement => {
  const { isDrawerOpen, setDrawerOpen, callback, companyId } = props;
  const [load, setLoad] = useState(false);

  async function createDeal(data: CreateDealRequest) {
    try {
      setLoad(true);
      await makeCreateDealUseCase().execute(data);
      reset();
      callback();
      setDrawerOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    setValue('attach_companies', [`${companyId}`]);
  }, [companyId, isDrawerOpen]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<CreateDealRequest>();

  return (
    <Drawer
      width={300}
      isOpen={isDrawerOpen}
      onClose={() => setDrawerOpen(false)}
      title="Adicionar Negócio"
      icon={Case}
      submitText={'Salvar'}
      onSubmit={handleSubmit(createDeal)}
      secondarySubmitText="Cancelar"
      secondarySubmitAction={() => {
        setDrawerOpen(false);
        reset();
      }}
      loadingSubmit={load}
      loadingSubmitText="Salvando..."
    >
      <form onSubmit={handleSubmit(createDeal)}>
        <S.EditDrawerForm>
          <Input
            autoFocus
            label="Descrição do negócio"
            placeholder="Ex: Venda de licença"
            type="text"
            name="name"
            register={register}
            errors={errors}
            validations={{
              required: true,
            }}
          />
          <Input
            label="Valor"
            placeholder="Valor do Negócio"
            type="number"
            required
            name="value"
            register={register}
            errors={errors}
          />
          <Input
            type="hidden"
            required
            name="attach_contacts[]"
            register={register}
            errors={errors}
          />
        </S.EditDrawerForm>
        <input type="submit" hidden />
      </form>
    </Drawer>
  );
};
export default AddDealDrawer;
