import { RemoteGetCompany } from 'data/usecases/company/remote-get-company';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeGetSingleCompanyUseCase = (
  companyId: string
): RemoteGetCompany => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteGetCompany(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/companies/${encodeURI(companyId)}`,
    toastify
  );
};
