import styled from 'styled-components';

export const Container = styled.div`
  min-width: 450px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 1.5rem;
  @media (max-width: 564px) {
    min-width: auto;
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  gap: 64px;
`;

export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const IconWrap = styled.div`
  display: block;

  @media (max-width: 564px) {
    > div > div > svg {
      width: 132px;
      height: 132px;
    }
  }
`;
