import { Button, Input, Spacer } from 'mino-ui';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import * as S from './styles';
import { ReactComponent as Caution } from 'presentation/assets/icons/caution.svg';
import { SignUpContext } from 'presentation/contexts';

const StepOne = (): ReactElement => {
  const [validate, SetValidate] = useState(false);

  const { setStep, setTitle, setBreadCrumb, step, handleInputChange, form } =
    useContext(SignUpContext);

  function handleStepChange(): void {
    setStep(2);
  }

  useEffect(() => {
    setBreadCrumb([
      {
        title: 'Meus dados',
        action: () => undefined,
        active: step === 1 ? true : false,
      },
      {
        title: 'Dados da empresa',
        action: () => undefined,
        active: step === 2 ? true : false,
      },
      {
        title: 'Senha',
        action: () => undefined,
        active: step === 3 ? true : false,
      },
    ]);
  }, []);

  useEffect(() => {
    setTitle({
      title: 'Crie uma conta',
      subtitle: 'já possui uma conta?',
    });
  }, []);

  useEffect(() => {
    if (form.company !== '' && form.email !== '' && form.username !== '') {
      SetValidate(true);
    } else {
      SetValidate(false);
    }
  }, [form]);

  return (
    <>
      <Input
        full
        label="Nome da empresa"
        name="company"
        onChange={handleInputChange}
        placeholder="Nome da empresa"
      />
      <Spacer height={24} />
      <Input
        full
        label="Nome do Administrador"
        name="username"
        onChange={handleInputChange}
        placeholder="Ex: João Carlos"
      />
      <S.SmallText>
        <Caution />
        Este usuário não poderá ser excluído.
      </S.SmallText>
      <Spacer height={24} />

      <Input
        full
        onChange={handleInputChange}
        label="E-mail"
        name="email"
        type="email"
        placeholder="E-mail"
      />
      <Spacer height={24} />

      <S.WrapperSmallText>
        <p>
          Ao se inscrever, você concorda com os
          <a href="#"> Termos de Serviço</a> e a{' '}
          <a href="#">Política de Privacidade.</a>
        </p>
      </S.WrapperSmallText>

      <Button
        disabled={!validate}
        full
        text="Avançar"
        onClick={() => handleStepChange()}
      />
    </>
  );
};

export default StepOne;
