import styled, { css, keyframes } from 'styled-components';
import * as Dialog from '@radix-ui/react-dialog';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

export const Container = styled(Dialog.Root)``;

export const Trigger = styled(Dialog.Trigger)`
  background: none;
  width: 100%;
`;

export const Overlay = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  @media (prefers-reduced-motion: no-preference) {
    animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
`;

export const DialogWrap = styled(Dialog.Portal)`
  pointer-events: auto;
`;

export const Content = styled(Dialog.Content)<{ maxWidth?: number }>`
  max-height: calc(100vh - 100px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray.white};
  position: fixed;
  z-index: 12;
  width: 80vw;
  max-width: 450px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 16px 40px 20px rgba(112, 144, 176, 0.1);
  border-radius: 8px;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
`;

export const Header = styled.div<{ hasBorder?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray.white};
  z-index: 1;
  padding: 16px 24px;
  border-radius: 8px 8px 0 0;

  ${({ hasBorder }) =>
    hasBorder &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray[30]};
    `}
`;
export const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
  }
`;
export const Body = styled.div<{ overflowing?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow-y: auto;

  height: calc(100vh - 176px);
  ${({ overflowing }) => !overflowing && `height: auto;`}
`;

export const Footer = styled.div<{ hasBorder?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.colors.gray.white};
  border-radius: 0 0 8px 8px;
  z-index: 1;
  gap: 8px;

  ${({ hasBorder }) =>
    hasBorder &&
    css`
      border-top: 1px solid ${({ theme }) => theme.colors.gray[30]};
    `}
`;

export const Title = styled(Dialog.Title)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const CloseWrap = styled(Dialog.Close)`
  cursor: pointer;
  border-radius: 50%;
  background-color: transparent;

  :hover {
    background-color: ${({ theme }) => theme.colors.gray[30]};
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
  }
`;

export const Description = styled(Dialog.Description)`
  padding: 1rem 0;
`;
