import { RemoteSaveFacebookToken } from 'data/usecases/facebook/remote-save-facebook-token';
import { SaveFacebookTokenUseCase } from 'domain/usecases/facebook/save-token-use-case';
import { makeAxiosHttpClient } from 'main/factories/http';
import { makeToastify } from 'main/factories/notification';

export const makeRemoteSaveFacebookToken = (): SaveFacebookTokenUseCase => {
  const axios = makeAxiosHttpClient();
  const toastify = makeToastify();

  return new RemoteSaveFacebookToken(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/facebook/save-token`,
    toastify
  );
};
