import styled, { css } from 'styled-components';

interface TitleProps {
  type: string;
  hasDescription?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
  padding-bottom: 1rem;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

export const Title = styled.div<TitleProps>`
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0 0.5rem;
  gap: 0.5rem;
  border-radius: 0.25rem;

  ${({ hasDescription }) =>
    hasDescription === true &&
    css`
      cursor: pointer;
    `};

  background: ${({ type }) => {
    switch (type) {
      case 'Ligação':
        return ({ theme }) => theme.colors.blue[50];
      case 'E-mail':
        return ({ theme }) => theme.colors.violet[50];
      case 'Reunião':
        return ({ theme }) => theme.colors.green[50];
      case 'Proposta':
        return ({ theme }) => theme.colors.teal[50];
      case 'Anotação':
        return ({ theme }) => theme.colors.orange[50];
      case 'Mensagem':
        return ({ theme }) => theme.colors.red[50];
      case 'Sell':
        return ({ theme }) => theme.colors.system.success;
      case 'Loss':
        return ({ theme }) => theme.colors.system.error;
      default:
        return ({ theme }) => theme.colors.gray[200];
    }
  }};

  & svg {
    path {
      fill: ${({ type }) => {
        switch (type) {
          case 'Ligação':
            return ({ theme }) => theme.colors.blue.primary;
          case 'E-mail':
            return ({ theme }) => theme.colors.violet.primary;
          case 'Reunião':
            return ({ theme }) => theme.colors.green.primary;
          case 'Proposta':
            return ({ theme }) => theme.colors.teal.primary;
          case 'Anotação':
            return ({ theme }) => theme.colors.orange.primary;
          case 'Mensagem':
            return ({ theme }) => theme.colors.red.primary;
          case 'Sell':
            return ({ theme }) => theme.colors.system.success;
          case 'Loss':
            return ({ theme }) => theme.colors.system.error;
          default:
            return ({ theme }) => theme.colors.gray[200];
        }
      }};
    }
  }

  ::before {
    content: '';
    position: absolute;
    border-radius: 0.5rem;
    left: 16px;
    width: 2px;
    height: 21px;
    background: ${({ type }) => {
      switch (type) {
        case 'Ligação':
          return ({ theme }) => theme.colors.blue.primary;
        case 'E-mail':
          return ({ theme }) => theme.colors.violet.primary;
        case 'Reunião':
          return ({ theme }) => theme.colors.green.primary;
        case 'Proposta':
          return ({ theme }) => theme.colors.teal.primary;
        case 'Anotação':
          return ({ theme }) => theme.colors.orange.primary;
        case 'Mensagem':
          return ({ theme }) => theme.colors.red.primary;
        case 'Sell':
          return ({ theme }) => theme.colors.system.success;
        case 'Loss':
          return ({ theme }) => theme.colors.system.error;
        default:
          return ({ theme }) => theme.colors.gray[200];
      }
    }};
  }
`;

export const TypeWrap = styled.div<TitleProps>`
  & p {
    color: ${({ type }) => {
      switch (type) {
        case 'Ligação':
          return ({ theme }) => theme.colors.blue.primary;
        case 'E-mail':
          return ({ theme }) => theme.colors.violet.primary;
        case 'Reunião':
          return ({ theme }) => theme.colors.green.primary;
        case 'Proposta':
          return ({ theme }) => theme.colors.teal.primary;
        case 'Anotação':
          return ({ theme }) => theme.colors.orange.primary;
        case 'Mensagem':
          return ({ theme }) => theme.colors.red.primary;
        case 'Sell':
          return ({ theme }) => theme.colors.system.success;
        case 'Loss':
          return ({ theme }) => theme.colors.system.error;
        default:
          return ({ theme }) => theme.colors.gray[200];
      }
    }};
  }
`;

export const DropIconWrap = styled.div<TitleProps>`
  display: flex;
  & svg {
    path {
      stroke: ${({ type }) => {
        switch (type) {
          case 'Ligação':
            return ({ theme }) => theme.colors.blue.primary;
          case 'E-mail':
            return ({ theme }) => theme.colors.violet.primary;
          case 'Reunião':
            return ({ theme }) => theme.colors.green.primary;
          case 'Proposta':
            return ({ theme }) => theme.colors.teal.primary;
          case 'Anotação':
            return ({ theme }) => theme.colors.orange.primary;
          case 'Mensagem':
            return ({ theme }) => theme.colors.red.primary;
          case 'Sell':
            return ({ theme }) => theme.colors.system.success;
          case 'Loss':
            return ({ theme }) => theme.colors.system.error;
          default:
            return ({ theme }) => theme.colors.gray[200];
        }
      }};
    }
  }
`;

export const TimeWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  transition: ${({ theme }) => theme.animation.default};
  overflow: hidden;
  padding: 0 0.25rem;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
    justify-content: space-between;

    & button {
      width: auto;
    }
  }
`;

export const IconWrap = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  width: 34px;
`;

export const FileCount = styled.div`
  color: ${({ theme }) => theme.colors.gray[200]};
  font-size: 0.75rem;
`;

export const LinkWrap = styled.div`
  display: flex;
  align-items: center;

  & a {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 34px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const DateHourWrap = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.25rem 0.5rem;
  gap: 0.25rem;
  background: ${({ theme }) => theme.colors.gray[20]};
  border-radius: 0.25rem;
`;

export const DialogWrap = styled.div`
  max-width: 30px;
  max-height: 17px;
`;
