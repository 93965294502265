import React, { ReactElement, useState } from 'react';
import { Box, Button, Dialog, Spinner, Typography } from 'mino-ui';
import { useEffect } from 'react';
import { makeRemoteSaveFacebookToken } from 'main/factories/usecases/facebook/save-facebook-token-factory';
import { IFacebookPage } from 'domain/usecases/facebook/save-token-use-case';
import { makeRemoteGetFacebookPages } from 'main/factories/usecases/facebook/get-facebook-pages-factory';
import { makeRemoteDisconnectFacebook } from 'main/factories/usecases/facebook/disconnect-facebook-token-factory';

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any;
  }
}

interface Page {
  id: string;
  name: string;
  access_token: string;
}

const IntegrateFacebook = (): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [pagesIds, setPagesIds] = useState<string[]>([]);

  const [pages, setPages] = useState<IFacebookPage[]>([]);

  async function handleDisconnect() {
    try {
      setLoading(true);
      await makeRemoteDisconnectFacebook().execute();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      fetchFacebookPages();
    }
  }

  async function fetchFacebookPages() {
    try {
      setLoading(true);
      const data = await makeRemoteGetFacebookPages().execute();

      setPages(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchFacebookPages();
  }, []);

  useEffect(() => {
    async function handleSaveToken(accessToken: string, pagesIds: string[]) {
      if (!accessToken || pagesIds.length === 0) return;

      try {
        setLoading(true);
        await makeRemoteSaveFacebookToken().execute({
          access_token: accessToken,
          pages_ids: pagesIds.join('-') ?? '',
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        fetchFacebookPages();
      }
    }

    handleSaveToken(accessToken, pagesIds);
  }, [pagesIds, accessToken]);

  function myFacebookLogin() {
    window.FB.login(
      function (response: any) {
        console.log('Successfully logged in', response);

        // Capture the access token here and set state
        if (response.authResponse) {
          const { accessToken } = response.authResponse;
          setAccessToken(accessToken); // assuming you've defined setAccessToken to update your component state
        }

        window.FB.api('/me/accounts', function (response: any) {
          console.log('Successfully retrieved pages', response);
          const pages: Page[] = response.data;
          setPagesIds(pages.map((page) => page.id));

          for (let i = 0, len = pages.length; i < len; i++) {
            const page = pages[i];

            subscribeApp(page.id, page.access_token);
          }
        });
      },
      {
        scope: [
          'leads_retrieval',
          'pages_show_list',
          'pages_manage_metadata',
          'pages_manage_ads',
          'pages_read_engagement',
          'pages_manage_engagement',
          'ads_management',
        ],
      }
    );
  }

  function subscribeApp(pageId: string, pageAccessToken: string) {
    console.log(`Subscribing page to app! ${pageId}`);

    window.FB.api(
      `/${pageId}/subscribed_apps`,
      'post',
      {
        access_token: pageAccessToken,
        subscribed_fields: ['leadgen'],
        callback_url: 'https://api-crm.minosuite.com.br/facebook/webhook',
      },
      function (response: any) {
        console.log('Successfully subscribed page', response);
      }
    );
  }

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '1363257777799285',
        xfbml: true,
        version: 'v16.0',
      });
    };

    (function (d, s, id) {
      // eslint-disable-next-line no-var
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);

  return (
    <>
      {loading ? (
        <Box>
          <Spinner />
        </Box>
      ) : (
        <>
          {pages.length > 0 ? (
            <Dialog
              submitText="Desconectar"
              onSubmit={() => handleDisconnect()}
              title="Desconectar do Facebook"
              trigger={
                <Button
                  type="button"
                  text="Desconectar"
                  variant="ghostSecondary"
                  icon="Delete20Regular"
                />
              }
              content={
                <Box flexDirection="column" gap={8}>
                  <Typography variant="body" fontSize={16} weight="bold" noWrap>
                    Atenção!
                  </Typography>
                  <Typography variant="body" noWrap>
                    Tem certeza que deseja desconectar sua conta do Facebook?
                  </Typography>
                </Box>
              }
            />
          ) : (
            <Button text="Login com Facebook" onClick={myFacebookLogin} />
          )}

          <Box flexDirection="column" gap={8}>
            {pages.map((page) => (
              <Box
                key={page.id}
                flexDirection="row"
                gap={8}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: 4,
                  padding: 8,
                }}
              >
                {page.name}
              </Box>
            ))}
          </Box>
        </>
      )}
    </>
  );
};

export default IntegrateFacebook;
