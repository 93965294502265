import * as ScrollArea from '@radix-ui/react-scroll-area';
import styled, { css } from 'styled-components';

interface ViewportStyledProps {
  maxWidth?: number | string;
  maxHeight?: number | string;
  fill?: boolean;
  fillHeight?: boolean;
}

export const Container = styled(ScrollArea.Root)`
  width: auto;
  height: auto;
`;

export const ViewPort = styled(ScrollArea.Viewport)<ViewportStyledProps>`
  position: relative;
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${typeof maxHeight === 'number'
        ? maxHeight + 'px'
        : maxHeight};
    `}

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${typeof maxWidth === 'number' ? maxWidth + 'px' : maxWidth};
    `}

  ${({ fill }) =>
    fill &&
    css`
      max-width: 100%;
      width: 100%;
    `}
  ${({ fillHeight }) =>
    fillHeight &&
    css`
      max-height: 100%;
      height: 100%;
    `}
`;

export const ScrollBar = styled(ScrollArea.Scrollbar)`
  display: flex;
  padding: 2px;
  background: ${({ theme }) => theme.colors.gray[30]};
  transition: all 160ms ease-out;

  :hover {
    background: ${({ theme }) => theme.colors.gray[600]};
  }

  &[data-orientation='vertical'] {
  }

  &[data-orientation='horizontal'] {
    flex-direction: column;
    height: 200px;
  }
`;

export const Thumb = styled(ScrollArea.Thumb)`
  flex: 1;
  background: ${({ theme }) => theme.colors.blue[50]};
  border-radius: 4px;
  position: 'relative';

  ::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
  }
`;

export const Corner = styled(ScrollArea.Corner)`
  background: ${({ theme }) => theme.colors.gray[600]};
`;
