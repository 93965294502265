import styled from 'styled-components';

export const Toolbar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
  height: auto;
  min-height: 72px;
  background-color: ${({ theme }) => theme.colors.gray.white};
  width: 100%;
  border: solid 1px ${({ theme }) => theme.colors.gray[30]};
  gap: 8px;
  overflow: hidden;
`;

export const Separator = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.gray[30]};
`;

export const FilterWrap = styled.div`
  position: relative;
`;

export const WrapInputSearch = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  > div {
    max-width: 340px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const FetchButtonWrap = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
export const DrawerForm = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
`;

export const WrapTrigger = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TriggerText = styled.span`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.blue.primary};
`;

export const FilterBadge = styled.div`
  background-color: ${({ theme }) => theme.colors.system.error};
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 8px;

  &[data-state='float'] {
    position: absolute;
    top: 2px;
    right: 62px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    &[data-state='float'] {
      right: 10px;
    }
  }
`;
