import React, { ReactElement, useContext, useEffect, useState } from 'react';
import * as S from './styles';
import {
  Avatar,
  Box,
  Button,
  Dropdown,
  PopOver,
  Spinner,
  Table,
  Typography,
} from 'mino-ui';
import { IconColors } from 'presentation/shared/templates/ActivityCard/actions';
import { SharedLinkPayload } from './type';
import DeleteDialog from './DeleteDialog';
import axios from 'axios';
import BuyPhoneNumberModal from './BuyPhoneNumberModal';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import { useToken } from 'main/hooks/usetoken';
import EditModal from './EditModal';
import { useUsers } from 'main/hooks/useUsers';
import { IUser } from 'domain/entity/IUser';

type Phone = {
  id: string;
  phoneNumber: string;
  locality: string;
  userId?: string;
  userName?: string;
  userAvatar?: string;
};

const Calls = (): ReactElement => {
  const {
    session: { tenant },
  } = useContext(SessionContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [contentPayload, setContentPayload] = useState<SharedLinkPayload>();
  const [createAppLoading, setCreateAppLoading] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState<Phone[]>([]);

  const [loadingPhoneNumbers, setLoadingPhoneNumbers] = useState(false);
  const apiToken = useToken();

  const { users, loading: loadingUsers } = useUsers();

  async function fetchMyPhoneNumbers(users: IUser[]) {
    try {
      setLoadingPhoneNumbers(true);
      const response = await axios.get(
        process.env.REACT_APP_API_BASE_URL + '/voice/phones/active',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiToken.token}`,
            Tenant: apiToken.tenant || '',
          },
        }
      );

      const phoneNumbersWithUser = response.data.map((phone: Phone) => {
        const user = users.find(
          (user) => user.phone_number === phone.phoneNumber
        );

        return {
          ...phone,
          userId: user?.id || '',
          userName: user?.username || '',
          userAvatar: user?.avatar || '',
        };
      });

      setPhoneNumbers(phoneNumbersWithUser);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPhoneNumbers(false);
    }
  }
  useEffect(() => {
    if (users.length) fetchMyPhoneNumbers(users);
  }, [users]);

  async function createTwiMLApp() {
    try {
      setCreateAppLoading(true);
      await axios.post(
        process.env.REACT_APP_API_BASE_URL + '/voice/create',
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiToken.token}`,
            Tenant: apiToken.tenant || '',
          },
        }
      );

      window.location.href = `${window.location.origin}/config?active=comercial`;
    } catch (error) {
      console.error(error);
    } finally {
      setCreateAppLoading(false);
    }
  }

  return (
    <Box flexDirection="column" gap={16}>
      {!tenant.applicationSid ? (
        <Box
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          style={{ height: '100%', width: '100%', padding: '20%' }}
        >
          <Box flexDirection="column" gap={32}>
            <Typography variant="h4" noWrap>
              Receba e faça ligações com o Modiv Voice
            </Typography>

            <Typography variant="body" noWrap>
              O Modiv Voice é uma funcionalidade do nosso CRM que oferece uma
              solução simples e integrada para chamadas telefônicas.
            </Typography>

            <Button
              icon="Save20Regular"
              iconColor={'white' as any}
              text="Ativar Recurso"
              onClick={createTwiMLApp}
              loading={createAppLoading}
            />
          </Box>
        </Box>
      ) : (
        <>
          <Typography variant="h4">Telefones ativos</Typography>

          <Box justifyContent="space-between">
            <Button
              icon="AddCircle20Regular"
              iconColor={IconColors.white}
              text="Comprar número"
              onClick={() => setModalOpen(true)}
            />
          </Box>

          <Box>
            {loadingPhoneNumbers || loadingUsers ? (
              <Box
                style={{
                  minHeight: '400px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spinner />
              </Box>
            ) : (
              <Table
                hasOutline
                compact
                columns={[
                  { field: 'phoneNumber', headerName: 'Telefone' },
                  { field: 'user', headerName: 'Usuário' },
                  { field: 'active', headerName: 'Ativo' },
                  { field: 'action', headerName: '', width: 1 },
                ]}
                rows={phoneNumbers.map((phoneNumber) => ({
                  id: `${phoneNumber.id}`,
                  phoneNumber: phoneNumber.phoneNumber,
                  user: (
                    <>
                      {phoneNumber.userId ? (
                        <Box alignItems="center" gap={8}>
                          <Avatar
                            image={phoneNumber.userAvatar}
                            name={phoneNumber.userName || ''}
                          />
                          <Box
                            style={{
                              maxWidth: '150px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Typography variant="body" noWrap ellipsis>
                              {phoneNumber.userName}
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <Typography variant="body" noWrap>
                          Não atribuído
                        </Typography>
                      )}
                    </>
                  ),
                  active: 'Sim',
                  action: (
                    <Dropdown
                      trigger="action-button"
                      items={[
                        {
                          label: 'Gerenciar',
                          icon: 'Edit20Regular',
                          value: 'edit',
                        },
                      ]}
                      onClick={(value) => {
                        if (value === 'edit') {
                          setEdit(true);
                        }
                      }}
                    />
                  ),
                }))}
              />
            )}
          </Box>
        </>
      )}

      <DeleteDialog
        isOpen={isDialogOpen}
        setOpen={setDialogOpen}
        deletePayload={contentPayload}
        callback={() => {
          setDialogOpen(false);
          fetchMyPhoneNumbers(users);
        }}
      />

      <EditModal isOpen={edit} setOpen={setEdit} />

      <BuyPhoneNumberModal
        isOpen={isModalOpen}
        setOpen={setModalOpen}
        callback={() => setModalOpen(false)}
      />
    </Box>
  );
};

export default Calls;
