import axios from 'axios';
import { Box, Card, Spinner, Typography } from 'mino-ui';
import React, { ReactElement, useEffect } from 'react';
import { useToken } from 'main/hooks/usetoken';
import { useNavigate } from 'react-router';
import { makeToastify } from 'main/factories/notification';

export const GoogleCallback = (): ReactElement => {
  const navigate = useNavigate();

  async function handleSaveToken() {
    try {
      const { token, tenant = '' } = useToken();

      const code = window.location.search.split('=')[1];

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/google/get-token`,
        {
          code: code,
        },
        {
          headers: {
            Tenant: tenant,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data.success) {
        makeToastify().show('Integração realizada com sucesso!', {
          type: 'success',
        });

        setTimeout(() => {
          window.location.href = '/activities';
        }, 500);
      }
    } catch (error) {
      console.error(error);
      navigate('/activities');
    }
  }

  useEffect(() => {
    handleSaveToken();
  }, []);

  return (
    <Card
      fillHeight
      direction="column"
      justifyContent="center"
      align="center"
      fill
    >
      <Box gap={24}>
        <Spinner />
        <Typography fill noWrap variant="h4">
          Um instante, estamos finalizando a integração...
        </Typography>
      </Box>
    </Card>
  );
};
