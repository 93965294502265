import styled, { css } from 'styled-components';

interface Trigger {
  active?: boolean;
}
interface TriggerWrapProp {
  open: boolean;
}

export const FLexArea = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: auto;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray[30]};
`;

export const TriggerWrap = styled.div<TriggerWrapProp>`
  width: 100%;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: fit-content;

  @media (max-width: 768px) {
    height: 55px;
    overflow: hidden;

    ${({ open }) =>
      open == true &&
      css`
        height: 100%;
        transition: ease all 0.3s;
      `};
  }
`;
export const IconWrap = styled.div`
  cursor: pointer;
  @media (min-width: 769px) {
    display: none;
  }
`;
export const ContentBox = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
`;

export const Title = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
`;

export const TriggerBox = styled.div``;

export const ConfigType = styled.div<Trigger>`
  padding: 0.62rem 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: default;
`;

export const Triggers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0rem 1.5rem;
`;

export const Trigger = styled.div<Trigger>`
  position: relative;
  display: flex;
  align-items: center;
  height: 2.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.gray[20]};
  }

  ${({ active }) =>
    active == true &&
    css`
      background-color: ${({ theme }) => theme.colors.gray[30]};

      ::before {
        content: '';
        width: 3px;
        height: 50%;
        position: absolute;
        left: 0;
        background-color: ${({ theme }) => theme.colors.blue.primary};
        top: 50%;
        transform: translateY(-50%);
      }

      :hover {
        background-color: ${({ theme }) => theme.colors.gray[30]};
      }
    `};
`;
