import React, { useContext, useEffect, useState } from 'react';
import { Box, Datepicker, Dialog, Select, Typography } from 'mino-ui';
import { useForm } from 'react-hook-form';
import DealContext from 'presentation/pages/Deal/contexts/DealContext';
import { makeBulkActionsDeals } from 'main/factories/usecases/deal/bulk-actions-deals-factory';
import { BulkActionsDealsRequest } from 'domain/usecases/deal/bulk-actions-deals-use-case';
import { useLoss } from 'main/hooks/useLoss';
import SeedingIcon from './icons/seeding.gif';

interface BulkOwnerProps {
  ids: string[];
}

export enum IconNames {
  ThumbDislike20Regular = 'ThumbDislike20Regular',
}
export enum IconColors {
  white = 'white',
}

const BulkLoss = (props: BulkOwnerProps) => {
  const { ids } = props;
  const { bulkLoss, setBulkLoss, fetchDeals } = useContext(DealContext);
  const [loading, setLoading] = useState(false);
  const { loss } = useLoss();
  const { handleSubmit, setValue, control, reset } =
    useForm<BulkActionsDealsRequest>();

  async function bulkActionsDeals(data: BulkActionsDealsRequest) {
    try {
      setLoading(true);
      await makeBulkActionsDeals().execute(data);
    } catch (error) {
      console.log(error);
    } finally {
      fetchDeals();
      reset();
      setLoading(false);
      setBulkLoss(false);
    }
  }

  useEffect(() => {
    if (ids) {
      setValue('ids', ids);
    }
    setValue('result', 0);
  }, [ids, setValue]);

  return (
    <Dialog
      title="Marcar como Perdido"
      open={bulkLoss}
      onOpenChange={() => {
        setBulkLoss(false);
        reset();
      }}
      onSubmit={handleSubmit(bulkActionsDeals)}
      submitIconName={IconNames.ThumbDislike20Regular}
      submitIconColor={IconColors.white}
      submitText="Confirmar"
      loading={loading}
      content={
        <form onSubmit={handleSubmit(bulkActionsDeals)}>
          <Box flexDirection="column" gap={24} alignItems="center">
            <Typography color="primary" variant="body" fontSize={14}>
              {ids.length} selecionados
            </Typography>
            <Select
              label="Motivo de perda"
              fullWidth
              required
              control={control}
              name="loss_id"
              options={loss.map((l) => ({
                label: l.name,
                value: l.id,
              }))}
            />
            <Datepicker
              fullWidth
              time
              required
              placeholder="Selecione a data da perda"
              name="date_loss"
              label="Data da perda"
              control={control}
            />
            <input name="result" value={0} hidden />
            <input type="submit" hidden />

            <img src={SeedingIcon} width="320" />
          </Box>
        </form>
      }
    />
  );
};

export default BulkLoss;
