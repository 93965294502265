import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  AcceptInviteRequestDTO,
  AcceptInviteResponseDTO,
  AcceptInviteUseCase,
} from 'domain/usecases/session/accept-invite';

export class RemoteAcceptInvitedUseCase implements AcceptInviteUseCase {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient,
    private readonly toastService: ToastService
  ) {}

  async execute(
    params: AcceptInviteRequestDTO
  ): Promise<AcceptInviteResponseDTO> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        this.toastService.show('Senha atualizada com sucesso!', {
          type: 'success',
        });
        return {
          message: httpResponse.body.message,
          success: httpResponse.body.success,
        };

      case HttpStatusCode.unauthorized:
        this.toastService.show('Token inválido ou expirado!', {
          type: 'error',
        });
        throw new NotAuthorizedError('Token inválido ou expirado!');
      default:
        throw new UnexpectedError('Erro inesperado!');
    }
  }
}
