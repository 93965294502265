import { RemoteBulkActionsCompanies } from 'data/usecases/company/remote-bulk-actions-company';
import { BulkActionsCompaniesUseCase } from 'domain/usecases/company/bulk-actions-companies-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeBulkActionsCompanies = (): BulkActionsCompaniesUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteBulkActionsCompanies(
    `${process.env.REACT_APP_API_BASE_URL}/bulk-companies`,
    axios,
    toastify
  );
};
