import { Box, Card, Skeleton, Spacer } from 'mino-ui';
import React, { ReactElement } from 'react';

export const ActiviesSingleListSkeleton = (): ReactElement => {
  return (
    <>
      <Spacer height={32} />
      <Box flexDirection="row" gap={20}>
        <Box width="28px">
          <Skeleton
            alignItems="center"
            gap={12}
            height={10}
            lines={[
              { widthPercent: 70, height: 18 },
              { widthPercent: 100, height: 33 },
              { widthPercent: 70, height: 18 },
            ]}
          />
        </Box>

        <Box flexDirection="column" width="100%" gap={16}>
          <Card hasBorder gap={8}>
            <Skeleton
              gap={16}
              height={24}
              lines={[
                {
                  height: 24,
                  widthPercent: 50,
                },
                {
                  height: 24,
                  widthPercent: 100,
                },
              ]}
            />

            <Skeleton
              flexDirection="row"
              justifyContent="flex-end"
              height={24}
              lines={[
                {
                  height: 24,
                  widthPercent: 25,
                },
                {
                  height: 24,
                  widthPercent: 20,
                },
                {
                  height: 24,
                  widthPercent: 20,
                },
              ]}
            />
          </Card>

          <Card hasBorder gap={8}>
            <Skeleton
              gap={16}
              height={24}
              lines={[
                {
                  height: 24,
                  widthPercent: 50,
                },
                {
                  height: 24,
                  widthPercent: 100,
                },
              ]}
            />

            <Skeleton
              flexDirection="row"
              justifyContent="flex-end"
              height={24}
              lines={[
                {
                  height: 24,
                  widthPercent: 25,
                },
                {
                  height: 24,
                  widthPercent: 20,
                },
                {
                  height: 24,
                  widthPercent: 20,
                },
              ]}
            />
          </Card>

          <Card hasBorder gap={8}>
            <Skeleton
              gap={16}
              height={24}
              lines={[
                {
                  height: 24,
                  widthPercent: 50,
                },
                {
                  height: 24,
                  widthPercent: 100,
                },
              ]}
            />

            <Skeleton
              flexDirection="row"
              justifyContent="flex-end"
              height={24}
              lines={[
                {
                  height: 24,
                  widthPercent: 25,
                },
                {
                  height: 24,
                  widthPercent: 20,
                },
                {
                  height: 24,
                  widthPercent: 20,
                },
              ]}
            />
          </Card>

          <Card hasBorder gap={8}>
            <Skeleton
              gap={16}
              height={24}
              lines={[
                {
                  height: 24,
                  widthPercent: 50,
                },
                {
                  height: 24,
                  widthPercent: 100,
                },
              ]}
            />

            <Skeleton
              flexDirection="row"
              justifyContent="flex-end"
              height={24}
              lines={[
                {
                  height: 24,
                  widthPercent: 25,
                },
                {
                  height: 24,
                  widthPercent: 20,
                },
                {
                  height: 24,
                  widthPercent: 20,
                },
              ]}
            />
          </Card>

          <Card hasBorder gap={8}>
            <Skeleton
              gap={16}
              height={24}
              lines={[
                {
                  height: 24,
                  widthPercent: 50,
                },
                {
                  height: 24,
                  widthPercent: 100,
                },
              ]}
            />

            <Skeleton
              flexDirection="row"
              justifyContent="flex-end"
              height={24}
              lines={[
                {
                  height: 24,
                  widthPercent: 25,
                },
                {
                  height: 24,
                  widthPercent: 20,
                },
                {
                  height: 24,
                  widthPercent: 20,
                },
              ]}
            />
          </Card>

          <Card hasBorder gap={8}>
            <Skeleton
              gap={16}
              height={24}
              lines={[
                {
                  height: 24,
                  widthPercent: 50,
                },
                {
                  height: 24,
                  widthPercent: 100,
                },
              ]}
            />

            <Skeleton
              flexDirection="row"
              justifyContent="flex-end"
              height={24}
              lines={[
                {
                  height: 24,
                  widthPercent: 25,
                },
                {
                  height: 24,
                  widthPercent: 20,
                },
                {
                  height: 24,
                  widthPercent: 20,
                },
              ]}
            />
          </Card>

          <Card hasBorder gap={8}>
            <Skeleton
              gap={16}
              height={24}
              lines={[
                {
                  height: 24,
                  widthPercent: 50,
                },
                {
                  height: 24,
                  widthPercent: 100,
                },
              ]}
            />

            <Skeleton
              flexDirection="row"
              justifyContent="flex-end"
              height={24}
              lines={[
                {
                  height: 24,
                  widthPercent: 25,
                },
                {
                  height: 24,
                  widthPercent: 20,
                },
                {
                  height: 24,
                  widthPercent: 20,
                },
              ]}
            />
          </Card>

          <Card hasBorder gap={8}>
            <Skeleton
              gap={16}
              height={24}
              lines={[
                {
                  height: 24,
                  widthPercent: 50,
                },
                {
                  height: 24,
                  widthPercent: 100,
                },
              ]}
            />

            <Skeleton
              flexDirection="row"
              justifyContent="flex-end"
              height={24}
              lines={[
                {
                  height: 24,
                  widthPercent: 25,
                },
                {
                  height: 24,
                  widthPercent: 20,
                },
                {
                  height: 24,
                  widthPercent: 20,
                },
              ]}
            />
          </Card>
        </Box>
      </Box>

      <Spacer height={8} />
    </>
  );
};
