import { RemoteUpdateDeal } from 'data/usecases';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeUpdateDealUseCase = (
  dealId: string,
  result?: boolean
): RemoteUpdateDeal => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteUpdateDeal(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}${
      result
        ? `/return-product/${encodeURI(dealId)}`
        : `/products/${encodeURI(dealId)}`
    }`,
    toastify
  );
};
