import styled from 'styled-components';

export const ControlsContaioner = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[20]};
  padding: 8px;
  display: flex;
  flex-wrap: wrap;

  & div {
    border: none;
    background-color: transparent;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.blue.primary};
  }

  & button {
    z-index: 1;
    border: none;
    background: transparent;
    font-family: 'Noto Sans', sans-serif;
    margin-right: 1rem;
    min-height: 40px;
    cursor: pointer;
  }

  & button:hover {
    color: ${({ theme }) => theme.colors.blue.primary};
    path {
      fill: ${({ theme }) => theme.colors.blue.primary};
    }
  }
  & button.is-active svg {
    path {
      fill: ${({ theme }) => theme.colors.blue.primary};
    }
  }
`;
