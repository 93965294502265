import styled from 'styled-components';

export const AccordionWrap = styled.div`
  border-radius: 0.5rem;
  border: solid 1px ${({ theme }) => theme.colors.gray[30]};
`;

export const AccordionContentWrap = styled.div`
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 0.5rem;
`;

export const SelectAllWrap = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 0;
`;

export const CheckboxWrap = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
