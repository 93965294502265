import React, { ReactElement, useRef } from 'react';
import * as S from './styles';
import * as T from './types';
import Typography from '../Typography';
import { ReactSVG } from 'react-svg';
import Close from './icons/close.svg';
import Button from '../Button';

const Dialog = (props: T.DialogProps): ReactElement => {
  const {
    content,
    trigger,
    title,
    description,
    open,
    defaultOpen,
    onOpenChange,
    hideCloseButton,
    maxWidth = 450,
    onSubmit,
    submitText = 'Salvar',
    submitVariant = 'primary',
    loading,
    ref,
    submitIconName = 'Save20Regular',
    cancelIconName,
    hasDivider = true,
    submitIconColor = 'white',
    cancelIconColor,
    headerIcon,
  } = props;

  const BodyRef = useRef<HTMLDivElement>(null);
  const CloseRef = useRef<HTMLButtonElement>(null);

  const hasOverflow = BodyRef.current
    ? BodyRef.current.scrollHeight > BodyRef.current.clientHeight
    : false;

  return (
    <S.Container
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      open={open}
      modal={false}
    >
      {trigger && <S.Trigger>{trigger}</S.Trigger>}
      <S.DialogWrap>
        <S.Overlay />
        <S.Content ref={ref} maxWidth={maxWidth} data-testid="content-dialog">
          <S.Header hasBorder={hasDivider}>
            <S.Title>
              {headerIcon && (
                <S.IconWrap>
                  <ReactSVG src={headerIcon} />
                </S.IconWrap>
              )}
              <Typography noWrap weight="bold" fontSize={18} variant="h4">
                {title}
              </Typography>
            </S.Title>

            {!hideCloseButton && (
              <S.CloseWrap ref={CloseRef} data-testid="close-dialog">
                <ReactSVG src={Close} />
              </S.CloseWrap>
            )}
          </S.Header>
          <S.Body overflowing={hasOverflow} ref={BodyRef}>
            {description && (
              <S.Description>
                <Typography
                  noWrap
                  variant="body"
                  weight="regular"
                  fontSize={14}
                >
                  {description}
                </Typography>
              </S.Description>
            )}
            {content}
          </S.Body>

          {onSubmit ? (
            <S.Footer hasBorder={hasDivider}>
              {onSubmit && (
                <>
                  <input type="submit" hidden />
                  <Button
                    onClick={onSubmit}
                    loading={loading}
                    text={submitText}
                    variant={submitVariant}
                    icon={submitIconName}
                    iconColor={submitIconColor}
                  />
                </>
              )}

              <Button
                onClick={() => CloseRef.current?.click()}
                text="Cancelar"
                variant="ghostSecondary"
                data-testid="cancel-dialog"
                icon={cancelIconName}
                iconColor={cancelIconColor}
              />
            </S.Footer>
          ) : null}
        </S.Content>
      </S.DialogWrap>
    </S.Container>
  );
};
export default Dialog;
