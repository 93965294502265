import { RemoteListTeams } from 'data/usecases/team/remote-list-teams';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeListTeamsUseCase = (): RemoteListTeams => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteListTeams(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/teams`,
    toastify
  );
};
