import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  TeamResponse,
  ListTeamsUseCase,
} from 'domain/usecases/team/list-teams-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteListTeams implements ListTeamsUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(): Promise<TeamResponse[]> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Tenant: tenant,
      },
    });

    const data = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return data.map((team: TeamResponse) => ({
          id: team.id,
          name: team.name,
          tenant_id: team.tenant_id,
          updated_at: team.updated_at,
          created_at: team.created_at,
        }));

      case HttpStatusCode.unauthorized:
        this.toastService.show('Unauthorized', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');
      default:
        this.toastService.show('Unexpected error', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
