import { makeRegisterSessionUseCase } from 'main/factories/usecases/sessions/register-session-factory';
import { saveLocalToken } from 'main/hooks/saveLocalToken';
import { Button, Input, Spacer } from 'mino-ui';
import { SignUpContext } from 'presentation/contexts';
import React, {
  ChangeEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';

const StepThree = (): ReactElement => {
  const [pass, setPass] = useState('');
  const [loading, setLoading] = useState(false);
  const [passValidation, setPassValidation] = useState({
    valid: false,
    error: '',
  });

  const { setTitle, setBreadCrumb, step, form, setForm } =
    useContext(SignUpContext);

  useEffect(() => {
    setTitle({
      title: 'Escolha uma senha',
      subtitle: 'Você poderá alterar posteriormente.',
    });
  }, []);

  useEffect(() => {
    setBreadCrumb([
      {
        title: 'Meus dados',
        action: () => undefined,
        active: step === 1 ? true : false,
      },
      {
        title: 'Dados da empresa',
        action: () => undefined,
        active: step === 2 ? true : false,
      },
      {
        title: 'Senha',
        action: () => undefined,
        active: step === 3 ? true : false,
      },
    ]);
  }, []);

  function handleValidatePass(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    if (value === pass) {
      setPassValidation({
        valid: true,
        error: '',
      });
      return;
    }

    setPassValidation({
      valid: false,
      error: 'As senhas não conferem!',
    });
  }

  async function handleFormSubmit() {
    try {
      setLoading(true);
      const response = await makeRegisterSessionUseCase().execute(form);

      saveLocalToken(response.token, response.tenant_id.toString());
      setLoading(false);
      window.location.href = '/';
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (passValidation.valid && pass) {
      setForm({ ...form, password: pass });
    }
  }, [pass, passValidation]);

  return (
    <>
      <Input
        full
        label="Senha"
        placeholder="Senha"
        type="password"
        name="password"
        onChange={(e) => setPass(e.target.value)}
        value={pass}
      />
      <Spacer height={24} />

      <Input
        full
        onChange={handleValidatePass}
        label="Confirmar senha"
        placeholder="Confirme a senha"
        type="password"
        name="confirm_pass"
        autoComplete="off"
        errors={passValidation.error}
      />

      <Spacer height={24} />

      <Button
        disabled={!passValidation.valid || loading}
        full
        text={loading ? 'Carregando' : 'Concluir'}
        onClick={() => handleFormSubmit()}
      />
    </>
  );
};

export default StepThree;
