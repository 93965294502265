import React, { ReactElement } from 'react';
import * as T from './types';
import * as S from './styles';
import SearchIcon from './icons/search.svg';
import { ReactSVG } from 'react-svg';

const InputSearch = (props: T.InputProps): ReactElement => {
  const {
    name,
    value,
    placeholder,
    type,
    autoComplete,
    onChange,
    required,
    defaultValue,
    full = false,
    hiddenOnMobile = false,
  } = props;

  const styles = {
    full,
    hiddenOnMobile,
  };

  return (
    <S.Container {...styles}>
      <label htmlFor={name} style={{ position: 'relative' }}>
        <S.Input
          {...styles}
          id={name}
          data-testid="input-text"
          required={required}
          autoComplete={autoComplete}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
        />

        <S.WrapperIcon>
          <ReactSVG src={SearchIcon} />
        </S.WrapperIcon>
      </label>
    </S.Container>
  );
};

export default InputSearch;
