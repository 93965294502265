import { RemoteDeleteSharedLink } from 'data/usecases/sharedlink/remote-delete-sharedLink';
import { DeleteSharedLinkUseCase } from 'domain/usecases/SharedLink/delete-SharedLink-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeDeleteSharedLinks = (id: string): DeleteSharedLinkUseCase => {
  const Axios = new AxiosProvider();
  const Toastify = new ToastifyProvier();

  return new RemoteDeleteSharedLink(
    Axios,
    `${process.env.REACT_APP_API_BASE_URL}/shared-links/${id}`,
    Toastify
  );
};
