import { RemoteSaveSharedLinks } from 'data/usecases/sharedlink/remote-save-SharedLinks';
import { SaveSharedLinksUseCase } from 'domain/usecases/SharedLink/save-sharedLink-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const MakeSaveSharedLinksUseCase = (
  id?: number
): SaveSharedLinksUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteSaveSharedLinks(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/shared-links${id ? `/${id}` : ''}`,
    toastify
  );
};
