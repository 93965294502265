import React, { ReactElement, useContext } from 'react';
import { Box, Button, Card, Typography } from 'mino-ui';
import * as S from './styles';
import { ReactSVG } from 'react-svg';
import Papers from './icons/papers.svg';
import Task from './icons/task.svg';
import ActivityListContext from '../../context/ActivityListContext';

const EmptyStateActivity = (): ReactElement => {
  const { setIsDrawerOpen } = useContext(ActivityListContext);

  return (
    <Card fill>
      <S.Container>
        <S.IconWrap>
          <ReactSVG src={Papers} />
        </S.IconWrap>
        <Box flexDirection="column" alignItems="center" gap={24}>
          <Box flexDirection="column" justifyContent="center" gap={24}>
            <Typography
              variant="h4"
              fontSize={18}
              align="center"
              weight="bold"
              fill
              noWrap
            >
              Parece que nenhuma tarefa <br />
              foi adicionada ainda
            </Typography>
            <Typography variant="body" align={'center'} noWrap fontSize={12}>
              Você pode começar adicionando sua primeira tarefa <br />
              clicando no botão abaixo.
            </Typography>
          </Box>
          <Box>
            <Button
              text="Adicionar uma Tarefa"
              size="medium"
              icon={Task}
              onClick={() => setIsDrawerOpen(true)}
            />
          </Box>
        </Box>
      </S.Container>
    </Card>
  );
};

export default EmptyStateActivity;
