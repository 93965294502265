/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react';
import * as S from '../styles';
import { GroupListProps, GroupListItem } from '../types';
import Icon from 'components/common/Icon';
import Avatar from 'components/common/Avatar';
import Box from 'components/common/Box';

type TriggerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  type: GroupListProps['trigger'];
  multi: boolean;
  placeholder?: string;
  items: GroupListItem[];
  selectedValue: string | string[];
  initialSelectedItem?: GroupListItem;
  hasAvatar?: boolean;
  setSelectedValue: (value: string | string[]) => void;
};

export const Trigger = (props: TriggerProps): ReactElement => {
  const {
    open,
    setOpen,
    type,
    multi,
    placeholder = 'Selecione',
    selectedValue,
    initialSelectedItem,
    hasAvatar,
  } = props;

  const [selectedItem, setSelectedItem] = useState<GroupListItem | undefined>(
    initialSelectedItem
  );

  useEffect(() => {
    if (selectedValue && !Array.isArray(selectedValue)) {
      setSelectedItem(
        props.items.find((item) => item.value === selectedValue) || undefined
      );
    }
  }, [selectedValue]);

  return (
    <S.Trigger
      as={!type ? 'button' : 'div'}
      selected={!!selectedValue}
      onClick={() => setOpen(!open)}
      data-state={open ? 'open' : 'closed'}
    >
      {type === 'action-button' ? (
        <Icon iconName="MoreVertical20Regular" />
      ) : type === 'default' ? (
        <span>
          {!multi && selectedItem && !Array.isArray(selectedItem) ? (
            <Box alignItems="center" gap={8}>
              {hasAvatar && (
                <Avatar
                  name={selectedItem?.label || selectedItem.value}
                  image={selectedItem?.avatar}
                />
              )}
              {selectedItem?.label || selectedItem.value}
            </Box>
          ) : (
            placeholder
          )}

          <Icon iconName="ChevronDown20Regular" />
        </span>
      ) : (
        type
      )}
    </S.Trigger>
  );
};
