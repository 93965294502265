import React, { memo, useCallback, useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import * as S from './styles';
import { RichTextProps } from './types';

const RichText: React.FC<RichTextProps> = ({
  value,
  defaultValue,
  onChange,
  ...rest
}) => {
  const quillRef = useRef<Quill | null>(null);
  const editorRef = useRef<HTMLDivElement | null>(null);

  const handleChange = useCallback(() => {
    if (quillRef.current && onChange) {
      const htmlContent = quillRef.current.root.innerHTML;
      onChange(htmlContent);
    }
  }, [onChange]);

  useEffect(() => {
    if (editorRef.current) {
      quillRef.current = new Quill(editorRef.current, {
        theme: 'snow',
      });

      quillRef.current.on('text-change', handleChange);
      quillRef.current.root.innerHTML = defaultValue || '';
    }

    return () => {
      if (quillRef.current) {
        quillRef.current.off('text-change', handleChange);
      }
    };
  }, []);

  useEffect(() => {
    if (value) {
      quillRef.current.root.innerHTML = value;
    }
  }, [value]);

  return (
    <S.Container>
      <S.EditorContainer ref={editorRef} style={{ border: 'none' }} {...rest} />
    </S.Container>
  );
};

export default memo(RichText);
