import { RemoteSaveGroup } from 'data/usecases/group/remote-save-group';
import { SaveGroupUseCase } from 'domain/usecases/group/save-group-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeSaveGroupUseCase = (id?: string): SaveGroupUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteSaveGroup(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/groups${id ? `/${id}` : ''}`,
    toastify
  );
};
