import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { DealResponseDTO } from 'domain/dtos/deal/deal-response-dto';
import { IDeal } from 'domain/entity/IDeal';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  DealRequestPayload,
  ListDealsUseCase,
} from 'domain/usecases/deal/list-deals-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteListDeals implements ListDealsUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(params: DealRequestPayload): Promise<DealResponseDTO> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Tenant: tenant,
      },
      params: params,
    });

    const { data } = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return {
          total: response.body.total,
          perPage: response.body.perPage,
          lastPage: response.body.lastPage,
          page: response.body.page,
          totalValue:
            response.body.totalValue &&
            response.body.totalValue['sum(`value`)'],
          data: data.map((deal: IDeal) => ({
            id: deal.id,
            name: deal.name,
            tenant_id: deal.tenant_id,
            funnel_id: deal.funnel_id,
            created_at: deal.created_at,
            updated_at: deal.updated_at,
            value: deal.value,
            contacts: deal.contacts,
            companies: deal.companies,
            tags: deal.tags,
            result: deal.result,
            user_id: deal.user_id,
            user: deal.user,
            services: deal.services,
            campaign: deal.campaign,
            description: deal.description,
            channel: deal.channel,
            funnel: deal.funnel,
          })),
        };

      case HttpStatusCode.unauthorized:
        this.toastService.show('Unauthorized', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');
      default:
        this.toastService.show('Unexpected error', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
