import styled, { css } from 'styled-components';

interface TableHeadingStyledProps {
  align?: 'left' | 'right' | 'center';
  width?: number;
  hiddenOnMobile?: boolean;
  compact?: boolean;
  sortable?: boolean;
}
interface TableRowStyledProps {
  striped?: boolean;
  selected?: boolean;
  compact?: boolean;
  isHeadingRow?: boolean;
}

interface TextContentStyedProps {
  fontWeight?: number;
  fontSize?: number;
  align?: TableHeadingStyledProps['align'];
}

export const Container = styled.div<{ hasOutline?: boolean }>`
  width: 100%;
  outline: 1px solid transparent;
  border-radius: 4px;
  position: relative;
  z-index: 1;

  ${({ hasOutline }) =>
    hasOutline &&
    css`
      outline: 1px solid ${({ theme }) => theme.colors.gray[30]};
      overflow: hidden;
    `}
  &::-webkit-scrollbar {
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.gray[30]};
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray[30]};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.gray[50]};
  }
`;

export const Table = styled.table<{ hasOutline?: boolean }>`
  width: 100%;
  overflow-x: auto;
  border-collapse: collapse;
  background-color: ${({ theme }) => theme.colors.gray.white};
  padding: 1rem 2rem;

  & td {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray[200]};
  }
`;

export const Thead = styled.thead`
  position: relative;
  font-family: 'Noto Sans', sans-serif;
`;

export const TextContent = styled.span<TextContentStyedProps>`
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 16px);

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `};

  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize}px;
    `};

  ${({ fontWeight }) =>
    fontWeight &&
    css`
      font-weight: ${fontWeight};
    `};
`;

export const HeadingContent = styled.div<{
  sortable?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  ${({ sortable }) =>
    sortable &&
    css`
      cursor: pointer;
    `}
`;

export const TableHeading = styled.th<TableHeadingStyledProps>`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[30]};
  background-color: ${({ theme }) => theme.colors.gray.white};
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.gray[600]};
  padding: 0 2rem;
  height: 56px;

  ${({ className }) =>
    className === 'has-checkbox' &&
    css`
      padding: 1rem;
      width: 60px !important;
    `}

  ${({ compact }) =>
    compact &&
    css`
      height: 48px;
    `}
  ${({ width }) => width && `width: ${width}%`};

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

  @media (max-width: 768px) {
    ${({ hiddenOnMobile }) => hiddenOnMobile && `display: none`}
  }

  &:not(:first-child) {
    ::before {
      content: '';
      position: absolute;
      left: 0;
      height: 70%;
      top: 15%;
      width: 1px;
      background-color: ${({ theme }) => theme.colors.gray[30]};
    }
  }
`;

export const TableRow = styled.tr<TableRowStyledProps>`
  background-color: ${({ theme }) => theme.colors.gray.white};
  transition: box-shadow 0.2s ease-in-out;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[30]};

  ${({ striped }) =>
    striped &&
    css`
      &:nth-child(2n) {
        background-color: ${({ theme }) => theme.colors.gray[20]};
      }
    `}

  & a {
    :focus {
      outline: 2px solid ${({ theme }) => theme.colors.blue.primary};
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.gray[20]};
    border-color: ${({ theme }) => theme.colors.gray[20]};
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.blue[50]};
      border-color: ${({ theme }) => theme.colors.gray[20]};
      :hover {
        background-color: ${({ theme }) => theme.colors.blue[50]};
      }
    `}
`;

export const DataColumn = styled.td<{
  hiddenOnMobile?: boolean;
  widthMobile?: number;
  compact?: boolean;
}>`
  height: 64px;
  padding: 0 1rem;

  ${({ compact }) =>
    compact &&
    css`
      height: 48px;
    `}

  @media (max-width: 768px) {
    ${({ hiddenOnMobile }) => hiddenOnMobile && `display: none`}
    ${({ widthMobile }) => widthMobile && `width: ${widthMobile}%`};
  }
`;

export const BulkActions = styled.tr<{ active?: boolean }>`
  display: none;
  z-index: 2;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 100%;
  background-color: ${({ theme }) => theme.colors.gray[20]};
  border: 1px solid ${({ theme }) => theme.colors.gray[30]};
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  padding: 0;

  ${({ active }) =>
    active &&
    css`
      display: flex;
    `}
`;

export const CheckBoxTableColumn = styled.td<{ bulkActions?: boolean }>`
  text-align: center;
  width: 56px;

  ${({ bulkActions }) =>
    bulkActions &&
    css`
      position: relative;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const WrapperPills = styled.div`
  display: flex;
  align-items: center;

  & button {
    margin-left: 8px;
  }
`;

export const WrapperActions = styled.div`
  display: flex;
  align-items: center;

  > p {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 21px;
    margin: 0;
  }
`;
