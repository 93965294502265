import React, { ReactElement, useState } from 'react';
import { Box, Button, Table, Tooltip } from 'mino-ui';
import { IconColors } from 'presentation/pages/Activity';
import { UseImportBatch } from 'main/hooks/useImportBatch';
import { RevertImportBatchDialog } from './components/RevertImportBatchDialog';
import { IImportBatch } from 'domain/entity/IImportBatch';

const ImportBatchConfig = (): ReactElement => {
  const { importBatch, fatchImportBatch } = UseImportBatch();
  const [openRevertDialog, setOpenRevertDialog] = useState(false);
  const [batchPayload, setBatchPayload] = useState<IImportBatch>();

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap={16}
    >
      <Table
        hasOutline
        compact
        columns={[
          { headerName: 'Nome', field: 'importation_title' },
          {
            headerName: 'Items',
            field: 'count',
            width: 15,
            align: 'left',
          },
          {
            headerName: 'Data',
            field: 'Importation_data',
            width: 15,
            align: 'center',
          },
          { headerName: '', field: 'action', width: 1 },
        ]}
        rows={importBatch.map((batch) => ({
          id: `${batch.id}`,
          importation_title: batch.import_batch_title,
          Importation_data: new Date(batch.created_at).toLocaleString(),
          count: batch.count,
          action: (
            <Tooltip
              key={batch.id}
              content="Reverter importação"
              align="center"
              side="top"
              trigger={
                <div>
                  <Button
                    variant="ghostSecondary"
                    size="small"
                    text=""
                    icon="ArchiveArrowBack20Regular"
                    iconColor={IconColors.systemRed}
                    onClick={() => {
                      setBatchPayload(batch);
                      setOpenRevertDialog(true);
                    }}
                  />
                </div>
              }
            />
          ),
        }))}
      />

      <RevertImportBatchDialog
        batchPayload={batchPayload}
        open={openRevertDialog}
        setOpen={setOpenRevertDialog}
        callback={() => fatchImportBatch()}
      />
    </Box>
  );
};

export default ImportBatchConfig;
