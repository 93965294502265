import styled, { css } from 'styled-components';

interface InputStyledProps {
  error?: string;
  full?: boolean;
  hiddenOnMobile?: boolean;
}

export const Container = styled.div<InputStyledProps>`
  position: relative;
  width: 100%;
  max-width: 260px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    ${({ hiddenOnMobile }) =>
      hiddenOnMobile &&
      css`
        display: none;
      `}
  }
`;

export const Input = styled.input<InputStyledProps>`
  background: ${({ theme }) => theme.colors.gray[30]};
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  color: ${({ theme }) => theme.colors.gray[200]};
  padding: 0.5rem 1rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 19px;
  width: 100%;

  :focus {
    border: 1px solid ${({ theme }) => theme.colors.system.success};
  }

  ${({ error }) =>
    error &&
    error !== '' &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.system.error};

      :focus {
        border-color: ${({ theme }) => theme.colors.system.error};
      }
    `}

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray[200]};
  }
`;

export const WrapperIcon = styled.div`
  > * {
    height: 20px;
    cursor: pointer;
  }
  > div > div > svg {
    margin-right: 1rem;
  }
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;
