import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { PaginateDTO } from 'domain/dtos/paginate-dto';
import { IUser } from 'domain/entity/IUser';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  LoadUsersUseCase,
  RequestUsersParams,
} from 'domain/usecases/user/load-users-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteLoadUsers implements LoadUsersUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(params: RequestUsersParams): Promise<PaginateDTO<IUser>> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Tenant: tenant,
      },
      params: params,
    });

    const { data } = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return {
          total: response.body.total,
          perPage: response.body.perPage,
          lastPage: response.body.lastPage,
          page: response.body.page,
          data: data.map((user: IUser) => ({
            id: user.id,
            username: user.username,
            email: user.email,
            avatar: user.avatar,
            group_id: user.group_id,
            created_at: user.created_at,
            active: user.active,
            automation: user.automation,
            ramal: user.ramal,
            is_supervisor: user.is_supervisor,
            is_admin: user.is_admin,
            team_id: user.team_id,
            invite_accepted: user?.invite_accepted,
            team: user?.team,
            phone_number: user?.phone_number,
            invite_link: user?.invite_link,
          })),
        };

      case HttpStatusCode.unauthorized:
        this.toastService.show('Unauthorized', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');
      default:
        this.toastService.show('Unexpected error', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
