import { RemoteDeleteCompany } from 'data/usecases/company/remote-delete-company';
import { DeleteCompanyUseCase } from 'domain/usecases/company/delete-company-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeDeleteCompanyUseCase = (id: string): DeleteCompanyUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteDeleteCompany(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/companies/${id}`,
    toastify
  );
};
