import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ICustomField } from 'domain/entity/ICustomField';
import { UnexpectedError } from 'domain/errors';
import {
  FilterCustomField,
  ListCustomFields,
} from 'domain/usecases/custom_field/list-custom-fields';

export class RemoteListCustomField implements ListCustomFields {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  async execute(filter?: FilterCustomField): Promise<ICustomField[]> {
    const response = await this.httpClient.request({
      url: this.url,
      method: 'get',
    });

    if (response.statusCode === HttpStatusCode.ok) {
      return response.body as ICustomField[];
    }

    throw new UnexpectedError('Erro ao carregar campos personalizados');
  }
}
