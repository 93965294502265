import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  max-width: 870px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
  width: 100%;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const PhotoContent = styled.div`
  max-height: 300px;
  background-color: red;
`;
export const PhotoWrap = styled.div`
  position: relative;
`;
export const TextWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;
export const Photo = styled.div`
  width: 156px;
  height: 156px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ButtonWrap = styled.div`
  background: ${({ theme }) => theme.colors.blue.primary};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  > div > * {
    height: 18px;
  }
`;
export const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
`;
export const InputWrap = styled.div`
  display: grid;
  grid-template-columns: 15% 75%;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[30]};
  padding: 1rem 0;
  > * {
    margin: 0;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 100%;
  }
`;
export const ActionsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  padding-top: 1rem;
`;
export const DialogWrap = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const AvatarWrap = styled.div`
  position: relative;
  margin-bottom: 2.25rem;
`;
export const showAvatar = styled.div`
  width: 156px;
  height: 156px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadow.default};
`;

export const removeAvatar = styled.div`
  background: ${({ theme }) => theme.colors.blue.primary};
  color: ${({ theme }) => theme.colors.gray.white};
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
`;
