import { RemoteDeleteFunnel } from 'data/usecases';
import { DeleteFunnelUseCase } from 'domain/usecases/funnel/delete-funnel-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeDeleteFunnelUseCase = (id: string): DeleteFunnelUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteDeleteFunnel(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/funnels/${id}`,
    toastify
  );
};
