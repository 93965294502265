import React, { ReactElement, useState } from 'react';
import * as S from './styles';
import { Box, Button, Dropdown, PopOver, Table } from 'mino-ui';
import { IconColors } from 'presentation/shared/templates/ActivityCard/actions';
import ManageModal from './ManageModal';
import { SharedLinkPayload } from './type';
import { useSharedLinks } from 'main/hooks/useSharedLinks';
import DeleteDialog from './DeleteDialog';

const SharedLinks = (): ReactElement => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [contentPayload, setContentPayload] = useState<SharedLinkPayload>();

  const { links, fetchLinks } = useSharedLinks();

  return (
    <Box flexDirection="column" gap={16}>
      <Box justifyContent="space-between">
        <Button
          icon="AddCircle20Regular"
          iconColor={IconColors.white}
          text="Novo Link"
          onClick={() => setModalOpen(true)}
        />
      </Box>

      <Box>
        <Table
          hasOutline
          compact
          columns={[
            { field: 'name', headerName: 'Nome' },
            { field: 'link', headerName: 'Link' },
            { field: 'action', headerName: '', width: 1 },
          ]}
          rows={links.map((link) => ({
            id: `${link.id}`,
            name: link.shared_link_name,
            link: link.url,
            action: (
              <Dropdown
                trigger="action-button"
                items={[
                  {
                    label: 'Editar',
                    icon: 'Edit20Regular',
                    value: 'edit',
                  },
                  {
                    label: 'Excluir',
                    icon: 'Delete20Regular',
                    iconColor: IconColors.systemRed,
                    value: 'delete',
                  },
                ]}
                onClick={(value) => {
                  if (value === 'edit') {
                    setModalOpen(true);
                    setEdit(true);
                    setContentPayload(link);
                  }
                  if (value === 'delete') {
                    setDialogOpen(true);
                    setContentPayload(link);
                  }
                }}
              />
            ),
          }))}
        />
      </Box>
      <ManageModal
        isOpen={isModalOpen}
        setOpen={setModalOpen}
        edit={edit}
        setEdit={setEdit}
        updatePayload={contentPayload}
        callback={() => {
          setContentPayload(undefined);
          fetchLinks();
          setEdit(false);
        }}
      />

      <DeleteDialog
        isOpen={isDialogOpen}
        setOpen={setDialogOpen}
        deletePayload={contentPayload}
        callback={() => {
          fetchLinks();
        }}
      />
    </Box>
  );
};

export default SharedLinks;
