import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  Box,
  Collapse,
  Datepicker,
  Drawer,
  Input,
  Select,
  Spacer,
  ToggleGroup,
} from 'mino-ui';
import DealContext from '../contexts/DealContext';
import { DrawerForm, FilterBadge, TriggerText, WrapTrigger } from './styles';
import { DealRequestPayload } from 'domain/usecases/deal/list-deals-use-case';
import { useForm } from 'react-hook-form';
import { useLoss } from 'main/hooks/useLoss';
import FilterIcon from './icons/filter.svg';
import {
  DealsResourcesPermissions,
  hasDealsPermissions,
} from 'presentation/hooks/usePermissions';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import { useUsers } from 'main/hooks/useUsers';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { useTeams } from 'main/hooks/useTeams';
import { useChannels } from 'main/hooks/useChannel';
import { useServices } from 'main/hooks/useServices';
import { useContacts } from 'main/hooks/useContacts';
import { useCompanies } from 'main/hooks/useCompanies';
import { useTags } from 'main/hooks/useTags';
import { useCustomFields } from 'main/hooks/useCustomFields';

const DrawerFilterDeal = (): ReactElement => {
  const { session } = useContext(SessionContext);
  const { isFilterOpen, setIsFilterOpen, setFilter, filter, initialFilter } =
    useContext(DealContext);

  const { loss } = useLoss();

  const { users, fetchUsers } = useUsers();
  const { teams } = useTeams();
  const { channels } = useChannels();
  const { services, fetchServices } = useServices();
  const { contacts, fetchContacts } = useContacts();
  const { companies, fetchCompanies } = useCompanies();
  const { tags } = useTags();

  const [searchContact, setSearchContact] = useState('');
  const [searchCompany, setSearchCompany] = useState('');
  const [, setSearchTag] = useState('');
  const [searchService, setSearchService] = useState('');
  const [searchUser, setSearchUser] = useState('');

  const [stateFilter, setStateFilter] = useState(filter);

  function handleFilter(data: DealRequestPayload): void {
    setIsFilterOpen(false);
    setFilter({
      ...stateFilter,
      ...data,
    });
  }

  function resetFilter() {
    reset();
    setFilter(initialFilter);
  }

  const { register, handleSubmit, reset, watch, control, setValue } =
    useForm<DealRequestPayload>();

  useEffect(() => {
    setValue('result', filter.result);
    setValue('type', filter.type);
    setValue('loss_id', filter.loss_id);
    setValue('funnel_id', filter.funnel_id);
    setValue('users', filter.users);
    setValue('teams', filter.teams);
    setValue('date_from', filter.date_from);
    setValue('date_to', filter.date_to);
    setValue('date_closing_from', filter.date_closing_from);
    setValue('date_closing_to', filter.date_closing_to);
    setValue('date_loss_from', filter.date_loss_from);
    setValue('date_loss_to', filter.date_loss_to);
    setValue('channel_id', filter.channel_id);
    setValue('services', filter.services);
    setValue('contacts', filter.contacts);
    setValue('companies', filter.companies);
    setValue('tags', filter.tags);

    setStateFilter(filter);

    custom_fields.forEach((custom_field) => {
      setValue(`cf_${custom_field.id}`, filter[`cf_${custom_field.id}`]);
    });
  }, [filter]);

  useDebouncedEffect(
    () => {
      fetchUsers(searchUser);
    },
    [searchUser],
    300
  );

  useDebouncedEffect(
    () => {
      fetchServices(searchService);
    },
    [searchService],
    300
  );

  useDebouncedEffect(
    () => {
      fetchContacts(searchContact);
    },
    [searchContact],
    300
  );

  useDebouncedEffect(
    () => {
      fetchCompanies(searchCompany);
    },
    [searchCompany],
    300
  );

  const watchType = watch('type');
  const watchUsers = watch('users');
  const watchResult = watch('result');

  const { custom_fields } = useCustomFields('deal');

  return (
    <Drawer
      width={300}
      isOpen={isFilterOpen}
      onClose={() => setIsFilterOpen(false)}
      title="Filtrar Negócios"
      icon={FilterIcon}
      submitText="Filtrar"
      onSubmit={handleSubmit(handleFilter)}
      secondarySubmitText="Limpar"
      secondarySubmitAction={() => {
        resetFilter();
        setIsFilterOpen(false);
      }}
    >
      <form onSubmit={handleSubmit(handleFilter)}>
        <DrawerForm>
          <Collapse
            defaultOpen={true}
            hasArrow
            trigger={
              <WrapTrigger>
                <TriggerText>Nome</TriggerText>
                {stateFilter.search && <FilterBadge />}
              </WrapTrigger>
            }
            content={
              <Input
                register={register}
                label="Nome"
                autoFocus
                name="search"
                placeholder="Digite o nome do negócio"
              />
            }
          />
          {hasDealsPermissions(session, DealsResourcesPermissions.WATCH_ALL) ? (
            <Collapse
              hasArrow
              defaultOpen
              trigger={
                <WrapTrigger>
                  <TriggerText>Responsável</TriggerText>
                  {stateFilter.type !== initialFilter.type ? (
                    <FilterBadge />
                  ) : null}
                </WrapTrigger>
              }
              content={
                <Box flexDirection="column" gap={8} alignItems="center">
                  <ToggleGroup
                    size="small"
                    type="single"
                    onValueChange={(value) =>
                      setValue('type', value as DealRequestPayload['type'])
                    }
                    defaultValue={watchType}
                    items={[
                      {
                        value: 'my',
                        label: 'Meus',
                        icon: '',
                      },
                      {
                        value: 'all',
                        label: 'Todos',
                        icon: '',
                      },
                      {
                        value: 'user',
                        label: 'Usuário',
                        icon: '',
                      },
                      {
                        value: 'team',
                        label: 'Equipe',
                        icon: '',
                      },
                    ]}
                  />

                  {watchType === 'user' ? (
                    <Select
                      label="Usuários"
                      fullWidth
                      control={control}
                      name="users"
                      multi
                      defaultValue={watchUsers}
                      onInputChange={(value) => setSearchUser(value)}
                      options={users.map((u) => ({
                        value: u.id,
                        label: u.username,
                      }))}
                    />
                  ) : null}

                  {watchType === 'team' ? (
                    <Select
                      label="Equipes"
                      fullWidth
                      control={control}
                      name="teams"
                      multi
                      defaultValue={watch('teams')}
                      options={teams.map((t) => ({
                        value: t.id,
                        label: t.name,
                      }))}
                    />
                  ) : null}
                </Box>
              }
            />
          ) : null}

          <Collapse
            hasArrow
            defaultOpen={watchResult !== initialFilter.result}
            trigger={
              <WrapTrigger>
                <TriggerText>Resultado</TriggerText>
                {stateFilter.result !== 'open' && <FilterBadge />}
              </WrapTrigger>
            }
            content={
              <Box flexDirection="column" gap={8}>
                <Select
                  fullWidth
                  control={control}
                  name="result"
                  options={[
                    {
                      value: 'open',
                      label: 'Abertos',
                    },
                    {
                      value: '1',
                      label: 'Ganhos',
                    },
                    {
                      value: '0',
                      label: 'Perdidos',
                    },
                    {
                      value: '',
                      label: 'Todos',
                    },
                  ]}
                />

                {watchResult == 0 ? (
                  <Select
                    label="Motivo de perda"
                    fullWidth
                    control={control}
                    name="loss_id"
                    defaultValue={watch('loss_id')}
                    options={loss.map((l) => ({
                      label: l.name,
                      value: l.id,
                    }))}
                  />
                ) : null}
              </Box>
            }
          />

          <Collapse
            hasArrow
            defaultOpen={
              (stateFilter.date_from || stateFilter.date_to) !==
              (initialFilter.date_from || initialFilter.date_to)
            }
            trigger={
              <WrapTrigger>
                <TriggerText>Data de Criação</TriggerText>
                {(stateFilter.date_from || stateFilter.date_to) && (
                  <FilterBadge />
                )}
              </WrapTrigger>
            }
            content={
              <>
                <Datepicker
                  control={control}
                  label="De"
                  isClearable
                  name="date_from"
                  placeholder="Selecione a data de criação"
                />
                <Spacer height={8} />
                <Datepicker
                  control={control}
                  isClearable
                  label="Até"
                  name="date_to"
                  placeholder="Selecione a data de criação"
                />
              </>
            }
          />

          <Collapse
            hasArrow
            defaultOpen={
              (stateFilter.date_closing_from || stateFilter.date_closing_to) !==
              (initialFilter.date_closing_from || initialFilter.date_closing_to)
            }
            trigger={
              <WrapTrigger>
                <TriggerText>Data de Fechamento</TriggerText>
                {(stateFilter.date_closing_from ||
                  stateFilter.date_closing_to) && <FilterBadge />}
              </WrapTrigger>
            }
            content={
              <>
                <Datepicker
                  control={control}
                  label="De"
                  isClearable
                  name="date_closing_from"
                  placeholder="Selecione a data de fechamento"
                />
                <Spacer height={8} />
                <Datepicker
                  control={control}
                  isClearable
                  label="Até"
                  name="date_closing_to"
                  placeholder="Selecione a data de fechamento"
                />
              </>
            }
          />
          <Collapse
            hasArrow
            defaultOpen={
              (stateFilter.date_loss_from || stateFilter.date_loss_to) !==
              (initialFilter.date_loss_from || initialFilter.date_loss_to)
            }
            trigger={
              <WrapTrigger>
                <TriggerText>Data de Perda</TriggerText>
                {(stateFilter.date_loss_from || stateFilter.date_loss_to) && (
                  <FilterBadge />
                )}
              </WrapTrigger>
            }
            content={
              <>
                <Datepicker
                  control={control}
                  label="De"
                  isClearable
                  name="date_loss_from"
                  placeholder="Selecione a data de perda"
                />
                <Spacer height={8} />
                <Datepicker
                  control={control}
                  isClearable
                  label="Até"
                  name="date_loss_to"
                  placeholder="Selecione a data de perda"
                />
              </>
            }
          />

          <Collapse
            hasArrow
            defaultOpen={watch('services') !== initialFilter.services}
            trigger={
              <WrapTrigger>
                <TriggerText>Produtos</TriggerText>
                {stateFilter.services && <FilterBadge />}
              </WrapTrigger>
            }
            content={
              <>
                <Select
                  label="Produtos"
                  fullWidth
                  control={control}
                  multi
                  name="services"
                  defaultValue={watch('services')}
                  onInputChange={(value) => setSearchService(value)}
                  options={services.map((s) => ({
                    label: s.name,
                    value: s.id,
                  }))}
                />
              </>
            }
          />

          <Collapse
            hasArrow
            defaultOpen={watch('contacts') !== initialFilter.contacts}
            trigger={
              <WrapTrigger>
                <TriggerText>Contatos</TriggerText>
                {stateFilter.contacts && <FilterBadge />}
              </WrapTrigger>
            }
            content={
              <>
                <Select
                  label="Contatos"
                  fullWidth
                  control={control}
                  multi
                  name="contacts"
                  defaultValue={watch('contacts')}
                  onInputChange={(value) => setSearchContact(value)}
                  options={contacts.map((c) => ({
                    label: c.name,
                    value: c.id,
                  }))}
                />
              </>
            }
          />

          <Collapse
            hasArrow
            defaultOpen={watch('companies') !== initialFilter.companies}
            trigger={
              <WrapTrigger>
                <TriggerText>Empresas</TriggerText>
                {stateFilter.companies && <FilterBadge />}
              </WrapTrigger>
            }
            content={
              <>
                <Select
                  label="Empresas"
                  fullWidth
                  control={control}
                  multi
                  name="companies"
                  defaultValue={watch('companies')}
                  onInputChange={(value) => setSearchCompany(value)}
                  options={companies.map((c) => ({
                    label: c.name,
                    value: c.id,
                  }))}
                />
              </>
            }
          />
          <Collapse
            hasArrow
            defaultOpen={watch('tags') !== initialFilter.tags}
            trigger={
              <WrapTrigger>
                <TriggerText>Tags</TriggerText>
                {stateFilter.tags && <FilterBadge />}
              </WrapTrigger>
            }
            content={
              <>
                <Select
                  label="Tags"
                  fullWidth
                  control={control}
                  multi
                  name="tags"
                  defaultValue={watch('tags')}
                  onInputChange={(value) => setSearchTag(value)}
                  options={tags.map((t) => ({
                    label: t.name,
                    value: t.id,
                  }))}
                />
              </>
            }
          />

          <Collapse
            hasArrow
            defaultOpen={stateFilter.channel_id !== initialFilter.channel_id}
            trigger={
              <WrapTrigger>
                <TriggerText>Origem</TriggerText>
                {stateFilter.channel_id && <FilterBadge />}
              </WrapTrigger>
            }
            content={
              <>
                <Select
                  label="Origem"
                  fullWidth
                  control={control}
                  name="channel_id"
                  options={channels.map((c) => ({
                    label: c.name,
                    value: c.id,
                  }))}
                />
              </>
            }
          />

          {custom_fields?.map((field) => (
            <Collapse
              key={field.id}
              hasArrow
              defaultOpen={stateFilter[`cf_${field.id}`]}
              trigger={
                <WrapTrigger>
                  <TriggerText>{field.name}</TriggerText>
                  {stateFilter[`cf_${field.id}`] && <FilterBadge />}
                </WrapTrigger>
              }
              content={
                <>
                  <Input
                    label={field.name}
                    full
                    register={register}
                    name={`cf_${field.id}`}
                    placeholder={`${field.name}`}
                  />
                </>
              }
            />
          ))}

          <Spacer height={16} />
          <input type="submit" hidden />
        </DrawerForm>
      </form>
    </Drawer>
  );
};

export default DrawerFilterDeal;
