import React from 'react';
import { Dropdown, Icon } from 'mino-ui';
import * as S from './styles';
import * as T from '../types';

const headingOptions = [
  {
    label: 'Título 1',
    value: 'h1',
  },
  {
    label: 'Título 2',
    value: 'h2',
  },
  {
    label: 'Título 3',
    value: 'h3',
  },
  {
    label: 'Normal',
    value: 'normal',
  },
];

const Controls = (props: T.RichTextControls) => {
  const { editor, defaultSetup } = props;

  if (!editor) {
    return null;
  }

  const setLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    const url = window.prompt('URL');
    e.preventDefault();
    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url as string })
      .run();
  };

  return defaultSetup ? (
    <S.ControlsContaioner>
      <Dropdown
        items={headingOptions}
        initialSelectedItem={{ label: 'Normal ', value: 'normal' }}
        onChange={(value) => {
          if (value == 'h1') {
            editor.chain().focus().toggleHeading({ level: 1 }).run();
          }
          if (value == 'h2') {
            editor.chain().focus().toggleHeading({ level: 2 }).run();
          }
          if (value == 'h3') {
            editor.chain().focus().toggleHeading({ level: 3 }).run();
          }
        }}
      />
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
        <Icon color="dark" iconName="TextBold20Filled" />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? 'is-active' : ''}
      >
        <Icon color="dark" iconName="TextItalic20Filled" />
      </button>

      <button
        type="button"
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={editor.isActive('underline') ? 'is-active' : ''}
      >
        <Icon color="dark" iconName="TextUnderline20Filled" />
      </button>

      <button
        type="button"
        onClick={(e) => setLink(e)}
        className="p-1 border-2 border-gray-200 rounded-md hover:bg-gray-400"
      >
        <Icon color="dark" iconName="Link20Filled" />
      </button>

      <button
        type="button"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
        <Icon color="dark" iconName="TextNumberListLtr20Filled" />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >
        <Icon color="dark" iconName="TextBulletList20Filled" />
      </button>

      <button
        type="button"
        onClick={() => {
          editor.chain().focus().clearNodes().run(),
            editor.chain().focus().unsetAllMarks().run();
        }}
      >
        <Icon color="dark" iconName="ClearFormatting20Filled" />
      </button>
    </S.ControlsContaioner>
  ) : (
    <></>
  );
};
export default Controls;
