import React, { ReactElement } from 'react';
import { Card, Tabs } from 'mino-ui';
import CallTab from './atoms/CallTab';
import MessageTab from './atoms/MessageTab';
import PurposeTab from './atoms/PurposeTab';
import EmailTab from './atoms/EmailTab';
import MeetingTab from './atoms/MeetingTab';
import NotesTab from './atoms/NotesTab';
import * as T from './types';

const ActivityTabs = (props: T.ActivityTabsProps): ReactElement => {
  const { context } = props;

  return (
    <Card aria-label={context}>
      <Tabs
        hiddenTriggerTextOnMobile
        triggerGap={0}
        trigger={[
          {
            id: 'call',
            text: 'Ligação',
            icon: 'Call20Regular',
          },
          {
            id: 'email',
            text: 'E-mail',
            icon: 'Mail20Regular',
          },
          {
            id: 'message',
            text: 'Mensagem',
            icon: 'Chat20Regular',
          },
          {
            id: 'purpose',
            text: 'Proposta',
            icon: 'Handshake20Regular',
          },
          {
            id: 'meeting',
            text: 'Reunião',
            icon: 'BuildingPeople20Regular',
          },
          {
            id: 'notes',
            text: 'Notas',
            icon: 'Notepad20Regular',
          },
        ]}
        content={[
          {
            id: 'call',
            component: <CallTab />,
          },
          {
            id: 'message',
            component: <MessageTab />,
          },
          {
            id: 'purpose',
            component: <PurposeTab />,
          },
          {
            id: 'email',
            component: <EmailTab />,
          },
          {
            id: 'meeting',
            component: <MeetingTab />,
          },
          {
            id: 'notes',
            component: <NotesTab />,
          },
        ]}
      />
    </Card>
  );
};
export default ActivityTabs;
