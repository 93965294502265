import { useEffect, useState } from 'react';
import { LossResponse } from 'domain/usecases/loss/list-loss-use-case';
import { makeListLossUseCase } from 'main/factories/usecases/loss/list-loss-factory';

type UseLossesType = {
  loss: LossResponse[] | [];
  fetchLoss: () => Promise<void>;
  loading: boolean;
};

export const useLoss = (): UseLossesType => {
  const [loss, setLoss] = useState<LossResponse[] | []>([]);
  const [loading, setLoading] = useState(false);

  async function fetchLoss(): Promise<void> {
    try {
      setLoading(true);
      const data = await makeListLossUseCase().execute();
      setLoss(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchLoss();
  }, []);

  return { loss, fetchLoss, loading };
};
