import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { IService } from 'domain/entity/IService';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  RequestPayloadService,
  SaveServicesUseCase,
} from 'domain/usecases/service/save-services-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteSaveService implements SaveServicesUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(
    payload: RequestPayloadService,
    type?: 'update' | 'create'
  ): Promise<IService> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: type === 'update' ? 'put' : 'post',
      url: this.url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Tenant: tenant,
      },
      body: payload,
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        this.toastService.show('Salvo com sucesso!', {
          type: 'success',
        });

        return {
          id: response.body.id,
          name: response.body.name,
          price: response.body.price,
          created_at: response.body.created_at,
          tenant_id: response.body.tenant_id,
        };
      case HttpStatusCode.unauthorized:
        this.toastService.show('Unauthorized', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');

      case HttpStatusCode.badRequest:
        this.toastService.show('Preencha todos os campos obrigatórios', {
          type: 'error',
        });
        throw new UnexpectedError('Preencha todos os campos obrigatórios');
      default:
        this.toastService.show('Unexpected Error', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected Error');
    }
  }
}
