import React, { ReactElement, createRef, useEffect, useState } from 'react';
import { Box, Dialog, Input } from 'mino-ui';
import { useForm } from 'react-hook-form';
import {
  IconColors,
  IconNames,
  ManageModalType,
  SharedLinkPayload,
} from './type';
import { MakeSaveSharedLinksUseCase } from 'main/factories/usecases/sharedLinks/save-sharedLinks-factory';
import { RequestPayloadSharedLink } from 'domain/usecases/SharedLink/save-sharedLink-use-case';
import { firstLetterUpper } from 'main/helpers';

const ManageModal = (props: ManageModalType): ReactElement => {
  const {
    isOpen,
    setOpen,
    edit,
    setEdit,
    updatePayload,

    callback,
  } = props;

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm<SharedLinkPayload>({
    defaultValues: { id: undefined, shared_link_name: '', url: '' },
  });

  async function SaveOrUpdateSharedLink(data: RequestPayloadSharedLink) {
    try {
      setLoading(true);
      await MakeSaveSharedLinksUseCase(data.id).execute(
        data,
        edit ? 'update' : 'create'
      );
      reset();
      callback();
      setOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function UpdateForm() {
    if (updatePayload) {
      setValue('id', updatePayload.id);
      setValue('shared_link_name', updatePayload.shared_link_name);
      setValue('url', updatePayload.url);
    }
  }

  useEffect(() => {
    if (edit) {
      UpdateForm();
    }
  }, [edit]);

  const submitRef = createRef<HTMLInputElement>();

  return (
    <Dialog
      modal
      maxWidth={400}
      open={isOpen}
      onOpenChange={(state) => {
        !state && reset?.();
        setOpen?.(state);
        setEdit(state);
      }}
      title={edit ? 'Editar Link' : 'Adicionar Link'}
      onSubmit={() => submitRef.current?.click()}
      submitVariant="primary"
      submitText={'Salvar'}
      submitIconName={IconNames.Save20Regular}
      submitIconColor={IconColors.white}
      loading={loading}
      content={
        <form
          onSubmit={handleSubmit((data) => {
            SaveOrUpdateSharedLink({
              id: data.id,
              shared_link_name: firstLetterUpper(data.shared_link_name),
              url: data.url,
            });
          })}
        >
          <Box gap={24} flexDirection="column">
            <Input
              name="shared_link_name"
              label="Nome do site"
              autoFocus
              validations={{ required: true, minLength: 3 }}
              register={register}
              errors={errors}
            />
            <Input
              name="url"
              label="Link"
              placeholder="Ex:. modiv.com.br"
              validations={{
                required: true,
                minLength: 10,
                pattern:
                  /^(?!https?:\/\/)(?:[a-zA-Z\d-]+\.)+[a-zA-Z\d-]{2,}(?:\/[\w\d-]+)*(?:\/?|(?:\?[^\s]*))$/,
              }}
              register={register}
              errors={errors}
            />
            <input type="submit" hidden ref={submitRef} />
          </Box>
        </form>
      }
    />
  );
};

export default ManageModal;
