import { AcceptInviteUseCase } from 'domain/usecases/session/accept-invite';
import { RemoteAcceptInvitedUseCase } from 'data/usecases/sessions/remote-accept-invite';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeAcceptInvite = (): AcceptInviteUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteAcceptInvitedUseCase(
    `${process.env.REACT_APP_API_BASE_URL}/accept-invite`,
    axios,
    toastify
  );
};
