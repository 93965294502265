import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  DeleteCompanyUseCase,
  ResponseDeleteCompany,
} from 'domain/usecases/company/delete-company-use-case';

import { useToken } from 'main/hooks/usetoken';

export class RemoteDeleteCompany implements DeleteCompanyUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(): Promise<ResponseDeleteCompany> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'delete',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Tenant: tenant,
      },
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok: {
        this.toastService.show('Excluído com sucesso!', {
          type: 'success',
        });

        return {
          success: true,
        };
      }
      case HttpStatusCode.unauthorized:
        this.toastService.show('Unauthorized', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');

      default:
        this.toastService.show('Erro inesperado no servidor!', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
