import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { LoginResponseDTO } from 'domain/dtos/login-response-dto';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import { LoginUseCase } from 'domain/usecases/session/login-use-case';

export class RemoteLoginUseCase implements LoginUseCase {
  constructor(
    private readonly httpClient: HttpClient<LoginResponseDTO>,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(email: string, password: string): Promise<LoginResponseDTO> {
    const response = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: {
        email,
        password,
      },
    });

    const data = response.body || {
      token: '',
      tenant_id: '',
    };

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return data;

      case HttpStatusCode.unauthorized:
        if (response.body && response.body.message === 'NO_AUTHORIZED') {
          this.toastService.show('Credenciais incorretas', {
            type: 'error',
          });
          throw new NotAuthorizedError('Unauthorized');
        }
        if (response.body && response.body.message === 'UNACTIVE') {
          this.toastService.show(
            'Conta desativada entre em contato com o suporte',
            {
              type: 'error',
            }
          );
          throw new NotAuthorizedError('Unauthorized');
        } else {
          this.toastService.show('Erro inesperado', {
            type: 'error',
          });
          throw new UnexpectedError('Unexpected Error');
        }

      case HttpStatusCode.conflict:
        if (response.body && response.body.message === 'UNKNOW_USER') {
          this.toastService.show('Email não cadastrado', {
            type: 'error',
          });
          throw new NotAuthorizedError('Unauthorized');
        } else {
          this.toastService.show('Erro inesperado', {
            type: 'error',
          });
          throw new UnexpectedError('Unexpected Error');
        }

      default:
        this.toastService.show('Erro inesperado', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected Error');
    }
  }
}
