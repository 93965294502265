import { RemoteListServices } from 'data/usecases/service/remote-list-service';
import { ListServicesUseCase } from 'domain/usecases/service/list-services-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeListServicesUseCase = (): ListServicesUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteListServices(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/services`,
    toastify
  );
};
