import { RemoteExportContacts } from 'data/usecases/contact/remote-export-contact';
import { ExportContactsUseCase } from 'domain/usecases/contact/export-contact-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeExportContactsFactory = (): ExportContactsUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteExportContacts(
    `${process.env.REACT_APP_API_BASE_URL}/contacts`,
    axios,
    toastify
  );
};
