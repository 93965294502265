export const formatToReal = (value: number | string): string => {
  return (
    'R$' +
    value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
};

export const formatToRealRelative = (num: number): string => {
  if (num >= 1000000) {
    return 'R$' + (num / 1000000).toFixed(2) + 'M';
  } else if (num >= 1000) {
    return 'R$' + (num / 1000).toFixed(2) + 'k';
  } else {
    return 'R$' + num;
  }
};

export const maskReal = (value: string): string => {
  value = value.replace('.', '').replace(',', '').replace(/\D/g, '');

  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat('pt-BR', options).format(
    parseFloat(value) / 100
  );

  return 'R$ ' + result;
};
