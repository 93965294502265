export enum IconNames {
  AddCircle20Regular = 'AddCircle20Regular',
  Delete20Regular = 'Delete20Regular',
  Save20Regular = 'Save20Regular',
}

export enum IconColors {
  white = 'white',
  blue = 'blue',
}

export type SharedLinkPayload = {
  id: number;
  url: string;
  shared_link_name: string;
};

export interface ManageModalType {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  edit: boolean;
  setEdit: (value: boolean) => void;
  updatePayload?: SharedLinkPayload;
  callback: () => void;
}

export interface DeleteDialogType {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  callback: () => void;
  deletePayload?: SharedLinkPayload;
}
