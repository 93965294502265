import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  div > div {
    display: flex;
  }
`;

export const Body = styled.div``;

export const IconTittle = styled.div`
  display: flex;
  padding: 4px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.blue[50]};
`;

export const EmptyState = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 6px;
`;

export const ListWrap = styled.div`
  display: flex;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray[30]};
  border-radius: 8px;
`;
export const GridTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;
export const Tag = styled.div`
  padding: 0.5rem;
  background-color: blue;
  opacity: 0.3;
  border-radius: 1rem;
  display: flex;
  gap: 0.5rem;
  color: #fff;
`;

export const EmptyStateButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`;
