import { RemoteExportdeals } from 'data/usecases/deal/remote-export-deals';
import { ExportDealsUseCase } from 'domain/usecases/deal/export-deal-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeExportDealsFactory = (): ExportDealsUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteExportdeals(
    `${process.env.REACT_APP_API_BASE_URL}/products`,
    axios,
    toastify
  );
};
