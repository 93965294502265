import React, { useContext, useEffect, useState } from 'react';
import { Box, Dialog, Select, Typography } from 'mino-ui';
import ContactListContext from '../../context/ContactListContext';
import { useForm } from 'react-hook-form';
import { BulkActionsContactsRequest } from 'domain/usecases/contact/bulk-actions-contacts-use-case';
import { makeBulkActionsContacts } from 'main/factories/usecases/contact/bulk-actions-contacts-factory';
import { useTags } from 'main/hooks/useTags';

interface BulkOwnerProps {
  ids: string[];
}

export enum IconNames {
  Save20Regular = 'Save20Regular',
}
export enum IconColors {
  white = 'white',
}

const BulkTagsContacts = (props: BulkOwnerProps) => {
  const { ids } = props;
  const { setBulkContactsTags, bulkContactsTags } =
    useContext(ContactListContext);
  const { tags } = useTags();
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, setValue, reset } =
    useForm<BulkActionsContactsRequest>();

  async function bulkActionsContacts(data: BulkActionsContactsRequest) {
    try {
      setLoading(true);
      await makeBulkActionsContacts().execute(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setBulkContactsTags(false);
    }
  }

  useEffect(() => {
    if (ids) {
      setValue('ids', ids);
    }
  }, [ids, setValue]);

  return (
    <Dialog
      title="Adicionar Tags"
      open={bulkContactsTags}
      onOpenChange={() => {
        setBulkContactsTags(false);
        reset();
      }}
      onSubmit={handleSubmit(bulkActionsContacts)}
      submitIconName={IconNames.Save20Regular}
      submitIconColor={IconColors.white}
      loading={loading}
      content={
        <Box flexDirection="column" gap={24}>
          <Typography color="primary" variant="body" fontSize={14}>
            {ids.length} selecionadas
          </Typography>
          <form onSubmit={handleSubmit(bulkActionsContacts)}>
            <Select
              fullWidth
              required
              control={control}
              name="tags"
              label="Selecionar tag"
              placeholder="Selecione uma tag"
              multi
              options={tags.map((tag) => ({
                value: tag.id,
                label: tag.name,
              }))}
            />
          </form>
          <input type="submit" hidden />
        </Box>
      }
    />
  );
};

export default BulkTagsContacts;
