import styled, { css } from 'styled-components';
import { IconColor } from './types';

type ContainerProps = {
  color?: IconColor;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;

  & div {
    display: flex;
    align-items: center;
  }

  & svg {
    ${({ color }) =>
      color === 'blue' &&
      css`
        path {
          fill: ${({ theme }) => theme.colors.blue.primary};
        }
      `}

    ${({ color }) =>
      color === 'green' &&
      css`
        path {
          fill: ${({ theme }) => theme.colors.green.primary};
        }
      `}
    ${({ color }) =>
      color === 'gray' &&
      css`
        path {
          fill: ${({ theme }) => theme.colors.gray[200]};
        }
      `}
      
    ${({ color }) =>
      color === 'red' &&
      css`
        path {
          fill: ${({ theme }) => theme.colors.red.primary};
        }
      `}
      
    ${({ color }) =>
      color === 'white' &&
      css`
        path {
          fill: ${({ theme }) => theme.colors.gray.white};
        }
      `}
    ${({ color }) =>
      color === 'dark' &&
      css`
        path {
          fill: ${({ theme }) => theme.colors.gray[600]};
        }
      `}
    ${({ color }) =>
      color === 'orange' &&
      css`
        path {
          fill: ${({ theme }) => theme.colors.orange.primary};
        }
      `}
    ${({ color }) =>
      color === 'violet' &&
      css`
        path {
          fill: ${({ theme }) => theme.colors.violet.primary};
        }
      `}
      ${({ color }) =>
      color === 'teal' &&
      css`
        path {
          fill: ${({ theme }) => theme.colors.teal.primary};
        }
      `}
      ${({ color }) =>
      color === 'systemGreen' &&
      css`
        path {
          fill: ${({ theme }) => theme.colors.system.success};
        }
      `}
      ${({ color }) =>
      color === 'systemRed' &&
      css`
        path {
          fill: ${({ theme }) => theme.colors.system.error};
        }
      `}
  }
`;
