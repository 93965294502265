import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { UnexpectedError } from 'domain/errors';

import {
  GetFacebookPages,
  IFacebookPage,
} from 'domain/usecases/facebook/save-token-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteGetFacebookPages implements GetFacebookPages {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(): Promise<IFacebookPage[]> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Tenant: tenant,
      },
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return response.body.map((page: IFacebookPage) => ({
          id: page.id,
          name: page.name,
        }));

      default:
        this.toastService.show('Erro ao listar páginas do facebook!', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected Error');
    }
  }
}
