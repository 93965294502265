import { RemoteUpdateCustomField } from 'data/usecases/custom_field/remote-update-custom-fields';
import { UpdateCustomField } from 'domain/usecases/custom_field/update-custom-field';
import { AxiosProvider } from 'infra/http';
import { useToken } from 'main/hooks/usetoken';

export const makeUpdateCustomField = (
  customFieldId: string
): UpdateCustomField => {
  const { tenant = '', token = '' } = useToken();
  const axios = new AxiosProvider({
    tenant,
    token,
  });

  return new RemoteUpdateCustomField(
    `${process.env.REACT_APP_API_BASE_URL}/custom-fields/${customFieldId}`,
    axios
  );
};
