import { RemoteSaveFunnel } from 'data/usecases';
import { SaveFunnelUseCase } from 'domain/usecases/funnel/save-funnels-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeSaveFunnelUseCase = (id?: string): SaveFunnelUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteSaveFunnel(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/funnels${id ? `/${id}` : ''}`,
    toastify
  );
};
