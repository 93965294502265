import { RemoteDeleteServices } from 'data/usecases';
import { DeleteServiceUseCase } from 'domain/usecases/service/delete-service-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeDeleteServiceUseCase = (id: string): DeleteServiceUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteDeleteServices(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/services/${id}`,
    toastify
  );
};
