import styled from 'styled-components';
import * as Checkbox from '@radix-ui/react-checkbox';

export const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Container = styled(Checkbox.Root)`
  width: 16px;
  height: 16px;
  background: ${({ theme }) => theme.colors.gray[30]};
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  &[data-state='indeterminate'],
  &[data-state='checked'] {
    background: ${({ theme }) => theme.colors.blue.primary};
  }

  &[data-disabled] {
    cursor: not-allowed;
  }
`;

export const Indicator = styled(Checkbox.Indicator)`
  > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const LabelWrap = styled.div`
  > span {
    font-size: 12px;
    font-weight: bold;
  }
`;
