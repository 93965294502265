import styled, { css } from 'styled-components';

interface BreadCrumbItemStyledProps {
  active?: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const BreadCrumb = styled.div`
  display: flex;
`;

export const Item = styled.div<BreadCrumbItemStyledProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blue.primary};

  > svg {
    margin: 2px 8px 2px 8px;
  }

  ${({ active }) =>
    active == false &&
    css`
      font-weight: 400;
      color: ${({ theme }) => theme.colors.gray[200]};
    `}
`;

export const Link = styled.span``;

export const Title = styled.span`
  font-size: 0.875rem;
  display: flex;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  & svg {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
`;
