import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

export const GridArea = styled.div`
  width: 100%;
  height: 57px;
  display: grid;
  gap: 1rem;
  align-items: start;
  justify-content: space-between;
  grid-template-columns: calc(70% - 0.5rem) calc(30% - 0.5rem);

  @media (max-width: 960px) {
    grid-template-columns: calc(60% - 0.5rem) calc(40% - 0.5rem);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 100%;
  }
`;
export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Toolbar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.25rem 1.5rem;
  height: 78px;
  background-color: ${({ theme }) => theme.colors.gray.white};
  width: 100%;
  border: solid 1px ${({ theme }) => theme.colors.gray[30]};
  gap: 8px;
  overflow: hidden;
`;

export const ToolbarLeftContent = styled.div`
  position: absolute;
  left: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const ToolbarRightContent = styled.div`
  position: absolute;
  right: 1.5rem;
`;

export const ArrayInfoWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    display: none;
  }
`;
export const CompanyInfoWrap = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.gray[30]};
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
`;
export const Separator = styled.div`
  width: 1px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.gray[30]};
`;

export const FunnelSwitcher = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.gray.white};
  height: 100%;
  border-radius: 8px;
  justify-content: center;
`;

export const DropdownTriggerWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 55px;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.gray.white};

  > div > button {
    background-color: ${({ theme }) => theme.colors.gray.white};
  }
  & button:hover {
    background-color: ${({ theme }) => theme.colors.gray.white};
  }
`;

export const DropdownWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ActionButtonWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
`;

export const EditButtonWrap = styled.div`
  cursor: pointer;
`;
