import { RemoteRemoveSale } from 'data/usecases/sales/remote-remove-sale-use-case';
import { RemoveSaleUseCase } from 'domain/usecases/sales/remove-sale-use-case';
import { makeAxiosHttpClient } from 'main/factories/http';
import { makeToastify } from 'main/factories/notification';

export const makeRemoveSale = (id: string): RemoveSaleUseCase => {
  const axios = makeAxiosHttpClient();
  const toastify = makeToastify();

  const remoteCreateSale = new RemoteRemoveSale(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/sales/${id}`,
    toastify
  );

  return remoteCreateSale;
};
