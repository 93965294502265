import styled, { css } from 'styled-components';
import { variantSize, variantSizeMobile } from './sizes';
import {
  TypographyAlignProps,
  TypographyColorsProps,
  TypographyVariantsProps,
  TypographyWeightProps,
} from './types';

interface TypographyStyledProps {
  variant: TypographyVariantsProps;
  align: TypographyAlignProps;
  color: TypographyColorsProps;
  weight?: TypographyWeightProps;
  noWrap: boolean;
  fontSize?: number;
  lineHeight?: number;
  fill?: boolean;
  ellipsis?: boolean;
  alignMobile?: TypographyAlignProps;
  overflowHidden?: boolean;
  width?: string;
}

function variantHeading(variant: string): string {
  if (variant === 'body') return 'p';

  return variant;
}

function fontSizePxToRem(fontSize: number): string {
  return `${fontSize / 16}rem`;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
`;

export const Heading = styled.h1.attrs<TypographyStyledProps>(
  ({ variant }) => ({
    as: variantHeading(variant),
  })
)<TypographyStyledProps>`
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;

  ${({ variant }) =>
    variant === 'h1' &&
    css`
      font-weight: 800;
    `}

  ${({ fill }) =>
    fill &&
    css`
      width: 100%;
    `}

  ${({ variant }) =>
    variant === ('h2' || 'h3' || 'h4') &&
    css`
      font-weight: 700;
    `}

  ${({ variant }) =>
    variant === 'body' &&
    css`
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 1.375rem;
      color: ${({ theme }) => theme.colors.gray[200]};
    `}

 
  ${({ color }) =>
    color === 'primary' &&
    css`
      color: ${({ theme }) => theme.colors.blue.primary};
    `};

  ${({ color }) =>
    color === 'red' &&
    css`
      color: ${({ theme }) => theme.colors.red.primary};
    `};

  ${({ color }) =>
    color === 'light' &&
    css`
      color: ${({ theme }) => theme.colors.gray.white};
    `};

  ${({ color }) =>
    color === 'gray' &&
    css`
      color: ${({ theme }) => theme.colors.gray[200]};
    `};

  ${({ color }) =>
    color === 'violet' &&
    css`
      color: ${({ theme }) => theme.colors.violet.primary};
    `};

  ${({ color }) =>
    color === 'green' &&
    css`
      color: ${({ theme }) => theme.colors.green.primary};
    `};

  ${({ color }) =>
    color === 'dark' &&
    css`
      color: ${({ theme }) => theme.colors.gray[600]};
    `};

  ${({ color }) =>
    color === 'orange' &&
    css`
      color: ${({ theme }) => theme.colors.orange.primary};
    `};

  text-align: ${({ align }) => align};

  width: ${({ width }) => width};

  ${({ overflowHidden }) =>
    overflowHidden === true &&
    css`
      overflow: hidden;
    `};

  @media (max-width: 768px) {
    text-align: ${({ alignMobile }) => alignMobile};
  }

  ${({ weight }) =>
    weight === 'regular' &&
    css`
      font-weight: 400;
    `};

  ${({ weight }) =>
    weight === 'bold' &&
    css`
      font-weight: 700;
    `};
  ${({ weight }) =>
    weight === 'black' &&
    css`
      font-weight: 800;
    `};

  display: inline-block;
  margin: 0px;
  padding: 0px;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: nowrap;

  ${({ noWrap }) =>
    noWrap === true &&
    css`
      overflow: unset;
      text-overflow: unset;
      white-space: unset;
    `}
  font-size: ${({ variant }) => variantSize(variant)};

  @media (max-width: 768px) {
    font-size: ${({ variant }) => variantSizeMobile(variant)};
  }

  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSizePxToRem(fontSize)};
    `}

  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${fontSizePxToRem(lineHeight)};
    `}
    
  ${({ ellipsis }) =>
    ellipsis &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `}
`;
