import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { ActivitiesResponseDTO } from 'domain/dtos/activity/activity-dto';
import { IActivity } from 'domain/entity/IActivity';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  ActivityFilterParams,
  ListActivitiesUseCase,
} from 'domain/usecases/activity/list-activities-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteListActivities implements ListActivitiesUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(params?: ActivityFilterParams): Promise<ActivitiesResponseDTO> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Tenant: tenant,
      },
      params: params,
    });

    const data = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return {
          total: data.total,
          page: data.page,
          perPage: data.perPage,
          lastPage: data.lastPage,
          activities: data.data.map((activity: IActivity) => ({
            id: activity.id,
            title: activity.title,
            start: activity.start,
            end: activity.end,
            allDay: activity.allDay,
            description: activity.description,
            contact: activity.contact,
            product: activity.product,
            user: activity.user,
            created_at: activity.created_at,
            updated_at: activity.updated_at,
            calling_result: activity.calling_result,
            activity_type: activity.activity_type,
            product_id: activity.product_id,
            contact_id: activity.contact_id,
            company_id: activity.company_id,
            user_id: activity.user_id,
            company: activity.company,
            status: activity.status,
            files: activity.files,
          })),
        };

      case HttpStatusCode.unauthorized:
        this.toastService.show('Unauthorized', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');
      default:
        this.toastService.show('Unexpected error', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
