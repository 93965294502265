import React, { ReactElement, useContext } from 'react';
import { Card, Typography, Spacer, Button, Icon } from 'mino-ui';
import * as S from './styles';
import { ICompany } from 'domain/entity/ICompany';
import { SingleCompanyContext } from 'presentation/pages/Company/contexts/SingleCompanyContext';
import { formatBrazilianDate } from 'main/helpers/formatBrazilianDate';
import { IconColors } from 'presentation/pages/Config/components/customTabs/component';
import { copyContent } from 'main/helpers';

interface CardProps {
  company?: ICompany;
}

const CompanyCard = (props: CardProps): ReactElement => {
  const { setEditCompany, all_custom_fields } =
    useContext(SingleCompanyContext);

  const { company } = props;

  return (
    <S.Container>
      <Card direction="column">
        <S.Header>
          <S.TitleWrap>
            <S.IconTittle>
              <Icon
                iconName="BuildingMultiple24Regular"
                color={IconColors.blue}
              />
            </S.IconTittle>
            <Typography
              variant="body"
              color="dark"
              noWrap
              fontSize={14}
              weight="bold"
            >
              Detalhes da Empresa
            </Typography>
          </S.TitleWrap>
          <Button
            type="button"
            variant="ghost"
            text=""
            size="small"
            icon="Edit20Regular"
            onClick={() => setEditCompany(true)}
          />
        </S.Header>
        <S.Body>
          <Spacer height={16} />
          <S.ListWrap>
            {company?.name && (
              <S.Item>
                <Icon
                  iconName="BuildingMultiple20Regular"
                  color={IconColors.dark}
                />
                <S.ItemWrap>
                  <Typography
                    variant="body"
                    color="dark"
                    fontSize={12}
                    weight="regular"
                    noWrap
                  >
                    <span>Nome: </span>
                    {company?.name}
                  </Typography>
                </S.ItemWrap>
              </S.Item>
            )}

            {company?.email ? (
              <S.Item>
                <Icon iconName="Mail20Regular" color={IconColors.dark} />
                <S.ItemWrap>
                  <Typography
                    variant="body"
                    color="dark"
                    fontSize={12}
                    weight="regular"
                    noWrap
                  >
                    <span>E-mail: </span>
                    {company?.email}
                  </Typography>
                  <button onClick={() => copyContent(`${company?.email}`)}>
                    <Icon iconName="Copy16Regular" color={IconColors.dark} />
                  </button>
                </S.ItemWrap>
              </S.Item>
            ) : (
              ''
            )}

            {company?.phone ? (
              <S.Item>
                <Icon iconName="Call20Regular" color={IconColors.dark} />
                <S.ItemWrap>
                  <Typography
                    variant="body"
                    color="dark"
                    fontSize={12}
                    weight="regular"
                    noWrap
                  >
                    <span>Telefone: </span>
                    {company?.phone}
                  </Typography>
                  <button onClick={() => copyContent(`${company?.phone}`)}>
                    <Icon iconName="Copy16Regular" color={IconColors.dark} />
                  </button>
                </S.ItemWrap>
              </S.Item>
            ) : (
              ''
            )}

            {company?.address && company?.city && company.state ? (
              <S.Item>
                <Icon iconName="Location20Regular" color={IconColors.dark} />
                <S.ItemWrap>
                  <Typography
                    variant="body"
                    color="dark"
                    fontSize={12}
                    weight="regular"
                    noWrap
                  >
                    <span>Endereço: </span>
                    {`${company?.address}, ${company?.city}, ${company?.state}`}
                  </Typography>
                </S.ItemWrap>
              </S.Item>
            ) : (
              ''
            )}

            {company?.cnpj ? (
              <S.Item>
                <Icon iconName="Info20Regular" color={IconColors.dark} />
                <S.ItemWrap>
                  <Typography
                    variant="body"
                    color="dark"
                    fontSize={12}
                    weight="regular"
                    noWrap
                  >
                    <span>CNPJ: </span>
                    {company.cnpj}
                  </Typography>
                </S.ItemWrap>
              </S.Item>
            ) : (
              ''
            )}

            {company?.site ? (
              <S.Item>
                <Icon iconName="Link20Regular" color={IconColors.dark} />
                <S.ItemWrap>
                  <Typography
                    variant="body"
                    color="dark"
                    fontSize={12}
                    weight="regular"
                    noWrap
                  >
                    <span>Site: </span>
                    {company.site}
                  </Typography>
                  <button
                    onClick={() =>
                      window.open(`https://${company.site}`, '_BLANK')
                    }
                  >
                    <Icon iconName="Open16Regular" color={IconColors.dark} />
                  </button>
                </S.ItemWrap>
              </S.Item>
            ) : (
              ''
            )}

            {company?.user && (
              <S.Item>
                <Icon iconName="Person20Regular" color={IconColors.dark} />
                <S.ItemWrap>
                  <Typography
                    variant="body"
                    color="dark"
                    fontSize={12}
                    weight="regular"
                    noWrap
                  >
                    <span>Proprietário: </span>
                    {company?.user.username}
                  </Typography>
                </S.ItemWrap>
              </S.Item>
            )}

            {company?.created_at ? (
              <S.Item>
                <Icon iconName="CalendarLtr20Regular" color={IconColors.dark} />
                <S.ItemWrap>
                  <Typography
                    variant="body"
                    color="dark"
                    fontSize={12}
                    weight="regular"
                    noWrap
                  >
                    <span>Criado em: </span>
                    {formatBrazilianDate({
                      date: new Date(company.created_at),
                    })}
                  </Typography>
                </S.ItemWrap>
              </S.Item>
            ) : (
              ''
            )}

            {company?.description ? (
              <S.Item>
                <Icon iconName="Info20Regular" color={IconColors.dark} />
                <S.ItemWrap>
                  <Typography
                    variant="body"
                    color="dark"
                    fontSize={12}
                    weight="regular"
                    noWrap
                  >
                    <span>Observações: </span>
                    {company.description}
                  </Typography>
                </S.ItemWrap>
              </S.Item>
            ) : (
              ''
            )}

            {company?.custom_fields?.map(
              (field) =>
                field.value && (
                  <S.Item key={field.id}>
                    <Icon iconName="Info20Regular" />
                    <S.ItemWrap>
                      <Typography
                        variant="body"
                        color="dark"
                        fontSize={12}
                        weight="regular"
                        ellipsis
                        noWrap
                        fill
                      >
                        <span>
                          {
                            all_custom_fields.find(
                              (f) => f.id === field.custom_field_id
                            )?.name
                          }
                        </span>{' '}
                        {field.value}
                      </Typography>
                    </S.ItemWrap>
                  </S.Item>
                )
            )}
          </S.ListWrap>
        </S.Body>
      </Card>
    </S.Container>
  );
};
export default CompanyCard;
