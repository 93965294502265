import { useEffect, useState } from 'react';
import { TagResponse } from 'domain/usecases/tag/list-tags-use-case';
import { makeListTagUseCase } from 'main/factories/usecases/tags/list-tags-factory';

type UseTagsType = {
  tags: TagResponse[] | [];
  fetchTags: () => Promise<void>;
  loading: boolean;
};

export const useTags = (): UseTagsType => {
  const [tags, setTags] = useState<TagResponse[] | []>([]);
  const [loading, setLoading] = useState(false);

  async function fetchTags(): Promise<void> {
    try {
      setLoading(true);
      const data = await makeListTagUseCase().execute();
      setTags(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchTags();
  }, []);

  return { tags, fetchTags, loading };
};
