import React, { ReactElement, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import * as T from './types';
import * as S from './styles';
import { isMobile } from 'react-device-detect';

const Calendar = (props: T.CalendarProps): ReactElement => {
  const {
    showWeekends = true,
    events,
    handleEventClick,
    handleSelect,
    view = 'dayGridMonth',
  } = props;

  const [containerWidth, setContainerWidth] = React.useState(0);

  const ContainerRef = React.useRef<HTMLDivElement>(null);

  const handleResize = useCallback(() => {
    if (ContainerRef.current) {
      setContainerWidth(ContainerRef.current.offsetWidth);
    }
  }, [ContainerRef]);

  React.useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <S.Container ref={ContainerRef}>
      <FullCalendar
        slotMinTime={15}
        moreLinkText="atividades"
        initialView={isMobile ? 'timeGridDay' : view}
        locale="pt-br"
        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
        weekends={showWeekends}
        events={events}
        eventClick={handleEventClick}
        editable={false}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        allDayText="Dia inteiro"
        displayEventTime={false}
        weekText="Semana"
        noEventsText="Sem eventos"
        droppable={false}
        dragScroll={false}
        select={handleSelect}
        headerToolbar={{
          left: isMobile ? 'prev,next' : 'prev,next today',
          center: !isMobile && containerWidth > 600 ? 'title' : '',
          right: !isMobile ? 'dayGridMonth,timeGridWeek,timeGridDay' : '',
        }}
        buttonText={{
          day: 'Dia',
          month: 'Mês',
          week: 'Semana',
          today: 'Hoje',
        }}
      />
    </S.Container>
  );
};

export default Calendar;
