import { Box, Button, Card, Typography } from 'mino-ui';
import React from 'react';
import { useNavigate } from 'react-router';
import * as S from './styles';

const NotFound = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <S.Container>
      <Card
        gap={24}
        hasBorder
        direction="column"
        justifyContent="center"
        align="center"
      >
        <Box flexDirection="column" alignItems="center">
          <S.Code>
            <Typography variant="h1" fontSize={84} color="gray">
              404
            </Typography>
          </S.Code>
          <S.Title>
            <Typography variant="h2" color="gray">
              Página não encontrada
            </Typography>
          </S.Title>
        </Box>

        <Button
          size="large"
          text="Voltar para a página inicial"
          onClick={() => navigate('/')}
        />
      </Card>
    </S.Container>
  );
};

export default NotFound;
