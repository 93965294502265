import { ICustomField } from 'domain/entity/ICustomField';

export enum IconNames {
  AddCircle20Regular = 'AddCircle20Regular',
  Delete20Regular = 'Delete20Regular',
  Save20Regular = 'Save20Regular',
}

export interface ManageModalType {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  setUpdatePayload: (updatePayload?: ICustomField) => void;
  updatePayload?: ICustomField;
  callback: () => void;
}

export interface DeleteDialogType {
  id: string;
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  callback: () => void;
}
