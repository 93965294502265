import { RemoteCreateSale } from 'data/usecases/sales/remote-create-sale';
import { CreateSaleUseCase } from 'domain/usecases/sales/create-sale-use-case';
import { makeAxiosHttpClient } from 'main/factories/http';
import { makeToastify } from 'main/factories/notification';

export const makeRemoteCreateSale = (): CreateSaleUseCase => {
  const axios = makeAxiosHttpClient();
  const toastify = makeToastify();

  const remoteCreateSale = new RemoteCreateSale(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/sales`,
    toastify
  );

  return remoteCreateSale;
};
