import React, { ReactElement, useContext } from 'react';
import { Card, Typography, Icon, Button, Tag, Box } from 'mino-ui';
import * as S from './styles';
import { IconColors } from 'presentation/pages/Config/components/customTabs/component';
import { ITag } from 'domain/entity/ITag';
import { SingleCompanyContext } from 'presentation/pages/Company/contexts/SingleCompanyContext';

interface CardProps {
  tags: ITag[];
}
const TagsCard = (props: CardProps): ReactElement => {
  const { tags } = props;
  const { setAddTagsDialog } = useContext(SingleCompanyContext);

  return (
    <S.Container>
      <Card direction="column" padding="1rem" gap={16}>
        <S.Header>
          <S.TitleWrap>
            <S.IconTittle>
              <Icon iconName="Tag24Regular" color={IconColors.blue} />
            </S.IconTittle>
            <Typography
              variant="body"
              color="dark"
              fontSize={14}
              lineHeight={14}
              weight="bold"
            >
              Tags
            </Typography>
          </S.TitleWrap>
          <Button
            type="button"
            variant="ghost"
            text=""
            size="small"
            icon="Edit20Regular"
            onClick={() => setAddTagsDialog(true)}
          />
        </S.Header>
        <S.Body>
          <S.ListWrap>
            {tags.length == 0 && (
              <Box flexDirection="column" gap={16}>
                <S.EmptyState>
                  <Icon iconName="Info20Regular" />
                  <Typography variant="body" noWrap={true} fontSize={12}>
                    Esta Empresa não possui nenhuma Tag adicionada. Clique no
                    botão abaixo para adicionar uma agora.
                  </Typography>
                </S.EmptyState>
              </Box>
            )}
            {tags.length > 0 && (
              <S.GridTags>
                {tags?.map((tag) => (
                  <Tag color={tag.color} name={tag.name} key={tag.id} />
                ))}
              </S.GridTags>
            )}
          </S.ListWrap>
        </S.Body>
        {tags.length == 0 && (
          <>
            <S.EmptyStateButtonWrap>
              <Button
                text="Adicionar Tag"
                variant="ghost"
                icon="AddCircle20Regular"
                iconColor={IconColors.blue}
                onClick={() => setAddTagsDialog(true)}
              />
            </S.EmptyStateButtonWrap>
          </>
        )}
      </Card>
    </S.Container>
  );
};

export default TagsCard;
