import { RemoteSaveChannel } from 'data/usecases/channel/remote-save-channel';
import { SaveChannelUseCase } from 'domain/usecases/channel/create-channel-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeSaveChannelUseCase = (id?: string): SaveChannelUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteSaveChannel(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/channels${id ? `/${id}` : ''}`,
    toastify
  );
};
