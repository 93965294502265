import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Drawer, Input, Select, Spacer, Datepicker } from 'mino-ui';
import * as S from './styles';
import { IDeal } from 'domain/entity/IDeal';
import { UpdateDealRequest } from 'domain/usecases/deal/update-deal-use-case';
import { makeUpdateDealUseCase } from 'main/factories/usecases';
import Icon from '../icons/case.svg';
import { useForm } from 'react-hook-form';
import { useChannels } from 'main/hooks/useChannel';
import { useUsers } from 'main/hooks/useUsers';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { SingleDealContext } from '../../contexts/SingleDealContext';

interface EditDrawerProps {
  isDrawerOpen: boolean;
  setDrawerOpen: (isOpen: boolean) => void;
  deal: IDeal;
  callback: () => void;
  salesValue: number;
}
const EditDealDrawer = (props: EditDrawerProps): ReactElement => {
  const { deal, isDrawerOpen, setDrawerOpen, callback, salesValue } = props;
  const [load, setLoad] = useState(false);
  const { setTransferDataDialog, all_custom_fields } =
    useContext(SingleDealContext);

  const { channels } = useChannels();

  const [userSearch, setUserSearch] = useState('');
  const { users, fetchUsers } = useUsers();

  async function updateDeal(data: UpdateDealRequest) {
    try {
      setLoad(true);
      await makeUpdateDealUseCase(`${deal?.id}`).execute(data);
      callback();
      setDrawerOpen(false);
      if (data.user_id != deal.user_id) {
        openDialogAfterRequest();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoad(false);
    }
  }

  const {
    register,
    formState: { errors },
    control,

    handleSubmit,
    setValue,
  } = useForm<UpdateDealRequest>();

  useEffect(() => {
    setValue('name', deal.name);
    setValue('value', deal.value);
    setValue('payment', deal.payment);
    setValue('channel_id', deal?.channel_id);
    setValue('closing_date', deal?.closing_date || undefined);
    setValue('user_id', deal?.user?.id);

    if (deal.custom_fields?.length) {
      deal.custom_fields.forEach((field) => {
        setValue(`cf_${field.custom_field_id}` as any, field.value);
      });
    }
  }, [deal, setValue]);

  useDebouncedEffect(
    () => {
      if (userSearch.length > 0) {
        fetchUsers(userSearch);
      }
    },
    [userSearch],
    300
  );

  function openDialogAfterRequest() {
    setTimeout(() => {
      setTransferDataDialog(true);
    }, 2000);
  }

  return (
    <Drawer
      width={300}
      isOpen={isDrawerOpen}
      onClose={() => setDrawerOpen(false)}
      title="Editar Negócio"
      icon={Icon}
      submitText="Salvar"
      onSubmit={handleSubmit(updateDeal)}
      secondarySubmitText="cancelar"
      secondarySubmitAction={() => {
        setDrawerOpen(false);
      }}
      loadingSubmit={load}
      loadingSubmitText="Salvando..."
    >
      <form onSubmit={handleSubmit(updateDeal)}>
        <S.EditDrawerFormWrap>
          <Input
            autoFocus
            label="Descrição do negócio"
            placeholder="Ex: Venda de licença"
            type="text"
            name="name"
            register={register}
            errors={errors}
            validations={{
              required: true,
            }}
          />

          <Input
            label="Valor"
            placeholder="Valor do Negócio"
            type="number"
            name="value"
            register={register}
            disabled={salesValue > 0}
          />
          <Select
            fullWidth
            placeholder="Selecione o pagamento"
            label="Pagamento"
            control={control}
            name="payment"
            options={[
              {
                label: 'Único',
                value: 'unique',
              },
              {
                label: 'Recorrente',
                value: 'recurrent',
              },
            ]}
          />
          <S.InputsWrap>
            <Select
              fullWidth
              placeholder="Selecione a origem"
              label="Origem"
              name="channel_id"
              control={control}
              defaultValue={deal.channel_id}
              options={channels.map((c) => ({
                label: c.name,
                value: c.id,
              }))}
            />
          </S.InputsWrap>

          <S.InputsWrap>
            <Select
              fullWidth
              placeholder="Selecione o dono"
              label="Dono"
              name="user_id"
              onInputChange={(v) => setUserSearch(v)}
              control={control}
              defaultValue={deal.user_id}
              options={[
                {
                  value: null,
                  label: 'Sem dono',
                },
                ...users.map((u) => ({
                  label: u.username,
                  value: u.id,
                })),
              ]}
            />
          </S.InputsWrap>

          <S.InputsWrap>
            <Datepicker
              isClearable
              name="closing_date"
              control={control}
              label="Previsão de fechamento"
              placeholder="Selecione a data"
            />
          </S.InputsWrap>

          {all_custom_fields?.map((field) => (
            <S.InputsWrap key={field.id}>
              <Input
                full
                label={field.name}
                placeholder={field.name}
                type="text"
                name={`cf_${field.id}`}
                register={register}
              />
            </S.InputsWrap>
          ))}

          <Spacer height={32} />
        </S.EditDrawerFormWrap>
        <input type="submit" hidden />
      </form>
    </Drawer>
  );
};
export default EditDealDrawer;
