import { RemoteNewDealsReportUseCase } from 'data/usecases/report/new-deals-report';
import { NewDealsReportUseCase } from 'domain/usecases/report/new-deals-report-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeNewDealsReportUseCase = (): NewDealsReportUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteNewDealsReportUseCase(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/reports/new-deals`,
    toastify
  );
};
