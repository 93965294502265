import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import {
  NewDealsReportRequestDTO,
  NewDealsReportResponseDTO,
  NewDealsReportUseCase,
} from 'domain/usecases/report/new-deals-report-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteNewDealsReportUseCase implements NewDealsReportUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(
    params: NewDealsReportRequestDTO
  ): Promise<NewDealsReportResponseDTO> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
        Tenant: tenant,
      },
      params: params,
    });

    const data = response.body;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return data as NewDealsReportResponseDTO;

      case HttpStatusCode.unauthorized:
        this.toastService.show('Não autorizado!', {
          type: 'error',
        });
        throw new NotAuthorizedError('Unauthorized');
      default:
        this.toastService.show('Erro inesperado!', {
          type: 'error',
        });
        throw new UnexpectedError('Unexpected error');
    }
  }
}
