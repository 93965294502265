import React, { useEffect, useState } from 'react';
import { IActivity } from 'domain/entity/IActivity';
import { makeListActivities } from '../usecases';
import ActivityPage from 'presentation/pages/Activity';
import { ActivityFilterParams } from 'domain/usecases/activity/list-activities-use-case';

export const makeActivityPage = (): React.ReactElement => {
  const [activities, setActivities] = useState<IActivity[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<ActivityFilterParams>({});
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  async function fetchData(params: ActivityFilterParams) {
    try {
      setLoading(true);

      const { activities, lastPage, page } = await makeListActivities({
        type: 'all',
      }).execute({ ...params });

      setActivities(activities);
      setPage(page);
      setLastPage(lastPage);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(filter);
    setFilter(filter);
  }, []);

  return (
    <ActivityPage
      page={page}
      lastPage={lastPage}
      setPage={setPage}
      fetchData={fetchData}
      loading={loading}
      activities={activities}
      setActivities={setActivities}
    />
  );
};
