import styled, { css, keyframes } from 'styled-components';

interface DrawerStyledProps {
  side?: 'left' | 'right';
  width?: number;
  secondarySubmitAction?: () => void | undefined;
  submitText?: string;
}

const displayDrawerAnimation = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  display: flex;
  z-index: 12;
`;

export const Drawer = styled.div<DrawerStyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.gray.white};
  border: 1px solid ${({ theme }) => theme.colors.gray[30]};
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  max-width: 383px;
  width: 100%;
  overflow: auto;

  animation: ${displayDrawerAnimation} 0.2s ease-in-out;

  ${({ width }) =>
    width &&
    css`
      max-width: ${width}px;
    `}

  ${({ side }) =>
    side === 'right' &&
    css`
      right: 0;
    `}

  ${({ side }) =>
    side === 'left' &&
    css`
      left: 0;
    `}

  @media (max-width: 564px) {
    max-width: 100%;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.gray.white};
  z-index: 1;
`;

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
export const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0 0.625rem;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.blue[50]};

  > div > div {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 16px;
  }
`;

export const Content = styled.div<DrawerStyledProps>`
  padding: 0 1.5rem 1.5rem 1.5rem;
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 29px;
  color: ${({ theme }) => theme.colors.blue.primary};
  width: 100%;
  white-space: nowrap;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > * {
    padding: 0;
  }
`;

export const ButtonMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  * {
    padding: 0;
  }
  @media (min-width: 564px) {
    display: none;
  }
`;
export const ButtonDesktop = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  * {
    padding: 0;
  }
  @media (max-width: 564px) {
    display: none;
  }
`;

export const Footer = styled.div<DrawerStyledProps>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[30]};
  background-color: ${({ theme }) => theme.colors.gray.white};
  position: sticky;
  bottom: 0;
`;

export const ButtonSpacing = styled.div`
  margin-right: 8px;
`;
