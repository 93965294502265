import React, { useEffect } from 'react';
import { Badge, Icon, Tooltip } from 'mino-ui';
import { RemoveFileButton, UploadFiles, WrapUploadFiles } from '../styles';
import { makeToastify } from 'main/factories/notification';

type UploadFileProps = {
  files?: FileList;
  setFiles: (files?: FileList) => void;
};

export const UploadFile = (props: UploadFileProps) => {
  const { files, setFiles } = props;

  const toast = makeToastify();

  useEffect(() => {
    if (files && files.length > 5) {
      files.length > 5 &&
        toast.show('Limite de arquivos excedido', {
          type: 'error',
        });

      setFiles(undefined);
    }
  }, [files, setFiles, toast]);

  return (
    <WrapUploadFiles>
      <Tooltip
        side="top"
        trigger={
          <UploadFiles>
            <Icon iconName="Attach16Regular" />
            <span>
              {files?.length ? (
                <>
                  {Array.from(files)
                    .map((file) => file.name)
                    .join(', ')}
                  {files.length > 1 ? (
                    <Badge value={`${files.length} + `} />
                  ) : (
                    ''
                  )}
                </>
              ) : (
                'Anexar arquivos'
              )}
            </span>

            <input
              title=""
              name="files"
              multiple
              maxLength={5}
              type="file"
              onChange={(e) => setFiles(e.target.files as FileList)}
            />
          </UploadFiles>
        }
        content="Adicionar arquivos (Max 5. - 10mb)"
      />

      {files?.length ? (
        <RemoveFileButton type="button" onClick={() => setFiles(undefined)}>
          <Icon iconName="Delete16Regular" />
        </RemoveFileButton>
      ) : null}
    </WrapUploadFiles>
  );
};
