import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: max-content;
`;

export const TriggerWrapper = styled.div``;

export const Content = styled.div<{
  minWidth?: number;
  maxHeight?: number;
}>`
  width: max-content;
  min-width: 160px;
  max-width: 311px;
  text-overflow: ellipsis;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.gray.white};
  z-index: 11;

  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight}px;
    `}

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray[50]};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.gray[20]};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  box-shadow: 4px 4px 10px rgba(32, 35, 130, 0.05);

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `}
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray[30]};
`;

export const SearchWrapper = styled.div`
  position: sticky;
  top: 0px;
  left: -8px;
  right: -8px;
  padding: 0.5rem 1rem;
  overflow: hidden;

  background-color: ${({ theme }) => theme.colors.gray.white};
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
`;

export const GroupLabel = styled.span`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.gray[200]};
  text-align: left;
  padding: 0.5rem;
  width: 100%;
`;

export const Item = styled.div<{
  focused?: boolean;
  selected?: boolean;
  disabled?: boolean;
}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  gap: 1rem;
  width: 100%;
  background-color: transparent;
  padding: 10px 1rem;
  transition: background-color 0.1s ease-in-out;

  :hover {
    background-color: ${({ theme }) => theme.colors.gray[20]};
  }

  :active {
    background-color: ${({ theme }) => theme.colors.gray[50]};
  }

  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
    :hover {
      background-color: ${({ theme }) => theme.colors.gray[20]};
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.gray[30]};

      ::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        width: 4px;
        background-color: ${({ theme }) => theme.colors.blue.primary};
      }
    `}
`;

export const ItemText = styled.span<{
  hasIcon: boolean;
}>`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray[600]};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 16px);
  width: calc(100% - 16px);
`;

export const ItemDescription = styled.span`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.gray[200]};
  text-align: left;
`;

export const ItemLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  width: 100%;
`;

export const Trigger = styled.button<{
  selected?: boolean;
}>`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 40px;
  cursor: pointer;
  background: transparent;
  border: none;
  gap: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 0.5rem;

  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.gray[600]};

  border: 1px solid ${({ theme }) => theme.colors.gray[30]};
  border-radius: 0.25rem;

  &[data-state='open'] {
    background-color: ${({ theme }) => theme.colors.gray[30]};
    color: ${({ theme }) => theme.colors.gray[600]};
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.gray[30]};
      color: ${({ theme }) => theme.colors.gray[600]};
    `}

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: calc(100% - 0.1px);
  }
`;
