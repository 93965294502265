import React, { ReactElement } from 'react';
import * as S from './styles';
import * as T from './types';

const Skeleton = (props: T.SkeletonProps): ReactElement => {
  const {
    height,
    lines,
    gap,
    alignItems,
    flexDirection,
    flexDirectionMobile,
    justifyContent,
  } = props;

  const containerStyles = {
    gap,
    alignItems,
    flexDirection,
    flexDirectionMobile,
    justifyContent,
  };

  return (
    <S.Container {...containerStyles}>
      {lines.map((line, index) => (
        <S.Skeleton
          key={index}
          height={line.height || height}
          width={line.widthPercent}
        />
      ))}
    </S.Container>
  );
};

export default Skeleton;
