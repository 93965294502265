import { useEffect, useState } from 'react';
import { FunnelResponse } from 'domain/usecases/funnel/list-funnels-use-case';
import { makeListFunnelsUseCase } from 'main/factories/usecases';

type UseFunnelsType = {
  funnels: FunnelResponse[] | [];
  fetchFunnels: () => Promise<void>;
  loading: boolean;
};

export const useFunnels = (): UseFunnelsType => {
  const [funnels, setFunnels] = useState<FunnelResponse[] | []>([]);
  const [loading, setLoading] = useState(false);

  async function fetchFunnels(): Promise<void> {
    try {
      setLoading(true);
      const data = await makeListFunnelsUseCase().execute();
      setFunnels(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchFunnels();
  }, []);

  return { funnels, fetchFunnels, loading };
};
