import React, { ReactElement, useContext, useEffect } from 'react';
import { Box, Spinner, Typography } from 'mino-ui';
import { makeToastify } from 'main/factories/notification';
import axios from 'axios';
import { useToken } from 'main/hooks/usetoken';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';

export const RdCallBackPage = (): ReactElement => {
  const { session } = useContext(SessionContext);

  useEffect(() => {
    async function callback() {
      const param = new URLSearchParams(window.location.search);
      const code = param.get('code');

      if (session) {
        try {
          await axios
            .post(process.env.REACT_APP_API_BASE_URL + '/rd/get-token', {
              code: code,
            })
            .then(async (response: any) => {
              await axios.post(
                process.env.REACT_APP_API_BASE_URL + '/token-rd',
                {
                  access_token: response.data.access_token,
                  refresh_token: response.data.refresh_token,
                },
                {
                  headers: {
                    Authorization: `Bearer ${useToken().token}`,
                    Tenant: session?.tenant.id as string,
                  },
                }
              );
            });

          window.location.href = '/config?active=integrations&app=20';
        } catch (error) {
          console.error(error);
          makeToastify().show('Algo deu errado na integração!', {
            type: 'error',
          });
        }
      }
    }

    callback();
  }, [session]);

  return (
    <Box
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      gap={24}
      boxMain
    >
      <Spinner size="large" />
      <Typography variant="body" fontSize={18} color="primary">
        Aguarde... Estamos integrando sua conta!
      </Typography>
    </Box>
  );
};
