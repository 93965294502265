import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  Dropdown,
  InputSearch,
  PopOver,
  Spacer,
  Typography,
} from 'mino-ui';
import * as S from './styles';
import ContactListContext from './context/ContactListContext';
import { IContact } from 'domain/entity/IContact';
import { RequestParamsListContacts } from 'domain/usecases/contact/list-contact-use-case';
import DrawerFilterContact from './filterContact';
import { ContactListTable } from './datatable';
import { makeExportContactsFactory } from 'main/factories/usecases/contact/export-contact-factory';
import { ImportContacts } from './import-contacts';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import {
  ContactsResourcesPermissions,
  hasContactsPermissions,
} from 'presentation/hooks/usePermissions';
import EmptyStateContact from './components/EmptyState/component';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import NotFoundStateContact from './components/NotFoundState/component';
import AddContactDialog from 'presentation/shared/templates/Contacts/addContactDialog';

type PageProps = {
  contacts: IContact[];
  loading: boolean;
  fetchContacts: (params: RequestParamsListContacts) => void;
  total: number;
};

export enum IconColors {
  blue = 'blue',
  white = 'white',
}

const ContactList = (props: PageProps): ReactElement => {
  const { session } = useContext(SessionContext);

  const { contacts, total, loading, fetchContacts } = props;

  const [addContact, setAddContact] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [loadingExport, setLoadingExport] = useState(false);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [bulkContacts, setBulkContacts] = useState(false);
  const [bulkContactsTags, setBulkContactsTags] = useState(false);

  const [searchInput, setSearchInput] = useState('initial');

  const watchAllContactsPermission = (): boolean =>
    hasContactsPermissions(session, ContactsResourcesPermissions.WATCH_ALL);

  const [filter, setFilter] = useState<RequestParamsListContacts>({
    user: watchAllContactsPermission() ? 'all' : 'my',
    search: '',
  });

  useDebouncedEffect(
    () => {
      if (searchInput !== 'initial')
        setFilter({
          ...filter,
          search: searchInput,
        });
    },
    [searchInput],
    500
  );

  useEffect(() => {
    fetchContacts(filter);
  }, [fetchContacts, filter]);

  const initialFilter: RequestParamsListContacts = {
    user: watchAllContactsPermission() ? 'all' : 'my',
    search: '',
    sortColumn: 'name',
    sortDirection: 'asc',
  };

  function handlePageChange(pageNumber: number) {
    setPage(pageNumber);
    setFilter({
      ...filter,
      page: pageNumber,
    });
  }

  const [exportDialogOpen, setExportDialogOpen] = useState(false);

  async function handleExportContacts(): Promise<void> {
    try {
      setLoadingExport(true);

      const res = await makeExportContactsFactory().execute({
        ...filter,
        export: true,
      });

      if (res.success) {
        const url = process.env.REACT_APP_API_BASE_URL + res.url;
        window.open(url, '_blank');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingExport(false);
      setExportDialogOpen(false);
    }
  }

  interface GroupListProps {
    label: string;
    value: string;
    icon: string;
  }

  const GroupListArray: GroupListProps[] = [];

  if (hasContactsPermissions(session, ContactsResourcesPermissions.EXPORT)) {
    GroupListArray.push(
      {
        label: 'Importar',
        value: 'import',
        icon: 'ArrowExportUp20Regular',
      },
      {
        label: loadingExport ? 'Exportando' : 'Exportar',
        value: 'export',
        icon: 'ArrowDownload20Regular',
      }
    );
  }

  return (
    <ContactListContext.Provider
      value={{
        addContact,
        setAddContact,
        filterOpen,
        setFilterOpen,
        filter,
        setFilter,
        initialFilter,
        activePage: page,
        totalItemsCount: total,
        handlePageChange,
        importDialogOpen,
        setImportDialogOpen,
        bulkContacts,
        setBulkContacts,
        bulkContactsTags,
        setBulkContactsTags,
      }}
    >
      <S.Toolbar>
        <S.LeftBox>
          <Button
            icon="AddCircle20Regular"
            iconColor={IconColors.white}
            text="Contato"
            onClick={() => setAddContact(true)}
            hiddenTextOnMobile
            variant="primary"
            type="button"
          />
        </S.LeftBox>

        <Box alignItems="center" width="100%" justifyContent="center">
          <S.WrapInputSearch>
            <InputSearch
              full
              placeholder="Busque por contato"
              name="search"
              type="text"
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </S.WrapInputSearch>
        </Box>

        <S.RightBox>
          <Button
            text="Filtros"
            hiddenTextOnMobile
            iconPosition="left"
            icon="Filter20Filled"
            iconColor={IconColors.blue}
            variant="ghost"
            onClick={() => setFilterOpen(true)}
          />

          <Dropdown
            trigger={
              <Button
                variant="ghost"
                text=""
                size="small"
                icon="MoreVertical20Regular"
              />
            }
            align="end"
            items={GroupListArray}
            onChange={(value) => {
              if (value === 'import') {
                setImportDialogOpen(true);
              }
              if (value === 'export') {
                setExportDialogOpen(true);
              }
            }}
          />
        </S.RightBox>
      </S.Toolbar>
      <Box boxMain>
        <Spacer height={16} />
        {JSON.stringify(initialFilter) === JSON.stringify(filter) &&
        contacts.length === 0 &&
        !loading ? (
          <EmptyStateContact />
        ) : JSON.stringify(initialFilter) !== JSON.stringify(filter) &&
          contacts.length === 0 &&
          !loading ? (
          <NotFoundStateContact />
        ) : (
          <ContactListTable loading={loading} contacts={contacts} />
        )}

        <AddContactDialog
          context="contact"
          isDialogOpen={addContact}
          setDialogOpen={setAddContact}
          singleId=""
        />

        <ImportContacts
          isDialogOpen={importDialogOpen}
          setDialogOpen={setImportDialogOpen}
          callback={() => {
            fetchContacts(filter);
          }}
        />

        <DrawerFilterContact />
      </Box>

      <Dialog
        title="Exportar contatos"
        open={exportDialogOpen}
        onOpenChange={setExportDialogOpen}
        onSubmit={handleExportContacts}
        submitText="Exportar"
        loading={loadingExport}
        content={
          <Box gap={24} flexDirection="column">
            <Typography variant="body" color="gray" fontSize={14}>
              Exportar <strong>{total}</strong> contatos
            </Typography>
            <Typography color="gray" variant="body">
              Confirme para exportar os contatos com o filtro atual
            </Typography>
          </Box>
        }
      />
    </ContactListContext.Provider>
  );
};

export default ContactList;
