import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { makeListActivities } from 'main/factories/usecases';
import { Box, Button, Dropdown, Icon, Spacer, Typography } from 'mino-ui';
import * as S from './styles';
import { IActivity } from 'domain/entity/IActivity';
import {
  ActivityFilterParams,
  ActivityTypes,
} from 'domain/usecases/activity/list-activities-use-case';
import { translateActivityType } from 'main/helpers/translate-activity-type';
import { ActivityTemplateProps } from './types';
import { ActivityContext } from '../../../../pages/Activity/context/ActivityContext';
import { CreateActivityRequest } from 'domain/usecases/activity/create-activities-use-case';
import { makeSaveActivityUseCase } from 'main/factories/usecases/activity/create-activity-factory';
import ScheduleActivityDialog from '../dialogs/ScheduleActivityDialog';
import { ActivityTypeResponseDTO } from 'domain/dtos/activity/activity-dto';
import ActivityTabs from '../../ActivityTabs';
import ActivityCard from '../../ActivityCard';
import { makeSaveFileUseCase } from 'main/factories/usecases/file/save-file-factory';
import { IconColors } from 'presentation/pages/Config/components/customTabs/component';
import { ActiviesSingleListSkeleton } from '../../Skeletons/ActiviesSingleListSkeleton';

type ActivitiesPerDay = {
  activities: IActivity[];
  date: string;
};

const ActivitiesTemplate = (props: ActivityTemplateProps): ReactElement => {
  const { context, contextId, emails, phoneNumbers } = props;
  const [activities, setActivities] = useState<IActivity[]>();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [load, setLoad] = useState(false);
  const [loadingActivityList, setLoadingActivityList] = useState(false);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [filters, setFilters] = useState<ActivityFilterParams>();

  const [files, setFiles] = useState<FileList>();

  const [loadingMore, setLoadingMore] = useState(false);

  const [activitiesPerDay, setActivitiesPerDay] =
    useState<ActivitiesPerDay[]>();

  const [scheduleActivityType, setScheduleActivityType] =
    useState<ActivityTypeResponseDTO>('Ligação');

  async function handleSaveFile(activityId: string) {
    try {
      const filesArray = Array.from(files || []);

      await Promise.all(
        filesArray.map(async (file) => {
          await makeSaveFileUseCase(activityId).execute({
            file,
          });
        })
      );

      setFiles(undefined);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleSubmit(
    payLoad: CreateActivityRequest,
    preventToast?: boolean
  ) {
    try {
      setLoad(true);

      const response = await makeSaveActivityUseCase(
        undefined,
        preventToast
      ).execute({
        product_id: context === 'deal' ? contextId : undefined,
        contact_id: context === 'contact' ? contextId : undefined,
        company_id: context === 'company' ? contextId : undefined,
        ...payLoad,
      });

      if (files) {
        await handleSaveFile(response.id?.toString());
      }

      fetchActivities();
    } catch (error) {
      console.log(error);
    } finally {
      setLoad(false);
    }
  }

  async function fetchActivities(): Promise<void> {
    try {
      setLoadingActivityList(true);
      const {
        activities,
        page: dataPage,
        lastPage: dataLastPage,
        total,
      } = await makeListActivities({
        type: context,
        [`${context}Id`]: contextId,
      }).execute(filters);

      setTotal(total);
      setPage(dataPage);
      setLastPage(dataLastPage);
      setActivities(activities);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingActivityList(false);
    }
  }

  useEffect(() => {
    fetchActivities();
  }, [filters]);

  async function handleMoreButton(): Promise<void> {
    try {
      setLoadingMore(true);
      if (activities) {
        const { activities: newActivities, page: dataPage } =
          await makeListActivities({
            type: context,
            [`${context}Id`]: contextId,
          }).execute({
            page: page + 1,
          });

        setActivities([...activities, ...newActivities]);
        setPage(dataPage);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMore(false);
    }
  }

  const mapActivitiesPerDay = useCallback(() => {
    if (activities) {
      const activitiesPerDay = activities.reduce(
        (acc: ActivitiesPerDay[], activity: IActivity) => {
          const index = acc.findIndex(
            (activityPerDay) =>
              activityPerDay.date ===
              new Date(activity.created_at).toDateString()
          );

          if (index > -1) {
            acc[index].activities.push(activity);
          } else {
            acc.push({
              activities: [activity],
              date: new Date(activity.created_at).toDateString(),
            });
          }

          return acc;
        },
        []
      );

      setActivitiesPerDay(activitiesPerDay);
    }
  }, [activities, setActivitiesPerDay]);

  useEffect(() => {
    mapActivitiesPerDay();
  }, [mapActivitiesPerDay]);

  return (
    <ActivityContext.Provider
      value={{
        handleSubmit,
        load,
        setLoad,
        openSchedule,
        setOpenSchedule,
        context,
        contextId,
        scheduleActivityType,
        setScheduleActivityType,
        emails,
        phoneNumbers,
        files,
        setFiles,
      }}
    >
      <ActivityTabs />

      <S.ActivitiesWrapper>
        <S.ActivitiesHeader>
          <Box gap={8}>
            <Icon iconName="History24Regular" color={IconColors.dark} />
            <Typography
              variant="body"
              fill
              color="dark"
              fontSize={16}
              weight="bold"
            >
              Histórico de atividades
            </Typography>
          </Box>

          <Box gap={8}>
            <ScheduleActivityDialog
              open={openSchedule}
              setOpen={setOpenSchedule}
              callback={() => {
                fetchActivities();
                setOpenSchedule(false);
              }}
              trigger={<div style={{ display: 'none' }} />}
              initialType={scheduleActivityType}
              hideContext
              initialContext={context}
              initialContextId={contextId}
            />
            <S.ActivitiesHeaderRightWrap>
              <Dropdown
                onChange={(v) =>
                  Array.isArray(v) &&
                  setFilters({
                    ...filters,
                    activity_type: v.map((v) => v as ActivityTypes),
                  })
                }
                placeholder="Tipo de Atividade"
                multi
                items={[
                  {
                    value: 'Ligação',
                    label: 'Ligação',
                  },
                  {
                    value: 'Mensagem',
                    label: 'Mensagem',
                  },
                  {
                    value: 'E-mail',
                    label: 'E-mail',
                  },
                  {
                    value: 'Proposta',
                    label: 'Proposta',
                  },
                  {
                    value: 'Reunião',
                    label: 'Reunião',
                  },
                  {
                    value: 'Anotação',
                    label: 'Anotação',
                  },
                ]}
              />

              <S.ActivitiesCount>
                <Typography
                  variant="body"
                  color="dark"
                  fontSize={14}
                  weight="regular"
                >
                  {total} atividade(s)
                </Typography>
              </S.ActivitiesCount>
            </S.ActivitiesHeaderRightWrap>
          </Box>
        </S.ActivitiesHeader>

        {loadingActivityList ? (
          <ActiviesSingleListSkeleton />
        ) : (
          activitiesPerDay?.map(({ activities, date }, index) => {
            const dateString = new Date(date).toLocaleDateString('pt-BR', {
              weekday: 'long',
              day: 'numeric',
              month: 'long',
            });

            const dayOnly = dateString
              ?.split(',')[0]
              .substring(0, 3)
              .toUpperCase();
            const dayNumber = dateString?.split(',')[1].split(' ')[1];
            const monthOnly =
              dateString
                ?.split(',')[1]
                .split(' ')[3]
                .substring(0, 1)
                .toUpperCase() +
              dateString?.split(',')[1].split(' ')[3].substring(1, 3);

            return (
              <Box key={index} flexDirection="column">
                <S.DailyContent key={index}>
                  <S.ActivityCardDate>
                    <span>{dayOnly}</span>
                    <span>{dayNumber}</span>
                    <span>{monthOnly}</span>
                  </S.ActivityCardDate>

                  <Box flexDirection="column" width="100%" gap={16}>
                    {activities?.map((act) => (
                      <ActivityCard
                        key={act.id}
                        title={act.title}
                        type={translateActivityType(act.activity_type)}
                        date={act.start?.toString()}
                        time={act.start?.toString()}
                        content={act.description}
                        userName={act.user?.username}
                        avatar={act.user?.avatar}
                        files={act.files}
                      />
                    ))}
                  </Box>
                </S.DailyContent>
                <S.Divider />
              </Box>
            );
          })
        )}

        {!loadingActivityList && lastPage > page && (
          <S.LoadButtonWrap>
            <Button
              onClick={handleMoreButton}
              loading={loadingMore}
              text="carregar mais"
              variant="ghost"
            />
          </S.LoadButtonWrap>
        )}
      </S.ActivitiesWrapper>

      <Spacer height={24} heightMobile={0} />
    </ActivityContext.Provider>
  );
};

export default ActivitiesTemplate;
