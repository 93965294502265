import styled, { css } from 'styled-components';

interface InputStyledProps {
  error?: string | any;
  full?: boolean;
  height?: number;
}

export const Container = styled.div<InputStyledProps>`
  > p {
    margin-top: 0.5rem;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${({ theme }) => theme.colors.system.error};
  }

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}
`;

export const Input = styled.input<InputStyledProps>`
  background: ${({ theme }) => theme.colors.gray[20]};
  border: 1px solid ${({ theme }) => theme.colors.gray[20]};
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  color: ${({ theme }) => theme.colors.gray[600]};
  padding: 0.75rem 1rem;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 19px;
  max-width: 311px;
  width: 100%;
  font-family: 'Noto Sans', sans-serif;

  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}

  :focus {
    border: solid 1px ${({ theme }) => theme.colors.blue[100]};
    background: ${({ theme }) => theme.colors.blue[50]};
  }

  :active {
    background: ${({ theme }) => theme.colors.blue[50]};
    border: solid 1px ${({ theme }) => theme.colors.blue[100]};
  }
  :hover {
    border: solid 1px ${({ theme }) => theme.colors.blue[100]};
  }

  :disabled {
    background: ${({ theme }) => theme.colors.gray[20]};
    border: solid 1px ${({ theme }) => theme.colors.gray[20]};
    color: ${({ theme }) => theme.colors.gray[50]};
    cursor: not-allowed;
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.gray[50]};
    font-size: 0.75rem;
  }

  ${({ error }) =>
    error &&
    error !== '' &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.system.error};

      :focus {
        border-color: ${({ theme }) => theme.colors.system.error};
      }
    `}

  ${({ full }) =>
    full &&
    css`
      max-width: 100%;
      width: 100%;
    `}
`;
