import { RemoteCreateCustomFields } from 'data/usecases/custom_field/remote-create-custom-fields';
import { CreateCustomField } from 'domain/usecases/custom_field/create-custom-field';
import { AxiosProvider } from 'infra/http';
import { useToken } from 'main/hooks/usetoken';

export const makeCreateCustomField = (): CreateCustomField => {
  const { tenant = '', token = '' } = useToken();
  const axios = new AxiosProvider({
    tenant,
    token,
  });

  return new RemoteCreateCustomFields(
    `${process.env.REACT_APP_API_BASE_URL}/custom-fields`,
    axios
  );
};
