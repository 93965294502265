import React, { useContext, useEffect, useState } from 'react';
import CompaniesList from 'presentation/pages/Company/list';
import { makeListCompaniesUseCase } from '../usecases/company/list-companies-factory';
import { CompanyRequestParams } from 'domain/usecases/company/list-companies-use-case';
import { ICompany } from 'domain/entity/ICompany';
import {
  ContactsResourcesPermissions,
  hasContactsPermissions,
} from 'presentation/hooks/usePermissions';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';

export const makeCompaniesListPage = (): React.ReactElement => {
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [total, setTotal] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<CompanyRequestParams>({
    page: 1,
  });

  const { session } = useContext(SessionContext);
  const permissions = hasContactsPermissions(
    session,
    ContactsResourcesPermissions.WATCH_ALL
  );

  async function fetchCompanies(params?: CompanyRequestParams): Promise<void> {
    try {
      setLoading(true);
      const { data, total } = await makeListCompaniesUseCase().execute({
        ...params,
        user: permissions ? 'all' : 'my',
      });
      setCompanies(data);
      setTotal(total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCompanies(filters);
    setFilters(filters);
  }, []);

  return (
    <>
      <CompaniesList
        fetchCompanies={fetchCompanies}
        loading={loading}
        companies={companies}
        total={total}
      />
    </>
  );
};
