import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { ISharedLinks } from 'domain/entity/ISharedLinks';
import {
  NotAuthorizedError,
  UnexpectedError,
  badRequestError,
} from 'domain/errors';
import {
  RequestPayloadSharedLink,
  SaveSharedLinksUseCase,
} from 'domain/usecases/SharedLink/save-sharedLink-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteSaveSharedLinks implements SaveSharedLinksUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(
    payload: RequestPayloadSharedLink,
    type?: 'create' | 'update' | undefined
  ): Promise<ISharedLinks> {
    const { tenant, token } = useToken();

    const response = await this.httpClient.request({
      method: type === 'update' ? 'put' : 'post',
      url: this.url,
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
        tenant: tenant,
      },
      body: payload,
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        this.toastService.show('Salvo com sucesso!', { type: 'success' });
        return {
          id: response.body.id,
          shared_link_name: response.body.shared_link_name,
          url: response.body.url,
        };

      case HttpStatusCode.unauthorized:
        this.toastService.show('Não autorizado', { type: 'error' });
        throw new NotAuthorizedError('Unauthorized');

      case HttpStatusCode.badRequest:
        this.toastService.show('Preencha todos os campos obrigatórios', {
          type: 'error',
        });
        throw new badRequestError('Preencha todos os campos obrigatórios');

      default:
        this.toastService.show('Unexpected Error', { type: 'error' });
        throw new UnexpectedError('Unexpected Error');
    }
  }
}
