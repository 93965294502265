import styled, { css } from 'styled-components';

interface BottomBarPropsStyle {
  hasText?: boolean;
  active?: boolean;
}

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.gray.white};

  @media (min-width: 769px) {
    display: none;
  }
`;

export const BottomBar = styled.div<BottomBarPropsStyle>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: auto;
  padding: 0px 8px;
  gap: 16px;
  box-shadow: 0px -4px 50px rgba(0, 0, 0, 0.1);

  ${({ hasText }) =>
    hasText === true &&
    css`
      height: 64px;
    `}
`;

export const Button = styled.button<BottomBarPropsStyle>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48px;
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.gray[200]};
  background-color: transparent;
  text-align: center;

  ${({ hasText }) =>
    hasText === true &&
    css`
      gap: 4px;
      & svg {
        width: 16px;
        height: 16px;
      }
    `}

  ${({ active }) =>
    active === true &&
    css`
      color: ${({ theme }) => theme.colors.blue.primary};
      & svg {
        path {
          stroke: ${({ theme }) => theme.colors.blue.primary};
        }
      }
    `}
`;
