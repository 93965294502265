import { createGlobalStyle } from 'styled-components';

interface DefaultTheme {
  theme: {
    colors: {
      [key: string]: string;
    };
  };
}

export default createGlobalStyle<DefaultTheme>`
  * {
    border: 0;
    margin: 0;
    padding: 0;
    font-size: 100%;
    box-sizing: border-box;
    font-family: 'Noto Sans', sans-serif;

  }

  html, body, #root {
    height: 100%;
   
  }

  code {
    background-color: #e6eafd;
    border-radius: 4px;
    padding: 4px;
  }

  *, button, input {
    border: 0;
    outline: 0;
  }

  body {
    font-family: 'Noto Sans', sans-serif;
    pointer-events: auto !important;
  }

  button{
    font-family: 'Noto Sans', sans-serif;
  }

  img {
    max-width: 100% !important;
  }

  a{
    text-decoration: none;
    color: #0052cc;
    font-weight: 500;
    line-height: 1.2;
    :focus{
      border-radius: 1px;
      outline: 1px solid #6b86f6 !important;
      background-color: #e6eafd;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .page-item{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    color: #666666;
    border-radius: 4px;
    transition: 0.3s ease-in-out;

    :hover{
      & a  {
        background-color: #bfbfbf;
      }
    }
    
  }

  .page-link{
    font-family: 'Noto Sans';
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    transition: 0.3s ease-in-out;

  }

  .active-page{
    background-color: #E6EEFA;
    & a  {
      color: #0032FD;
      border:  1px solid #6b86f6 ;
    }
  }

  .force-align-center{
      display: flex;
      align-items: center;

    & * {
      display: flex;
      align-items: center;
    }
  } 

`;
