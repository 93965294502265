import { ICompany } from 'domain/entity/ICompany';
import SingleCompanyPage from 'presentation/pages/Company/single';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeGetSingleCompanyUseCase } from '../usecases/company/get-company-factory';

export const makeSingleCompanyPage = (): React.ReactElement => {
  const [company, setCompany] = useState<ICompany>({} as ICompany);

  const [loading, setLoading] = useState(false);

  const { id = '' } = useParams();

  async function fetchCompany(): Promise<void> {
    try {
      setLoading(true);
      const company = await makeGetSingleCompanyUseCase(id).execute();
      setCompany(company);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCompany();
  }, []);

  return (
    <SingleCompanyPage
      callback={fetchCompany}
      company={company}
      loading={loading}
    />
  );
};
