import { RemoteListFunnels } from 'data/usecases';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeListFunnelsUseCase = (): RemoteListFunnels => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteListFunnels(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/funnels`,
    toastify
  );
};
