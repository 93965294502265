import { useEffect, useState } from 'react';
import { TeamResponse } from 'domain/usecases/team/list-teams-use-case';
import { makeListTeamsUseCase } from 'main/factories/usecases/team/list-teams-factory';

type UseTeamsType = {
  teams: TeamResponse[] | [];
  fetchTeams: () => Promise<void>;
  loading: boolean;
};

export const useTeams = (): UseTeamsType => {
  const [teams, setTeams] = useState<TeamResponse[] | []>([]);
  const [loading, setLoading] = useState(false);

  async function fetchTeams(): Promise<void> {
    try {
      setLoading(true);
      const data = await makeListTeamsUseCase().execute();
      setTeams(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchTeams();
  }, []);

  return { teams, fetchTeams, loading };
};
