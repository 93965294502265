import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { ISession } from 'domain/entity/ISession';
import { NotAuthorizedError, UnexpectedError } from 'domain/errors';
import { SessionUseCase } from 'domain/usecases/session/session-use-case';

export class RemoteGetSessionUseCase implements SessionUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(): Promise<ISession> {
    const response = await this.httpClient.request({
      url: this.url,
      method: 'post',
    });

    const sessionDate = response.body as ISession;

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return sessionDate as ISession;

      case HttpStatusCode.unauthorized:
        this.toastService.show('Não autorizado!', {
          type: 'error',
        });
        throw new NotAuthorizedError('Não autorizado!');

      default:
        this.toastService.show('Algo deu errado ao obter sua sessão.', {
          type: 'error',
        });
        throw new UnexpectedError('Algo deu errado ao obter sua sessão.');
    }
  }
}
