import React, { ReactElement, useState } from 'react';
import * as S from './style';
import { ContactRequestDTO } from 'domain/dtos/contact/contact-request-dto';
import {
  makeSaveCompanyUseCase,
  makeUpdateDealUseCase,
} from 'main/factories/usecases';
import { useForm } from 'react-hook-form';
import { Dialog, Input, Select, Spacer } from 'mino-ui';
import { BrazilianStates } from 'config/consts/brazilian-states';
import { useNavigate } from 'react-router';
import { makeUpdateContactUseCase } from 'main/factories/usecases/contact/update-contact-factory';
import { useCustomFields } from 'main/hooks/useCustomFields';

interface AddCompanyDialogProps {
  isDialogOpen: boolean;
  setDialogOpen: (val: boolean) => void;
  context: 'contact' | 'deal' | 'company';
  singleId: string;
  callback?: () => void;
  trigger?: ReactElement;
}

const AddCompanyDialog = (props: AddCompanyDialogProps): ReactElement => {
  const { isDialogOpen, setDialogOpen, callback, singleId, context, trigger } =
    props;
  const [load, setLoad] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm<ContactRequestDTO>();

  async function createCompany(data: ContactRequestDTO) {
    try {
      setLoad(true);

      if (context === 'company') {
        const { id } = await makeSaveCompanyUseCase().execute(data);

        if (id) {
          navigate(`/companies/${id}`);
        }
      }

      if (context === 'deal') {
        const { id } = await makeSaveCompanyUseCase().execute(data);
        await makeUpdateDealUseCase(singleId).execute({
          attach_companies: [id],
        });
      }

      if (context === 'contact') {
        const { id } = await makeSaveCompanyUseCase().execute(data);
        await makeUpdateContactUseCase(singleId).execute({ company_id: id });
      }

      reset();
      callback?.();
      setDialogOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoad(false);
    }
  }

  const { custom_fields } = useCustomFields('company');

  return (
    <Dialog
      maxWidth={520}
      title="Nova Empresa"
      loading={load}
      open={isDialogOpen}
      onOpenChange={(state) => {
        !state && reset?.();
        setDialogOpen?.(state);
      }}
      trigger={trigger}
      onSubmit={handleSubmit(createCompany)}
      content={
        <form onSubmit={handleSubmit(createCompany)}>
          <Input
            autoFocus
            type="text"
            full
            name="name"
            label="Nome"
            placeholder="Nome da empresa"
            register={register}
            errors={errors}
            validations={{
              required: true,
            }}
          />
          <Spacer height={16} />
          <Input
            full
            type="text"
            name="email"
            label="Email"
            placeholder="E-mail da empresa"
            register={register}
            errors={errors}
            validations={{
              pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            }}
          />

          <Spacer height={16} />
          <Input
            full
            type="text"
            name="phone"
            label="Telefone"
            placeholder="Telefone da empresa"
            register={register}
            errors={errors}
          />

          <Spacer height={16} />
          <Input
            full
            type="text"
            name="site"
            label="Site"
            placeholder="Site da empresa"
            register={register}
            errors={errors}
          />

          <Spacer height={16} />
          <Input
            full
            type="text"
            name="cnpj"
            label="CNPJ"
            placeholder="CNPJ da empresa"
            register={register}
            errors={errors}
          />

          <Spacer height={16} />
          <Input
            full
            type="text"
            name="address"
            label="Endereço"
            placeholder="Rua A, 125"
            register={register}
            errors={errors}
          />

          <Spacer height={16} />

          <Input
            full
            type="text"
            name="city"
            label="Cidade"
            placeholder="Cidade da empresa"
            register={register}
            errors={errors}
          />

          <Spacer height={16} />

          <Select
            fullWidth
            name="state"
            control={control}
            placeholder="Selecione o estado"
            label="Estado"
            options={BrazilianStates.map((es) => ({
              label: es.nome,
              value: es.sigla,
            }))}
          />

          <Spacer height={16} />

          <Input
            full
            type="text"
            name="description"
            label="Observações"
            placeholder="Observações do contato"
            register={register}
            errors={errors}
          />

          {custom_fields.map((field) => (
            <>
              <Spacer height={16} />
              <Input
                full
                key={field.id}
                register={register}
                type={'text'}
                placeholder={field.name}
                name={`cf_${field.id}`}
                label={field.name}
                errors={errors}
              />
            </>
          ))}
          <Spacer height={16} />

          <input type="submit" hidden />
        </form>
      }
    />
  );
};

export default AddCompanyDialog;
