import React, { useEffect } from 'react';
import * as S from './styles';
import LogoMobile from 'presentation/assets/icons/ModivLogoDarkTextRigth.svg';
import Logo from 'presentation/assets/icons/ModivWhiteLogoTextRigth.svg';
import Browsers from 'presentation/assets/images/browsers.png';
import { SendMailForm } from './send-mail-form';
import { ResetPasswordForm } from './reset-password-form';
import { ReactSVG } from 'react-svg';

const ForgotPasswordPage = (): JSX.Element => {
  const [view, setView] = React.useState<'send-mail' | 'reset-password'>(
    'send-mail'
  );
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');

  useEffect(() => {
    if (token) {
      setView('reset-password');
    }
  }, [token, setView]);

  return (
    <S.Container>
      <S.TextColumn>
        <ReactSVG src={Logo} />
        <span>Modiv CRM v.1.3.0-beta.2</span>
      </S.TextColumn>

      <S.WrapperForm>
        <S.LogoMobile>
          <ReactSVG src={LogoMobile} />
        </S.LogoMobile>

        <S.StyledForm>
          {view === 'send-mail' ? <SendMailForm /> : <ResetPasswordForm />}
        </S.StyledForm>

        <S.BrowserWrap>
          <span>Navegadores suportados</span>
          <img src={Browsers} />
        </S.BrowserWrap>
      </S.WrapperForm>
    </S.Container>
  );
};

export default ForgotPasswordPage;
