import styled from 'styled-components';

export const RankingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray[20]};
  color: ${({ theme }) => theme.colors.gray[200]};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 700;
`;

export const BarsWraper = styled.div<{ maxWidth: number }>`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth}px;
  margin: auto;

  > canvas {
    width: 100% !important;
    height: auto !important;
  }
`;
