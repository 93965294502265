import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 2rem;
  padding: 0;
  border: 0px solid transparent;
`;

export const RadioItem = styled.input`
  display: flex;
  appearance: none;
  height: 16px;
  width: 16px;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  cursor: pointer;

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.gray[600]};
  }

  :focus {
    border: 1px solid ${({ theme }) => theme.colors.blue[500]};
  }

  &:checked {
    border: 1px solid ${({ theme }) => theme.colors.blue.primary};

    :hover {
      border: 1px solid ${({ theme }) => theme.colors.blue[500]};
    }

    :focus {
      border: 1px solid ${({ theme }) => theme.colors.blue[500]};
    }

    :after {
      content: '';
      position: absolute;
      top: 27px;
      left: 19px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.blue.primary};
    }

    :hover:after {
      background-color: ${({ theme }) => theme.colors.blue[500]};
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      border-color: ${({ theme }) => theme.colors.gray[50]};
      cursor: no-drop;

      :hover {
        border-color: ${({ theme }) => theme.colors.gray[50]};
      }
    `}
`;

export const RadioLabel = styled.label<{ disabled: boolean }>`
  color: ${({ theme }) => theme.colors.gray[600]};
  font-size: 0.75rem;
  line-height: 150%;
  cursor: pointer;
  padding-left: 0.25rem;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.gray[50]};
      cursor: no-drop;
    `}
`;
