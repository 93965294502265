import styled, { css } from 'styled-components';
import { EditorProvider } from '@tiptap/react';

export const EditorProviderr = styled(EditorProvider)`
  width: 100%;
  background-color: red;
  height: 300px;
`;

export const Container = styled.div<{ isFocused: boolean }>`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray[20]};
  overflow: hidden;

  :hover {
    outline: 1px solid ${({ theme }) => theme.colors.gray[50]};
  }

  :active {
    outline: 1px solid ${({ theme }) => theme.colors.blue.primary};
  }

  ${({ isFocused }) =>
    isFocused &&
    css`
      outline: 1px solid ${({ theme }) => theme.colors.blue.primary};

      :hover {
        outline: 1px solid ${({ theme }) => theme.colors.blue.primary};
      }
    `}

  .ProseMirror {
    background-color: transparent;
    width: 100%;
    min-height: 80px;
    padding: 8px;
    font-size: 0.75rem;
  }

  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.17em;
  }

  p {
    font-size: 0.75rem;
  }

  ul,
  ol {
    margin-left: 1rem;
  }

  ol > li {
    list-style-type: decimal !important;
  }
  ul > li {
    list-style-type: disc !important;
  }
`;
