import React from 'react';
import { Box, Icon, Typography } from 'mino-ui';
import ColumnChart from '../icons/ColumnChart.svg';

interface EmptyStateProps {
  title: string;
  text: string;
  iconColor: IconColors;
}

enum IconColors {
  blue = 'blue',
  dark = 'dark',
  systemRed = 'systemRed',
  systemGreen = 'systemGreen',
  violet = 'violet',
}

export const EmptyState = (props: EmptyStateProps) => {
  const { title, text, iconColor } = props;

  return (
    <Box flexDirection="column" alignItems="center" gap={16}>
      <Icon color={iconColor} src={ColumnChart} />
      <Box width="350px" flexDirection="column" alignItems="center" gap={12}>
        <Typography
          noWrap
          variant="h3"
          align="center"
          weight="bold"
          fontSize={18}
          width="100%"
        >
          {title}
        </Typography>
        <Typography
          variant="h4"
          align="center"
          weight="regular"
          fontSize={14}
          noWrap
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};
