import { ICompany } from 'domain/entity/ICompany';
import { ICustomField } from 'domain/entity/ICustomField';
import { createContext } from 'react';

export type SingleCompanyContextProps = {
  editCompanyOpen: boolean;
  setEditCompany: (edit: boolean) => void;
  addDealDrawer: boolean;
  setAddDealDrawer: (boolean) => void;
  contactDrawer: boolean;
  setContactDrawer: (boolean) => void;
  addContactDrawerOpen: boolean;
  setAddContactDrawerOpen: (boolean) => void;
  addTagsDialog: boolean;
  setAddTagsDialog: (boolean) => void;
  company: ICompany;
  all_custom_fields: ICustomField[];
};
export const SingleCompanyContext = createContext(
  {} as SingleCompanyContextProps
);
