import styled, { css } from 'styled-components';

interface DrawerProps {
  isOpen: boolean;
  footer?: boolean;
  hidden?: boolean;
  position?: 'relative' | 'sticky';
}

export const Container = styled.header<DrawerProps>`
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray.white};
  max-width: 208px;
  padding: 1rem 0.5rem;
  width: 100%;
  height: 100%;
  transition: ${({ theme }) => theme.animation.default};
  overflow: hidden;
  z-index: 1;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      max-width: 68px;
    `}

  @media (max-width: 768px) {
    display: none;
  }

  ${({ position }) =>
    position === 'relative' &&
    css`
      position: relative;
    `}
`;

export const Toggle = styled.div<DrawerProps>`
  height: 20px;
  padding: 0rem 0.5rem;
  color: ${({ theme }) => theme.colors.gray[200]};

  @media (max-width: 960px) {
    cursor: not-allowed;
    & svg {
      cursor: not-allowed !important;
    }
  }
`;

export const Top = styled.div<DrawerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 120px;
  width: 100%;
  gap: 2rem;

  & svg {
    cursor: pointer;
  }

  ${({ isOpen }) =>
    !isOpen &&
    css`
      align-items: center;
    `}
`;

export const BodyContent = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[30]};
  padding: 0.5rem 0;
`;

export const LogoWrapper = styled.div<DrawerProps>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 1.5rem;
  transition: ${({ theme }) => theme.animation.default};

  ${({ isOpen }) =>
    !isOpen &&
    css`
      padding: 0;
      justify-content: center;
    `}
`;

export const Logo = styled.div<DrawerProps>`
  opacity: 1;

  & svg {
    width: 144px;
    height: 32px;
  }

  ${({ footer }) =>
    footer &&
    css`
      & svg {
        width: 12px;
        height: 12px;
      }
    `}
`;

export const Links = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  color: ${({ theme }) => theme.colors.gray.black};
  line-height: 19px;
  font-size: 0.875rem;
  width: 100%;
  height: 100%;
`;

export const Link = styled.li<{ active?: boolean }>`
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0px 16px;
  border-radius: 8px;
  position: relative;

  > button {
    width: 100%;
  }

  ${({ active }) =>
    active
      ? css`
          background: ${({ theme }) => theme.colors.blue[50]};

          ::after {
            content: '';
            background-color: ${({ theme }) => theme.colors.blue.primary};
            width: 3px;
            height: 60%;
            border-radius: 4px;
            position: absolute;
            left: 0px;
          }
          & a {
            color: ${({ theme }) => theme.colors.blue.primary};
          }
          > button {
            color: ${({ theme }) => theme.colors.blue.primary};
            > div > SVG {
              path {
                fill: ${({ theme }) => theme.colors.blue.primary};
              }
            }
          }
        `
      : css`
          :hover {
            background-color: ${({ theme }) => theme.colors.gray[20]};

            ::after {
              content: '';
              background-color: ${({ theme }) => theme.colors.gray[50]};
              width: 3px;
              height: 60%;
              border-radius: 4px;
              position: absolute;
              left: 0px;
            }
          }
        `}
`;

export const ButtonLink = styled.button<{ active?: boolean }>`
  display: flex;
  cursor: pointer;
  background: transparent;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray[200]};
  font-weight: 700;
  text-decoration: none;
  font-size: 0.875rem;
  width: 100%;
  white-space: nowrap;
  height: 40px;
  gap: 0.5rem;

  > svg {
    min-width: 20px;
  }

  ${({ active }) =>
    active &&
    css`
      display: none;
    `}
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 30%;
  height: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[30]};
  width: 100%;
  padding: 0.5rem 0 0;
`;

export const SharedLinks = styled.div`
  > li > div {
    width: 100%;
  }

  & button > div > div {
    border: none;
    width: 100%;
  }
`;

export const FooterLinks = styled.ul<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.gray.black};
  line-height: 19px;
  font-size: 1rem;
  width: 100%;
  height: 100%;
  gap: 0.25rem;
`;

export const Info = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 1.5rem;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      padding: 0;
      justify-content: center;
    `}
`;

export const InfoText = styled.span<{ isOpen: boolean }>`
  font-size: 0.5rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[200]};
  white-space: nowrap;
  opacity: 1;
  transition: opacity 5s;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      display: none;
      opacity: 0;
      visibility: hidden;
      transition: opacity 5s;
    `}
`;
