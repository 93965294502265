import { RemoteListActivities } from 'data/usecases';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export type ActivityType = 'company' | 'contact' | 'deal' | 'all';

export type ListActivitiesParams = {
  type: ActivityType;
  dealId?: string;
  contactId?: string;
  companyId?: string;
};

export const makeListActivities = (
  params: ListActivitiesParams
): RemoteListActivities => {
  const { type, companyId, dealId, contactId } = params;

  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  const dealActivitiesUrl = `product-activities/${dealId}`;
  const contactActivitiesUrl = `contact-activities/${contactId}`;
  const companyActivitiesUrl = `company-activities/${companyId}`;

  function getActivityTypeUrl(type: ActivityType): string {
    switch (type) {
      case 'company':
        return companyActivitiesUrl;
      case 'contact':
        return contactActivitiesUrl;
      case 'deal':
        return dealActivitiesUrl;
      default:
        return 'activities';
    }
  }

  return new RemoteListActivities(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/${getActivityTypeUrl(type)}`,
    toastify
  );
};
