import { RemoteDeleteLoss } from 'data/usecases/loss/remote-delete-loss';
import { DeleteLossUseCase } from 'domain/usecases/loss/delete-loss-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeDeleteLossUseCase = (id: string): DeleteLossUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteDeleteLoss(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/losses/${id}`,
    toastify
  );
};
