import { AvatarColorVariants } from './types';

export function getColorByName(name: string): AvatarColorVariants {
  const initialLetter = name.charAt(0);

  switch (initialLetter.toLowerCase()) {
    case 'a':
    case 'b':
    case 'c':
    case 'd':
    case 'e':
    case '1':
    case '2':
      return 'violet';

    case 'f':
    case 'g':
    case 'h':
    case 'i':
    case 'j':
    case '3':
    case '4':
      return 'teal';

    case 'k':
    case 'l':
    case 'm':
    case 'n':
    case 'o':
    case '5':
    case '6':
      return 'red';

    case 'p':
    case 'q':
    case 'r':
    case 's':
    case 't':
    case '7':
    case '8':
      return 'green';

    case 'u':
    case 'v':
    case 'w':
    case 'y':
    case '9':
      return 'orange';

    default:
      return 'blue';
  }
}
