import { RemoteSaveCompany } from 'data/usecases/company/remote-save-company';
import { SaveCompanyUseCase } from 'domain/usecases/company/save-company-use-case';
import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeSaveCompanyUseCase = (id?: string): SaveCompanyUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteSaveCompany(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/companies${id ? `/${id}` : ''}`,
    toastify
  );
};
