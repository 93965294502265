import { useEffect, useState } from 'react';
import { IService } from 'domain/entity/IService';
import { makeListServicesUseCase } from 'main/factories/usecases';

type UseServicesType = {
  services: IService[] | [];
  fetchServices: (search?: string) => Promise<void>;
  loading: boolean;
};

export const useServices = (): UseServicesType => {
  const [services, setServices] = useState<IService[]>([]);
  const [loading, setLoading] = useState(false);

  async function fetchServices(search?: string): Promise<void> {
    try {
      setLoading(true);
      const { data } = await makeListServicesUseCase().execute({
        search,
      });
      setServices(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchServices();
  }, []);

  return { services, fetchServices, loading };
};
