export const moneyMask = (value: string) => {
  const onlyNumbersCharacters = value.replace(/[^0-9]/g, '').replace(/^0+/, '');

  if (onlyNumbersCharacters === '' || typeof value === 'undefined') {
    return 'R$ 0,00';
  }

  if (value.length > 1 && (value.includes(',') || value.includes('.'))) {
    const startCentsPart = onlyNumbersCharacters.padStart(2, '0');

    const centPart = startCentsPart.slice(-2);
    const realPart = startCentsPart.slice(0, -2) || '0';

    const finalResult =
      realPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + `,${centPart}`;
    const maskedResult = 'R$ ' + finalResult;

    return maskedResult;
  } else {
    const formattedNumber = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(value));

    return formattedNumber;
  }
};

export const formatPriceForBackend = (price: string): number => {
  const formattedPrice = price.slice(3).replace('.', '').replace(',', '.');

  return Number(formattedPrice);
};
