import { ConfigResourcesPermissions } from 'presentation/hooks/usePermissions';

export const configPermissionsVerify = [
  ConfigResourcesPermissions.CHANNELS,
  ConfigResourcesPermissions.FUNNELS,
  ConfigResourcesPermissions.GROUPS,
  ConfigResourcesPermissions.INTEGRATIONS,
  ConfigResourcesPermissions.LOSSES,
  ConfigResourcesPermissions.PAGES,
  ConfigResourcesPermissions.SERVICES,
  ConfigResourcesPermissions.TAGS,
  ConfigResourcesPermissions.TEAMS,
  ConfigResourcesPermissions.USERS,
  ConfigResourcesPermissions.LINKS,
];
