import styled from 'styled-components';

export const Subtitle = styled.h4`
  font-size: 0.875rem;
  font-weight: 700;
`;

export const WrapFields = styled.div`
  padding: 1rem 0;
`;

export const WrapContent = styled.div``;

export const Divider = styled.div`
  width: 100%;
  border-top: solid 1px ${({ theme }) => theme.colors.gray[30]};
  padding-bottom: 1rem;
`;
