import styled, { css } from 'styled-components';
import * as Tabs from '@radix-ui/react-tabs';

export const Container = styled(Tabs.Root)`
  display: flex;
  flex-direction: column;

  &[data-orientation='vertical'] {
    flex-direction: row;
  }

  gap: 1rem;
  width: 100%;
`;

export const List = styled(Tabs.List)<{ gap?: number }>`
  display: flex;
  gap: ${({ gap }) => gap || 8}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[30]};

  &[data-orientation='vertical'] {
    flex-direction: column;
  }
`;

export const Trigger = styled(Tabs.Trigger)<{ className?: string }>`
  position: relative;
  :active {
    background-color: ${({ theme }) => theme.colors.gray[20]};
  }

  :focus {
    background-color: ${({ theme }) => theme.colors.gray[20]};
  }

  :active {
    & svg {
      transform: scale(0.85);
      transition: transform 0.125s linear;
    }
  }

  ${({ className }) =>
    className?.includes('active') &&
    css`
      :after {
        content: '';
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 2px;
        background: ${({ theme }) => theme.colors.blue.primary};
      }
    `}
`;

export const TabButton = styled.button<{ className?: string }>`
  font-family: 'Noto Sans', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.md};
  font-weight: 700;
  height: 40px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.gray[200]};
  cursor: pointer;
  transition: ${({ theme }) => theme.animation.default};
  font-size: 0.875rem;
  line-height: 1rem;
  white-space: nowrap;
  gap: ${({ theme }) => theme.spacing.sm};

  & svg {
    path {
      fill: ${({ theme }) => theme.colors.gray[200]};
    }
  }

  ${({ className }) =>
    className?.includes('active') &&
    css`
      color: ${({ theme }) => theme.colors.blue.primary};
      & svg {
        path {
          fill: ${({ theme }) => theme.colors.blue.primary};
        }
      }
    `}
  > div {
    height: 16px;
  }
`;

export const ButtonText = styled.span<{ className?: string }>`
  @media (max-width: ${({ theme }) => theme.breakpoints.xlg}) {
    ${({ className }) =>
      className?.includes('h-text') &&
      css`
        display: none;
      `}
  }
`;

export const WrapTrigger = styled.div`
  display: flex;
  align-items: center;
`;
export const Content = styled(Tabs.Content)`
  width: 100%;
`;
