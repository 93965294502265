import React, { ReactElement, useContext } from 'react';
import {
  Card,
  Typography,
  Spacer,
  Accordion,
  Box,
  Button,
  Icon,
} from 'mino-ui';
import * as S from '../ContactCard/styles';
import { IContact } from 'domain/entity/IContact';
import { SingleDealContext } from 'presentation/pages/Deal/contexts/SingleDealContext';
import { formatBrazilianDate, copyContent } from 'main/helpers';
import { IconColors } from 'presentation/pages/Config/components/customTabs/component';
import { useNavigate } from 'react-router';

interface CardProps {
  contacts: IContact[];
}
const ContactsCard = (props: CardProps): ReactElement => {
  const { setAddContactDialogOpen, setContactDrawerOpen } =
    useContext(SingleDealContext);
  const navigate = useNavigate();
  const { contacts } = props;

  return (
    <S.Container>
      <Card direction="column" padding="1rem 1rem 0">
        <S.Header>
          <S.TitleWrap>
            <S.IconTittle>
              <Icon iconName="BookContacts24Regular" color={IconColors.blue} />
            </S.IconTittle>
            <Typography
              variant="body"
              color="dark"
              fontSize={14}
              weight="bold"
              noWrap
            >
              Contatos Relacionados
            </Typography>
          </S.TitleWrap>
          <Button
            type="button"
            variant="ghost"
            text=""
            size="small"
            icon="Edit20Regular"
            onClick={() => setContactDrawerOpen(true)}
          />
        </S.Header>
        <S.Body>
          {contacts.length === 0 && (
            <Box flexDirection="column" gap={16}>
              <S.EmptyState>
                <Icon iconName="Info20Regular" />
                <Typography variant="body" noWrap={true} fontSize={12}>
                  Este Negócio não possui nenhum Contato vinculado. Clique no
                  botão abaixo para vincular um agora.
                </Typography>
              </S.EmptyState>
              <Spacer />
            </Box>
          )}

          <S.ListWrap>
            <Accordion
              type="single"
              border
              hasDivider
              defaultValue={contacts.length ? contacts[0].name : ''}
              items={contacts.map((contact) => ({
                value: contact.name,
                trigger: (
                  <S.Trigger>
                    <Typography
                      lineHeight={12}
                      variant="body"
                      color="primary"
                      fontSize={12}
                      weight="bold"
                      noWrap
                    >
                      {contact.name}
                    </Typography>
                    <button onClick={() => navigate(`/contacts/${contact.id}`)}>
                      <Icon iconName="Open16Regular" color={IconColors.blue} />
                    </button>
                  </S.Trigger>
                ),
                content: (
                  <S.ItensWrapper>
                    {contact.phone && (
                      <S.Item>
                        <Icon
                          iconName="Call20Regular"
                          color={IconColors.dark}
                        />
                        <Box gap={4}>
                          <Typography
                            variant="body"
                            color="gray"
                            fontSize={12}
                            weight="regular"
                            noWrap
                          >
                            <span>Telefone: </span> {contact.phone}
                          </Typography>
                          <button
                            onClick={() => copyContent(`${contact.phone}`)}
                          >
                            <Icon
                              iconName="Copy16Regular"
                              color={IconColors.dark}
                            />
                          </button>
                        </Box>
                      </S.Item>
                    )}

                    {contact.email && (
                      <S.Item>
                        <Icon
                          iconName="Mail20Regular"
                          color={IconColors.dark}
                        />
                        <Box gap={4}>
                          <Typography
                            variant="body"
                            color="gray"
                            fontSize={12}
                            weight="regular"
                            noWrap
                          >
                            <span>E-mail: </span> {contact.email}
                          </Typography>
                          <button
                            onClick={() => copyContent(`${contact.email}`)}
                          >
                            <Icon
                              iconName="Copy16Regular"
                              color={IconColors.dark}
                            />
                          </button>
                        </Box>
                      </S.Item>
                    )}

                    {contact.address && contact.city && contact.state && (
                      <S.Item>
                        <Icon
                          iconName="Location20Regular"
                          color={IconColors.dark}
                        />
                        <Box>
                          <Typography
                            variant="body"
                            color="gray"
                            fontSize={12}
                            weight="regular"
                            noWrap
                          >
                            <span>Endereço: </span>
                            {`${contact.address}, ${contact.city}, ${contact.state}`}
                          </Typography>
                        </Box>
                      </S.Item>
                    )}

                    <S.Item>
                      <Icon
                        iconName="CalendarLtr20Regular"
                        color={IconColors.dark}
                      />
                      <Box>
                        <Typography
                          variant="body"
                          color="gray"
                          fontSize={12}
                          weight="regular"
                          noWrap
                        >
                          <span>Criado em: </span>
                          {contact.created_at &&
                            formatBrazilianDate({
                              date: new Date(contact.created_at),
                            })}
                        </Typography>
                      </Box>
                    </S.Item>
                  </S.ItensWrapper>
                ),
              }))}
            />
          </S.ListWrap>
          <S.ButtonWrap>
            <Button
              variant="ghost"
              text="Vincular"
              icon="LinkSquare20Regular"
              iconColor={IconColors.blue}
              onClick={() => setContactDrawerOpen(true)}
            />
            <Button
              variant="ghostSecondary"
              text="Novo contato"
              icon="AddCircle20Regular"
              iconColor={IconColors.dark}
              onClick={() => setAddContactDialogOpen(true)}
            />
          </S.ButtonWrap>
        </S.Body>
      </Card>
    </S.Container>
  );
};

export default ContactsCard;
