import React from 'react';
import * as S from './styles';

export interface SpacerProps {
  height?: number;
  heightMobile?: number;
  width?: number;
  widthMobile?: number;
}

const Spacer: React.FC<SpacerProps> = ({
  height,
  heightMobile,
  width,
  widthMobile,
}) => {
  return (
    <S.Spacer
      width={width}
      widthMobile={widthMobile}
      height={height}
      heightMobile={heightMobile}
    />
  );
};

export default Spacer;
