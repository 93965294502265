import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { ActivityTypeResponseDTO } from 'domain/dtos/activity/activity-dto';
import {
  Box,
  Datepicker,
  Dialog,
  Dropdown,
  Input,
  Label,
  RichText,
  Select,
  ToggleGroup,
} from 'mino-ui';
import { useForm } from 'react-hook-form';
import { makeSaveActivityUseCase } from 'main/factories/usecases/activity/create-activity-factory';
import { activityTypesOptions, durationOptions } from './options';
import { SessionContext } from 'presentation/layout/contexts/SessionContext';
import { IActivity } from 'domain/entity/IActivity';
import { DealResponseDTO } from 'domain/dtos/deal/deal-response-dto';
import { useDebouncedEffect } from 'presentation/hooks/useDebouncedEffect';
import { makeListDealsUseCase } from 'main/factories/usecases';
import { useContacts } from 'main/hooks/useContacts';
import { useCompanies } from 'main/hooks/useCompanies';
import { UploadFile } from '../../ActivityTabs/atoms/UploadFile';
import { makeSaveFileUseCase } from 'main/factories/usecases/file/save-file-factory';
import { RichTextWrap } from './styles';

export type ScheduleActivityForm = Props['activity'] & {
  start: Date;
  end?: Date;
  allDay?: boolean;
  title: string;
  on_google?: boolean;
  activity_type: ActivityTypeResponseDTO;
  duration?: string;
  description?: string;
};

type Props = {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  trigger: ReactElement;
  callback(): void;
  hideContext?: boolean;
  activity?: Omit<
    IActivity,
    | 'created_at'
    | 'updated_at'
    | 'user_id'
    | 'contact'
    | 'company'
    | 'product'
    | 'user'
    | 'user_id'
  >;
  initialType?: ActivityTypeResponseDTO;
  initialContext?: 'deal' | 'contact' | 'company';
  initialContextId?: string;
  initialStart?: Date;
  initialEnd?: Date;
};

const ScheduleActivityDialog = (props: Props): ReactElement => {
  const {
    open,
    setOpen,
    activity,
    callback,
    trigger,
    initialContext,
    initialContextId,
    initialType,
    hideContext,
    initialStart,
    initialEnd,
  } = props;

  const {
    session: {
      integrations: { google },
    },
  } = useContext(SessionContext);

  const [context, setContext] = useState<'deal' | 'contact' | 'company'>(
    'deal'
  );
  const [loading, setLoading] = useState(false);
  const [searchDeal, setSearchDeal] = useState<string>('');
  const [searchContact, setSearchContact] = useState<string>('');
  const [searchCompany, setSearchCompany] = useState<string>('');

  const [deals, setDeals] = useState<DealResponseDTO>();
  const { fetchContacts, contacts } = useContacts();
  const { fetchCompanies, companies } = useCompanies();
  const [files, setFiles] = useState<FileList>();

  const [descriptionEditor, setDescriptionEditor] = useState('');

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
  } = useForm<ScheduleActivityForm>({
    defaultValues: {
      activity_type: initialType || activity?.activity_type || 'Ligação',
      start: initialStart || activity?.start || new Date(),
      on_google: google.active,
      duration: '30',
    },
  });

  async function fetchDeals(search: string) {
    try {
      const response = await makeListDealsUseCase().execute({
        result: undefined,
        type: 'all',
        funnel_id: '',
        search,
        page: 1,
      });

      setDeals(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSaveFiles(activityId: string) {
    try {
      const filesArray = Array.from(files || []);

      await Promise.all(
        filesArray.map(async (file) => {
          await makeSaveFileUseCase(activityId).execute({
            file,
          });
        })
      );

      setFiles(undefined);
    } catch (error) {
      console.error(error);
    }
  }

  async function saveActivitySchedule(data: ScheduleActivityForm) {
    try {
      setLoading(true);

      const endDate = new Date(
        new Date(data.start).getTime() + Number(data.duration) * 60000
      );

      const response = await makeSaveActivityUseCase(data?.id).execute(
        {
          title: data.title,
          activity_type: data.activity_type,
          start: data.start,
          end: endDate,
          allDay: data.allDay,
          status: false,
          description: descriptionEditor,
          product_id:
            initialContext === 'deal' && initialContextId
              ? initialContextId
              : data?.product_id?.toString() || undefined,
          contact_id:
            initialContext === 'contact' && initialContextId
              ? initialContextId
              : data?.contact_id?.toString() || undefined,
          company_id:
            initialContext === 'company' && initialContextId
              ? initialContextId
              : data?.company_id?.toString() || undefined,
        },
        data.id ? 'update' : 'create'
      );

      if (files && files.length) {
        await handleSaveFiles(response.id);
      }

      callback();
      reset();
      setDescriptionEditor('');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useDebouncedEffect(
    () => {
      fetchDeals(searchDeal);
    },
    [searchDeal],
    300
  );

  useDebouncedEffect(
    () => {
      fetchContacts(searchContact);
    },
    [searchContact],
    300
  );

  useDebouncedEffect(
    () => {
      fetchCompanies(searchCompany);
    },
    [searchCompany],
    300
  );

  useEffect(() => {
    if (initialStart) {
      setValue('start', initialStart);
    }
    if (initialEnd) {
      setValue('end', initialEnd);
    }
  }, [initialStart, initialEnd, setValue]);

  useEffect(() => {
    if (activity) {
      setValue('id', activity.id);
      setValue('allDay', activity.allDay);
      setValue('start', activity.start);
      setValue('end', activity.end);
      setValue('title', activity.title);
      setValue('contact_id', activity.contact_id);
      setValue('company_id', activity.company_id);
      setValue('product_id', activity.product_id);
      setValue('activity_type', activity.activity_type);
      setValue('description', activity.description);
      return;
    }

    reset();
  }, [activity, reset, setValue]);

  useEffect(() => {
    if (initialType) {
      setValue('activity_type', initialType);
    }
  }, [initialType, setValue]);

  useEffect(() => {
    function getInitialDurationByStartAndEnd() {
      if (activity?.start && activity?.end) {
        const start = new Date(activity.start);
        const end = new Date(activity.end);

        const duration = Math.floor((end.getTime() - start.getTime()) / 60000);
        setValue('duration', duration.toString());
        return;
      }

      setValue('duration', '30');
    }

    getInitialDurationByStartAndEnd();
  }, [activity, setValue]);

  return (
    <Dialog
      open={open}
      loading={loading}
      maxWidth={520}
      trigger={trigger}
      onOpenChange={(open) => {
        !open && reset();
        setOpen?.(open);
      }}
      onSubmit={handleSubmit(saveActivitySchedule)}
      title="Nova tarefa"
      content={
        <form onSubmit={handleSubmit(saveActivitySchedule)}>
          <Box gap={16} flexDirection="column">
            {/*  {google.active &&
            !activity?.product_id &&
            !activity?.contact_id &&
            !activity?.company_id ? (
              <Box alignItems="center">
                <Checkbox
                  control={control}
                  label="Vincular Google Calendar"
                  name="on_google"
                />
              </Box>
            ) : null} */}

            <Box>
              <Dropdown
                label="Tipo da atividade"
                placeholder="Tipo da atividade"
                items={activityTypesOptions}
                initialSelectedItem={{
                  label: initialType || activity?.activity_type || 'Ligação',
                  value: initialType || activity?.activity_type || 'Ligação',
                }}
                onChange={(value) =>
                  typeof value === 'string' &&
                  setValue('activity_type', value as ActivityTypeResponseDTO)
                }
              />
            </Box>

            {!hideContext &&
            !activity?.product_id &&
            !activity?.contact_id &&
            !activity?.company_id ? (
              <Box flexDirection="column" gap={8}>
                <Box flexDirection="column">
                  <Label text="Contexto" />
                  <ToggleGroup
                    value={context}
                    onValueChange={(value) =>
                      typeof value === 'string'
                        ? setContext(value as typeof context)
                        : undefined
                    }
                    type="single"
                    size="small"
                    defaultValue="contact"
                    items={[
                      {
                        value: 'contact',
                        label: 'Contato',
                        icon: '',
                      },
                      {
                        value: 'deal',
                        label: 'Negócio',
                        icon: '',
                      },
                      {
                        value: 'company',
                        label: 'Empresa',
                        icon: '',
                      },
                    ]}
                  />
                </Box>

                {context === 'deal' && (
                  <Select
                    fullWidth
                    defaultValue={activity?.product_id?.toString()}
                    name="product_id"
                    control={control}
                    required
                    placeholder="Vincular um negócio"
                    label="Negócio"
                    onInputChange={(value) => setSearchDeal(value)}
                    options={
                      deals
                        ? deals.data.map((d) => ({
                            label: d.name,
                            value: d.id,
                          }))
                        : []
                    }
                  />
                )}

                {context === 'contact' && (
                  <Select
                    fullWidth
                    name="contact_id"
                    control={control}
                    required
                    placeholder="Vincular um contato"
                    label="Contato"
                    onInputChange={(value) => setSearchContact(value)}
                    options={contacts?.map((d) => ({
                      label: d.name,
                      value: d.id,
                    }))}
                  />
                )}

                {context === 'company' && (
                  <Select
                    fullWidth
                    name="company_id"
                    control={control}
                    required
                    placeholder="Vincular uma empresa"
                    label="Empresa"
                    onInputChange={(value) => setSearchCompany(value)}
                    options={companies?.map((d) => ({
                      label: d.name,
                      value: d.id,
                    }))}
                  />
                )}
              </Box>
            ) : null}

            <Input
              full
              label="Título da atividade"
              register={register}
              errors={errors}
              autoFocus
              name="title"
              placeholder='Ex: "Ligar para apresentar proposta"'
              validations={{
                required: true,
                minLength: 5,
              }}
            />

            <Datepicker
              label="Data da atividade"
              placeholder="Selecione uma data"
              required
              control={control}
              name="start"
              time
              fullWidth={true}
            />

            <Box>
              <Select
                label="Duração"
                name="duration"
                options={durationOptions}
                control={control}
                required
              />
            </Box>

            <RichTextWrap>
              <Label text="Comentário" />
              <RichText
                onChange={(e) => setDescriptionEditor(e)}
                value={descriptionEditor}
              />
            </RichTextWrap>

            <UploadFile setFiles={setFiles} files={files} />

            <input hidden type="submit" />
          </Box>
        </form>
      }
    />
  );
};

export default ScheduleActivityDialog;
