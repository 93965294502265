import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const IconTittle = styled.div`
  padding: 4px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.blue[50]};
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const Body = styled.div``;

export const ListWrap = styled.ul`
  display: grid;
  padding: 1rem;
  border: solid 1px ${({ theme }) => theme.colors.gray[30]};
  border-radius: 0.5rem;
  gap: 1rem;
`;

export const Item = styled.li`
  list-style: none;
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;

  > div > p {
    margin-left: 30px;
  }

  :last-child {
    align-items: flex-start;
  }

  :last-child > div > p {
    margin-left: 0;
  }
`;

export const ItemWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  > div > div {
    height: 20px;
  }

  & span {
    color: ${({ theme }) => theme.colors.gray[600]};
    font-weight: bold;
  }

  > div:last-child > p {
    white-space: break-spaces;
  }

  & button {
    background: none;
    padding: 2px;
    border-radius: 4px;

    :hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.gray[20]};
    }
  }
`;

export const ButtonWrap = styled.div`
  cursor: pointer;
`;
