import { RequestParamsListContacts } from 'domain/usecases/contact/list-contact-use-case';
import { createContext } from 'react';

interface ContactListProps {
  addContact: boolean;
  setAddContact: (value: boolean) => void;
  filterOpen: boolean;
  setFilterOpen: (value: boolean) => void;
  importDialogOpen: boolean;
  setImportDialogOpen: (value: boolean) => void;
  bulkContacts: boolean;
  setBulkContacts: (value: boolean) => void;
  bulkContactsTags: boolean;
  setBulkContactsTags: (value: boolean) => void;
  filter: RequestParamsListContacts;
  setFilter: (filter: RequestParamsListContacts) => void;
  initialFilter: RequestParamsListContacts;
  handlePageChange: (pageNumber: number) => void;
  activePage: number;
  totalItemsCount: number;
}

const ContactListContext = createContext<ContactListProps>(
  {} as ContactListProps
);

export default ContactListContext;
