import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Toolbar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
  height: auto;
  min-height: 72px;
  background-color: ${({ theme }) => theme.colors.gray.white};
  width: 100%;
  border: solid 1px ${({ theme }) => theme.colors.gray[30]};
  gap: 8px;
  overflow: hidden;
`;

export const WrapInputSearch = styled.div`
  max-width: 340px;
  width: 100%;

  > div {
    max-width: 340px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
export const DrawerForm = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
`;

export const WrapTrigger = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TriggerText = styled.span`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.blue.primary};
`;

export const LinkButton = styled.button`
  background: ${({ theme }) => theme.colors.blue[50]};
  color: ${({ theme }) => theme.colors.blue.primary};
  font-size: 0.75rem !important;
  font-weight: 700;
  border-radius: 20px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
`;

export const EmailWrap = styled.div`
  font-size: 0.75rem !important;
`;

export const PhoneWrap = styled.div`
  font-size: 0.75rem !important;
`;
export const LeftBox = styled.div``;
export const RightBox = styled.div`
  display: flex;
`;
