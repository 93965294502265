import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { UnexpectedError } from 'domain/errors';
import {
  RegisterRequestDTO,
  RegisterResponseDTO,
  RegisterUseCase,
} from 'domain/usecases/session/register-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteRegisterUseCase implements RegisterUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly toastService: ToastService
  ) {}

  async execute(data: RegisterRequestDTO): Promise<RegisterResponseDTO> {
    const { token, tenant } = useToken();

    const response = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: data,
      headers: {
        Authorization: `Bearer ${token}`,
        Tenant: tenant,
      },
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        this.toastService.show('Registrado com sucesso!', {
          type: 'success',
        });
        return {
          token: response.body.token,
          tenant_id: response.body.tenant_id,
        };

      case HttpStatusCode.conflict:
        this.toastService.show('Usuário já registrado!', {
          type: 'error',
        });
        throw new Error(
          'The email provided is already registered in the database'
        );

      default:
        this.toastService.show('Erro na requisição!', {
          type: 'error',
        });
        throw new UnexpectedError('Erro na requisição');
    }
  }
}
