import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ToastService } from 'data/protocols/notification/ToastService';
import { UnexpectedError } from 'domain/errors';
import {
  CreateFileDTO,
  CreateFileResponseDTO,
  SaveFileUseCase,
} from 'domain/usecases/file/save-file-use-case';
import { useToken } from 'main/hooks/usetoken';

export class RemoteSaveFileUseCase implements SaveFileUseCase {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient,
    private readonly toastService: ToastService
  ) {}

  async execute(data: CreateFileDTO): Promise<CreateFileResponseDTO> {
    const { token, tenant } = useToken();

    const formData = new FormData();
    formData.append('file', data.file);

    const response = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        Tenant: tenant,
        'Content-Type': 'application/json',
      },
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return {
          success: true,
          url: response.body?.url,
          message: response.body?.message,
        };

      default:
        this.toastService.show('Erro ao salvar arquivo!', {
          type: 'error',
        });
        throw new UnexpectedError('Erro ao salvar arquivo!');
    }
  }
}
