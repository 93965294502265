import React, { useContext, useEffect, useState } from 'react';
import { Box, Dialog, Select, Typography } from 'mino-ui';
import { useForm } from 'react-hook-form';
import DealContext from 'presentation/pages/Deal/contexts/DealContext';
import { makeBulkActionsDeals } from 'main/factories/usecases/deal/bulk-actions-deals-factory';
import { BulkActionsDealsRequest } from 'domain/usecases/deal/bulk-actions-deals-use-case';
import { useFunnels } from 'main/hooks/useFunnels';

interface BulkOwnerProps {
  ids: string[];
}

export enum IconNames {
  Save20Regular = 'Save20Regular',
}
export enum IconColors {
  white = 'white',
}

const BulkFunnels = (props: BulkOwnerProps) => {
  const { ids } = props;
  const { bulkFunnel, setBulkFunnel, fetchDeals } = useContext(DealContext);
  const { funnels } = useFunnels();
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, setValue, reset } =
    useForm<BulkActionsDealsRequest>();

  async function bulkActionsDeals(data: BulkActionsDealsRequest) {
    try {
      setLoading(true);
      await makeBulkActionsDeals().execute(data);
    } catch (error) {
      console.log(error);
    } finally {
      fetchDeals();
      reset();
      setLoading(false);
      setBulkFunnel(false);
    }
  }

  useEffect(() => {
    if (ids) {
      setValue('ids', ids);
    }
  }, [ids, setValue]);

  return (
    <Dialog
      title="Mudança de Etapa"
      open={bulkFunnel}
      onOpenChange={() => {
        setBulkFunnel(false);
        reset();
      }}
      onSubmit={handleSubmit(bulkActionsDeals)}
      submitIconName={IconNames.Save20Regular}
      submitIconColor={IconColors.white}
      loading={loading}
      content={
        <Box flexDirection="column" gap={24}>
          <Typography color="primary" variant="body" fontSize={14}>
            {ids.length} selecionados
          </Typography>
          <form onSubmit={handleSubmit(bulkActionsDeals)}>
            <Select
              fullWidth
              required
              name="funnel_id"
              control={control}
              placeholder="Selecione a etapa"
              label="Etapa"
              options={funnels.map((u) => ({
                label: u.name,
                value: u.id,
              }))}
            />
          </form>
          <input type="submit" hidden />
        </Box>
      }
    />
  );
};

export default BulkFunnels;
