import React, { ReactElement, useContext } from 'react';
import { Box, Button, Card, Typography } from 'mino-ui';
import * as S from './styles';
import { ReactSVG } from 'react-svg';
import Search from './icons/search.svg';
import Filter from './icons/filter.svg';
import Add from './icons/add.svg';
import ContactListContext from '../../context/ContactListContext';

const NotFoundStateContact = (): ReactElement => {
  const { setFilterOpen, setAddContact } = useContext(ContactListContext);

  return (
    <Card fill>
      <S.Container>
        <S.IconWrap>
          <ReactSVG src={Search} />
        </S.IconWrap>
        <Box flexDirection="column" gap={24} alignItems="center">
          <Typography
            variant="h4"
            fontSize={18}
            align="center"
            weight="bold"
            fill
            noWrap
          >
            Nenhum resultado encontrado
          </Typography>
          <Typography variant="body" align="center" noWrap fontSize={12}>
            Infelizmente não encontramos sua busca. Você poder editar o filtro
            clicando no botão abaixo ou adicionar um novo contato.
          </Typography>
          <S.ButtonWrap>
            <Button
              text="Editar filtro"
              size="medium"
              icon={Filter}
              onClick={() => setFilterOpen(true)}
              full
            />

            <Button
              text="Adicionar Contato"
              size="medium"
              variant="ghost"
              icon={Add}
              onClick={() => setAddContact(true)}
            />
          </S.ButtonWrap>
        </Box>
      </S.Container>
    </Card>
  );
};

export default NotFoundStateContact;
