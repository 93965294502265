import styled, { css } from 'styled-components';

interface TagsProps {
  color: string;
}

export const Container = styled.div<TagsProps>`
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  height: 26px;

  div > div {
    height: 24px;
  }

  ${({ color }) =>
    color == 'blue' &&
    css`
      background-color: ${({ theme }) => theme.colors.blue[50]};
      color: ${({ theme }) => theme.colors.blue[100]};

      div > div > svg {
        height: 24px;
        path {
          fill: ${({ theme }) => theme.colors.blue[100]};
        }
      }
    `}

  ${({ color }) =>
    color == 'orange' &&
    css`
      background-color: ${({ theme }) => theme.colors.orange[50]};
      color: ${({ theme }) => theme.colors.orange[100]};

      div > div > svg {
        height: 24px;
        path {
          fill: ${({ theme }) => theme.colors.orange[100]};
        }
      }
    `}

  ${({ color }) =>
    color == 'red' &&
    css`
      background-color: ${({ theme }) => theme.colors.red[50]};
      color: ${({ theme }) => theme.colors.red[100]};

      div > div > svg {
        height: 24px;
        path {
          fill: ${({ theme }) => theme.colors.red[100]};
        }
      }
    `}

  ${({ color }) =>
    color == 'yellow' &&
    css`
      background-color: ${({ theme }) => theme.colors.yellow[50]};
      color: ${({ theme }) => theme.colors.yellow.primary};

      div > div > svg {
        height: 24px;
        path {
          fill: ${({ theme }) => theme.colors.yellow.primary};
        }
      }
    `}

  ${({ color }) =>
    color == 'green' &&
    css`
      background-color: ${({ theme }) => theme.colors.green[50]};
      color: ${({ theme }) => theme.colors.green[100]};

      div > div > svg {
        height: 24px;
        path {
          fill: ${({ theme }) => theme.colors.green[100]};
        }
      }
    `}

  ${({ color }) =>
    color == 'gray' &&
    css`
      background-color: ${({ theme }) => theme.colors.gray[30]};
      color: ${({ theme }) => theme.colors.gray[200]};

      div > div > svg {
        height: 24px;
        path {
          fill: ${({ theme }) => theme.colors.gray[200]};
        }
      }
    `}

  ${({ color }) =>
    color == 'teal' &&
    css`
      background-color: ${({ theme }) => theme.colors.teal[50]};
      color: ${({ theme }) => theme.colors.teal[100]};

      div > div > svg {
        height: 24px;
        path {
          fill: ${({ theme }) => theme.colors.teal[100]};
        }
      }
    `}

  ${({ color }) =>
    color == 'violet' &&
    css`
      background-color: ${({ theme }) => theme.colors.violet[50]};
      color: ${({ theme }) => theme.colors.violet[100]};

      div > div > svg {
        height: 24px;
        path {
          fill: ${({ theme }) => theme.colors.violet[100]};
        }
      }
    `}
`;
export const ButtonWrap = styled.div`
  cursor: pointer;
`;
