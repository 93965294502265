import { RemoteDeleteContact } from 'data/usecases/contact/remote-delete-contact';
import { DeleteContactUseCase } from 'domain/usecases/contact/delete-contact-use-case';

import { AxiosProvider } from 'infra/http';
import { ToastifyProvier } from 'infra/notification/toastify/ToastifyProvider';

export const makeDeleteContactUseCase = (id: string): DeleteContactUseCase => {
  const axios = new AxiosProvider();
  const toastify = new ToastifyProvier();

  return new RemoteDeleteContact(
    axios,
    `${process.env.REACT_APP_API_BASE_URL}/contacts/${id}`,
    toastify
  );
};
